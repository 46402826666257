<template>
    <span v-if="modelValue && showTrue"
        :class="{ 'has-text-success': showColor }"
        class="icon">
        <span class="mdi mdi-check-bold"></span>
    </span>
    <span v-if="!modelValue && showFalse"
        :class="{ 'has-text-danger': showColor }"
        class="icon">
        <span class="mdi mdi-close-thick"></span>
    </span>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showFalse: {
            default: false
        },
        showTrue: {
            default: true
        },
        showColor: {
            default: false
        },
    },
}
</script>