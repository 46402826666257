<template></template>

<script>
import http from "@/http";

export default {
    async created() {
        let reference = this.$route.query.reference;

        let page = await http.get(`${process.env.VUE_APP_API_URL_V1}/pages/reference/`, { reference })
            .then(resp => resp.json());

        if (page) {
            this.$router.replace({ name: 'page-detail-id', params: { id: page.id } })
        } else {
            this.$router.replace({ name: 'page-index' });
        }
    }
}
</script>