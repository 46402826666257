<template>
    <nav class="breadcrumb is-transparent"
        aria-label="breadcrumbs"
        v-if="links">
        <ul>
            <li :key="link.name"
                v-for="link in links">
                <router-link :to="{ name: link.route, query: link.query, params: link.params }"
                    class="navbar-item">{{ link.name }}</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            links: [],
        }
    },
    mounted() {
        this.links = this.$route.meta.breadcrumbs;

        this.emitter.on('breadcrumbs', (links) => {
            if (links)
                this.links = links;
        })
    },
};
</script>

<style lang="sass" scoped>
.navbar
    background: transparent

.navbar-item
    color: #fafafa

.navbar-item:hover
    background: transparent
    color: #fafafa
</style>