import { splitFilepath } from "@/utils";
import BaseData from "@/core/models/BaseData";
import {
    AUTOCAD_BLOCK,
    AUTOCAD_TEMPLATE,
    CIVIL3D_GRAVITY_PIPE_PART,
    CIVIL3D_GRAVITY_STRUCTURE_PART,
    CIVIL3D_PRESSURE_PART,
    CIVIL3D_SUBASSEMBLY,
    FAMILY,
    FAMILY_TEMPLATE,
    INVENTOR_ASSEMBLY,
    INVENTOR_PART,
    MICROSTATION_CELL,
    REVIT_HOSTED_CONTENT,
    REVIT_TEMPLATE,
} from "../constants";

const defaultGrouper = (files) => {
    let filesMap = {};
    files.forEach(x => {
        let fileInfo = splitFilepath(x.name);
        if (filesMap[fileInfo.basename] == undefined)
            filesMap[fileInfo.basename] = {};
        filesMap[fileInfo.basename][fileInfo.ext] = x;
    });
    return filesMap;
}

const IPGrouper = (files) => {
    let filesMap = {};
    files.forEach(x => {
        let fileInfo = splitFilepath(x.name);
        if (fileInfo.basename.endsWith('_dim'))
            fileInfo.basename = fileInfo.basename.split(/_dim$/)[0];
        if (fileInfo.basename.endsWith('_200'))
            return;
        if (filesMap[fileInfo.basename] == undefined)
            filesMap[fileInfo.basename] = {};
        filesMap[fileInfo.basename][fileInfo.ext] = x;
    });
    return filesMap;
}

const IAGrouper = (files) => {
    return { inventor: files }
}

const IARemove = (files) => {
    return files.filter(x => x.name.endsWith('_200.jpg'));
}

const EXTENSIONS = {
    [AUTOCAD_BLOCK]: ['dwg'],
    [AUTOCAD_TEMPLATE]: ['dwt'],
    [CIVIL3D_GRAVITY_PIPE_PART]: ['zip'],
    [CIVIL3D_GRAVITY_STRUCTURE_PART]: ['zip'],
    [CIVIL3D_PRESSURE_PART]: ['zip'],
    [CIVIL3D_SUBASSEMBLY]: ['pkt'],
    [INVENTOR_ASSEMBLY]: ['iam', 'ipt', 'xml', 'jpg'],
    [INVENTOR_PART]: ['ipt', 'xml', 'jpg'],
    [MICROSTATION_CELL]: ['cel', 'dgn'],
    [FAMILY]: ['rfa', 'txt'],
    [FAMILY_TEMPLATE]: ['rft'],
    [REVIT_HOSTED_CONTENT]: ['rvt'],
    [REVIT_TEMPLATE]: ['rvt', 'rte'],
}

const CORE_EXTENSIONS = {
    [INVENTOR_ASSEMBLY]: ['iam'],
    [INVENTOR_PART]: ['ipt'],
    [FAMILY]: ['rfa'],
}

const GROUPER_FUNCTIONS = {
    [INVENTOR_ASSEMBLY]: IAGrouper,
    [INVENTOR_PART]: IPGrouper,
}

const REMOVE_FUNCTIONS = {
    [INVENTOR_PART]: IARemove,
}

class ContentType extends BaseData {
    constructor(data) {
        super(data);

        this.extensions = this.extensions || EXTENSIONS[this.code];
        this.coreExtensions = this.coreExtensions || CORE_EXTENSIONS[this.code];
        this.groupFiles = this.groupFiles || GROUPER_FUNCTIONS[this.code] || defaultGrouper;
        this.filesToRemove = this.filesToRemove || REMOVE_FUNCTIONS[this.code];
    }
}

export default ContentType;