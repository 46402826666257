<template>
    <span :class="classObject"
        class="tag">
        {{ text }}
    </span>
</template>

<script>
export default {
    props: {
        text: String,
        size: {
            type: String,
            default: 'medium',
            validator: (val) => ['small', 'medium', 'large'].includes(val),
        },
    },
    computed: {
        classObject() {
            return {
                'is-small': this.size == 'small',
                'is-medium': this.size == 'medium',
                'is-large': this.size == 'large'
            }
        }
    }
}
</script>
