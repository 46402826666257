<template>
    <router-link :to="route"
        :title="routeName"
        :class="{ button: showButton }">
        <span v-if="meta?.icon && showIcon"
            class="icon">
            <span :class="`mdi mdi-${meta.icon}`"></span>
        </span>
        <span>
            <slot>
                <template v-if="showName">
                    {{ routeName }}
                </template>
            </slot>
        </span>
    </router-link>
</template>

<script>
import router from "@/router";

export default {
    props: {
        route: {
            type: Object,
            required: true,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showButton: {
            default: true,
        },
        strip: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            meta: null,
        }
    },
    created() {
        this.meta = this.route.meta;
        for (let r of router.getRoutes()) {
            if (r.name == this.route.name) {
                this.meta = r.meta;
                break;
            }
        }
    },
    computed: {
        routeName() {
            let name = this.meta?.title || this.route.name;

            if (this.strip)
                name = name.replace(this.strip, "");

            return name;
        }
    },
}
</script>