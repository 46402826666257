<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedModel">
                <option :value="null">All models</option>
                <option v-for="model in models"
                    :value="model">{{ model.filename }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import http from "@/http";

export default {
    props: {
        modelValue: Object,
        project: Object
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedModel: null,
            models: [],
        }
    },
    async created() {
        await this.loadModels();
    },
    methods: {
        async loadModels() {
            if (!this.project) {
                this.models = [];
            } else {
                let data = await http.get(
                    `${process.env.VUE_APP_API_URL_V1}/bim/models/`,
                    { active: 'True', has_elements: 1, limit: 999, project_number: this.project.number })
                    .then(resp => resp.json());

                this.models = data.results;
            }
        }
    },
    watch: {
        selectedModel(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedModel = val;
        },
        project() {
            this.loadModels();
        }
    }
}
</script>