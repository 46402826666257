<template>
    <tr>
        <th>{{ section.name }}</th>
        <td>
            <table v-if="isTable"
                class="table">
                <thead>
                    <tr>
                        <th v-for="key in Object.keys(items[0])">
                            {{ renderKey(key) }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items">
                        <td v-for="(v, k) in item"
                            v-if="item">
                            <audit-summary-item :section="section.name"
                                :k="k"
                                :val="v">
                            </audit-summary-item>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ul v-else>
                <li v-for="item in items">
                    <audit-summary-item :section="section.name"
                        :k="null"
                        :val="item">
                    </audit-summary-item>
                </li>
            </ul>
        </td>
    </tr>
</template>

<script>
import _ from "lodash";
import AuditSummaryItem from "./AuditSummaryItem";

export default {
    props: ['section'],
    components: { AuditSummaryItem },
    data() {
        return {
            items: [],
        }
    },
    created() {
        if (Array.isArray(this.section.value))
            this.items = this.section.value;
        else
            this.items = [this.section.value];
    },
    methods: {
        renderKey(key) {
            return _.startCase(key.replace('data__', ''));
        }
    },
    computed: {
        isTable() {
            return this.items && this.items[0] && typeof this.items[0] == 'object';
        },
    }
}
</script>
