<template>
    <table v-if="data"
        class="table is-fullwidth">
        <thead>
            <tr>
                <th v-for="(header, index) in data.headers"
                    :key="index">{{ header }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, index) in data.data"
                :key="index">
                <td v-for="value in row"
                    :key="value">{{ value }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: ['data'],
};
</script>
