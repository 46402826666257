import http from '@/http';
import Project from './models/Project';

export default {
    namespaced: true,
    state: {
        projectNumbers: {},
        projectIds: {},
    },
    actions: {
        async loadProjectByNumber({ commit, state }, number) {
            if (!state.projectNumbers[number]) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/projects/`, { number })
                    .then(resp => resp.json());
                let projectData = data.results[0];
                commit('SET_PROJECT_BY_NUMBER', { data: { number, project: new Project(projectData) } });
            }
        },
        async loadProjectById({ commit, state }, id) {
            if (!state.projectIds[id]) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/projects/${id}/`)
                    .then(resp => resp.json());
                commit('SET_PROJECT_BY_ID', { data: { id, project: new Project(data) } });
            }
        },
    },
    mutations: {
        SET_PROJECT_BY_NUMBER: (state, { data }) => {
            state.projectNumbers[data.number] = data.project;
        },
        SET_PROJECT_BY_ID: (state, { data }) => {
            state.projectIds[data.id] = data.project;
        },
    },
    getters: {
        getProjectByNumber: state => (number) => state.projectNumbers[number],
        getProjectById: state => (id) => state.projectIds[id],
    }
}
