<template>
    <div v-if="item">
        <div class="is-pulled-right">
            <section-nav :routes="sectionRoutes" />
        </div>
        <h2 class="title">{{ item.filename }}</h2>
        <div class="columns">
            <div class="column is-half">
                <model-properties :model="item"></model-properties>
            </div>
        </div>

        <div class="box">
            <h4 class="is-size-5">By day</h4>
            <model-data :model="item"
                period="day"></model-data>
        </div>
        <div class="columns">
            <div class="column">
                <div class="box">
                    <h4 class="is-size-5">By month</h4>
                    <model-data :model="item"
                        period="month"></model-data>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <h4 class="is-size-5">By hour</h4>
                    <model-data :model="item"
                        period="hour"></model-data>
                </div>
            </div>
        </div>
        <div class="box">
            <h4 class="is-size-5">Events timeline</h4>
            <events-timeline :model="item"></events-timeline>
        </div>
    </div>
    <teleport to="body">
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </teleport>
</template>

<script>
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import ConfirmDialog from "@/core/components/ConfirmDialog";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import EventsTimeline from "@/stats/components/EventsTimeline.vue";
import { showMessage } from "@/utils";
import Model from "../models/Model";
import ModelData from "./ModelData";
import ModelProperties from "./ModelProperties";

export default {
    mixins: [BaseDetailComponent],
    components: {
        ConfirmDialog,
        EventsTimeline,
        ModelData,
        SectionNav,
        ModelProperties,
    },
    data() {
        return {
            sectionRoutes: [],
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/bim/models/${this.$route.params.id}/`,
            modelClass: Model,
        };
    },
    methods: {
        async resetModelInfoDate() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Reset Date?',
                message: `Are you sure you want to reset the last model information upload date?`,
            });

            if (!ok)
                return;

            let resp = await http.patch(this.detailUrl, { last_model_information_update: null });
            if (resp.status == 200) {
                this.item.lastModelInformationUpdate = null;
                showMessage("Last model information upload date reset")
            }
        },
        async setup() {
            await this.item.load();

            this.emitter.emit("breadcrumbs", [
                { route: 'home', name: 'Home' },
                { route: 'model-list', name: `${this.item.project.number} models`, query: { project: this.item.project.id } },
                { route: 'model-detail', name: this.item.filename, params: { id: this.item.id } },
            ]);

            this.sectionRoutes = [
                { name: 'project-detail', params: { id: this.item.project.id } },
                {
                    name: 'audit-element-list',
                    query: {
                        model: this.item.id,
                        project: this.item.project.id,
                        application: this.item.application
                    }
                },
                { name: 'journal-list', query: { model: this.item.id } },
                { name: 'model-events', query: { model: this.item.id } },
            ]
        },
    },
};
</script>