<template>
    <div class="control typology-select">
        <span class="control tags p-3"
            v-if="showResults">
            <span v-for="typology in selectedTypologies"
                class="tag">
                {{ typology }}
                <button class="delete is-small"
                    @click="removeTypology(typology)">
                </button>
            </span>
        </span>
        <ul class="typologies">
            <typology-node v-for="typology in typologies"
                :preselected="selectedTypologies.map(x => x.id)"
                :typology="typology"
                :handler="handleTypologySelect">
            </typology-node>
        </ul>
    </div>
</template>

<script>
import http from "@/http";
import ContentTypology from "../models/ContentTypology";
import TypologyNode from "./TypologyNode";

export default {
    props: {
        modelValue: Array,
        showResults: {
            default: true,
        }
    },
    emits: ['update:modelValue'],
    components: {
        TypologyNode
    },
    data() {
        return {
            typologies: [],
            selectedTypologies: [],
            recommended: [],
            multiSelect: true,
        }
    },
    async created() {
        this.selectedTypologies = this.modelValue || [];

        let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/typologies/`, { level: 0 })
            .then(resp => resp.json());

        this.typologies = data.results.map(x => new ContentTypology(x));
    },
    methods: {
        handleTypologySelect(t) {
            if (!this.multiSelect) {
                this.selectedTypologies.forEach(x => x.selected = false);
                this.selectedTypologies = [];
            }

            if (this.selectedTypologies.some(x => x.id == t.id)) {
                this.selectedTypologies = this.selectedTypologies.filter(x => x.id != t.id);
                t.selected = false;
            } else {
                this.selectedTypologies.push(t);
                t.selected = true;
            }
        },
        removeTypology(typology) {
            this.selectedTypologies = this.selectedTypologies.filter(x => !x == typology.id);
        },
    },
    computed: {
        result() {
            if (this.multiSelect)
                return this.selectedTypologies;
            else
                return this.selectedTypologies[0];
        }
    },
    watch: {
        selectedTypologies(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedTypologies = val;
        },
    },
}

</script>