const NON_DATA_FIELDS = ['name', 'creator', 'sub_type', 'duplicates'];

class SectionKey {
    constructor(name, value, negate, op) {
        this.name = name || '';
        this.negate = negate || false;
        this.op = op || '';
        this.value = value || '';
        this.dataKey = false;
        this.typeFunction = x => x.toString();
        this.error = '';
    }

    completedName() {
        let completed = '';

        if (this.negate)
            completed += '~';

        if (!NON_DATA_FIELDS.includes(this.name))
            completed += 'data__';

        if (this.op)
            completed += this.name + '__' + this.op;
        else
            completed += this.name;

        return completed;
    }

    isComplete() {
        return this.name;
    }

    completedValue() {
        try {
            let val = this.typeFunction(this.value);
            this.error = '';
            return val;
        } catch (error) {
            this.error = 'Cannot convert value';
        }
    }

    valueType() {
        return typeof this.value;
    }
}

export default SectionKey;
