import Login from './components/Login';
import LoginComplete from './components/LoginComplete';
import LoginFailed from './components/LoginFailed';
import Logout from './components/Logout';
import PasswordChange from './components/PasswordChange';
import PasswordReset from './components/PasswordReset';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import Registration from './components/Registration';
import RegistrationActivate from './components/RegistrationActivate';

let routes = [
    {
        path: '/login/',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ]
        }
    },
    {
        path: '/login-fail/',
        name: 'login-fail',
        component: LoginFailed,
        meta: {},
    },
    {
        path: '/login-complete/',
        name: 'login-complete',
        component: LoginComplete,
        meta: {},
    },
    {
        path: '/logout/',
        name: 'logout',
        component: Logout,
        meta: {
            title: 'Logged out',
        }
    },
    {
        path: '/register/',
        name: 'registration',
        component: Registration,
        meta: {
            title: "Registration",
        }
    },
    {
        path: '/register/activate/:token/',
        name: 'registration-activate',
        component: RegistrationActivate,
        meta: {
            title: "Activation",
        }
    },
    {
        path: '/password-reset/',
        name: 'password-reset',
        component: PasswordReset,
        meta: {
            title: 'Password Reset',
        }
    },
    {
        path: '/password-change/',
        name: 'password-change',
        component: PasswordChange,
        meta: {
            title: 'Password Change',
        }
    },
    {
        path: '/password-reset/confirm/:uid/:token/',
        name: 'password-reset-confirm',
        component: PasswordResetConfirm,
        meta: {
            title: 'Password Reset Confirm',
        }
    },
]

routes.forEach(x => x.meta.group = 'Auth');
export default routes;