<template>
    <div v-if="contentRequest">
        <h1 class="title">
            Family Request
        </h1>
        <table class="table">
            <tbody>
                <tr>
                    <th>Requested by:</th>
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: contentRequest.author.id } }"
                            v-if="contentRequest.author"
                            target="_blank">
                            {{ contentRequest.author.name }}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <th>Assigned to:</th>
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: contentRequest.creator.id } }"
                            v-if="contentRequest.creator"
                            target="_blank">
                            {{ contentRequest.creator.name }}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <th>Status:</th>
                    <td>{{ contentRequest.stateName }}</td>
                </tr>
                <tr>
                    <th>Library:</th>
                    <td>{{ contentRequest.library.title }}</td>
                </tr>
                <tr>
                    <th>Description:</th>
                    <td>{{ contentRequest.description }}</td>
                </tr>
                <tr v-if="contentRequest.attachments.length">
                    <th>Attachments:</th>
                    <td>
                        <ul>
                            <li v-for="attachment in contentRequest.attachments"
                                :key="attachment.id">
                                <a :href="attachment.url">
                                    <span class="icon">
                                        <span class="mdi mdi-file-outline"></span>
                                    </span>
                                    <span>
                                        {{ attachment.filename }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="buttons">
            <template v-if="contentRequest.state != 'C'">
                <button class="button"
                    v-if="!contentRequest.creator"
                    @click="assignCreator">
                    <span class="icon">
                        <span class="mdi mdi-plus-thick"></span>
                    </span>
                    <span>
                        Assign yourself
                    </span>
                </button>
                <button class="button"
                    v-if="user && contentRequest.creator && contentRequest.creator.id === user.id"
                    @click="removeCreator">
                    <span class="icon">
                        <span class="mdi mdi-minus-thick"></span>
                    </span>
                    <span>
                        Unassign yourself
                    </span>
                </button>
                <router-link :to="{ name: 'content-request-edit', params: { id: this.contentRequest.id } }"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-pencil"></span>
                    </span>
                    <span>
                        Edit
                    </span>
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import http from "@/http";

import ContentRequest from "../models/ContentRequest";
import User from "@/users/models/User";

export default {
    data() {
        return {
            contentRequest: null,
            user: null,
        };
    },
    async mounted() {
        let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/requests/${this.$route.params.id}/`)
            .then(resp => resp.json());
        this.contentRequest = new ContentRequest(data);

        let userData = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/current/`)
            .then(resp => resp.json());
        this.user = new User(userData);
    },
    methods: {
        async assigncreator() {
            let data = {
                creator: this.user.id,
                state: "A",
            };

            let resp = await http.patch(`${process.env.VUE_APP_API_URL_V2}/content/requests/${this.$route.params.id}/`, data);
            if (resp.status == 200) {
                let result = await resp.json();
                this.contentRequest.state = result.state;
                this.contentRequest.creator = this.user;
            }
        },
        async removecreator() {
            let data = {
                state: "N",
            };

            let resp = await http.patch(`${process.env.VUE_APP_API_URL_V2}/content/requests/${this.$route.params.id}/`, data);
            if (resp.status == 200) {
                let result = await resp.json();
                this.contentRequest.state = result.state;
                this.contentRequest.creator = null;
            }
        }
    },
}
</script>