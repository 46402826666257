import AuditAdd from './components/AuditAdd';
import AuditDetail from './components/AuditDetail';
import AuditElementList from './components/AuditElementList';
import AuditList from './components/AuditList';
import AuditRuleEdit from './components/AuditRuleEdit';
import AuditRuleList from './components/AuditRuleList';
import AuditSummaryDetail from './components/AuditSummaryDetail';
import AuditSummaryList from './components/AuditSummaryList';
import AuditTemplateEdit from './components/AuditTemplateEdit';
import AuditTemplateList from './components/AuditTemplateList';
import { PERMISSIONS } from '@/constants';


let routes = [
    {
        path: '/audits/',
        name: 'audit-list',
        component: AuditList,
        meta: {
            title: "Audits",
            icon: 'table-large',
            permissionsRequired: [PERMISSIONS.VIEW_AUDIT],
        }
    },
    {
        path: '/audits/:id/',
        name: 'audit-detail',
        component: AuditDetail,
        meta: {
            title: "Audit Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_AUDIT],
        }
    },
    {
        path: '/audits/add/',
        name: 'audit-add',
        component: AuditAdd,
        meta: {
            title: "Create Audit",
            permissionsRequired: [PERMISSIONS.VIEW_AUDIT],
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
            ],
        }
    },
    {
        path: '/audits/content/',
        name: 'audit-summary-list',
        component: AuditSummaryList,
        meta: {
            title: "Content Audit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
            ],
        },
        permissionsRequired: [PERMISSIONS.VIEW_AUDITSUMMARY],
    },
    {
        path: '/audits/summaries/:id/',
        name: 'audit-summary-detail',
        component: AuditSummaryDetail,
        meta: {
            title: "Audit Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
                { name: 'Audit Summaries', route: 'audit-summary-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_AUDITSUMMARY],
        }
    },
    {
        path: '/audits/rules/',
        name: 'audit-rule-list',
        component: AuditRuleList,
        meta: {
            title: "Audit Rules",
            icon: 'cogs',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_AUDITRULE],
        }
    },
    {
        path: '/audits/rules/add/',
        name: 'audit-rule-add',
        component: AuditRuleEdit,
        meta: {
            title: "Audit Rule Add",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
                { name: 'Audit rules', route: 'audit-rule-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_AUDITRULE],
        }
    },
    {
        path: '/audits/rules/:id/edit/',
        name: 'audit-rule-edit',
        component: AuditRuleEdit,
        meta: {
            title: "Audit Rule Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
                { name: 'Audit rules', route: 'audit-rule-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_AUDITRULE],
        }
    },
    {
        path: '/audits/templates/',
        name: 'audit-template-list',
        component: AuditTemplateList,
        meta: {
            title: "Audit Templates",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_AUDITTEMPLATE],
        }
    },
    {
        path: '/audits/templates/:id/',
        name: 'audit-template-edit',
        component: AuditTemplateEdit,
        meta: {
            title: "Audit Template Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
                { name: 'Audit templates', route: 'audit-template-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_AUDITTEMPLATE],
        }
    },
    {
        path: '/audits/templates/add/',
        name: 'audit-template-add',
        component: AuditTemplateEdit,
        meta: {
            title: "Audit Template Add",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
                { name: 'Audit templates', route: 'audit-template-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_AUDITTEMPLATE],
        }
    },
    {
        path: '/audits/elements/',
        name: 'audit-element-list',
        component: AuditElementList,
        meta: {
            title: "Audit Elements",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Audits', route: 'audit-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_AUDITELEMENT],
        }
    },
]

routes.forEach(x => x.meta.group = 'Audits');
export default routes;