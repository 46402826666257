<template>
    <div class="panel">
        <div class="panel-heading">
            <label class="label">Filename Formats</label>
        </div>
        <div v-for="fmt in formats"
            class="panel-block is-block"
            :class="{ disabled: !fmt.selected }">
            <div class="columns is-vcentered">
                <div v-if="fmt == selectedFormat"
                    class="column">
                    <content-filename-format-edit v-model="selectedFormat"
                        :content="exampleContent"
                        :show-preview="true"
                        :show-default="showDefault"
                        :extra-fields="dataFields">
                    </content-filename-format-edit>
                </div>
                <template v-else>
                    <div class="column is-narrow p-3">
                        <span style="display: inline-block; width: 5rem">
                            {{ fmt.application.name }}
                        </span>
                    </div>
                    <div v-if="showDefault && fmt.default"
                        class="column is-narrow"
                        :title="`This format is the default for ${fmt.application.name} content`">
                        <span class="icon has-text-success">
                            <span class="mdi mdi-check-decagram"></span>
                        </span>
                    </div>
                    <div class="column"
                        @dblclick="editFormat(fmt)">
                        <pre>{{ fmt.format }}</pre>
                    </div>
                </template>
                <div class="column is-narrow">
                    <template v-if="fmt == selectedFormat">
                        <a @click="loadExampleContent"
                            title="Reload example content item">
                            <span class="icon">
                                <span class="mdi mdi-refresh mdi-24px"
                                    :class="{ 'mdi-spin': loading }"></span>
                            </span>
                        </a>
                        <a @click="selectedFormat = null"
                            v-if="fmt.selected"
                            title="Finish editing format"
                            class="pl-3">
                            <span class="icon">
                                <span class="mdi mdi-check mdi-24px"></span>
                            </span>
                        </a>
                    </template>
                    <a v-else
                        @click="editFormat(fmt)"
                        title="Edit format"
                        class="pl-3">
                        <span class="icon">
                            <span class="mdi mdi-pencil-outline mdi-24px"></span>
                        </span>
                    </a>
                    <a @click="deleteFormat(fmt)"
                        title="Delete format"
                        class="pl-3">
                        <span class="icon">
                            <span class="mdi mdi-close mdi-24px"></span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div class="panel-block is-block is-clearfix">
            <a @click="addFilenameFormat"
                class="button">
                <span class="icon">
                    <span class="mdi mdi-plus"></span>
                </span>
                <span>
                    Add filename format
                </span>
            </a>
            <router-link :to="{ name: 'page-detail-slug', params: { slug: 'content-filenames' } }"
                target="_blank"
                title="What is this?"
                class="is-pulled-right">
                <span class="icon">
                    <span class="mdi mdi-help-circle mdi-24px"></span>
                </span>
            </router-link>
        </div>
        <div v-if="message?.text"
            class="panel-block">
            <div :class="{ 'has-text-danger': message.level == 'warning' }"
                v-html="message.text"
                class="is-italic">
            </div>
        </div>
    </div>
</template>

<script>
import { APPLICATIONS } from "@/constants";
import ContentFilenameFormatEdit from "@/content/components/ContentFilenameFormatEdit";
import ContentFilenameFormat from "@/content/models/ContentFilenameFormat";
import http from "@/http";
import { slugify } from "@/utils";
import { groupBy, random, uniq } from "lodash";
import Content from "../models/Content";
import ContentLibrary from "../models/ContentLibrary";

export default {
    props: {
        modelValue: Object,
        showDefault: Boolean,
        library: ContentLibrary,
    },
    emits: ['update:modelValue'],
    components: {
        ContentFilenameFormatEdit,
    },
    data() {
        return {
            formats: [],
            exampleContent: null,
            message: {},
            loading: false,
            selectedFormat: null,
        }
    },
    created() {
        this.formats = this.modelValue || [];
    },
    methods: {
        async loadExampleContent() {
            this.loading = true;

            let args = {};
            if (this.library)
                args.libraries = this.library.code;

            let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/`, args)
                .then(resp => resp.json());

            let index = random(1, 100);
            this.exampleContent = new Content(data.results[index]);

            this.loading = false;
            this.message = {
                level: 'info',
                text: `Example content <a target="_blank" href="${this.exampleContent.detailUrl}">${this.exampleContent.title}</a> loaded`,
            };
        },
        addFilenameFormat() {
            let ableToAdd = true;
            let applicationCodes = Object.keys(APPLICATIONS).filter(x => x != 'S');
            let usedApplications = uniq(this.formats.map(x => x.application.code));
            let unusedApplications = applicationCodes.filter(x => !usedApplications.includes(x));

            if (!this.showDefault && unusedApplications.length == 0) {
                this.message = { level: 'info', text: "All applications are used" };
                ableToAdd = false;
            }

            if (ableToAdd)
                this.formats.push(
                    new ContentFilenameFormat({
                        application: unusedApplications[0] || applicationCodes[0]
                    }));
        },
        editFormat(format) {
            if (!this.exampleContent)
                this.loadExampleContent();

            this.selectedFormat = format;
        },
        deleteFormat(format) {
            this.formats = this.formats.filter(x => x != format);
            this.message = { level: 'info', text: `${format} deleted` };
            this.checkFormats();
        },
        checkFormats() {
            if (!this.selectedFormat)
                return;

            this.message = null;

            let otherAppFormats = this.formats
                .filter(x => x.application.code == this.selectedFormat.application.code && x != this.selectedFormat);

            if (this.showDefault) {
                if (this.selectedFormat.default) {
                    otherAppFormats.forEach(x => x.default = false);
                } else {
                    if (!otherAppFormats.some(x => x.default))
                        otherAppFormats[0].default = true;
                }
            } else {
                this.selectedFormat.selected = otherAppFormats.filter(x => x.selected).length == 0;
                if (!this.selectedFormat.selected)
                    this.message = {
                        level: 'warning',
                        text: `More than one format exists for ${this.selectedFormat.application.name}`,
                    };
            }
        }
    },
    computed: {
        groupedFormats() {
            return groupBy(this.formats, x => x.application.code);
        },
        dataFields() {
            if (this.library?.dataFields)
                return this.library.dataFields.map(x => slugify(x).replace('-', '_'));
        }
    },
    watch: {
        modelValue(val) {
            this.formats = val;
        },
        formats: {
            handler() {
                this.checkFormats();
            },
            deep: true,
        }
    },
}
</script>