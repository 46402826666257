const OPS = [
    { key: '', abbrev: '=', label: 'equal to' },
    { key: 'iexact', abbrev: '=', label: 'equal to (case insensitive)' },
    { key: 'gt', abbrev: '>', label: 'greater than' },
    { key: 'lt', abbrev: '<', label: 'less than' },
    { key: 'gte', abbrev: '>=', label: 'greater than or equal' },
    { key: 'lte', abbrev: '<=', label: 'less than or equal' },
    { key: 'icontains', abbrev: 'contains', label: 'contains' },
    { key: 'isnull', abbrev: 'defined', label: 'defined' },
    { key: 'istartswith', abbrev: 'starts with', label: 'starts with' },
    { key: 'iendswith', abbrev: 'ends with', label: 'ends with' },
    { key: 'regex', abbrev: '~', label: 'matches' },
];

const FALSEY = ['f', 'false', 'no', 'n', '0'];
const TRUTHY = ['t', 'true', 'yes', 'y', '1'];

export { OPS, FALSEY, TRUTHY };