import BaseData from "@/core/models/BaseData";
import http from "@/http";

class ContentTypology extends BaseData {
    constructor(data) {
        super(data);
        this.tree = null;
        this.selected = false;
        this.children = [];
        this.isLeaf = (this.right - this.left) == 1;
    }

    async load() {
        if (this.tree)
            return;

        let data = await http.get(
            `${process.env.VUE_APP_API_URL_V2}/content/typologies/`,
            { level: 0, tree: this.treeId })
            .then(resp => resp.json());

        if (data.results.length == 1)
            this.tree = data.results[0];
    }

    toString() {
        let bits = [];

        if (this.tree)
            bits.push(this.tree.name);

        if (this.code)
            bits.push(this.code);

        if (this.name)
            bits.push(this.name);

        return bits.join(' - ');
    }

    get hasChildren() {
        return this.children?.length;
    }

}

export default ContentTypology;
