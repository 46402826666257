<template>
    <div>
        <code v-if="isCode && val">{{ val }}</code>
        <span v-if="isBool && val"
            class="has-text-success">
            <i class="mdi mdi-check-bold"></i>
        </span>
        <span v-if="isBool && !val"
            class="has-text-danger">
            <i class="mdi mdi-close-thick"></i>
        </span>
        <dl v-if="isObject">
            <template v-for="(v, k) in val">
                <dt>{{ _.startCase(k) }}</dt>
                <dd>{{ v }}</dd>
            </template>
        </dl>
        <span v-if="isNumber">{{ val.toLocaleString() }}</span>
        <span v-if="isString">{{ val }}</span>
    </div>
</template>

<script>
const CODE_KEYS = ['formula', 'guid'];

export default {
    props: ['section', 'k', 'val'],
    computed: {
        isCode() {
            return CODE_KEYS.includes(this.k);
        },
        isBool() {
            return typeof this.val == 'boolean';
        },
        isObject() {
            return typeof this.val == 'object';
        },
        isNumber() {
            return typeof this.val == 'number';
        },
        isString() {
            return typeof this.val == 'string' && !this.isCode;
        }
    }
}
</script>