<template>
    <div class="control">
        <div class="select">
            <select placeholder="All tools"
                v-model="selectedTool">
                <option :value="null"
                    v-if="showAll">All tools</option>
                <option v-for="tool in filteredTools"
                    :value="tool">{{ tool.description + '(' + tool.application + ')' }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showAll: Boolean,
        selectedApplication: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedTool: null,
        }
    },
    created() {
        this.selectedTool = this.modelValue;
        this.$store.dispatch("stats/loadToolsList");
    },
    computed: {
        tools() {
            return this.$store.getters["stats/getTools"];
        },
        filteredTools() {
            if (this.selectedApplication) {
                return this.tools.filter(tool => tool.application === this.selectedApplication.code);
            } else {
                return this.tools;
            }
        },
    },
    watch: {
        selectedTool(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedTool = val;
        }
    }
}
</script>