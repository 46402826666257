<template>
    <select-component v-model="selectedOffice"
        :show-all-text="showAllText"
        :choices="choices"></select-component>
</template>

<script>
import SelectComponent from './SelectComponent.vue';

export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        region: String,
    },
    emits: ['update:modelValue'],
    components: {
        SelectComponent,
    },
    data() {
        return {
            selectedOffice: null,
            offices: null,
        }
    },
    async created() {
        await this.$store.dispatch("core/loadOfficeList");
        this.offices = this.$store.getters["core/getOffices"];

        if (!this.modelValue)
            this.selectedOffice = null;
        else
            this.selectedOffice = this.offices.find(x => x.id == this.modelValue?.id);
    },
    computed: {
        choices() {
            if (!this.offices)
                return [];

            let filteredOffices = this.region
                ? this.offices.filter(x => x.region === this.region)
                : this.offices;

            return filteredOffices.map(x => [x, `${x.name} (${x.country})`]);
        },
        showAllText() {
            if (this.showNone)
                return 'No office';
            else if (this.showAll)
                return 'All offices';
        },
    },
    watch: {
        selectedOffice(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            if (val)
                this.selectedOffice = this.offices.find(x => x.id == val.id);
            else
                this.selectedOffice = null;
        }
    }
}
</script>