<template>
    <div v-if="contentSet">
        <h2 class="title"
            v-if="mode == 'edit'">Edit {{ contentSet.name }}
        </h2>
        <h2 class="title"
            v-else>Add Content Set
        </h2>
        <div class="subtitle">Content type: {{ contentSet.contentTypeName }}</div>
        <div class="field is-grouped">
            <div class="control">
                <input v-model="contentSet.name"
                    :disabled="nameIsLocked"
                    :title="nameIsLocked ? 'Name cannot be edited for this content type' : ''"
                    class="input"
                    placeholder="Content set name"
                    style="width: 25em;">
            </div>
            <save-button v-if="contentSet.hasChanged()"
                :saving="saving"
                @click="save"
                class="is-pulled-right"></save-button>
            <button v-if="userCanDelete"
                @click="deleteSet"
                class="button is-danger has-text-white">
                <span class="icon">
                    <span class="mdi mdi-delete"></span>
                </span>
                <span>
                    Delete Set
                </span>
            </button>
        </div>
        <div class="field">
            <label class="label">Associated projects:</label>
            <project-autocomplete-multiple v-model="contentSet.projects">
            </project-autocomplete-multiple>
        </div>
        <div class="columns">
            <div class="column is-half">
                <content-search-panel @selectItem="addContent"
                    :selected-items="contentSet.content"></content-search-panel>
            </div>
            <div class="column is-half">
                <div class="panel">
                    <div class="panel-heading">
                        {{ contentSet.content.length }}
                        Content Items Assigned to Set
                    </div>
                    <div class="panel-block-scrollbar">
                        <div class="panel-block is-justify-content-space-between is-clickable"
                            v-for="item in contentSet.content"
                            @dblclick="removeContent(item)">
                            <div class="media">
                                <div class="media-left">
                                    <figure class="image is-64x64">
                                        <img :src="item.thumbnail" />
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <router-link :to="{ name: 'content-detail', params: { id: item.id } }"
                                        target="_blank">{{ item.title }}</router-link>

                                </div>
                            </div>
                            <a class="pull-right"
                                @click="removeContent(item)">
                                <span class="icon">
                                    <span class="mdi mdi-close-thick mdi-24px"></span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <teleport to="body">
            <confirm-dialog ref="confirmDialog"></confirm-dialog>
        </teleport>
    </div>
</template>

<script>
import { PERMISSIONS } from "@/constants";
import ClearButton from "@/core/components/ClearButton";
import ConfirmDialog from "@/core/components/ConfirmDialog";
import SaveButton from "@/core/components/SaveButton";
import http from "@/http";
import ProjectAutocompleteMultiple from "@/projects/components/ProjectAutocompleteMultiple";
import { formatErrorMessage, showMessage } from "@/utils";
import {
    CIVIL3D_GRAVITY_PIPE_PART,
    CIVIL3D_GRAVITY_STRUCTURE_PART,
    CIVIL3D_PRESSURE_PART,
} from "../constants";
import ContentSet from "../models/ContentSet";
import ContentSearchPanel from "./ContentSearchPanel";

export default {
    components: {
        ClearButton,
        ConfirmDialog,
        ContentSearchPanel,
        SaveButton,
        ProjectAutocompleteMultiple,
    },
    data() {
        return {
            contentSet: null,
            mode: 'add',
            saving: false,
            loading: true,
            user: null,
        };
    },
    async mounted() {
        if (this.$route.params.id) {
            this.mode = 'edit'
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/sets/${this.$route.params.id}/`)
                .then(resp => resp.json());
            this.contentSet = new ContentSet(data);
        } else {
            this.contentSet = new ContentSet();
        }

        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;

        this.contentSet.setMark();
    },
    async beforeRouteLeave(to, from, next) {
        if (this.contentSet.hasChanged()) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Unsaved changes',
                message: `Are you sure you discard changes? `,
            });
            next(ok);
        } else {
            next(true);
        }
    },
    methods: {
        async deleteSet() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete Content Set',
                message: `Are you sure you want to delete ${this.contentSet.name}? `,
            });

            if (!ok)
                return

            this.contentSet.setMark();
            this.saving = true;

            if (this.contentSet.id) {
                let resp = await http.delete(`${process.env.VUE_APP_API_URL_V2}/content/sets/${this.contentSet.id}/`);
                if (resp.status == 204) {
                    showMessage("Content Set deleted");
                    this.$router.push({ name: 'content-sets' });
                } else {
                    showMessage("Content Set not deleted", 'danger');
                }
            } else {
                this.$router.push({ name: 'content-sets' });
            }

            this.saving = false;
        },
        async save() {
            if (!this.contentSet.name || this.contentSet.name.trim() === '') {
                showMessage("Set name is blank", 'danger');
                return;
            }

            if (this.contentSet.content.length == 0) {
                showMessage("Content Set must contain at least one item", 'danger');
                return;
            }

            this.contentSet.setMark();
            this.saving = true;

            const data = {
                name: this.contentSet.name,
                content: this.assignedIds,
            }

            if (this.contentSet.projects?.length)
                data.projects = this.contentSet.projects.map(x => x.id);

            if (this.contentSet.id) {
                let resp = await http.patch(`${process.env.VUE_APP_API_URL_V2}/content/sets/${this.contentSet.id}/`, data);
                if (resp.status == 200) {
                    this.contentSet.setMark();
                    showMessage(`Content set ${data.name} saved`);
                } else {
                    let data = await resp.json();
                    showMessage(formatErrorMessage(data), 'danger')
                }
            } else {
                let resp = await http.post(`${process.env.VUE_APP_API_URL_V2}/content/sets/`, data);
                if (resp.status == 201) {
                    let data = await resp.json();
                    this.$router.push({
                        name: 'content-sets-edit',
                        params: { id: data.id }
                    });
                    showMessage(`Content set ${data.name} successfully created`);
                } else if (resp.status == 400) {
                    let data = await resp.json();
                    showMessage(formatErrorMessage(data), 'danger')
                }
            }

            this.saving = false;
        },
        addContent(item) {
            if (this.assignedIds.includes(item.id)) {
                showMessage(`${item} is already part of this set`, 'danger');
                return;
            }

            this.contentSet.content.push(item);
        },
        removeContent(item) {
            this.contentSet.content = this.contentSet.content.filter(x => x.id != item.id);
        },
    },
    computed: {
        assignedIds() {
            if (this.contentSet)
                return this.contentSet.content.map(x => x.id);
        },
        userCanDelete() {
            return this.user?.hasPermission(PERMISSIONS.DELETE_CONTENTSET);
        },
        nameIsLocked() {
            return [
                CIVIL3D_GRAVITY_PIPE_PART,
                CIVIL3D_GRAVITY_STRUCTURE_PART,
                CIVIL3D_PRESSURE_PART,
            ].includes(this.contentSet.contentType);
        },
    },
    watch: {
        'filters.type': function () {
            this.filters.sub_type = null;
        }
    }
};
</script>
