<template>
    <div class="field">
        <label v-if="loading"
            class="label">Loading recommended tags&hellip;</label>
        <template v-if="!loading">
            <label class="label">
                {{ recommended.length }} recommended tag{{ recommended.length == 1 ? '' : 's' }}
            </label>
            <div class="control tags">
                <a @click="selectTag(tag)"
                    v-for="tag in recommended"
                    class="tag is-success">
                    {{ tag.name }}
                </a>
            </div>
        </template>
    </div>
</template>

<script>
import http from "@/http";
import Tag from "../models/Tag";

export default {
    props: {
        content: Object,
    },
    emits: ['selected'],
    data() {
        return {
            recommended: [],
            loading: false,
        }
    },
    mounted() {
        this.updateRecommendations();
    },
    methods: {
        selectTag(tag) {
            this.$emit('selected', tag);
        },
        async updateRecommendations() {
            let params = {
                typology: this.content.typologies[0].id,
                application: this.content.application.code,
                library: this.content.library.id,
                order_by: '-count',
                model: 'content.Content',
            }

            let res = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/tags-manage/`, params)
                .then(resp => resp.json());

            const contentTagNames = this.content.tags.map(y => y.name);

            this.recommended = res.results.filter(x => !contentTagNames.includes(x.name))
                .map(x => new Tag(x))
                .slice(0, 8);

            this.loading = false;
        },
    },
}
</script>