<template>
    <div class="control">
        <div class="select">
            <select v-model.number="selectedLimit"
                class="form-control">
                <option v-for="option in options"
                    :value="option">
                    Show {{ option }} items
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Number,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedLimit: null,
            options: [5, 10, 20, 50, 100],
        }
    },
    created() {
        this.selectedLimit = this.modelValue || 20;
    },
    watch: {
        selectedLimit() {
            this.$emit('update:modelValue', this.selectedLimit);
        },
        modelValue(val) {
            this.selectedLimit = val;
        }
    },
}
</script>