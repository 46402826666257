import http from '@/http';

export default {
    namespaced: true,
    state: {
        tools: [],
        models:[],
    },
    actions: {
        async loadToolsList({ commit, state }) {
            if (state.tools.length == 0) {
                let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/tools/`);
                commit('SET_TOOLLIST', { data: resp });
            } 
        },       
        async loadModelsList({ commit, state }) {
            if (state.models.length == 0) {
                let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/`);
                commit('SET_MODELLIST', { data: resp });
            } 
        },       
    },
    mutations: {
        SET_TOOLLIST: (state, { data }) => {
            state.tools = data;
        },
        SET_MODELLIST: (state, { data }) => {
            state.models = data;
        },
    },
    getters: {
        getTools: state => state.tools,
        getTools: state => state.models,
    }
}