import http from "@/http";
import PersistentObjectStore from "@/objectStore.js";
import store from "@/store";
import { cleanData } from "@/utils.js";
import _ from "lodash";

export default {
    data() {
        return {
            filters: {
                q: null,
                limit: null,
                offset: 0,
                order_by: null,
            },
            sortField: null,
            sortOrder: 'DESC',
            items: [],
            resultsCount: 0,
            user: null,
            loading: false,
            _filterState: {},
            _cache: {},
            _store: null,
            _abortController: null,
        }
    },
    async mounted() {
        this._store = new PersistentObjectStore(this.$route.href);
        this._cache.filters = Object.assign({}, this.filters);
        this.loadUrlArgs();
        this.loadState();

        await store.dispatch("users/loadCurrentUser");
        this.user = store.state.users.currentUser;

        await this.load();    
    },
    computed: {
        cleanedFilters() {
            // Default implementation, can be overriden in sub components
            return cleanData(this.filters);
        },
        hasPrev() {
            return this.filters.offset > 0;
        },
        hasNext() {
            return this.items.length > 0 && (this.resultsCount > this.filters.offset + this.items.length);
        },
        isFiltered() {
            const filteredFilters = { ...this.filters };
            delete filteredFilters.offset;

            const filteredCachedFilters = { ...this._cache.filters };
            delete filteredCachedFilters.offset;

            return this._cache.filters === undefined || !_.isEqual(filteredFilters, filteredCachedFilters);
        },
        pageTitle() {
            let start = this.filters.offset + 1;
            let end = this.filters.offset + this.items.length;
            let total = this.resultsCount || 0;

            if (end > total)
                end = total;

            if (end == 0)
                return 'Showing 0';
            else
                return `Showing ${start.toLocaleString()} to ${end.toLocaleString()} of ${total.toLocaleString()}`
        },
    },
    methods: {
        async load() {
            if (this._abortController)
                this._abortController.abort("Aborting previous request");

            this._abortController = new AbortController();
            await this.loadItems();
            this._abortController = null;
        },
        async loadItems() {
            this.loading = true;

            // Default implementation, can be overriden in sub components
            let resp = await http.get(
                this.listUrl,
                this.cleanedFilters,
                this._abortController?.signal);

            if (resp && resp.status == 200) {
                let data = await resp.json();

                this.resultsCount = data.count;
                if (typeof this.resultsCount != 'number')
                    this.resultsCount = this.resultsCount.value;

                this.items = data.results.map(x => new this.modelClass(x));
            }

            this.loading = false;
        },
        loadUrlArgs() {
            // Override in sub components
        },
        updateHistory(filters) {
            filters = filters || this.cleanedFilters;
            history.pushState({}, document.title, '#' + this.$route.path + '?' + new URLSearchParams(filters).toString());
        },
        clearFilters() {
            this.filters = Object.assign({}, this._cache.filters);
        },
        loadState() {
            this.filters = Object.assign(this.filters, this._store.get('filters'));
        },
        sortBy(field) {
            if (field == this.sortField) {
                this.sortOrder = this.sortOrder == 'DESC' ? 'ASC' : 'DESC';
            } else {
                this.sortField = field;
            }
            this.filters.order_by = this.sortOrder == 'DESC' ? this.sortField : `-${this.sortField}`
        },
        saveState() {
            if (this._store)
                this._store.set('filters', this.filters);
        },
        onPageChange(e) {
            if (e == 'previous')
                this.filters.offset -= this.filters.limit;
            else if (e == 'next')
                this.filters.offset += this.filters.limit;
        },
    },
    watch: {
        filters: {
            async handler() {
                await this.load();
                this.saveState();

                // Get state of filters minus the offset
                let currentFilterState = _.clone(this.filters);
                delete currentFilterState.offset;

                // If this filter state has changed then reset the offset
                if (!_.isEqual(currentFilterState, this._filterState))
                    this.filters.offset = null;

                // Save the filter state for the next time this is called
                this._filterState = currentFilterState;
            },
            deep: true
        }
    }
};
