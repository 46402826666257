<template>
    <div class="control">
        <autocomplete v-model="selectedProject"
            :placeholder="placeholder"
            :load-results="loadProjects"
            :result-namer="x => x.number + ' - ' + x.name"
            :result-getter="x => x.results">
        </autocomplete>
    </div>
</template>

<script>
import http from "@/http";

import Autocomplete from "@/core/components/Autocomplete.vue";

export default {
    props: {
        placeholder: {
            default: 'Search projects',
        },
        modelValue: Object,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            projects: [],
            selectedProject: null,
        }
    },
    created() {
        this.selectedProject = this.modelValue;
    },
    methods: {
        async loadProjects(q) {
            return await http.get(`${process.env.VUE_APP_API_URL_V1}/projects/?q=${q}`)
                .then(resp => resp.json());
        },
    },
    watch: {
        modelValue(val) {
            this.selectedProject = val;
        },
        selectedProject(val) {
            this.$emit('update:modelValue', val);
        }
    }
}
</script>