import { PERMISSIONS } from "@/constants";
import BaseData from "@/core/models/BaseData";
import http from "@/http";
import Office from "./Office";

const toolTypes = {
    AA: "AutoCAD addin",
    RA: "Revit addin",
    MA: "MicroStation addin",
    CL: "Content Lab",
    GL: "Global",
}

class ToolVersion extends BaseData {
    constructor(data) {
        super(data);
    }

    get typeName() {
        return toolTypes[this.type] || this.type;
    }
}


class User extends BaseData {
    constructor(data) {
        super(data);

        if (data?.revit_versions)
            this.revitVersions = Object.entries(data.revit_versions || {});

        if (data?.office)
            this.office = new Office(data.office);

        this.selected = false;
        this.comment = null;

        if (!this.name)
            this.name = this.fullName;

        // All regular users should have the is_authenticated permission
        if (this.permissions && !this.isSuperUser && !this.permissions.includes(PERMISSIONS.IS_AUTHENTICATED))
            this.permissions.push(PERMISSIONS.IS_AUTHENTICATED);

        if (this.toolVersions) {
            this.toolVersions = this.toolVersions.map(x => new ToolVersion(x));
            let toolOrder = ['GL', 'CL', 'AA', 'MA', 'RA'];
            this.toolVersions.sort((a, b) => toolOrder.indexOf(a.type) - toolOrder.indexOf(b.type));
        }

        this._loaded = false;
    }

    get isSuperUser() {
        return this.permissions.length == 1 && this.permissions[0] == "*";
    }

    get fullName() {
        return this.name || `${this.firstName} ${this.lastName}`;
    }

    get isIncomplete() {
        return !this.office || !this.discipline;
    }

    async load() {
        let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/${this.id}/`);
        if (resp.status == 200) {
            let data = await resp.json();
            this.groups = data.groups;
            this.permissions = data.permissions;

            if (this.permissions && !this.isSuperUser && !this.permissions.includes(PERMISSIONS.IS_AUTHENTICATED))
                this.permissions.push(PERMISSIONS.IS_AUTHENTICATED);

            this._loaded = true;
        }
    }

    hasPermission(permission) {
        return this.isSuperUser || this.permissions.includes(permission);
    }

    toString() {
        return this.fullName;
    }
}

export default (User);
