<template>
    <div>
        <section-nav :names="['permissions', 'notification-list']"
            class="is-pulled-right">
        </section-nav>
        <h2 class="title">Manage</h2>
        <div class="field is-grouped">
            <div class="control">
                <div class="select">
                    <select v-model="action">
                        <option :value="null"
                            disabled>Select action</option>
                        <option v-for="action in actions"
                            :value="action[0]">{{ action[1] }}</option>
                    </select>
                </div>
            </div>
            <content-library-select v-model="library"
                v-if="libraryRequired"
                :show-all="true"></content-library-select>

            <save-button @click="save"
                v-if="action && (!libraryRequired || library)">Submit</save-button>
        </div>
    </div>
</template>

<script>
import ContentLibrarySelect from '@/content/components/ContentLibrarySelect/';
import SaveButton from "@/core/components/SaveButton";
import http from '@/http';
import SectionNav from "@/core/components/SectionNav";

const CLEAR_CACHE = 'CC';
const UPDATE_INDEX = 'UI';
const REBUILD_TREE = 'RT';
const TASK_UPGRADE = 'TU';
const TASK_THUMBNAIL = 'TT';
const TASK_AUDIT = 'TA';

export default {
    components: {
        ContentLibrarySelect,
        SaveButton,
        SectionNav,
    },
    data() {
        return {
            action: null,
            actions: [
                [CLEAR_CACHE, 'Clear cache'],
                [UPDATE_INDEX, 'Update search index'],
                [REBUILD_TREE, 'Rebuild typology trees'],
                [TASK_UPGRADE, 'Generate content upgrade tasks'],
                [TASK_THUMBNAIL, 'Generate content thumbnail tasks'],
                [TASK_AUDIT, 'Generate content audit tasks'],
            ],
            library: null,
        }
    },
    methods: {
        async save() {
            let args = {
                action: this.action,
            }
            if (this.library)
                args.library = this.library.id;

            let data = await http.post(
                `${process.env.VUE_APP_API_URL_V1}/core/manage/`, args)
                .then(resp => resp.json());

            this.$store.dispatch(
                'core/setMessage', {
                text: data.message,
                type: data.success ? 'info' : 'danger',
            });
        }
    },
    computed: {
        libraryRequired() {
            return [TASK_UPGRADE, TASK_THUMBNAIL, TASK_AUDIT].includes(this.action);
        }
    }
}
</script>