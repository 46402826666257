
import BaseData from "@/core/models/BaseData";
import AuditRuleSectionKey from "./AuditRuleSectionKey";
import { OPS, FALSEY, TRUTHY } from '../constants';

const coerceVal = (val) => {
    if (typeof val == 'string') {
        if (FALSEY.includes(val.toLowerCase()))
            return false;
        else if (TRUTHY.includes(val.toLowerCase()))
            return true;
        else
            return val;
    } else {
        return val;
    }
}


const opDescriptions = {
    '': 'equals',
    'endswith': 'ends with',
    'iendswith': 'ends with',
    'istartwith': 'starts with',
    'regex': 'matches',
    'startwith': 'starts with',
}

class AuditRuleSection extends BaseData {
    constructor(data) {
        super(data);

        this.keys = [];
        if (data) {
            this.type = data.type;
            //delete data.type;

            const opKeys = OPS.map(x => x.key);

            Object.keys(data).forEach(key => {
                let val = data[key];
                let name = '';
                let negate = false;
                let dataKey = false;
                let op = null;

                if (key.startsWith('~')) {
                    key = key.substring(1, key.length);
                    negate = true;
                }

                let bits = key.split('__');

                if (bits[0] == 'data') {
                    dataKey = true;
                    bits.shift();
                }

                if (opKeys.includes(bits.at(-1)))
                    op = bits.pop();

                name = bits.join('__');

                let obj = new AuditRuleSectionKey(name, coerceVal(val), negate, op);
                this.keys.push(obj);
            })
        } else {
            this.type = '';
            this.keys.push(new AuditRuleSectionKey(null, null, null, null))
        }
    }

    isComplete() {
        return this.type && this.keys.filter(x => x.isComplete()).length > 0;
    }

    completedSection() {
        let completed = {
            type: this.type,
        };

        this.keys.filter(x => x.isComplete()).forEach(x => {
            completed[x.completedName()] = x.completedValue();
        });

        return completed;
    }
}

export default AuditRuleSection;
