import BaseData from "@/core/models/BaseData";
import _ from "lodash";

class Key {
    constructor(data) {
        this.visible = true;
        this.negate = false;
        this.op = null;
        this.value = '';
        this.subKey = null;
        Object.assign(this, data);
    }

    getFilter() {
        let filter = '';
        let prefix = 'data__';

        if (defaultKeys.includes(this.name))
            prefix = '';

        filter += prefix;
        filter += this.name;

        if (this.subKey)
            filter += '__' + this.subKey.replace(/[\W]+/g, '');

        if (this.op)
            filter += '__' + this.op;

        if (this.negate)
            filter = '~' + filter;

        return filter;
    }

    clear() {
        this.op = null;
        this.subKey = null;
        this.value = '';
    }

    getValue() {
        if (this.op == 'isnull')
            return true;
        else
            return this.value;
    }

    toString() {
        return _.startCase(this.name);
    }
}

class AuditElementType extends BaseData {
    constructor(data) {
        super(data);
        this.keys = (this.keys || []).map(x => new Key(x));
    }
}

export default AuditElementType;