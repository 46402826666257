import BaseData from "@/core/models/BaseData";

class Tag extends BaseData {
    constructor(data) {
        super(data);
        this.selected = false;
        this.deleted = false;
        this.editing = false;
    }

    get originalName() {
        return this._mark.name;
    }

    delete() {
        this.deleted = true;
        this.name = this._mark.name;
    }

    restore() {
        Object.assign(this, this._mark);
        this.setMark();
    }

    toString() {
        return this.name;
    }
}

export default Tag;
