<template>
    <h2 class="title">
        Help Pages
    </h2>
    <div class="mb-5">
        <div class="field is-grouped">
            <search-input v-model="q"
                placeholder="Search help"></search-input>
            <clear-button @click="q = ''"
                v-if="showSearchResults"></clear-button>
        </div>
    </div>
    <div v-if="showSearchResults">
        <div v-for="page in pages"
            class="search-result block">
            <router-link :to="{ name: 'page-detail-id', params: { id: page.id } }">
                <h4 class="is-size-5">
                    <span v-if="page.search.highlight?.title"
                        v-html="page.search.highlight.title"></span>
                    <span v-else>{{ page.title }}</span>
                </h4>
            </router-link>
            <div v-if="page.search.highlight?.body"
                v-html="page.search.highlight.body">
            </div>
            <div v-else
                v-html="page.description">
            </div>
        </div>
    </div>
    <div v-else>
        <div class="tree-panel is-pulled-left"
            v-for="page in rootPages">
            <div class="panel">
                <div class="panel-heading">
                    <router-link :to="{ name: 'page-detail-id', params: { id: page.id } }">
                        {{ page.title }}
                    </router-link><br>
                    <small>Last modified: {{ page.modified.fromNow() }}</small>
                </div>
                <div class="panel-block"
                    v-for="child in page.children">
                    <router-link :to="{ name: 'page-detail-id', params: { id: child.id } }">
                        {{ child.title }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import http from "@/http";
import Page from '../models/Page';
import SearchInput from '@/core/components/SearchInput';
import ClearButton from '@/core/components/ClearButton';

export default {
    components: {
        SearchInput,
        ClearButton,
    },
    data() {
        return {
            q: null,
            pages: [],
            rootPages: [],
            showSearchResults: false,
        }
    },
    async created() {
        await this.loadRootPages();
    },
    methods: {
        async loadRootPages() {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/pages/`, { level: 1 })
                .then(resp => resp.json());
            let pages = data.results.map(x => new Page(x));

            let pageMap = {};

            pages.forEach(x => {
                pageMap[x.id] = x;
            })

            pages.forEach(x => {
                if (x.parent && pageMap[x.parent])
                    pageMap[x.parent].children.push(x);
            });

            this.rootPages = _.sortBy(
                pages.filter(x => x.level == 0),
                [x => x.children.length > 1 ? 0 : 1, 'title']
            );
        },
        searchPages: _.debounce(async function () {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/pages/`, { q: this.q })
                .then(resp => resp.json());
            this.pages = data.results.map(x => new Page(x));
        }, 200),
    },
    watch: {
        q() {
            if (this.q.length > 3) {
                this.showSearchResults = true;
                this.searchPages();
            } else {
                this.showSearchResults = false;
            }
        }
    }
}
</script>

<style lang="sass">
.tree-panel
    margin-right: 2em
    margin-bottom: 2em
    min-width: 420px

.search-result
    em
        background: yellow
        padding: 2px
</style>