<template>
    <div v-if="rule">
        <a class="button is-danger is-pulled-right ml-2"
            @click="deleteRule"
            v-if="rule.id">
            Delete rule
        </a>
        <h2 class="title">{{ rule.name }}</h2>
        <div class="box">
            <FormKit type="form"
                @submit="save">
                <FormKit type="text"
                    label="Rule name"
                    validation="required"
                    v-model="rule.name">
                </FormKit>
                <FormKit type="select"
                    label="Rule type"
                    validation="required"
                    v-model="rule.type"
                    :options="{ C: 'Content', M: 'Model' }">
                </FormKit>
                <FormKit type="textarea"
                    label="Rule description"
                    v-model="rule.description">
                </FormKit>
                <FormKit type="text"
                    label="Tags"
                    v-model="rule.tags">
                </FormKit>
                <FormKit type="select"
                    label="Connector"
                    v-model="rule.connector"
                    v-if="rule.sections?.length > 1"
                    :options="{ AND: 'AND', OR: 'OR' }">
                </FormKit>
                <FormKit type="number"
                    label="Weight (default)"
                    help="Weighting of the rule when calculating the overall audit score"
                    v-model="rule.weight">
                </FormKit>
                <FormKit type="number"
                    label="Threshold (default)"
                    help="Number or percentage of elements at which pass or fail is determined"
                    v-model="rule.threshold">
                </FormKit>
                <FormKit type="checkbox"
                    label="Threshold is an absolute number"
                    v-model="rule.absolute_threshold">
                </FormKit>
                <FormKit type="select"
                    label="Polarity (default)"
                    :options="{ true: 'Positive', false: 'Negative' }"
                    v-model="rule.polarity"
                    help="Positive if the rule is to pass when the threshold is met, negative if it is to fail.">
                </FormKit>
                <div class="field">
                    <div class="help">
                        Note: fields marked as <strong>(default)</strong> can be overriden in the Audit Template.
                    </div>
                </div>
                <div class="field is-grouped is-multiline">
                    <div class="field">
                        <label class="label">Application</label>
                        <div class="field is-grouped">
                            <application-select v-model="rule.application">
                            </application-select>
                        </div>
                        <span class="help">
                            Associate this rule with an application.
                        </span>
                    </div>
                    <div class="field">
                        <label class="label">Project</label>
                        <div class="field is-grouped">
                            <project-autocomplete v-model="rule.project"
                                :placeholder="'Search projects'"
                                :load-results="loadProjects"
                                :result-namer="x => x.name">
                            </project-autocomplete>
                            <div class="control ml-3"
                                v-if="rule.project">
                                <span class="tag">
                                    {{ rule.project.name }}
                                    <button @click="rule.project = null"
                                        class="delete is-small"></button>
                                </span>
                            </div>
                        </div>
                        <span class="help">
                            Associate this rule with a project.
                        </span>
                    </div>
                </div>
                <div>
                    <div v-for="(section, i) in rule.sections">
                        <div class="box">
                            <div class="field is-grouped is-pulled-right">
                                <div class="control">
                                    <a class="button is-ghost"
                                        @click="rule.removeSection(section)">
                                        <span class="icon is-small">
                                            <span class="mdi mdi-minus-circle"></span>
                                        </span>
                                        <span>
                                            Remove section
                                        </span>
                                    </a>
                                </div>
                                <div class="control"></div>
                                <a class="button is-ghost"
                                    @click="rule.duplicateSection(section)"
                                    v-if="section.isComplete()">
                                    <span class="icon is-small">
                                        <span class="mdi mdi-content-copy"></span>
                                    </span>
                                    <span>
                                        Duplicate section
                                    </span>
                                </a>
                            </div>
                            <audit-rule-section-node :section="section"
                                :info="info">
                            </audit-rule-section-node>
                        </div>
                        <div v-if="rule.sections.length > 1 && i < rule.sections.length - 1"
                            class="has-text-centered mb-3">
                            {{ rule.connector }}
                        </div>
                    </div>
                </div>
                <div class="is-clearfix">
                    <a class="button is-ghost is-pulled-right"
                        @click="rule.addSection">
                        <span class="icon">
                            <span class="mdi mdi-plus-circle"></span>
                        </span>
                        <span>Add section</span>
                    </a>
                </div>
                <div v-if="summary"
                    class="notification"
                    :class="{ 'skeleton-block': summary.loading }">
                    <label class="label">Summary</label>
                    <p>{{ summary.text }}</p>
                </div>
            </FormKit>
        </div>

    </div>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import http from "@/http";
import ProjectAutocomplete from '@/projects/components/ProjectAutocomplete';
import { showMessage } from "@/utils";
import { FormKit } from "@formkit/vue";
import { FALSEY, OPS } from '../constants';
import AuditRule from "../models/AuditRule";
import AuditRuleSectionNode from "./AuditRuleSectionNode";
import { debounce } from "lodash";

const parseBool = (val) => {
    if (val) {
        if (typeof val == 'string')
            val = !FALSEY.includes(val.trim().toLowerCase());
        else
            val = Boolean(val);
    } else {
        val = false;
    }
    return val;
}

const parseString = (val) => val.toString();

const TYPE_FUNCTIONS = [
    { label: 'string', func: parseString },
    { label: 'number', func: parseFloat },
    { label: 'bool', func: parseBool },
    { label: 'object', func: JSON.parse },
];


export default {
    components: {
        ApplicationSelect,
        AuditRuleSectionNode,
        FormKit,
        ProjectAutocomplete,
    },
    data() {
        return {
            rule: null,
            summary: {
                text: null,
                loading: false,
            },
            showPreview: false,
            user: null,
            info: {
                types: [],
                ops: OPS,
                typeFunctions: TYPE_FUNCTIONS,
            },
        }
    },
    async created() {
        const ruleId = this.$route.params.id;
        let ruleData = { id: null, name: 'New Rule', type: 'M', project: null, application: 'R' };
        if (ruleId)
            ruleData = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/rules/${ruleId}/`)
                .then(resp => resp.json())

        this.rule = new AuditRule(ruleData);
        await this.rule.load();

        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
    },
    methods: {
        async deleteRule() {
            await http.delete(`${process.env.VUE_APP_API_URL_V1}/audits/rules/${this.rule.id}/`);
            this.$router.push({ name: 'audit-rule-list' });
        },
        async save() {
            if (this.rule.isComplete()) {
                let url = `${process.env.VUE_APP_API_URL_V1}/audits/rules/`;
                let httpMethod = http.post;

                if (this.rule.id) {
                    url += this.rule.id + '/';
                    httpMethod = http.patch;
                }

                let data = await httpMethod(url, this.completedRule)
                    .then(resp => resp.json());

                this.rule.id = data.id;
                this.$router.push({ name: 'audit-rule-list' });
            } else {
                showMessage("Rule is incomplete", 'danger');
            }
        },
        async loadTypes() {
            if (!this.rule?.application)
                return;

            await this.$store.dispatch("audits/loadElementTypes", this.rule.application?.code);
            this.info.types = this.$store.getters["audits/getElementTypes"](this.rule.application?.code);
        },
        loadSummary: debounce(async function () {
            this.summary.loading = true;
            let resp = await http.post(`${process.env.VUE_APP_API_URL_V1}/audits/rules/summary/`, this.completedRule.filters);
            this.summary.text = await resp.json();
            this.summary.loading = false;
        }, 200),
    },
    computed: {
        completedRule() {
            if (!this.rule)
                return;

            let rule = Object.assign({}, this.rule);
            rule.project = this.rule.project ? this.rule.project.id : null;
            rule.tags = (rule.tags || []).join(',');
            rule.application = rule.application.code;

            if (rule.creator)
                rule.creator = rule.creator.id;
            else if (this.user)
                rule.creator = this.user.id;

            let sections = this.rule.sections
                .filter(x => x.isComplete())
                .map(x => x.completedSection());
            if (this.rule.sections.length > 1) {
                rule.filters = {};
                rule.filters[this.rule.connector] = sections;
            } else {
                rule.filters = sections[0];
            }

            return rule;
        }
    },
    watch: {
        'rule.application': {
            handler() {
                this.loadTypes();
            }
        },
        completedRule: {
            handler() {
                this.loadSummary()
            },
            deep: true,
        }
    }
}
</script>


<style lang="sass" scoped>
.skeleton-block
    color: auto
</style>