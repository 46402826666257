import BaseData from "@/core/models/BaseData";
import { TYPES } from "@/stats/constants.js";

const SHORT_TYPE_NAMES = {
    DC: 'Closed',
    DM: 'Modified',
    DO: 'Opened',
    DS: 'Saved',
}

class StatsEvent extends BaseData {
    constructor(data) {
        super(data);
        this.data = this.data || {};
    }

    get typeName() {
        return TYPES[this.event][this.type] || this.type;
    }

    get shortTypeName() {
        return SHORT_TYPE_NAMES[this.type] || this.typeName;
    }
}

export default StatsEvent;