import BaseData from "@/core/models/BaseData";

class Attachment extends BaseData {
    constructor(data) {
        super(data);
        this.delete = false;
    }

    get filename() {
        return this.url.split('/').pop().split('?')[0];
    }
}

export default Attachment;

