<template>
    <div>
        <h2 class="title">
            {{ pageTitle }} Content Libraries
        </h2>
        <table class="table is-fullwidth mt-5">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Admins</th>
                    <th>Moderated</th>
                    <th>Content</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="library in items"
                    :key="library">
                    <td>
                        <router-link :to="{ name: 'content-library-detail', params: { id: library.id } }">
                            {{ library.title }}
                        </router-link>
                    </td>
                    <td>
                        {{ library.code }}
                    </td>
                    <td>
                        {{ library.description }}
                    </td>
                    <td>
                        <span v-for="admin in library.admins"
                            :key="admin"
                            class="username">
                            {{ admin.name }}<br>
                        </span>
                    </td>
                    <td>
                        <span v-if="library.enableWorkflows"
                            class="mdi mdi-check-bold mdi-18px">
                        </span>
                    </td>
                    <td>
                        {{ library.contentCount.toLocaleString() }}
                    </td>
                    <td>
                        <router-link :to="{ name: 'content-library-edit', params: { id: library.id } }">
                            <span class="mdi mdi-pencil mdi-18px"></span>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent.js";
import Pagination from "@/core/components/Pagination";
import ContentLibrary from "../models/ContentLibrary";

export default {
    mixins: [BaseListComponent],
    components: {
        Pagination,
    },
    data() {
        return {
            listUrl: `${process.env.VUE_APP_API_URL_V2}/content/libraries/`,
            modelClass: ContentLibrary,
        }
    },
}
</script>

<style lang="sass">
.username
    white-space: nowrap
</style>