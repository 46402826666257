import BaseData from "@/core/models/BaseData";
import { COUNTRIES } from "../../constants";

class Office extends BaseData {
    constructor(data) {
        super(data);
    }

    get countryName() {
        return COUNTRIES[this.country];
    }
}

export default (Office);
