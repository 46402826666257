<template>
    <div class="select">
        <select v-model="selectedFormat">
            <optgroup label="Default format"
                v-if="defaultFormats.length">
                <option v-for="format in defaultFormats"
                    :value="format">
                    {{ format.name || format.format }}
                </option>
            </optgroup>
            <optgroup label="Suggested formats"
                v-if="suggestedFormats.length">
                <option v-for="format in suggestedFormats"
                    :value="format">
                    {{ format.name || format.format }}
                </option>
            </optgroup>
            <optgroup label="Other applicable formats"
                v-if="otherFormats.length">
                <option v-for="format in otherFormats"
                    :value="format">
                    {{ format.name || format.format }}
                </option>
            </optgroup>
        </select>
    </div>
</template>

<script>
import http from "@/http";

export default {
    props: ['modelValue', 'application', 'library', 'type', 'discipline'],
    emits: ['update:modelValue'],
    data() {
        return {
            formats: [],
            selectedFormat: null,
        }
    },
    async created() {
        await this.loadFormats();
    },
    methods: {
        async loadFormats() {
            this.selectedFormat = this.modelValue;
            let params = {};

            if (this.application)
                params.application = this.application;

            if (this.library)
                params.library = this.library;

            if (this.discipline)
                params.discipline = this.discipline;

            this.formats = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/filename-formats/`, params)
                .then(resp => resp.json());

            if (this.selectedFormat)
                this.selectedFormat = this.formats.find(x => x.id == this.selectedFormat.id);
            else
                this.selectedFormat = this.formats.find(x => x.default);
        }
    },
    computed: {
        defaultFormats() {
            return this.formats.filter(x => x.default);
        },
        suggestedFormats() {
            return this.formats.filter(x => x.rank > 0 && !x.default);
        },
        otherFormats() {
            return this.formats.filter(x => x.rank == 0 && !x.default);
        },
    },
    watch: {
        selectedFormat() {
            this.$emit('update:modelValue', this.selectedFormat);
        },
        modelValue(val) {
            this.selectedFormat = val || this.formats[0];
        },
        application(val) {
            this.loadFormats();
        },
        library(val) {
            this.loadFormats();
        },
        type(val) {
            this.loadFormats();
        },
    }
}
</script>