<template>
    <transition name="fade">
        <div class="modal is-active"
            v-if="visible">
            <div class="modal-background"
                @click="close"></div>
            <div class="modal-card">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        close() {
            this.visible = false
        },
    },
}
</script>