<template>
    <h2 class="title">Stats Summary</h2>
    <div v-if="summary">
        <div class="box">
            <h4 class="is-size-5">Content Stats</h4>
            <base-chart :data="contentChartData"></base-chart>
            <div class="columns mt-3">
                <div v-for="stats in contentStats"
                    class="column is-third">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>{{ stats.label }}</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in stats.data">
                                <td>{{ row[0] }}</td>
                                <td>{{ row[1].toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="box">
            <span class="is-size-5 is-pulled-right">{{ summary.userStats.total }} total users</span>
            <h4 class="is-size-5">User Stats</h4>
            <base-chart :data="userChartData"></base-chart>
            <div class="columns mt-3">
                <div v-for="stats in userStats"
                    class="column is-third">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>{{ stats.label }}</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in stats.data">
                                <td>{{ row[0] }}</td>
                                <td>{{ row[1].toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="box">
            <h4 class="is-size-5">Search Index</h4>
            <table class="table is-fullwidth mt-3">
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Count</th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in summary.esStats.indices">
                        <td>{{ row[0] }}</td>
                        <td>{{ row[1].toLocaleString() }}</td>
                        <td>{{ fileSizeFormat(row[2]) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box">
            <h4 class="is-size-5">Tasks</h4>
            <table class="table is-fullwidth mt-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in summary.taskStats.count_by_task">
                        <td>{{ row.task__name }}</td>
                        <td>{{ row.count.toLocaleString() }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box">
            <h4 class="is-size-5">Database ({{ summary.dbTables.length }} longest tables)</h4>
            <p>Last change: {{ summary.databaseAge.fromNow() }}</p>
            <table class="table is-fullwidth mt-3">
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Table</th>
                        <th>Row Count</th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in summary.dbTables">
                        <td>{{ row.model }}</td>
                        <td>{{ row.table }}</td>
                        <td>{{ row.rows.toLocaleString() }}</td>
                        <td>{{ fileSizeFormat(row.size) }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3">Total (including all tables and indexes):</td>
                        <td>
                            <strong>
                                {{ fileSizeFormat(summary.totalSize) }}
                            </strong>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import { COLORS } from "@/constants";
import BaseChart from "@/core/components/BaseChart";
import http from "@/http";
import { fileSizeFormat } from "@/utils";
import StatsSummary from '../models/StatsSummary';

export default {
    components: {
        BaseChart,
    },
    data() {
        return {
            summary: null,
        }
    },
    async created() {
        let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/stats/summary/`)
            .then(resp => resp.json());
        this.summary = new StatsSummary(data);
    },
    computed: {
        userStats() {
            if (!this.summary)
                return [];

            return [
                { label: 'Office', data: this.summary.officeCounts },
                { label: 'Country', data: this.summary.countryCounts },
                { label: 'Discipline', data: this.summary.disciplineCounts },
            ]
        },
        contentStats() {
            if (!this.summary)
                return [];

            return [
                { label: 'Library', data: this.summary.contentStats.count_by_library },
                { label: 'Creator', data: this.summary.contentStats.count_by_creator.map(x => [`${x[0]} ${x[1]}`, x[2]]) },
            ]
        },
        contentChartData() {
            if (!this.summary)
                return;

            const data = this.summary.contentStats.count_by_month;
            const labels = data.labels;

            const createdData = {
                label: 'Created',
                data: data.data[0],
                type: 'bar',
                backgroundColor: COLORS.GREEN,
            }

            const eventsData = {
                label: 'Events',
                data: data.data[1],
                type: 'line',
                borderColor: COLORS.BLUE,
            }

            return {
                labels: labels,
                datasets: [createdData, eventsData],
            }
        },
        userChartData() {
            if (!this.summary)
                return;

            const data = this.summary.userStats;
            const labels = data.joined_labels;

            const joinedData = {
                label: 'Number of new users',
                data: data.joined_data,
                type: 'bar',
                backgroundColor: COLORS.GREEN,
            }

            return {
                labels: labels,
                datasets: [joinedData],
            }
        },
    },
    methods: {
        fileSizeFormat(bytes) {
            return fileSizeFormat(bytes);
        }
    }
}
</script>