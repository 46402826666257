<template>
    <div class="panel">
        <div class="panel-heading">
            <router-link class="is-pulled-right"
                title="Edit details"
                :to="{ name: 'model-edit', params: { id: model.id } }">
                <span class="mdi mdi-pencil"></span>
            </router-link>
            Details
        </div>
        <table class="table is-fullwidth">
            <tbody>
                <tr>
                    <th>Active:</th>
                    <td>{{ model.active ? 'True' : 'False' }}</td>
                </tr>
                <tr>
                    <th>Application:</th>
                    <td>{{ model.applicationName }}</td>
                </tr>
                <tr>
                    <th>Discipline:</th>
                    <td>{{ model.discipline }}</td>
                </tr>
                <tr>
                    <th>Project:</th>
                    <td>
                        <router-link v-if="model.project?.id"
                            :to="{ name: 'project-detail', params: { id: model.project?.id } }">
                            {{ model.project }}
                        </router-link>
                        <span v-else>{{ model.project }}</span>
                    </td>
                </tr>
                <tr>
                    <th>Filename:</th>
                    <td>{{ model.filename }}</td>
                </tr>
                <tr>
                    <th>Cloud ID:</th>
                    <td>
                        <code>{{ model.bim360Id }}</code>
                    </td>
                </tr>
                <tr>
                    <th>Cloud Project ID:</th>
                    <td>
                        <code>
                            {{ model.bim360ProjectId }}
                        </code>
                    </td>
                </tr>
                <tr>
                    <th>Version:</th>
                    <td>
                        {{ model.version }}
                    </td>
                </tr>
                <tr>
                    <th>Units:</th>
                    <td>{{ model.unitsName }}</td>
                </tr>
                <tr>
                    <th>File Size:</th>
                    <td>
                        <template v-if="model.filesize">
                            {{ (model.filesize / 1024 / 1024).toLocaleString() }} MB
                        </template>
                    </td>
                </tr>
                <tr>
                    <th>Elements:</th>
                    <td>
                        <template v-if="model.elements">{{ model.elements.toLocaleString() }}</template>
                    </td>
                </tr>
                <tr>
                    <th>Last Model Information Upload:</th>
                    <td>
                        {{ model.lastModelInformationUpdate?.fromNow() }}
                        <a v-if="model.lastModelInformationUpdate"
                            @click="resetModelInfoDate"
                            class="is-pulled-right">
                            <span class="icon">
                                <span class="mdi mdi-close"></span>
                            </span>
                            <span>Reset</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>Last Sync:</th>
                    <td>
                        {{ model.lastSync?.fromNow() }}
                    </td>
                </tr>
                <template v-if="model.application == 'R'">
                    <tr>
                        <th>Project base point:</th>
                        <td>
                            {{ formatPoint(model.projectBasePoint) }}
                        </td>
                    </tr>
                    <tr>
                        <th>Survey base point:</th>
                        <td>{{ formatPoint(model.surveyBasePoint) }}</td>
                    </tr>
                    <tr>
                        <th>Views:</th>
                        <td>{{ model.views?.toLocaleString() }}</td>
                    </tr>
                    <tr>
                        <th>Sheets:</th>
                        <td>{{ model.sheets?.toLocaleString() }}</td>
                    </tr>
                    <tr>
                        <th>Groups:</th>
                        <td>{{ model.groups?.toLocaleString() }}</td>
                    </tr>
                    <tr>
                        <th>Warnings:</th>
                        <td>{{ model.warnings?.toLocaleString() }}</td>
                    </tr>
                    <tr>
                        <th>Sync Average:</th>
                        <td>{{ model.syncDurationAvg }}</td>
                    </tr>
                    <tr>
                        <th>Open Average:</th>
                        <td>{{ model.openDurationAvg }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['model'],
    methods: {
        formatPoint(point) {
            if (!point)
                return point;
            else
                return point.join(', ');
        },
    }
}
</script>