import http from '@/http';
import Notification from './models/Notification';

export default {
    namespaced: true,
    state: {
        applications: [],
        disciplines: [],
        loading: false,
        offices: [],
        tags: {},
        message: null,
        snippets: {},
        notifications: [],
        toolVersions: [],
    },
    actions: {
        async loadDisciplineList({ commit, state }) {
            if (!state.disciplines.length) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/disciplines/`)
                    .then(resp => resp.json());
                commit('SET_DISCIPLINELIST', data);
            }
        },
        async loadOfficeList({ commit, state }) {
            if (!state.offices.length) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/offices/?limit=999`)
                    .then(resp => resp.json());
                commit('SET_OFFICELIST', data.results);
            }
        },
        async loadApplicationsList({ commit, state }) {
            if (!state.applications.length) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/applications/`)
                    .then(resp => resp.json());
                data = data.filter(x => x.code != 'S');
                commit('SET_APPLICATIONLIST', { data });
            }
        },
        async loadTagsList({ commit, state }, contentType) {
            if (!state.tags[contentType]) {
                let results = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/tags/`, { model: contentType })
                    .then(resp => resp.json());
                commit('SET_TAGS_LIST', { data: { tags: results, contentType: contentType } });
            }
        },
        async setMessage({ commit, state }, message) {
            commit('SET_MESSAGE', message);
        },
        async loadSnippet({ commit, state }, slug) {
            if (!state.snippets[slug]) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/snippets/${slug}/`)
                    .then(x => x.json());
                commit('SET_SNIPPET', { data });
            }
        },
        async loadNotifications({ commit, state }) {
            let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/notifications/`);
            if (resp.status != 200)
                return;

            let data = await resp.json();
            commit('SET_NOTIFICATIONS', { data: data.results.map(x => new Notification(x)) });
        },
        async loadToolVersions({ commit, state }) {
            if (!state.toolVersions.length) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/toolversions/`)
                    .then(x => x.json());
                commit('SET_TOOLVERSIONS', { data });
            }
        },
    },
    mutations: {
        SET_LOADING: (state, val) => {
            state.loading = val;
        },
        SET_DISCIPLINELIST: (state, val) => {
            state.disciplines = val;
        },
        SET_OFFICELIST: (state, val) => {
            state.offices = val;
        },
        SET_APPLICATIONLIST: (state, { data }) => {
            state.applications = data;
        },
        SET_TAGS_LIST: (state, { data }) => {
            state.tags[data.contentType] = data.tags;
        },
        SET_MESSAGE: (state, val) => {
            state.message = val;
        },
        SET_SNIPPET: (state, { data }) => {
            state.snippets[data.slug] = data;
        },
        SET_NOTIFICATIONS: (state, { data }) => {
            state.notifications = data;
        },
        SET_TOOLVERSIONS: (state, { data }) => {
            state.toolVersions = data;
        },
    },
    getters: {
        getDisciplines: state => state.disciplines,
        getLoading: state => state.loading,
        getOffices: state => state.offices,
        getApplications: state => state.applications,
        getApplicationByCode: state => (code) => state.applications.find(x => x.code == code),
        getDisciplineById: state => (id) => state.disciplines.find(x => x.id == id),
        getDisciplineByName: state => (name) => state.disciplines.find(x => x.name == name),
        getTagsByContentType: state => (contentType) => state.tags[contentType] || [],
        getMessage: state => state.message,
        getSnippet: state => (slug) => state.snippets[slug],
        getNotifications: state => state.notifications,
        getNotificationById: state => (id) => state.notifications.find(x => x.id == id),
        getToolVersions: state => state.toolVersions,
    }
}
