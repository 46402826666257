<template>
    <div class="dropdown autocomplete"
        :class="{ 'is-active': showResults }">
        <search-input :placeholder="placeholder"
            v-model="q"
            v-on:keyup="onKeyup"
            :debounce-input="false"></search-input>
        <div class="dropdown-menu"
            v-if="showResults && results && results.length">
            <div class="dropdown-content">
                <div class="dropdown-scrollbar">
                    <div v-for="(r, i) in results"
                        class="dropdown-item"
                        :class="{ selected: i == index }">
                        <a @click="selectResult(i)">{{ resultNamer(r) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchInput from "@/core/components/SearchInput.vue";

export default {
    props: {
        modelValue: Object,
        placeholder: String,
        loadResults: Function,
        initialValue: String,
        resultNamer: {
            type: Function,
            default: x => x.toString(),
        },
        resultGetter: {
            type: Function,
            default: x => x,
        },
        resultMaxLength: {
            type: Number,
            default: 10,
        },
    },
    components: {
        SearchInput,
    },
    emits: ['update:modelValue', 'keyup', 'selected'],
    data() {
        return {
            q: this.initialValue || '',
            selectedResult: null,
            results: [],
            index: 0,
        }
    },
    methods: {
        selectResult(i) {
            this.selectedResult = this.results[i];
            this.q = this.resultNamer(this.selectedResult);
            this.results = [];
            this.index = 0;
        },
        onKeyup(e) {
            switch (e.key) {
                case 'ArrowUp':
                    if (this.index > 0)
                        this.index--;
                    break;
                case 'ArrowDown':
                    if (this.index < (this.results.length - 1))
                        this.index++;
                    break;
                case 'Enter':
                    this.selectResult(this.index);
                    break;
                default:
                    if (this.q && this.q.length > 2) {
                        this.index = 0;
                        this.loadResults(this.q)
                            .then(x => {
                                let maxLength = this.resultMaxLength;

                                if (this.resultGetter)
                                    this.results = this.resultGetter(x)
                                else
                                    this.results = x;

                                this.results = this.results.slice(0, maxLength);
                            });
                    } else {
                        this.results = [];
                    }
            }

            this.$emit('keyup', this.q);
        }
    },
    computed: {
        showResults() {
            if (this.selectedResult && this.results.length == 1)
                return false;
            else if (!this.q)
                return false;
            else
                return true;
        },
    },
    watch: {
        modelValue(val) {
            if (val) {
                this.q = this.resultNamer(val);
            } else {
                this.q = null;
                this.$emit('update:modelValue', null);
            }
        },
        selectedResult(val) {
            this.$emit('selected', val);
            this.$emit('update:modelValue', val);
        },
        q(val) {
            if (!val) {
                this.selectedResult = null;
                this.$emit('update:modelValue', null);
            }
        }
    }
}
</script>
