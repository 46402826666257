<template>
    <div class="panel">
        <div class="panel-heading">
            Content Items
        </div>
        <div class="panel-block is-block">
            <div class="field is-grouped is-grouped-multiline">
                <search-input v-model="filters.q"
                    placeholder="Search content" />
                <content-library-select v-model="filters.libraries"
                    :show-all="true">
                </content-library-select>
            </div>
            <div class="field is-grouped is-grouped-multiline">
                <application-select v-model="filters.application"
                    :show-all="true">
                </application-select>
                <content-type-select v-if="filters.application?.code"
                    v-model="filters.type"
                    :application="filters.application?.code"
                    :show-all="true">
                </content-type-select>
                <content-sub-type-select v-if="filters.application?.code && filters.type"
                    v-model="filters.sub_type"
                    :type="filters.type"
                    :show-all="true">
                </content-sub-type-select>
            </div>
            <div class="field is-grouped is-grouped-multiline">
                <units-select v-model="filters.units"
                    :show-all="true">
                </units-select>
                <discipline-select v-model="filters.discipline"
                    :show-all="true">
                </discipline-select>
                <content-set-select v-model="filters.sets"
                    :show-all="true">
                </content-set-select>
                <tags-autocomplete v-model="filters.tags"></tags-autocomplete>
            </div>
            <div class="field is-grouped is-grouped-multiline">
                <limit-select v-model="filters.limit"></limit-select>
                <clear-button v-if="isFiltered"
                    @click="clearFilters">
                </clear-button>
            </div>
        </div>
        <spinner :active="loading"
            size="large"
            type="cog"></spinner>
        <div v-if="!loading"
            class="panel-block-scrollbar">
            <div v-for="item in items"
                @dblclick="selectItem(item)"
                class="panel-block is-justify-content-space-between is-clickable">
                <div class="media">
                    <div class="media-left">
                        <figure class="image is-64x64">
                            <img :src="item.thumbnail" />
                        </figure>
                    </div>
                    <div class="media-content">
                        <router-link :to="{ name: 'content-detail', params: { id: item.id } }"
                            target="_blank">
                            <span v-html="item.title"></span>
                        </router-link>
                        <div v-if="item.tags.length"
                            class="tags">
                            <span v-for="tag in item.tags"
                                :class="{ 'is-success': filters.tags.map(x => x.name).includes(tag.name) }"
                                class="tag">{{ tag.name }}</span>
                        </div>
                    </div>
                </div>
                <span v-if="isSelected(item)">
                    <span class="icon">
                        <span class="mdi mdi-check-bold mdi-24px"></span>
                    </span>
                </span>
                <a v-else
                    @click="selectItem(item)"
                    class="pull-right">
                    <span class="icon">
                        <span class="mdi mdi-arrow-right-thick mdi-24px"></span>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import LimitSelect from "@/core/components/LimitSelect";
import SearchInput from "@/core/components/SearchInput";
import Spinner from "@/core/components/Spinner";
import TagsAutocomplete from "@/core/components/TagsAutocomplete";
import UnitsSelect from "@/core/components/UnitsSelect";
import { cleanData } from "@/utils";
import Content from "../models/Content";
import ContentGroupSelect from "./ContentGroupSelect";
import ContentSetSelect from "./ContentSetSelect";
import ContentSubTypeSelect from "./ContentSubTypeSelect";
import ContentTypeSelect from "./ContentTypeSelect";

export default {
    props: {
        selectedItems: Array,
    },
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ClearButton,
        ContentGroupSelect,
        ContentLibrarySelect,
        ContentSetSelect,
        ContentSubTypeSelect,
        ContentTypeSelect,
        DisciplineSelect,
        LimitSelect,
        SearchInput,
        Spinner,
        TagsAutocomplete,
        UnitsSelect,
    },
    data() {
        return {
            filters: {
                application: null,
                discipline: null,
                libraries: null,
                limit: 20,
                q: "",
                sets: null,
                sub_type: null,
                tags: [],
                type: null,
                units: null,
            },
            listUrl: `${process.env.VUE_APP_API_URL_V2}/content/`,
            modelClass: Content,
        };
    },
    methods: {
        isSelected(item) {
            return this.selectedItems.map(x => x.id).includes(item.id);
        },
        selectItem(item) {
            this.$emit('selectItem', item);
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;
            if (filters.libraries)
                filters.libraries = filters.libraries.code;
            if (filters.discipline)
                filters.discipline = filters.discipline.name;
            if (filters.sets)
                filters.sets = filters.sets.id;
            if (filters.type)
                filters.type = filters.type.code;
            if (filters.tags)
                filters.tags = filters.tags.join(',');

            return filters;
        },
    },
    watch: {
        'filters.type': function () {
            this.filters.sub_type = null;
        }
    }
};
</script>

<style lang="sass">
.panel-block-scrollbar 
    height: auto
    max-height: 600px
    overflow-x: hidden

.media-content
    a 
        em
            background-color: yellow
            padding: 1px

.tag.is-success
    color: #fff
</style>