<template>
    <h2 class="title">Registration Activation</h2>
</template>

<script>
import { showMessage } from '@/utils';

export default {
    async created() {
        const key = this.$route.params.token;

        let resp = await fetch(`${process.env.VUE_APP_API_URL_V1}/auth/registration/activate/`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ key })
        });

        if (resp.status == 201) {
            this.$router.push({ name: 'login' });
            showMessage("Registration successful");
        } else {
            showMessage("Registration activation failed", 'danger');
        }
    }
}
</script>