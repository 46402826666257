<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedRegion">
                <option :value="null">All regions</option>
                <option v-for="region in regions">{{ region }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            regions: ['AMER', 'APME', 'EURO'],
            selectedRegion: null,
        }
    },
    created() {
        this.selectedRegion = this.modelValue || null;
    },
    watch: {
        selectedRegion() {
            this.$emit('update:modelValue', this.selectedRegion);
        },
        modelValue(val) {
            this.selectedRegion = val;
        }
    },
}
</script>