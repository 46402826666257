<template>
    <div class="dropdown is-hoverable">
        <div class="dropdown-trigger">
            <button class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu">
                <span>Showing {{ types.length }} types</span>
                <span class="icon is-small">
                    <i class="mdi mdi-chevron-down"
                        aria-hidden="true"></i>
                </span>
            </button>
        </div>
        <div class="dropdown-menu"
            id="dropdown-menu"
            role="menu">
            <div class="dropdown-content">
                <div class="dropdown-item">
                    <select-component v-model="types"
                        :choices="allTypes"
                        :multiple="true"></select-component>
                </div>
            </div>
        </div>
    </div>
    <spinner :active="loading"
        type="cog"
        size="large"></spinner>
    <div v-if="!loading">
        <div class="columns my-3">
            <div class="column is-narrow">
                <a @click="pageChange('previous')"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-chevron-left"></span>
                    </span>
                </a>
            </div>
            <div v-for="group in groupedEvents"
                class="column date-column">
                <label class="label">{{ group[0] }}</label>
                <div v-if="group[1].length"
                    class="tags">
                    <span v-for="event in group[1]"
                        class="tag">
                        {{ event.shortTypeName }}:
                        {{ event.date.format('h:ma') }}
                        <user-avatar v-if="event.user"
                            :user="event.user"
                            size="small"
                            class="ml-2"></user-avatar>
                    </span>
                </div>
                <small v-else>No events</small>
            </div>
            <div class="column is-narrow">
                <a v-if="showNext"
                    @click="pageChange('next')"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-chevron-right"></span>
                    </span>
                </a>
            </div>
        </div>
    </div>
    <a v-if="showLastEventButton"
        @click="setEndDateToLastEvent"
        class="button">
        <span class="icon">
            <span class="mdi mdi-chevron-double-left"></span>
        </span>
        <span>
            Last event
        </span>
    </a>
</template>

<script>
import Pagination from "@/core/components/Pagination.vue";
import SelectComponent from "@/core/components/SelectComponent.vue";
import Spinner from "@/core/components/Spinner.vue";
import http from "@/http";
import UserAvatar from "@/users/components/UserAvatar.vue";
import { getDateRange } from "@/utils.js";
import { groupBy } from "lodash";
import moment from "moment";
import { TYPES } from "../constants.js";
import StatsEvent from "../models/StatsEvent.js";

export default {
    props: ['model'],
    components: { UserAvatar, Spinner, Pagination, SelectComponent },
    data() {
        return {
            events: [],
            start: null,
            end: null,
            types: ['DO', 'DC', 'AC', 'YE'],
            numberOfPeriods: 6,
            periodFormat: 'ddd, MMM D',
            loading: false,
            allTypes: Object.entries(TYPES.Model),
            showLastEventButton: false,
        }
    },
    async created() {
        this.end = moment().add(1, 'days');
        this.start = moment().subtract(this.numberOfPeriods, 'days');
        await this.loadEvents();

        if (this.events.length == 0)
            this.showLastEventButton = true;
    },
    computed: {
        showNext() {
            return !this.end.isAfter(moment().subtract(1, 'days'));
        },
        dateRange() {
            let range = {};
            getDateRange(this.start, this.end, 'days').forEach(x => {
                range[x.format(this.periodFormat)] = [];
            });
            return range;
        },
        filteredEvents() {
            return this.events.filter(x => this.types.includes(x.type));
        },
        groupedEvents() {
            let grouped = groupBy(this.filteredEvents, x => x.date.format(this.periodFormat));
            let result = {};

            Object.assign(result, this.dateRange);
            Object.assign(result, grouped)

            return Object.entries(result);
        }
    },
    methods: {
        pageChange(e) {
            let end = moment(this.end);
            let start = moment(this.start);

            if (e == 'previous') {
                end.subtract(this.numberOfPeriods, 'days');
                start.subtract(this.numberOfPeriods, 'days');
            } else if (e == 'next') {
                end.add(this.numberOfPeriods, 'days');
                start.add(this.numberOfPeriods, 'days');
            }

            this.end = moment(end);
            this.start = moment(start);
        },
        async loadEvents() {
            this.loading = true;
            this.events = [];

            let args = {
                model: this.model.id,
                start: this.start.format('YYYY-MM-DD'),
                end: this.end.format('YYYY-MM-DD'),
            };

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/stats/combined/`, args)
                .then(resp => resp.json());
            this.events = data.results.map(x => new StatsEvent(x));

            this.loading = false;
        },
        async setEndDateToLastEvent() {
            let args = {
                model: this.model.id,
                limit: 1,
            };

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/stats/combined/`, args)
                .then(resp => resp.json());

            if (data.results.length == 0)
                return;

            this.end = new StatsEvent(data.results[0]).date;
            this.start = moment(this.end).subtract(this.numberOfPeriods, 'days');

            this.loadEvents();
        }
    },
    watch: {
        async dateRange() {
            await this.loadEvents();
        },
    }
}
</script>

<style lang="sass" scoped>
.date-column
    border: 1px solid #ccc
    margin-right: 0.5em
    max-height: 400px
    overflow-y: auto
</style>