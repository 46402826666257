<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedCreator">
                <option :value="null"
                    v-if="showAll">All creators</option>
                <option :value="null"
                    v-if="showNone">No creator</option>
                <option v-for="creator in creators"
                    :value="creator">
                    {{ creator.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showAll: Boolean,
        showNone: Boolean,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedCreator: null,
        }
    },
    async created() {
        await this.$store.dispatch("content/loadCreatorList");
        if (this.modelValue)
            this.selectedCreator = this.creators.find(x => x.id == this.modelValue.id);
    },
    computed: {
        creators() {
            return this.$store.getters["content/getCreators"];
        },
    },
    watch: {
        selectedCreator(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            if (val)
                this.selectedCreator = this.creators.find(x => x.id == val.id);
            else
                this.selectedCreator = val;
        }
    }
}
</script>