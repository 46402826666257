<template>
    <div class="progress-wrapper">
        <progress class="progress is-link"
            :value="modelValue"
            max="100"></progress>
        <span :class="{ light: modelValue > 25 }">
            <slot>
                {{ modelValue }}
                <template v-if="showPercent">%</template>
            </slot>
        </span>
    </div>
</template>

<script>
export default {
    props: ['modelValue', 'showPercent'],
    emits: ['update:modelValue'],
}
</script>

<style lang="sass">
.progress-wrapper
    position: relative
    min-width: 100px
    margin: 5px
    
    .progress
        margin: 0
        
    span
        position: absolute
        left: 7px
        top: 3px
        color: #fff
        font-size: 10px
        text-shadow: 0 0 1px #000

</style>