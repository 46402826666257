<template>
    <h2 class="title">There is no page with path "{{ $route.query.path }}"</h2>
</template>

<script>
import SectionNav from "@/core/components/SectionNav";
//import { showMessage } from "@/utils";

export default {
    components: { SectionNav },
    // data() {
    //     return {
    //         path: null,
    //     }
    // },
    // mounted() {
    //     this.path = this.$route.query.path || this.$route.path

    //     let allRoutes = this.$router.getRoutes();
    //     let routeBits = this.path.split('/');

    //     while (routeBits?.length) {
    //         routeBits.pop();
    //         let path = `/${routeBits.join("/")}/`;
    //         let matchingRoutes = allRoutes.filter(x => x.path == path);
    //         if (matchingRoutes.length) {
    //             this.$router.replace(matchingRoutes[0]);
    //             showMessage(`Page ${this.$route.path} not found`, 'danger');
    //             break;
    //         }
    //     }
    // },
};
</script>
