
import BaseData from "@/core/models/BaseData";

class ModelAudit extends BaseData {
    constructor(data) {
        super(data);
        this.pass = this.score > 50;
    }
}

export default ModelAudit;
