import _ from "lodash";

import BaseData from "@/core/models/BaseData";
import ContentFilenameFormat from "./ContentFilenameFormat";

class ContentLibrary extends BaseData {
    constructor(data) {
        super(data);
        if(this.filenameFormats)
            this.filenameFormats = this.filenameFormats.map(x => new ContentFilenameFormat(x));
    }
}

export default ContentLibrary;