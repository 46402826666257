<template>
    <div v-if="item">
        <div class="buttons is-pulled-right">
            <a :href="reportUrl"
                class="button">
                <span class="icon">
                    <span class="mdi mdi-file-excel-outline"></span>
                </span>
                <span>Download report</span>
            </a>
            <router-link :to="{ name: 'content-library-edit', params: { id: this.item.id } }"
                class="button">
                <span class="icon">
                    <span class="mdi mdi-pencil"></span>
                </span>
                <span>Edit</span>
            </router-link>
        </div>
        <h2 class="title">{{ item.title }}</h2>
        <div class="box">
            <p>{{ item.description }}</p>
        </div>
        <div class="columns">
            <div class="column">
                <div class="panel">
                    <div class="panel-heading">
                        Details
                    </div>
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Active:</th>
                                <td>{{ item.active }}</td>
                            </tr>
                            <tr>
                                <th>Code:</th>
                                <td>{{ item.code }}</td>
                            </tr>
                            <tr>
                                <th>Title:</th>
                                <td>{{ item.title }}</td>
                            </tr>
                            <tr>
                                <th>Scope:</th>
                                <td>{{ item.scope }}</td>
                            </tr>
                            <tr>
                                <th>Region:</th>
                                <td>{{ item.region }}</td>
                            </tr>
                            <tr>
                                <th>Moderated:</th>
                                <td>{{ item.enableWorkflows }}</td>
                            </tr>
                            <tr>
                                <th>Requests enabled:</th>
                                <td>{{ item.requestsEnabled }}</td>
                            </tr>
                            <tr>
                                <th>Requires code:</th>
                                <td>{{ item.requiresCode }}</td>
                            </tr>
                            <tr>
                                <th>Color:</th>
                                <td>
                                    <span class="tag"
                                        :style="{ 'background-color': item.color, color: 'white', 'text-shadow': '1px 1px 1px black' }">
                                        {{ item.color }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>Tags:</th>
                                <td>
                                    <span class="tags">
                                        <span class="tag"
                                            v-for="tag in item.tags">{{ tag }}</span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>Data Fields:</th>
                                <td>
                                    <div class="field is-grouped is-grouped-multiline">
                                        <data-field v-for="field in item.dataFields"
                                            :field="field">
                                        </data-field>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Admins:</th>
                                <td>
                                    <div v-for="admin in item.admins">
                                        <router-link :to="{ name: 'user-detail', params: { id: admin.id } }"
                                            target="_blank">
                                            {{ admin.name }}
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="column">
                <div v-if="item.filenameFormats.length"
                    class="panel">
                    <div class="panel-heading">
                        Content Filename Formats
                        <router-link :to="{ name: 'page-detail-slug', params: { slug: 'content-filenames' } }"
                            target="_blank"
                            title="What is this?"
                            class="is-pulled-right">
                            <span class="icon">
                                <span class="mdi mdi-help-circle"></span>
                            </span>
                        </router-link>
                    </div>
                    <table class="table">
                        <tr v-for="format in item.filenameFormats">
                            <th>{{ format.application.name }}:</th>
                            <td><code>{{ format.format }}</code></td>
                            <td>
                                {{ format.default ? 'Default' : '' }}
                            </td>
                        </tr>
                    </table>
                </div>
                <notifications template-name="panel"
                    :notification-types="['NC', 'PC']"
                    :library="item">
                </notifications>
                <router-link :to="{ name: 'notification-add', query: { library: item.id } }"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-message-outline"></span>
                    </span>
                    <span>
                        Create library notification
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import DataField from '@/core/components/DataField';
import Notifications from '@/core/components/Notifications';
import { groupBy } from "lodash";
import ContentLibrary from '../models/ContentLibrary';

export default {
    mixins: [BaseDetailComponent],
    components: {
        DataField,
        Notifications,
    },
    data() {
        return {
            detailUrl: `${process.env.VUE_APP_API_URL_V2}/content/libraries/${this.$route.params.id}/`,
            modelClass: ContentLibrary,
            groupedFormats: [],
        };
    },
    methods: {
        async setup() {
            this.groupedFormats = groupBy(this.item.filenameFormats, 'application.name')
        },
    },
    computed: {
        reportUrl() {
            return `${process.env.VUE_APP_API_URL_V2}/content/libraries/${this.item.id}/export/`;
        },
    },
}
</script>