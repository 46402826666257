<template>
    <div v-if="format"
        class="columns is-flex is-vcentered">
        <div class="column is-narrow application">
            <application-select v-model="format.application">
            </application-select>
        </div>
        <div v-if="showDefault"
            class="column is-narrow">
            <label class="checkbox">
                <input type="checkbox"
                    v-model="format.default">
                Default
            </label>
        </div>
        <div class="column format"
            v-if="hinter">
            <code-mirror-component v-model="format.format"
                :hinter="hinter"
                mode="django"></code-mirror-component>
        </div>
        <div v-if="showPreview"
            class="column is-narrow preview">
            <pre v-if="valid">{{ preview || "&hellip;" }}</pre>
            <span v-else>Format is not valid</span>
        </div>
    </div>
</template>

<script>
import http from "@/http";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import Content from "../models/Content";
import CodeMirrorComponent from "@/core/components/CodeMirrorComponent";
import CodeMirror from 'codemirror';

const contentFields = [
    'application',
    'category',
    'category.code',
    'category.name',
    'code',
    'creator',
    'creator.first_name',
    'creator.last_name',
    'current_version',
    'current_version.version',
    'dimension',
    'discipline',
    'discipline.code',
    'discipline.name',
    'library',
    'library.code',
    'library.name',
    'library.region',
    'library.scope',
    'masterformat',
    'masterformat.code',
    'masterformat.name',
    'original_title',
    'secondary_code',
    'sub_type',
    'title',
    'type',
    'uniclass',
    'uniclass.code',
    'uniclass.name',
    'units',
]

let contentFilters = [
    'capfirst',
    'cut:" "',
    'default:"value if false"',
    'first',
    'last',
    'lower',
    'pascal',
    'replace:"from,to"',
    'replace_spaces:"."',
    'slice:":2"',
    'title',
    'upper',
    'yesno:"value_if_true,value_if_false"',
]

const getHinter = (options) => {

    return (editor) => {
        var cur = editor.getCursor();
        var curLine = editor.getLine(cur.line);
        var start = cur.ch;
        var end = start;

        let depth = 0;
        let insideFilter = false;

        for (let i = 0; i < cur.ch; i++) {
            const lastChar = curLine.charAt(i);
            if (lastChar == '{') {
                depth++;
                insideFilter = false;
            } else if (lastChar == '}') {
                depth--;
                insideFilter = false;
            } else if (depth == 2) {
                // Inside brackets
                if (lastChar == '|')
                    insideFilter = true;
            }
        }
        const insideBrackets = depth == 2;

        var tokenPat = /[\w$.:\"]/;

        while (end < curLine.length && tokenPat.test(curLine.charAt(end)))
            ++end;

        while (start && tokenPat.test(curLine.charAt(start - 1)))
            --start;

        var currentWord = start !== end && curLine.slice(start, end);
        let choices = [];

        if (insideFilter)
            choices = options.filters;
        else if (insideBrackets)
            choices = options.fields;

        if (currentWord)
            choices = choices.filter(x => x.match(new RegExp('^' + currentWord, 'i')));
        else
            choices = choices.filter(x => !x.includes('.'));

        return {
            list: choices,
            from: CodeMirror.Pos(cur.line, start),
            to: CodeMirror.Pos(cur.line, end)
        }
    }
}

export default {
    props: {
        modelValue: Object,
        content: Content,
        showPreview: Boolean,
        showDefault: Boolean,
        extraFields: {
            type: Array,
            default: [],
        },
    },
    emits: ['update:modelValue'],
    components: {
        ApplicationSelect,
        CodeMirrorComponent,
    },
    data() {
        return {
            format: null,
            preview: null,
            valid: true,
            hinter: null,
        }
    },
    async created() {
        this.format = this.modelValue;

        let options = {
            fields: contentFields.concat(this.extraFields),
            filters: contentFilters,
        }

        let mappingsData = await http.get(
            `${process.env.VUE_APP_API_URL_V2}/content/filename-formats/mappings/`)
            .then(resp => resp.json());

        let mappings = mappingsData.results.map(x => `map:"${x.name}"`);

        options.filters = options.filters.concat(mappings);

        this.hinter = {
            name: 'content',
            hintFunction: getHinter(options),
        }
    },
    methods: {
        async loadPreview() {
            if (!this.format?.format || !this.content?.id)
                return;

            const params = {
                content: this.content?.id,
                format_text: this.format.format,
            }
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/filename-formats/preview/`,
                params)
                .then(resp => resp.json());

            this.valid = data.valid;
            this.preview = data.preview;
        },
        updateHinter() {
            let options = {
                fields: contentFields.concat(this.extraFields),
                filters: contentFilters,
            }
            this.hinter = {
                name: 'content',
                hintFunction: getHinter(options),
            }
        }
    },
    watch: {
        format: {
            handler(val) {
                this.$emit('update:modelValue', val);
                this.loadPreview();
            },
            deep: true,
        },
        modelValue(val) {
            this.format = val;
        },
        content() {
            this.loadPreview();
        },
        extraFields() {
            this.updateHinter();
        }
    },
}
</script>

<style lang="sass">
pre
    padding: 1em

.disabled
    opacity: 0.7
    background: #f8f8f8
</style>