<template>
    <div v-if="item">
        <section-nav v-if="isCurrentUser"
            :names="['user-current']"
            class="is-pulled-right"></section-nav>
        <figure class="image is-128x128 is-pulled-left">
            <img :src="item.avatarUrl"
                class="is-rounded" />
        </figure>
        <h2 class="title">{{ item.firstName }} {{ item.lastName }}</h2>
        <div class="is-clearfix"></div>

        <div class="fixed-grid my-5">
            <div class="grid">
                <div class="cell">
                    <div class="panel">
                        <div class="panel-heading">
                            <span v-if="isCurrentUser">Your</span>
                            <span v-else>User</span>
                            Details
                        </div>
                        <table class="table is-fullwidth">
                            <tr>
                                <th>Discipline:</th>
                                <td>
                                    {{ item.discipline?.name || item.otherDiscipline }}
                                </td>
                            </tr>
                            <tr>
                                <th>Office:</th>
                                <td>
                                    {{ item.office?.name || item.otherOffice }}
                                </td>
                            </tr>
                            <tr>
                                <th>Date joined:</th>
                                <td>{{ item.dateJoined?.fromNow() }}</td>
                            </tr>
                            <tr>
                                <th>Last active:</th>
                                <td>{{ item.lastActive?.fromNow() }}</td>
                            </tr>
                            <template v-if="isCurrentUser">
                                <tr>
                                    <th>Security groups:</th>
                                    <td>{{ item.groups.join(', ') }}</td>
                                </tr>
                                <tr>
                                    <th>Superuser:</th>
                                    <td>
                                        {{ item.isSuperUser }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Permissions:</th>
                                    <td>
                                        <span v-if="item.isSuperUser">All (super user)</span>
                                        <ul v-else>
                                            <li v-for="permission in item.permissions">
                                                {{ permission }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Admin Access:</th>
                                    <td>
                                        {{ item.isAdmin }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Forge ID:</th>
                                    <td>
                                        <code>{{ item.forgeId }}</code>
                                    </td>
                                </tr>
                                <tr>
                                    <th>BIM 360 ID:</th>
                                    <td>
                                        <code>{{ item.bim360Id }}</code>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Auth token:</th>
                                    <td>
                                        <reveal-on-click>
                                            <code>{{ item.authToken }}</code>
                                        </reveal-on-click>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                </div>
                <div v-if="hasChartData"
                    class="cell">
                    <div class="panel">
                        <div class="panel-heading">
                            Model Activity
                        </div>
                        <div class="panel-block">
                            <base-chart :data="chartData"
                                :options="chartOptions"></base-chart>
                        </div>
                    </div>
                </div>
                <div class="cell">
                    <div v-if="item.revitVersions.length"
                        class="panel">
                        <div class="panel-heading">
                            Revit Versions
                        </div>
                        <table class="table is-fullwidth">
                            <tr v-for="version in item.revitVersions">
                                <th>{{ version[0] }}</th>
                                <td>{{ version[1] }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="cell">
                    <div v-if="item.toolVersions.length"
                        class="panel">
                        <div class="panel-heading">
                            Tool Versions
                        </div>
                        <table class="table is-fullwidth">
                            <tr v-for="version in item.toolVersions">
                                <th>{{ version.typeName }}</th>
                                <td>{{ version.version }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { COLORS } from "@/constants";
import BaseChart from "@/core/components/BaseChart";
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import moment from "moment";
import User from "../models/User";
import RevealOnClick from "@/core/components/RevealOnClick";

export default {
    mixins: [BaseDetailComponent],
    components: {
        BaseChart,
        SectionNav,
        RevealOnClick
    },
    data() {
        return {
            chartData: null,
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/core/users/${this.$route.params.id}/`,
            modelClass: User,
            currentUser: null
        };
    },
    methods: {
        async setup() {
            await this.$store.dispatch("users/loadCurrentUser");
            this.currentUser = this.$store.state.users.currentUser;

            // Load chart data
            const start = moment().subtract(100, "days").format("YYYY-MM-DD");
            let chartData = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/stats/model/grouped/`,
                { user: this.item.id, by: 'day', start: start, fill: 1 }
            ).then(resp => resp.json());

            this.chartData = {
                labels: chartData.map((x) =>
                    moment(x.period).format("YYYY-MM-DD")
                ),
                datasets: [
                    {
                        label: "Recent Model activity",
                        data: chartData.map((x) => x.count),
                        backgroundColor: COLORS.GREEN,
                        borderWidth: 0,
                        type: "bar",
                    },
                ],
            };
        },
    },
    computed: {
        isCurrentUser() {
            return this.item.id == this.currentUser?.id;
        },
        hasChartData() {
            return this.chartData?.labels.length > 0;
        }
    }
};
</script>

<style lang="sass" scoped>
    table.table tr:last-child td, 
    table.table tr:last-child th 
        border: none
</style>
