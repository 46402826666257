import BaseData from "@/core/models/BaseData";
import User from "@/users/models/User";
import store from "@/store";


class ContentSet extends BaseData {
    constructor(data) {
        super(data);

        this.content = this.content || [];

        if (this.userprofile)
            this.userprofile = new User(this.userprofile);

        delete this.user;
    }

    get contentTypeName() {
        const allTypes = store.getters["content/getTypes"];
        return allTypes.find(x => x.code == this.contentType)?.name
            || this.contentType
            || "Miscellaneous";
    }
}

export default ContentSet;