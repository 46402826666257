<template>
    <section-nav :names="['audit-template-add']"
        class="is-pulled-right">
    </section-nav>
    <h2 class="title"
        v-if="items">
        {{ pageTitle }} Audit Templates
    </h2>
    <div class="field is-grouped">
        <search-input v-model="filters.q"
            placeholder="Search by name"></search-input>
        <application-select :show-all="true"
            v-model="filters.application"></application-select>
        <clear-button v-if="isFiltered"
            @click="clearFilters"></clear-button>
    </div>
    <table class="table is-hoverable is-fullwidth">
        <thead>
            <tr>
                <th>Template</th>
                <th>Type</th>
                <th>Application</th>
                <th>Project</th>
                <th>Rules</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="template in items">
                <td>
                    <router-link :to="{ name: 'audit-template-edit', params: { id: template.id } }"
                        title="Edit template">
                        {{ template.name }}
                    </router-link>
                </td>
                <td>{{ template.typeName }}</td>
                <td>{{ template.applicationName }}</td>
                <td>{{ template.project?.name }}</td>
                <td>{{ template.rules.length }}</td>
            </tr>
        </tbody>
    </table>
    <pagination :has-prev="hasPrev"
        :has-next="hasNext"
        @pagechanged="onPageChange">
    </pagination>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import SearchInput from '@/core/components/SearchInput';
import SectionNav from "@/core/components/SectionNav";
import { cleanData } from "@/utils.js";
import AuditTemplate from '../models/AuditTemplate';

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ClearButton,
        SectionNav,
        SearchInput,
    },
    data() {
        return {
            filters: {
                q: null,
                application: null,
                limit: null,
                offset: 0,
            },
            listUrl: `${process.env.VUE_APP_API_URL_V1}/audits/templates/`,
            modelClass: AuditTemplate,
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;

            return filters;
        }
    },
};
</script>
