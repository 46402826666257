import { APPLICATIONS } from "@/constants";
import BaseData from "@/core/models/BaseData";
import moment from "moment";

const STATES = {
    P: 'Pending',
    C: 'Complete',
    E: 'Error',
}

class Audit extends BaseData {
    constructor(data) {
        super(data);

        if (this.summary) {
            this.summary.forEach(x => x.date = moment(x.date));
    
            // Build audit summary information
            let disciplineMap = {};

            this.summary.forEach(x => {
                let key = x.discipline || 'Other';
                if (!disciplineMap[key])
                    disciplineMap[key] = { pass: 0, fail: 0, scores: [] };

                x.name = `${x.name} (${key})`;

                if (x.date)
                    x.date = moment(x.date);

                disciplineMap[key].scores.push(x.score);
            });

            this.disciplineSummary = Object.entries(disciplineMap).map(x => {
                const score = parseInt((x[1].scores.reduce((partialSum, a) => partialSum + a, 0)) / x[1].scores.length);
                return {
                    name: x[0],
                    score: score,
                }
            });
        }
    }

    get typeName() {
        return this.type == 'M' ? 'Model' : 'Content';
    }

    get statusName() {
        return STATES[this.status];
    }

    get applicationName() {
        const application = this.template?.application;
        return APPLICATIONS[application] || application;
    }
}

export default Audit;
