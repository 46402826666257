import { PERMISSIONS } from '@/constants';
import ContentAssociatedFilesEdit from './components/ContentAssociatedFilesEdit.vue';
import ContentBulkUpdate from './components/ContentBulkUpdate';
import ContentDetail from './components/ContentDetail';
import ContentEdit from './components/ContentEdit';
import ContentImages from './components/ContentImages';
import ContentImport from './components/ContentImport';
import ContentLibraryDetail from './components/ContentLibraryDetail';
import ContentLibraryEdit from './components/ContentLibraryEdit';
import ContentLibraryList from './components/ContentLibraryList';
import ContentList from './components/ContentList';
import ContentRequestDetail from './components/ContentRequestDetail';
import ContentRequestEdit from './components/ContentRequestEdit';
import ContentRequestList from './components/ContentRequestList';
import ContentSetEdit from './components/ContentSetEdit';
import ContentSetList from './components/ContentSetList';
import ContentUpload from './components/ContentUpload';
import ContentUsage from './components/ContentUsage';

let routes = [
    {
        path: '/content/',
        name: 'content-list',
        component: ContentList,
        meta: {
            title: "Content List",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENT],
        }
    },
    {
        path: '/content/:id/',
        name: 'content-detail',
        component: ContentDetail,
        meta: {
            title: "Content Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENT],
        }
    },
    {
        path: '/content/:id/associated-files/',
        name: 'associated-files',
        component: ContentAssociatedFilesEdit,
        meta: {
            title: "Associated Files",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Content', route: 'content-detail' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENT],
        }
    },
    {
        path: '/content/:id/edit/',
        name: 'content-edit',
        component: ContentEdit,
        meta: {
            title: "Content Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENT],
        }
    },
    {
        path: '/content/libraries/',
        name: 'content-library-list',
        component: ContentLibraryList,
        meta: {
            title: "Content Libraries",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'bookshelf',
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTLIBRARY],
        }
    },
    {
        path: '/content/libraries/:id/',
        name: 'content-library-detail',
        component: ContentLibraryDetail,
        meta: {
            title: "Content Library Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Libraries', route: 'content-library-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTLIBRARY],
        }
    },
    {
        path: '/content/sets/',
        name: 'content-sets',
        component: ContentSetList,
        meta: {
            title: "Content Sets",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'bookmark-outline',
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTSET],
        }
    },
    {
        path: '/content/sets/:id/',
        name: 'content-sets-edit',
        component: ContentSetEdit,
        meta: {
            title: "Edit Content Set",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Content Sets', route: 'content-sets' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENTSET],
        }
    },
    {
        path: '/content/sets/add/',
        name: 'content-sets-add',
        component: ContentSetEdit,
        meta: {
            title: "Add Content Set",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Content Sets', route: 'content-sets' },
            ],
            icon: 'bookmark-outline',
            permissionsRequired: [PERMISSIONS.ADD_CONTENTSET],
        }
    },
    {
        path: '/content/libraries/:id/edit/',
        name: 'content-library-edit',
        component: ContentLibraryEdit,
        meta: {
            title: "Content Library Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Libraries', route: 'content-library-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENTLIBRARY],
        }
    },
    {
        path: '/content/requests/',
        name: 'content-requests',
        component: ContentRequestList,
        meta: {
            title: "Content Requests",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'send-outline',
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTREQUEST],
        }
    },
    {
        path: '/content/requests/:id/',
        name: 'content-request-detail',
        component: ContentRequestDetail,
        meta: {
            title: "Content Request Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Content Requests', route: 'content-requests' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTREQUEST],
        }
    },
    {
        path: '/content/requests/:id/edit/',
        name: 'content-request-edit',
        component: ContentRequestEdit,
        meta: {
            title: "Content Request Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Content Requests', route: 'content-requests' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTREQUEST],
        }
    },
    {
        path: '/content/requests/add/',
        name: 'content-request-add',
        component: ContentRequestEdit,
        meta: {
            title: "Content Request Add",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Content Requests', route: 'content-requests' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTREQUEST],
        }
    },
    {
        path: '/content/bulk-update/',
        name: 'content-bulk-update',
        component: ContentBulkUpdate,
        meta: {
            title: "Content Bulk Update",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'pencil-box-multiple',
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENT],
        }
    },
    {
        path: '/content/upload/',
        name: 'content-upload',
        component: ContentUpload,
        meta: {
            title: "Content Upload",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'cloud-upload',
            permissionsRequired: [PERMISSIONS.ADD_CONTENT],
        }
    },
    {
        path: '/content/usage/',
        name: 'content-usage',
        component: ContentUsage,
        meta: {
            title: "Content Usage",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'chart-gantt',
            permissionsRequired: [PERMISSIONS.VIEW_REVITEVENT],
        }
    },
    {
        path: '/content/images/',
        name: 'content-images',
        component: ContentImages,
        meta: {
            title: "Content Images",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
            ],
            icon: 'image-multiple-outline',
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENTIMAGE, PERMISSIONS.DELETE_CONTENTIMAGE],
        }
    },
    {
        path: '/content/import/',
        name: 'content-import',
        component: ContentImport,
        meta: {
            title: "Bulk Rename",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Content', route: 'content-list' },
                { name: 'Bulk Update', route: 'content-bulk-update' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_CONTENT],
        }
    },
]

routes.forEach(x => x.meta.group = 'Content');
export default routes;
