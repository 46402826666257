<template>
    <div class="control">
        <button class="button is-link">
            <span class="icon is-small">
                <span class="mdi mdi-close"></span>
            </span>
            <span>
                <slot>Clear</slot>
            </span>
        </button>
    </div>
</template>


