<template>
    <h2 class="title">Import / Export Content Items</h2>
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                <file-upload v-model="files"
                    :max-files="1"
                    :enable-upload="false"
                    :show-filenames="true"
                    :show-preview="false"
                    instruction="Drag CSV file here to upload">
                </file-upload>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <div>
                    <div class="field has-addons">
                        <content-library-select v-model="library"
                            :showNone="true">
                        </content-library-select>
                        <div class="control">
                            <a :href="rawUrl"
                                class="button is-link"
                                :class="{ 'is-static': !userIsLibraryAdmin }">
                                <span class="icon">
                                    <span class="mdi mdi-cloud-download"></span>
                                </span>
                                <span>Download CSV file</span>
                            </a>
                        </div>
                    </div>
                    <div v-if="library && !userIsLibraryAdmin">
                        You do not have library admin permissions for this library
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="buttons">
        <button v-if="files.length && state == states.INITIAL"
            @click="upload(false)"
            class="button is-link">Test upload</button>
        <button v-if="files.length && state == states.PREVIEW"
            @click="upload(true)"
            class="button is-link">Final upload</button>
    </div>

    <div v-if="message"
        class="notification">{{ message }}</div>

    <table v-if="contentList.length"
        class="table">
        <thead>
            <tr>
                <th>Content Item</th>
                <th>Field</th>
                <th>From</th>
                <th>To</th>
                <th></th>
            </tr>
        </thead>
        <template v-for="content in contentList">
            <tr>
                <td>
                    <router-link :to="{ name: 'content-detail', params: { id: content.id } }">
                        {{ content.content }}
                    </router-link>
                    <div v-if="content.comments">
                        <span class="text-danger"
                            v-for="comment in content.comments">{{ comment }}</span>
                    </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr v-for="field in content.fields">
                <td>
                </td>
                <td>{{ field.field.split('__').join('.') }}</td>
                <td><code v-if="field.obj_val">{{ field.obj_val }}</code></td>
                <td><code v-if="field.csv_val">{{ field.csv_val }}</code></td>
                <td>
                    <i v-if="state == FINAL && content.success"
                        class="fa fa-fw fa-check text-success"></i>
                    <i v-if="state == FINAL && !content.success"
                        class="fa fa-fw fa-times text-danger"></i>
                </td>
            </tr>
        </template>
    </table>
</template>

<script>
import http from "@/http";

import ContentLibrarySelect from "./ContentLibrarySelect";
import FileUpload from "@/core/components/FileUpload";

export default {
    components: {
        ContentLibrarySelect,
        FileUpload,
    },
    data() {
        return {
            library: null,
            files: [],
            message: null,
            state: null,
            contentList: [],
            states: {
                INITIAL: 1,
                PREVIEW: 2,
                FINAL: 3,
                ERROR: 4,
            }
        };
    },
    async created() {
        this.state = this.states.INITIAL;
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
    },
    methods: {
        async upload(doSave) {

            let fd = new FormData();
            fd.append('file', this.files[0].file);

            if (doSave)
                fd.append('do_save', doSave);

            let data = await http.post(`${process.env.VUE_APP_API_URL_V2}/content/import/`, fd)
                .then(resp => resp.json());
            this.contentList = data;

            if (doSave && this.contentList.length) {
                this.state = this.states.FINAL;
                let saved = this.contentList.filter(x => x.success).length;
                let errors = this.contentList.filter(x => !x.success).length;
                this.message = `${saved.toLocaleString()} items were changed, ${errors.toLocaleString()} errors`;
            } else {
                this.state = this.states.PREVIEW;
                this.message = `${this.contentList.length.toLocaleString()} items will be modified`;
            }
        }
    },
    computed: {
        userIsLibraryAdmin() {
            if (!this.library?.id)
                return false;

            let adminIds = this.library.admins.map(x => x.id);
            return adminIds.includes(this.user.id);
        },
        rawUrl() {
            if (this.library)
                return `${process.env.VUE_APP_API_URL_V2}/content/libraries/${this.library.id}/export/`;
        }
    }
}
</script>