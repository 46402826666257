<template>
    <div v-if="journal">
        <div class="field is-grouped is-pulled-right">
            <div class="control">
                <div class="dropdown is-hoverable">
                    <div class="dropdown-trigger">
                        <button class="button">
                            <span>Related Journals</span>
                            <span class="icon is-small">
                                <span class="mdi mdi-chevron-down"></span>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item"
                                v-for="relatedId in journal.relatedJournals">
                                <span v-if="relatedId == journal.id">Current</span>
                                <router-link v-else
                                    :to="{ name: 'journal-detail', params: { id: relatedId } }">
                                    Related
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="control">
                <a class="button"
                    :href="rawUrl">
                    <span class="icon">
                        <span class="mdi mdi-file-download-outline"></span>
                    </span>
                    <span>
                        Download Journal file
                    </span>
                </a>
            </div>
        </div>
        <h2 class="title">
            Revit Journal: {{ journal.modelName }}
        </h2>
        <p class="subtitle">
            Created {{ journal.created.fromNow() }} - <router-link
                :to="{ name: 'user-detail', params: { id: journal.user.id } }"
                target="_blank">
                {{ journal.user.name }}
            </router-link>
        </p>
        <div class="tabs">
            <ul>
                <li :class="{ 'is-active': activeTab == 0 }">
                    <a @click="activeTab = 0">Parsed Data</a>
                </li>
                <li :class="{ 'is-active': activeTab == 1 }">
                    <a @click="activeTab = 1">Raw Data</a>
                </li>
            </ul>
        </div>
        <div class="tab-content"
            v-show="activeTab === 0">
            <table class="table parsed-data">
                <tbody>
                    <tr v-for="(value, key) in journal.sortedData"
                        :key="key">
                        <th>{{ key }}</th>
                        <td>
                            <template v-if="Array.isArray(value)">
                                <div v-for="(item, index) in value"
                                    :key="index">
                                    <template v-if="(item instanceof Object)">
                                        <table class="table detail">
                                            <tr v-for="key in Object.keys(item)">
                                                <th>{{ key }}</th>
                                                <td>{{ item[key] }}</td>
                                            </tr>
                                        </table>
                                    </template>
                                    <template v-else>
                                        {{ item }}
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="(value instanceof Object)">
                                <table class="table detail">
                                    <tr v-for="key in Object.keys(value)">
                                        <th>{{ key }}</th>
                                        <td>{{ value[key] }}</td>
                                    </tr>
                                </table>
                            </template>
                            <template v-else>
                                {{ value }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="tab-content"
            v-show="activeTab === 1">
            <div v-html="journal.highlightedText"></div>
        </div>
    </div>
</template>

<script>
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import '@/core/highlight.css';
import Journal from "../models/Journal";

export default {
    mixins: [BaseDetailComponent],
    data() {
        return {
            activeTab: 0,
            journal: null,
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/bim/journals/${this.$route.params.id}/`,
            modelClass: Journal,
        }
    },
    computed: {
        rawUrl() {
            return `${process.env.VUE_APP_API_URL_V1}/bim/journals/${this.$route.params.id}/?raw`;
        }
    },
}
</script>
<style lang="sass">
    .table.parsed-data
        .table
            margin-bottom: 1em 

            td, th
                border-bottom-width: 1px 

</style>