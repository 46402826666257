<template>
    <modal-dialog ref="dialog">
        <div class="modal-card-head">
            <p v-html="title"
                class="modal-card-title"></p>
            <button class="delete"
                aria-label="close"
                @click="cancel"></button>
        </div>
        <div class="modal-card-body">
            <slot></slot>
        </div>
        <div class="modal-card-foot"></div>
    </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog';

export default {
    components: { ModalDialog },
    data() {
        return {
            title: null,
            resolvePromise: null,
            rejectPromise: null,
        }
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title || 'Detail';
            this.$refs.dialog.open();
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },

        cancel() {
            this.$refs.dialog.close();
            this.resolvePromise(false);
        },
    },
}
</script>
