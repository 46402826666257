<template>
    <div>
        <h2 class="title">Feedback</h2>
        <div class="columns">
            <div class="column is-half">
                <textarea v-model="comment"
                    :placeholder="placeholder"
                    class="textarea"></textarea>
            </div>
        </div>
        <button class="button is-link"
            @click="save">Submit</button>
    </div>
</template>

<script>
import http from "@/http";
import { getSnippet } from '@/utils';

export default {
    data() {
        return {
            comment: null,
            url: null,
            placeholder: null,
            user: null,
        }
    },
    async created() {
        this.placeholder = await getSnippet('feedback-modal-help');
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
        this.url = this.$route.query?.url;
    },
    methods: {
        async save() {
            let resp = await http.post(
                `${process.env.VUE_APP_API_URL_V1}/core/feedback/`,
                { comment: this.comment, url: this.url, user: this.user?.id });
                
            if (resp.status == 201) {
                this.$store.dispatch(
                    'core/setMessage', {
                    text: 'Thanks for your feedback!',
                    type: 'info'
                });
                this.$router.push(this.url);
            } else {
                this.$store.dispatch(
                    'core/setMessage', {
                    text: 'Could not submit feedback',
                    type: 'danger'
                });
            }
        }
    }
}
</script>