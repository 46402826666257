<template>
    <div v-if="rule"
        class="is-block is-fullwidth">
        <a @click="$emit('selected', rule)"
            class="button is-ghost is-pulled-left"
            title="Add this rule to template">
            <span class="icon">
                <span class="mdi mdi-plus-circle "></span>
            </span>
        </a>
        <a class="button is-ghost is-pulled-right"
            @click="showDetails = !showDetails">
            <span class="icon">
                <span v-if="showDetails"
                    class="mdi mdi-chevron-up mdi-24px"></span>
                <span v-else
                    class="mdi mdi-chevron-down mdi-24px"></span>
            </span>
        </a>
        <div class="py-2">
            <a @click="showDetails = !showDetails">
                {{ rule.name }} ({{ rule.elementTypes.map(x => x.toUpperCase()).join(', ') }})
            </a>
            <router-link :to="{ name: 'audit-rule-edit', params: { id: rule.id } }"
                target="_blank"
                title="Edit rule">
                <span class="icon">
                    <span class="mdi mdi-pencil"></span>
                </span>
            </router-link>
        </div>
        <div v-if="rule.description"
            class="ml-3">
            <em>{{ rule.description }}</em>
        </div>
        <div v-if="showDetails">
            <table class="detail">
                <tr>
                    <th>Polarity</th>
                    <td>
                        <div class="select">
                            <select v-model="rule.polarity">
                                <option :value="true">Positive</option>
                                <option :value="false">Negative</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>Weight</th>
                    <td>
                        <input type="number"
                            v-model="rule.weight"
                            class="input">
                    </td>
                </tr>
                <tr>
                    <th>Threshold</th>
                    <td>
                        <input type="number"
                            v-model="rule.threshold"
                            class="input">
                        <label class="checkbox py-2 is-pulled-right">
                            Threshold is an absolute number
                            <input type="checkbox"
                                v-model="rule.absolute_threshold"
                                class="checkbox ml-2">
                        </label>
                    </td>
                </tr>
            </table>
            <pre>{{ rule.filters }}</pre>
        </div>
    </div>
    <div v-if="loading">Loading&hellip;</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            showDetails: false,
        }
    },
    emits: ['selected'],
    props: ['rule'],
}
</script>

<style lang="sass">
.is-block
    width: 100%
</style>