import { cleanData, fileSizeFormat, htmlEncode } from "@/utils";
import { FEET_TO_MM, SQFT_TO_SQM } from "@/constants";

const boolFormatter = x => x ? '✔' : '';
const xyzFormatter = x => `${x[0].toFixed(2)}, ${x[1].toFixed(2)}, ${x[2].toFixed(2)}`;
const arrayFormatter = x => x.map(y => htmlEncode(y)).join('<br>');
const numberFormatter = x => x.toLocaleString();
const pathFormatter = x => {
    const maxLength = 50;
    if (!x || x.length < maxLength)
        return x;
    else
        return `<span class="nowrap" title="${x}">${x.substring(0, maxLength / 2)}&hellip;${x.substring(x.length - maxLength / 2, x.length)}</span>`;
};
const objectFormatter = x => {
    var keys = Object.keys(x);
    if (keys.length)
        return keys.filter(k => x[k]).map(k => `${k}: ${x[k]}`).join('<br>');
}
const filterRuleFormatter = rule => {
    return rule.map(x => `<code>${x.parameter}</code> <em>${x.op}</em> <code>${x.value}</code>`).join(', ');
}
const colorFormatter = x => `<span class="color-swatch" style="background-color: rgb(${x.join(',')}">&nbsp;</span>${x.join(',')}`;

const convertFeet = (feet, ctx) => {
    if (ctx.displayUnits == 'M')
        return feet * FEET_TO_MM;
    else
        return feet;
}

const convertSquareFeet = (feet, ctx) => {
    if (ctx.displayUnits == 'M')
        return feet * SQFT_TO_SQM;
    else
        return feet;
}

const typeFormatters = {
    basepoints: {
        shared: boolFormatter,
        clipped: boolFormatter,
        position: xyzFormatter,
        shared_position: xyzFormatter,
    },
    designoptions: {
        primary: boolFormatter,
    },
    dimensions: {
        color: colorFormatter,
    },
    families: {
        system: boolFormatter,
        inplace: boolFormatter,
        path: pathFormatter,
        instance_ids: x => {
            if (!x)
                return '';
            if (x.length < 10)
                return x.join('; ');
            else
                return `${x.length} instance ids`;
        },
    },
    grids: {
        pinned: boolFormatter,
        start: xyzFormatter,
        end: xyzFormatter,
    },
    levels: {
        elevation: (x, ctx) => convertFeet(x, ctx).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
        project_elevation: (x, ctx) => convertFeet(x, ctx).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
        pinned: boolFormatter,
    },
    linestyles: {
        color: colorFormatter,
    },
    links: {
        room_bounding: boolFormatter,
        filesize: fileSizeFormat,
        path: pathFormatter,
        pinned: boolFormatter,
    },
    locations: {
        active: boolFormatter,
    },
    modelinformation: {
        filesize: fileSizeFormat,
        elements: numberFormatter,
        warnings: numberFormatter,
    },
    parameters: {
        instance: boolFormatter,
    },
    phasefilters: {
        default: boolFormatter,
    },
    phases: {
        element_count: numberFormatter,
    },
    referenceplanes: {
        pinned: boolFormatter,
    },
    revisions: {
        issued: boolFormatter,
    },
    rooms: {
        area: (x, ctx) => convertSquareFeet(x, ctx).toFixed(1).toLocaleString() + (ctx.displayUnits == 'M' ? ' m²' : ' ft²'),
        state: x => _.startCase(x),
    },
    scopeboxes: {
        min: xyzFormatter,
        max: xyzFormatter,
    },
    spaces: {
        area: (x, ctx) => convertSquareFeet(x, ctx).toFixed(1).toLocaleString() + (ctx.displayUnits == 'M' ? ' m²' : ' ft²'),
        space_type: x => _.startCase(x.substring(1, x.length)),
    },
    textnotes: {
        color: colorFormatter,
    },
    views: {
        is_template: boolFormatter,
        scale: x => `1:${x}`,
    },
    viewfilters: {
        rules: x => x.map(filterRuleFormatter).join(', '),
    },
    walls: {
        width: (x, ctx) => convertFeet(x).toFixed(1) + (ctx.displayUnits == 'M' ? 'mm' : 'ft'),
        length: (x, ctx) => convertFeet(x).toFixed(1) + (ctx.displayUnits == 'M' ? 'mm' : 'ft'),
        top_offset: (x, ctx) => convertFeet(x).toFixed(1) + (ctx.displayUnits == 'M' ? 'mm' : 'ft'),
        base_offset: (x, ctx) => convertFeet(x).toFixed(1) + (ctx.displayUnits == 'M' ? 'mm' : 'ft'),
    },
    walltypes: {
        width: (x, ctx) => convertFeet(x, ctx).toFixed(1) + (ctx.displayUnits == 'M' ? 'mm' : 'ft'),
    },
    warnings: {
        ids: x => `${_.flatten(x).length} elements`,
        priority: x => {
            if (x == 1)
                return 'High';
            else if (x == 2)
                return 'Medium';
            else
                return 'Low';
        }
    },
    worksets: {
        elements: numberFormatter,
        visible_by_default: boolFormatter,
        categories: arrayFormatter,
    },
}

export { typeFormatters, arrayFormatter, objectFormatter };