<template>
    <div class="columns">
        <div class="column is-one-third">
            <router-link :to="{ name: 'bim-index' }">
                <div class="card index-card section-bim">
                    <div class="card-image">
                    </div>
                    <div class="card-content">
                        <h4>BIM</h4>
                        <div class="is-size-4">Design models, parameters and events</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-two-thirds">
            <router-link :to="{ name: 'content-list' }">
                <div class="card index-card section-content">
                    <div class="card-image">
                    </div>
                    <div class="card-content">
                        <h4>Content Lab</h4>
                        <div class="is-size-4">Reusable design components</div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <router-link :to="{ name: 'project-list' }">
                <div class="card index-card section-projects">
                    <div class="card-image">
                    </div>
                    <div class="card-content">
                        <h4>Projects</h4>
                        <div class="is-size-4">Managed Jacobs Tools Projects</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column">
            <router-link :to="{ name: 'user-list' }">
                <div class="card index-card section-users">
                    <div class="card-image">
                    </div>
                    <div class="card-content">
                        <h4>Users</h4>
                        <div class="is-size-4">Jacobs Tools users</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-one-third">
            <router-link :to="{ name: 'stats-index' }">
                <div class="card index-card section-stats">
                    <div class="card-image">
                    </div>
                    <div class="card-content">
                        <h4>Stats</h4>
                        <div class="is-size-4">Usage events for content and tools</div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.columns
    margin-top: 1rem

.card-image   
    background-position: right top, left top
    background-repeat: no-repeat
    max-width: 100%
    position: relative

.section-bim
    .card-image 
        background-image: url(tile_bg_bim.png), url(tile_bg_wide.png)

.section-content
    .card-image  
        background-image: url(tile_bg_content.png), url(tile_bg_wide.png)

.section-projects
    .card-image
        background-image: url(tile_bg_projects.png), url(tile_bg_wide.png)

.section-users 
    .card-image
        background-image: url(tile_bg_users.png), url(tile_bg_wide.png)

.section-stats
    .card-image
        background-image: url(tile_bg_stats.png), url(tile_bg_wide.png)
</style>
