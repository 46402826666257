<template>
    <div class="control">
        <template v-if="type == 'checkbox'">
            <label class="checkbox">
                <input v-model="value"
                    type="checkbox">
                {{ label }}
            </label>
        </template>
        <template v-if="type == 'textarea'">
            <label v-if="label"
                class="label">
                {{ label }}
            </label>
            <textarea v-model="value"
                class="textarea">
            </textarea>
        </template>
        <template v-if="type == 'select'">
            <label v-if="label"
                class="label">
                {{ label }}
            </label>
            <div class="select">
                <select v-model="value">
                    <option v-for="choice in choices">{{ choice }}</option>
                </select>
            </div>
        </template>
        <template v-if="!(['checkbox', 'textarea', 'select'].includes(type))">
            <label v-if="label"
                class="label">
                {{ label }}
            </label>
            <input v-model="value"
                :type="type"
                :class="errorClass"
                :disabled="disabled"
                class="input">
        </template>
        <p v-if="!valid"
            class="help is-danger">{{ errorMessage }}</p>
        <p v-if="help"
            class="help">{{ help }}</p>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
        type: {
            type: String,
            default: 'text',
        },
        name: String,
        label: String,
        help: String,
        choices: {
            type: Array,
            required: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validator: {
            type: Function,
            required: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            value: null,
            valid: true,
            errorMessage: "Not valid",
        }
    },
    created() {
        this.value = this.modelValue;
    },
    computed: {
        errorClass() {
            return {
                'is-danger': !this.valid,
            }
        },
    },
    methods: {
        validate() {
            this.valid = true;

            if (this.required && !this.value) {
                this.valid = false;
                this.errorMessage = "Field is required";
                this.$emit('valid', [this, false]);
            }

            if (this.validator) {
                const res = this.validator(this.value);
                this.valid = res[0];
                this.errorMessage = res[1];
            }

            this.$emit(
                'changed',
                {
                    name: this.name || this.label,
                    value: this.value,
                    valid: this.valid,
                });
        }
    },
    watch: {
        modelValue(val) {
            this.value = val;
        },
        value(val) {
            this.$emit('update:modelValue', val);
            this.validate();
        }
    }
}
</script>