<template>
    <div v-if="content">
        <router-link :to="{ name: 'content-detail', params: { id: content.id } }"
            class="button is-pulled-right">
            Content detail page
        </router-link>
        <h2 class="title">Edit {{ content }} Data</h2>
        <div class="columns"
            v-if="!loading">
            <div class="column">
                <fieldset :disabled="saving">
                    <div class="field">
                        <label class="label">Title</label>
                        <input v-model="content.title"
                            type="text"
                            class="input">
                    </div>
                    <div class="field">
                        <label class="label">Description</label>
                        <textarea v-model="content.description"
                            class="textarea">
                    </textarea>
                    </div>
                    <div class="field">
                        <label class="label">Filename format</label>
                        <content-filename-formats-select v-model="content.formatString"
                            :application="content.application?.code"
                            :library="content.library?.id"
                            :discipline="content.discipline?.code">
                        </content-filename-formats-select>
                        <div class="control p-2">
                            <small>
                                Preview:
                                <code>{{ filenamePreview }}</code>
                            </small>
                        </div>
                    </div>
                    <div class="field is-grouped is-grouped-multiline">
                        <div class="control">
                            <label class="label">Library</label>
                            <content-library-select v-model="content.library"></content-library-select>
                        </div>
                        <div class="control">
                            <label class="label">Code</label>
                            <input v-model="content.code"
                                type="text"
                                class="input">
                        </div>
                        <div class="control">
                            <label class="label">Application</label>
                            <application-select v-model="content.application"
                                :show-all="false"></application-select>
                        </div>
                        <div class="control">
                            <label class="label">Type</label>
                            <content-type-select v-model="content.type"
                                :application="content.application?.code"></content-type-select>
                        </div>
                        <div class="control">
                            <label class="label">Units</label>
                            <units-select v-model="content.units"
                                :show-all="false">
                            </units-select>
                        </div>
                        <div class="control">
                            <label class="label">Discipline</label>
                            <discipline-select v-model="content.discipline"></discipline-select>
                        </div>
                        <div class="control">
                            <label class="label">Creator</label>
                            <user-autocomplete v-model="content.creator"></user-autocomplete>
                        </div>
                    </div>
                    <content-fields-edit v-model="content.dataFields"
                        :library="content.library"></content-fields-edit>
                    <div class="panel mt-3">
                        <div class="panel-heading">Tags</div>
                        <div class="panel-block">
                            <tags-autocomplete v-model="content.tags"></tags-autocomplete>
                        </div>
                        <div class="panel-block">
                            <tags-recommended :content="content"
                                @selected="addTag"
                                :key="tagsKey"></tags-recommended>
                        </div>
                    </div>
                    <div class="panel">
                        <div class="panel-heading">Classifications</div>
                        <div class="panel-block">
                            <div class="control tags"
                                v-if="content.typologies.length">
                                <span class="tag"
                                    v-for="typology in content.typologies">
                                    {{ typology }}
                                    <button class="delete is-small"
                                        @click="removeTypology(typology)"></button>
                                </span>
                            </div>
                        </div>
                        <div class="panel-block">
                            <div class="field is-grouped is-grouped-multiline">
                                <typology-autocomplete v-model="content.typologies"
                                    :show-results="false"
                                    v-if="typologyMode == 'search'"></typology-autocomplete>
                                <typology-select v-model="content.typologies"
                                    :show-results="false"
                                    v-if="typologyMode == 'browse'"></typology-select>
                                <div class="control">
                                    <a @click="typologyMode = 'search'"
                                        v-if="typologyMode == 'browse'"
                                        class="button is-ghost">
                                        <span class="icon">
                                            <span class="mdi mdi-magnify"></span>
                                        </span>
                                        <span>Search classifications</span>
                                    </a>
                                    <a @click="typologyMode = 'browse'"
                                        v-if="typologyMode == 'search'"
                                        class="button is-ghost">
                                        <span class="icon">
                                            <span class="mdi mdi-file-tree"></span>
                                        </span>
                                        <span>Browse classifications</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-block">
                            <typology-recommended-list :typologies="content.typologies"
                                @selected="addTypology"
                                class="mb-3"
                                :key="typologiesKey">
                            </typology-recommended-list>
                        </div>
                    </div>
                    <div class="buttons is-pulled-right mt-3">
                        <a @click="save(false)"
                            class="button">Save and continue editing</a>
                        <a @click="save(true)"
                            class="button is-link">Save</a>
                    </div>
                </fieldset>
            </div>
            <div class="column">
                <img :src="content.image"
                    class="is-pulled-right">
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import TagsAutocomplete from "@/core/components/TagsAutocomplete";
import TagsRecommended from "@/core/components/TagsRecommended";
import UnitsSelect from "@/core/components/UnitsSelect";
import http from "@/http";
import UserAutocomplete from "@/users/components/UserAutocomplete";
import { showMessage } from '@/utils';
import Content from "../models/Content";
import ContentCreatorSelect from "./ContentCreatorSelect";
import ContentFieldsEdit from "./ContentFieldsEdit";
import ContentFilenameFormatsSelect from "./ContentFilenameFormatsSelect";
import ContentGroupSelect from "./ContentGroupSelect";
import ContentLibrarySelect from "./ContentLibrarySelect";
import ContentSetSelect from "./ContentSetSelect";
import ContentTypeSelect from "./ContentTypeSelect";
import TypologyAutocomplete from "./TypologyAutocomplete";
import TypologyRecommendedList from "./TypologyRecommendedList";
import TypologySelect from "./TypologySelect";

export default {
    components: {
        ApplicationSelect,
        ContentCreatorSelect,
        ContentFieldsEdit,
        ContentFilenameFormatsSelect,
        ContentGroupSelect,
        ContentLibrarySelect,
        ContentSetSelect,
        ContentTypeSelect,
        DisciplineSelect,
        TagsAutocomplete,
        TagsRecommended,
        TypologyAutocomplete,
        TypologyRecommendedList,
        TypologySelect,
        UnitsSelect,
        UserAutocomplete,
    },
    data() {
        return {
            content: null,
            loading: false,
            filenamePreview: null,
            typologyMode: 'search',
            saving: false,
        }
    },
    async mounted() {
        let data = await http.get(
            `${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/`)
            .then(resp => resp.json());
        this.content = new Content(data);
        this.loading = true;
        await this.content.load();
        this.loading = false;
    },
    computed: {
        typologiesKey() {
            if (this.content.typologies)
                return this.content.typologies.map(x => x.id).join('');
        },
        tagsKey() {
            if (this.content.tags)
                return this.content.tags.map(x => x.id).join('');
        },
    },
    methods: {
        addTypology(typology) {
            this.content.typologies.push(typology);
        },
        removeTypology(typology) {
            this.content.typologies = this.content.typologies.filter(x => x.id != typology.id);
        },
        addTag(tag) {
            this.content.tags.push(tag);
        },
        async save(doRedirect) {
            this.saving = true;

            let fd = new FormData();
            fd.append('title', this.content.title);
            fd.append('description', this.content.description || '');
            fd.append('tags', JSON.stringify(this.content.tags.map(x => x.name)));
            fd.append('library', this.content.library.id);
            fd.append('units', this.content.units);
            fd.append('application', this.content.application.code);
            fd.append('type', this.content.type.code);
            fd.append('typologies', JSON.stringify(this.content.typologies.map(x => x.id)));

            if (this.content.creator?.id > 0)
                fd.append('creator', this.content.creator.id);

            if (this.content.discipline?.id > 0)
                fd.append('discipline', this.content.discipline.id);

            if (this.content.dataFields)
                fd.append('data_fields', JSON.stringify(this.content.dataFields));

            if (this.content.formatString?.id > 0)
                fd.append('format_string', this.content.formatString.id);

            if (this.content.code && this.content.code.trim())
                fd.append('code', this.content.code.trim());

            await http.patch(`${process.env.VUE_APP_API_URL_V2}/content/${this.content.id}/`, fd)
                .then(resp => resp.json());

            this.saving = false;

            showMessage(`${this.content} updated`);

            if (doRedirect)
                this.$router.push({ name: 'content-detail', params: { id: this.content.id } });
        }
    },
    watch: {
        'content.formatString': async function (val) {
            if (!val)
                return;

            const params = {
                content: this.content.id,
                format_id: this.content.formatString.id
            }
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/filename-formats/preview/`,
                params)
                .then(resp => resp.json());

            this.filenamePreview = data.preview;
        },
    }
}
</script>

<style lang="sass" scoped>

img
    max-height: 400px

.typology-select 
    max-height: 400px
    overflow-y: auto
</style>