<template>
    <div>
        <div v-if="loading" id="loading">
            <progress class="progress is-primary" max="100"></progress>
        </div>
        <div v-else id="not-loading"></div>
    </div>
</template>

<script>
export default {
    computed: {
        loading() {
            return this.$store.state.core.loading;
        },
    },
};
</script>

<style lang="sass">
#not-loading
    height: 3px
    width: 100%
    background: #231edc

#loading
    height: 3px
    background: red

    .progress
        background-image: linear-gradient(to right, #231edc 30%, #666 30%)
        border-radius: 0
</style>
