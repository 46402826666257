import BaseData from "@/core/models/BaseData";
import _ from "lodash";

class AuditSummary extends BaseData {
    constructor(data) {
        super(data);

        this.summaryFields = [];

        if (this.data) {
            this.summaryFields = Object.keys(this.data).map(x => {
                return {
                    name: _.startCase(x),
                    value: this.data[x],
                }
            })
            this.summaryFields = _.orderBy(this.summaryFields, 'name');
        }
    }
}

export default AuditSummary;