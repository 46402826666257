<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedLibrary">
                <option :value="null"
                    v-if="showNone">No library selected</option>
                <option :value="null"
                    v-if="showAll">All libraries</option>
                <option v-for="library in libraries"
                    :value="library">{{ library.title + (library.enableWorkflows ? ' (Moderated)' : '') }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import { isObject, isNumber } from 'lodash';

export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        requestsEnabled: {
            type: Boolean,
            default: null,
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedLibrary: null,
        }
    },
    async created() {
        await this.$store.dispatch("content/loadLibraryList");
        if (this.modelValue) {
            if (isNumber(this.modelValue))
                this.selectedLibrary = this.libraries.find(x => x.id == this.modelValue);
            else if (isObject(this.modelValue))
                this.selectedLibrary = this.libraries.find(x => x.code == this.modelValue.code);
        } else {
            this.selectedLibrary = null;
        }
    },
    computed: {
        libraries() {
            let allLibraries = this.$store.getters["content/getLibraries"];

            // Filter out inactive libraries
            allLibraries = allLibraries.filter(x => x.active);

            if (this.requestsEnabled)
                return allLibraries.filter(x => x.requestsEnabled);
            else
                return allLibraries;
        },
    },
    watch: {
        selectedLibrary(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            if (val)
                this.selectedLibrary = this.libraries.find(x => x.code == val.code);
            else
                this.selectedLibrary = val;
        }
    }
}
</script>