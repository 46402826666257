<template>
    <div v-if="item"
        class="page">
        <h2 class="title">{{ item.title }}</h2>
        <div class="subtitle is-6">
            Created by
            <router-link :to="{ name: 'user-detail', params: { id: item.author.id } }"
                target="_blank">
                {{ item.author.name }}
            </router-link>
            {{ item.created.fromNow() }} &middot;
            Last modified {{ item.modified.fromNow() }}
        </div>
        <div class="columns">
            <div class="column is-three-quarters">
                <div v-html="item.bodyRendered"
                    class="content"></div>
            </div>
            <div class="column">
                <page-menu :node="root"
                    :current="item"></page-menu>
            </div>
        </div>
        <div v-if="item.related.length"
            class="content mt-3">
            <h5 class="is-size-5">Related pages</h5>
            <ul>
                <li v-for="page in item.related"
                    :key="page.id">
                    <router-link :to="{ name: 'page-detail-id', params: { id: page.id } }">
                        <span>{{ page.title }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Page from '../models/Page';
import PageMenu from './PageMenu';
import BaseDetailComponent from "@/core/components/BaseDetailComponent";

export default {
    mixins: [BaseDetailComponent],
    components: {
        PageMenu,
    },
    data() {
        return {
            root: null,
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/pages/${this.$route.params.id || this.$route.params.slug}/`,
            modelClass: Page,
        }
    },
    methods: {
        async setup() {
            this.$store.dispatch("pages/resetNodes");
            await this.$store.dispatch("pages/loadTree", this.item.treeId);
            this.root = this.$store.getters["pages/getTreeRoot"](this.item.treeId);
        },
    },
}
</script>

<style lang="sass">
.page
    .content
        h1, h2, h3, h4, h5
            font-weight: 400
        img
            padding: 2px
            border: 1px solid silver
</style>