<template>
    <div>
        <autocomplete v-model="selectedCollection"
            :placeholder="placeholder"
            :load-results="loadCollections"
            :result-namer="x => x.name"
            :result-getter="x => x.results"
            @selected="onSelect">
        </autocomplete>
    </div>
</template>

<script>
import http from "@/http";
import Autocomplete from "@/core/components/Autocomplete.vue";

export default {
    props: {
        placeholder: String,
        modelValue: Object,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedCollection: null,
        }
    },
    created() {
        this.selectedCollection = this.modelValue;
    },
    methods: {
        async loadCollections(q) {
            return await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/`, { q })
                .then(resp => resp.json());
        },
        onSelect() {
            this.$emit('update:modelValue', this.selectedCollection);
        }
    },
    watch: {
        selectedCollection(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedCollection = val;
        },
    }
}
</script>