import { STATES_MODERATED } from "@/constants";
import BaseData from "@/core/models/BaseData";
import moment from 'moment';
import ContentFile from "./ContentFile";


class ContentVersion extends BaseData {
    constructor(data) {
        super(data);
        this.created = moment(data.created);
        this.files = data.files.map(x => new ContentFile(x));
        this.stateName = STATES_MODERATED[this.state].name;
    }
}

export default ContentVersion;
