import BaseData from "@/core/models/BaseData";
import { fileSizeFormat } from "@/utils";

class ContentFile extends BaseData {
    constructor(data) {
        super(data);
        this.filesize = fileSizeFormat(data.attachment_size);
    }

    get downloadUrl() {
        return `${process.env.VUE_APP_API_URL_V2}/content/download/${this.id}/`;
    }
}

export default ContentFile;
