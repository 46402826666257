<template>
    <span class="tags">
        <span v-for="field in fields"
            @click="toggleField(field)"
            :class="getFieldCSS(field)">
            {{ field.name }}:&nbsp;{{ field.value }}
        </span>
    </span>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        selectedFields: Object,
    },
    emits: ['update:modelValue', 'selected'],
    data() {
        return {
            fields: [],
        }
    },
    created() {
        Object.entries(this.modelValue).filter(x => x[1]).forEach(x => {
            this.fields.push({
                name: x[0],
                value: x[1],
                selected: false,
            })
        });
    },

    methods: {
        toggleField(field) {
            if (this.selectedFields[field.name])
                field.selected = false;
            else
                field.selected = !field.selected;

            this.$emit('selected', field);
        },
        getFieldCSS(field) {
            return {
                'tag': true,
                'is-info': true,
                'is-clickable': true,
                'is-light': !(field.selected || Object.keys(this.selectedFields).includes(field.name)),
            }
        }
    },
    watch: {
        selectedFields: {
            handler(val) {
                let selectedFieldNames = Object.keys(val);
                this.fields.forEach(x => x.selected = selectedFieldNames.includes(x.name));
            },
            deep: true,
        }
    }
}
</script>

<style lang="sass">
.tags
    align-items
        normal
</style>