<template>
    <h2 class="title">
        Content Usage
        <template v-if="project">
            for {{ project.number }} - {{ project.name }}
        </template>
    </h2>
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                <div class="field is-grouped">
                    <model-select v-model="model"
                        :project="project"
                        v-if="project"></model-select>
                    <project-autocomplete v-model="project"
                        placeholder="Select project"></project-autocomplete>
                </div>
            </div>
        </div>
        <div v-if="results.length"
            class="level-right">
            <div class="level-item">
                <div class="progress-wrapper">
                    <progress class="progress is-success"
                        :value="totalManaged"
                        :max="totalManaged + totalUnmanaged"></progress>
                    <span>{{ totalManaged }} managed items</span>
                </div>
            </div>
            <div class="level-item">
                <div class="progress-wrapper">
                    <progress class="progress is-danger"
                        :value="totalUnmanaged"
                        :max="totalManaged + totalUnmanaged"></progress>
                    <span>{{ totalUnmanaged }} unmanaged items</span>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!project"
        class="notification is-info">
        Select a project to continue
    </div>
    <div v-if="project && !results.length"
        class="notification is-info">
        No results available for this project
    </div>
    <table class="table is-fullwidth"
        v-if="results.length">
        <thead>
            <tr>
                <th>User</th>
                <th>Managed</th>
                <th>Unmanaged</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="result in results ">
                <td>
                    <a @click="loadUsageDetail(result)">
                        {{ result.userFullname }}
                    </a>
                    <spinner :active="result.loading"
                        size="large"></spinner>
                </td>
                <td>
                    <div class="progress-wrapper">
                        <progress class="progress is-success"
                            :value="result.managed"
                            :max="maxCount"></progress>
                        <span>{{ result.managed }}</span>
                    </div>
                    <ul class="mt-3"
                        v-if="result.showDetails">
                        <li v-for="item in result.getManagedDetails() "
                            :title="item.content_path">
                            <router-link :to="{ name: 'content-detail', params: { id: item.content_id } }">
                                {{ item.content_name }}
                            </router-link>
                        </li>
                    </ul>
                </td>
                <td>
                    <div class="progress-wrapper">
                        <progress class="progress is-danger"
                            :value="result.unmanaged"
                            :max="maxCount"></progress>
                        <span>{{ result.unmanaged }}</span>
                    </div>
                    <div v-if="result.showDetails"
                        class="unmanaged-details">
                        <div v-for="group in result.getUnmanagedDetails() ">
                            <label v-if="group[0]">
                                <span class="icon">
                                    <span class="mdi mdi-folder-outline"></span>
                                </span>
                                <span>{{ group[0] }}</span>
                            </label>
                            <ul>
                                <li v-for="item in group[1] ">
                                    {{ item.content_name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import ModelSelect from "@/bim/components/ModelSelect";
import ClearButton from "@/core/components/ClearButton";
import ProgressBar from "@/core/components/ProgressBar";
import Spinner from "@/core/components/Spinner";
import BaseData from "@/core/models/BaseData";
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import Project from "@/projects/models/Project";
import _ from "lodash";

class Result extends BaseData {
    constructor(data) {
        super(data);
        this.details = [];
        this.showDetails = false;
        this.loading = false;
    }

    getUnmanagedDetails() {
        let grouped = _.groupBy(this.details.filter(x => !x.content_id), 'content_path');
        return Object.entries(grouped);
    }

    getManagedDetails() {
        return this.details.filter(x => x.content_id);
    }
}

export default {
    components: {
        ClearButton,
        ModelSelect,
        ProgressBar,
        ProjectAutocomplete,
        Spinner,
    },
    data() {
        return {
            project: null,
            model: null,
            results: []
        }
    },
    async created() {
        if (!this.$route.query.project)
            return;

        let data = await http
            .get(`${process.env.VUE_APP_API_URL_V1}/projects/${this.$route.query.project}/`)
            .then(resp => resp.json());
        this.project = new Project(data);

        this.loadUsage();
    },
    computed: {
        maxCount() {
            let count = 0;
            this.results.forEach(x => {
                if (x.unmanaged > count)
                    count = x.unmanaged;

                if (x.managed > count)
                    count = x.managed;
            });
            return count;
        },
        totalManaged() {
            return this.results.map(x => x.managed).reduce((a, b) => a + b, 0);
        },
        totalUnmanaged() {
            return this.results.map(x => x.unmanaged).reduce((a, b) => a + b, 0);
        },
    },
    methods: {
        async loadUsage() {
            let args = {
                project: this.project.id,
            };

            if (this.model)
                args.model = this.model.id;

            let data = await http
                .get(`${process.env.VUE_APP_API_URL_V2}/content/usage/`, args)
                .then(resp => resp.json());

            this.results = data.map(x => new Result(x));
        },
        async loadUsageDetail(result) {
            result.showDetails = !result.showDetails;

            if (result.details.length)
                return;

            result.loading = true;

            let details = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/usage/detail/`,
                { user: result.userId, project: this.project.id })
                .then(resp => resp.json());

            result.details = _.sortBy(details, ['content_path', 'content_name']);
            result.loading = false;
            this.results = this.results.slice();
        }
    },
    watch: {
        model() {
            this.loadUsage();
        }
    }
}
</script>

<style lang="sass" scoped>
.unmanaged-details
    label
        font-style: italic
        display: inline-block
        margin: 5px 0

    ul
        margin-left: 1em
</style>