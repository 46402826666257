import { defaultConfig, plugin } from '@formkit/vue';
import '@mdi/font/css/materialdesignicons.css';
import 'bulma/css/bulma.css';
import mitt from 'mitt';
import * as Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

const app = Vue.createApp(App);

app.use(router);
app.use(store);
app.config.globalProperties.emitter = mitt();

// FormKit defaults
app.use(plugin, defaultConfig({
    config: {
        classes: {
            input: x => {
                const nodeType = x.props.type;
                if (nodeType == 'submit')
                    return 'button is-link';
                else if (nodeType == 'checkbox')
                    return 'checkbox';
                else if (nodeType == 'radio')
                    return '';
                else if (nodeType == 'textarea')
                    return 'textarea';
                else if (nodeType == 'select')
                    return '';
                else if (nodeType == 'file')
                    return '';
                else if (nodeType == 'button')
                    return 'button is-link';
                else
                    return 'input';
            },
            inner: x => {
                const nodeType = x.props.type;
                if (nodeType == 'select')
                    return 'select'
                if (nodeType == 'radio')
                    return '';
                else
                    return 'control';
            },
            label: x => {
                const nodeType = x.props.type;
                if (nodeType == 'checkbox')
                    return '';
                if (nodeType == 'radio')
                    return 'radio';
                else
                    return 'label';
            },
            message: x => {
                return '$reset notification is-danger';
            },
            button: 'button',
            outer: 'field',
            help: 'help',
        },
    },
}));

app.mount('#app');

export default app;
