<template>
    <div>
        <h2 class="title">Upload Shared Parameter File</h2>
        <div class="columns">
            <div class="column is-half">
                <div class="field">
                    <label class="label">Mode</label>
                    <select-component v-model="mode"
                        :choices="modes"></select-component>
                </div>
                <div v-if="mode == 'N'"
                    class="field">
                    <label class="label">Collection name</label>
                    <input v-model="name"
                        :class="{ 'is-danger': errors.name }"
                        class="input"
                        type="text">
                    <p v-if="errors.name"
                        class="help is-danger">{{ errors.name }}</p>
                </div>
                <div v-if="mode == 'E'"
                    class="field">
                    <label class="label">Collection</label>
                    <select-component v-if="collections"
                        v-model="collection"
                        :error="errors.collection"
                        :choices="collections"
                        show-all-text="No collection"></select-component>
                    <p v-if="errors.collection"
                        class="help is-danger">{{ errors.collection }}</p>
                </div>
                <div class="field">
                    <label class="label">File</label>
                    <input type="file"
                        :class="{ 'is-danger': errors.file }"
                        @change="fileChanged">
                    <p v-if="errors.file"
                        class="help is-danger">{{ errors.file }}</p>
                </div>
                <save-button @click="save"
                    :saving="saving"></save-button>
            </div>
            <div class="column is-half">
                <spinner :active="loading"
                    size="large"
                    type="cog"></spinner>
                <template v-if="!loading && preview">
                    <h4 class="subtitle">{{ preview.length }} Parameters</h4>
                    <table class="table is-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>GUID</th>
                                <th>Type</th>
                                <th>Group</th>
                            </tr>
                        </thead>
                        <tr v-for="param in preview">
                            <td>
                                <strong>{{ param.name }}</strong>
                            </td>
                            <td>
                                {{ param.guid }}
                            </td>
                            <td>
                                {{ param.dataType }}
                            </td>
                            <td>
                                {{ param.group }}
                            </td>
                        </tr>
                    </table>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import SaveButton from "@/core/components/SaveButton";
import SelectComponent from "@/core/components/SelectComponent";
import Spinner from "@/core/components/Spinner";
import http from "@/http";
import { orderBy } from "lodash";
import { showMessage } from "../../utils";
import { parseSharedParameterFile } from "../parsers";
import { error } from "@formkit/core";

const UPDATE_EXISTING = 'E';
const CREATE_NEW = 'N';

export default {
    components: { SelectComponent, SaveButton, Spinner },
    data() {
        return {
            collections: [],
            modes: {
                [UPDATE_EXISTING]: 'Update existing',
                [CREATE_NEW]: 'Create new',
            },
            collection: null,
            mode: CREATE_NEW,
            file: null,
            name: null,
            saving: false,
            loading: false,
            preview: null,
            errors: {},
        }
    },
    async created() {
        let data = await http.get(
            `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/`,
            { limit: 999 })
            .then(resp => resp.json());
        this.collections = data.results.map(x => {
            return [x.id, x.name];
        });
    },
    computed: {
        isValid() {
            this.errors = {};

            if (!this.file)
                this.errors.file = 'File is required';

            if (this.mode == UPDATE_EXISTING && !this.collection)
                this.errors.collection = 'Collection is required';
            else if (this.mode == CREATE_NEW && !this.name)
                this.errors.name = 'Name is required';

            return Object.keys(this.errors).length == 0;
        }
    },
    methods: {
        fileChanged(e) {
            this.file = e.target.files[0];
        },
        async save() {

            if (!this.isValid) {
                showMessage("Form is invalid", "danger");
                return;
            }

            this.saving = true;

            let formData = new FormData();
            formData.append('mode', this.mode);
            formData.append('file', this.file);

            if (this.mode == UPDATE_EXISTING)
                formData.append('collection', this.collection);
            else if (this.mode == CREATE_NEW)
                formData.append('name', this.name);

            let result = await http.post(
                `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/upload/`,
                formData)
                .then(resp => resp.json());

            if (result?.id)
                this.$router.push({
                    name: 'parameter-collection-detail',
                    params: {
                        id: result.id
                    }
                });

            this.saving = false;
        }
    },
    watch: {
        mode(val) {
            if (val == CREATE_NEW)
                this.collection = null;
            else if (val == UPDATE_EXISTING)
                this.name = null;
        },
        file(val) {
            this.loading = true;
            const reader = new FileReader();
            reader.onload = (e) => {
                this.preview = parseSharedParameterFile(e.target.result);
                this.preview = orderBy(this.preview, 'name');
                this.loading = false;
            };
            reader.readAsText(val);
        }
    }
}
</script>