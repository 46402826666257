<template>
    <div>
        <section-nav :names="['audit-rule-add']"
            strip="Audit"
            class="is-pulled-right">
        </section-nav>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }}
            Audit Rules
        </h2>
        <div class="field is-grouped is-grouped-multiline">
            <div class="control">
                <div class="select">
                    <select v-model="filters.type">
                        <option value="C">Content</option>
                        <option value="M">Model</option>
                    </select>
                </div>
            </div>
            <application-select v-model="filters.application"
                :show-all="true">
            </application-select>
            <div v-if="filters.application"
                class="control">
                <div class="select">
                    <select v-model="filters.element_type">
                        <option :value="null">All element types</option>
                        <option v-for="type in elementTypes"
                            :value="type">{{ type.toUpperCase() }}</option>
                    </select>
                </div>
            </div>
            <project-autocomplete v-model="filters.project">
            </project-autocomplete>
            <user-autocomplete v-model="filters.creator"
                v-if="!showMyRules"
                placeholder="Search rule creators">
            </user-autocomplete>
            <search-input v-model="filters.q"
                placeholder="Filter by name"></search-input>
            <div class="control">
                <label class="checkbox p-3">
                    <input v-model="showMyRules"
                        type="checkbox">
                    Show my rules only
                </label>
            </div>
            <div class="control">
                <label class="checkbox p-3">
                    <input v-model="filters.has_results"
                        type="checkbox">
                    Show proven valid rules only
                </label>
            </div>
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button @click="clearFilters"
                v-if="isFiltered"></clear-button>
        </div>
        <div class="columns is-multiline mt-3">
            <div class="column is-half"
                v-for="rule in items">
                <audit-rule-panel :rule="rule"></audit-rule-panel>
            </div>
        </div>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange"
            class="mt-5"></pagination>
    </div>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import UserAutocomplete from "@/users/components/UserAutocomplete";
import { cleanData } from "@/utils";
import ProjectAutocomplete from "../../projects/components/ProjectAutocomplete";
import AuditRule from "../models/AuditRule";
import AuditRulePanel from "./AuditRulePanel";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        AuditRulePanel,
        ClearButton,
        LimitSelect,
        Pagination,
        ProjectAutocomplete,
        SearchInput,
        SectionNav,
        UserAutocomplete,
    },
    data() {
        return {
            elementTypes: [],
            showMyRules: false,
            user: null,
            filters: {
                element_type: null,
                limit: null,
                offset: 0,
                q: null,
                type: 'M',
                has_results: true,
                application: null,
                project: null,
            },
            listUrl: `${process.env.VUE_APP_API_URL_V1}/audits/rules/`,
            modelClass: AuditRule,
        }
    },
    async created() {
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;
            if (filters.creator)
                filters.creator = filters.creator.id;
            if (filters.project)
                filters.project = filters.project.id;

            return filters;
        }
    },
    methods: {
        async loadTypes() {
            await this.$store.dispatch("audits/loadElementTypes", this.filters.application?.code);
            this.elementTypes = this.$store.getters["audits/getElementTypes"](this.filters.application?.code);
        },
        clearFilters() {
            this.filters = Object.assign({}, this._cache.filters);
            this.showMyRules = false;
        },
    },
    watch: {
        'filters.application': {
            handler(val) {
                if (val)
                    this.loadTypes();
            }
        },
        showMyRules(val) {
            if (val)
                this.filters.creator = this.user;
            else
                this.filters.creator = null;
        }
    }
}
</script>
