<template>
    <div>
        <section-nav :names="['stats-tools', 'stats-events']"
            class="is-pulled-right"></section-nav>
        <h2 class="title">Content Events</h2>
        <div class="field is-grouped is-grouped-multiline">
            <office-select v-model="filters.office">
            </office-select>
            <discipline-select v-model="filters.discipline"
                :show-all="true">
            </discipline-select>
            <application-select v-model="filters.application"
                :show-all="true">
            </application-select>
            <content-library-select v-model="filters.library"
                :show-all="true">
            </content-library-select>
            <project-autocomplete v-model="filters.project"
                :show-all="true">
            </project-autocomplete>
            <region-select v-model="filters.region">
            </region-select>
            <clear-button v-if="isFiltered"
                @click="clearFilters">
            </clear-button>
        </div>
        <div class="field is-grouped is-grouped-multiline">
            <div class="control">
                <div class="select">
                    <select v-model="filters.count">
                        <option value="id">
                            Count events
                        </option>
                        <option value="userprofile">
                            Count users
                        </option>
                    </select>
                </div>
            </div>
            <user-autocomplete v-model="filters.user">
            </user-autocomplete>
            <div class="control">
                <label class="checkbox p-3">
                    <input type="checkbox"
                        v-model="filters.no_admins">
                    Ignore events by library admins
                </label>
            </div>
        </div>
        <div>
            <div class="is-pulled-right"
                @click="toggleViewMode">
                <button class="button">
                    <span class="icon">
                        <span :class="viewModeIcon"></span>
                    </span>
                    <span>{{ viewModeText }}</span>
                </button>
            </div>
            <div class="level field is-grouped is-grouped-multiline">
                <div class="control p-2">
                    <label>From:</label>
                </div>
                <div class="control p-2">
                    <input class="input"
                        type="date"
                        v-model="filters.start"
                        :max="filters.end">
                </div>
                <div class="control p-2">
                    <label>To:</label>
                </div>
                <div class="control p-2">
                    <input class="input"
                        type="date"
                        v-model="filters.end">
                </div>
                <div class="control p-2">
                    <label>Group results by:</label>
                </div>
                <div class="control p-2">
                    <div class="select">
                        <select v-model="filters.period">
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                        </select>
                    </div>
                </div>
                <div class="control">
                    <label class="checkbox p-3">
                        <input type="checkbox"
                            v-model="filters.extrapolate">
                        Estimate count for last period
                    </label>
                </div>
            </div>
        </div>
        <div class="mt-6">
            <div v-if="mode == 'chart'">
                <base-chart v-if="chartData"
                    :data="chartData"
                    :options="chartOptions">
                </base-chart>
            </div>
            <div v-else>
                <base-table v-if="tableData"
                    :data="tableData">
                </base-table>
            </div>
        </div>
        <a class="button is-pulled-right mt-6"
            target="_blank"
            :href="reportUrl">
            <span class="icon">
                <span class="mdi mdi-file-excel-outline"></span>
            </span>
            <span>Download report</span>
        </a>
    </div>
</template>

<script>
import { COLORS } from "@/constants";
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseChart from "@/core/components/BaseChart";
import BaseListComponent from "@/core/components/BaseListComponent";
import BaseTable from "@/core/components/BaseTable";
import ClearButton from '@/core/components/ClearButton';
import DisciplineSelect from "@/core/components/DisciplineSelect";
import OfficeSelect from "@/core/components/OfficeSelect";
import RegionSelect from "@/core/components/RegionSelect";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import UserAutocomplete from "@/users/components/UserAutocomplete";
import { cleanData } from "@/utils";
import moment from "moment";
import { TYPES } from '../constants';

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        BaseChart,
        BaseTable,
        ClearButton,
        ContentLibrarySelect,
        DisciplineSelect,
        OfficeSelect,
        ProjectAutocomplete,
        RegionSelect,
        SectionNav,
        UserAutocomplete,
    },
    data() {
        return {
            filters: {
                office: null,
                discipline: null,
                application: null,
                library: null,
                count: 'id',
                period: 'month',
                end: moment().format('YYYY-MM-DD'),
                start: moment().subtract(12, 'months').format('YYYY-MM-DD'),
                type: null,
                user: null,
                project: null,
                region: null,
                no_admins: false,
                extrapolate: false,
            },
            chartData: [],
            chartOptions: {},
            tableData: [],
            mode: "chart",
        };
    },
    async mounted() {
        this.chartOptions = {
            aspectRatio: 1,
            responsive: true,
            scales: {
                A: {
                    position: "left",
                    stacked: true,
                    min: 0,
                },
                x: {
                    stacked: true,
                },
            },
        };
    },
    methods: {
        async loadItems() {
            const types = TYPES.Content;
            const typeColors = { D: COLORS.GREEN, L: COLORS.BLUE, V: COLORS.RED };

            let groupedItems = [];
            let filters = this.cleanedFilters;
            let labels = null;

            for (let typeCode of Object.keys(types)) {
                filters.type = typeCode;

                let resp = await http.get(
                    `${process.env.VUE_APP_API_URL_V1}/stats/content/grouped/`,
                    filters,
                    this._abortController?.signal,
                );

                if (resp && resp.status == 200) {
                    let data = await resp.json();
                    groupedItems.push({
                        type: typeCode,
                        label: types[typeCode],
                        data,
                    })

                    if (!labels && data.length)
                        labels = data.map(x => this.renderGrouper(x.period))
                }
            }

            this.chartData = {
                labels,
                datasets: groupedItems.map(x => {
                    return {
                        label: x.label,
                        data: x.data.map((entry) => entry.count),
                        backgroundColor: typeColors[x.type],
                        hidden: x.type == 'V',
                    }
                })
            }

            let tableRows = {};
            for (var group of groupedItems) {
                for (var data of group.data) {
                    let row = tableRows[data.period];
                    if (row)
                        row[group.type] = data.count;
                    else
                        tableRows[data.period] = { [group.type]: data.count };
                }
            }

            this.tableData = {
                headers: [this.renderHeader(this.filters.period), "Downloads", "Views", "Loaded"],
                data: Object.keys(tableRows).map(x => [
                    this.renderGrouper(x),
                    tableRows[x].D?.toLocaleString(),
                    tableRows[x].V?.toLocaleString(),
                    tableRows[x].L?.toLocaleString(),
                ]),
            }
        },
        renderGrouper(val) {
            if (this.filters.period == 'month')
                return moment(val).format('MMMM YY');
            if (this.filters.period == 'week')
                return moment(val).format('YYYY-MM-DD');
            if (this.filters.period == 'day')
                return moment(val).format('YYYY-MM-DD');
            else
                return val;
        },
        toggleViewMode() {
            this.mode = this.mode === "chart" ? "table" : "chart";
        },
        renderHeader(val) {
            return _.startCase(val);
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.office)
                filters.office = filters.office.id;
            if (filters.application)
                filters.application = filters.application.code;
            if (filters.discipline)
                filters.discipline = filters.discipline.id;
            if (filters.library)
                filters.library = filters.library.id;
            if (filters.user)
                filters.user = filters.user.id;
            if (filters.project)
                filters.project = filters.project.id;

            return filters;
        },
        viewModeText() {
            return this.mode === "chart" ? "Change to table view" : "Change to chart view";
        },
        viewModeIcon() {
            return this.mode === "chart" ? "mdi mdi-table" : "mdi mdi-chart-bar";
        },
        reportUrl() {
            return `${process.env.VUE_APP_API_URL_V1}/stats/content/report/?start=${this.filters.start}`;
        },
    },
};
</script>

<style lang="sass">
#bar-chart
    height: 600px
</style>