<template>
    <div v-if="user">
        <figure class="image is-128x128 is-pulled-right">
            <img :src="user.avatarUrl"
                class="is-rounded" />
        </figure>
        <h2 class="title">Tell us more about yourself&hellip;</h2>
        <div class="is-clearfix"></div>
        <div class="columns">
            <div class="column is-half">
                <div class="field">
                    <label class="label">First name:</label>
                    <div class="control">
                        <input type="text"
                            class="input"
                            v-model="user.firstName">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Last name:</label>
                    <div class="control">
                        <input type="text"
                            class="input"
                            v-model="user.lastName">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Discipline:</label>
                    <discipline-select v-model="user.discipline"
                        v-if="!showOtherDiscipline">
                    </discipline-select>
                    <div class="control">
                        <input type="text"
                            class="input"
                            v-if="showOtherDiscipline"
                            v-model="user.otherDiscipline">
                    </div>
                    <div class="help">
                        <a @click="showOtherDiscipline = true"
                            v-if="!showOtherDiscipline">
                            My discipline is not listed here</a>
                        <a @click="showOtherDiscipline = false"
                            v-else>Choose from list of disciplines</a>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Office:</label>
                    <office-select v-model="user.office"
                        v-if="!showOtherOffice"></office-select>
                    <div class="control">
                        <input type="text"
                            class="input"
                            v-if="showOtherOffice"
                            v-model="user.otherOffice">
                    </div>
                    <div class="help">
                        <a @click="showOtherOffice = true"
                            v-if="!showOtherOffice">My office is not listed here</a>
                        <a @click="showOtherOffice = false"
                            v-else>Choose from list of offices</a>
                    </div>
                </div>
                <save-button @click="save"
                    :saving="saving">Submit</save-button>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/http";
import User from "../models/User";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import OfficeSelect from "@/core/components/OfficeSelect";
import SaveButton from "@/core/components/SaveButton";

export default {
    components: {
        DisciplineSelect,
        OfficeSelect,
        SaveButton,
    },
    data() {
        return {
            user: null,
            showOtherOffice: false,
            showOtherDiscipline: false,
            saving: false,
        };
    },
    async mounted() {
        let url = "";
        if (this.$route.params.id)
            url = `${process.env.VUE_APP_API_URL_V1}/core/users/${this.$route.params.id}/`;
        else
            url = `${process.env.VUE_APP_API_URL_V1}/core/users/current/`;

        let data = await http.get(url).then(resp => resp.json());
        this.user = new User(data);

        // Redirect external users
        if (this.user.isExternal)
            this.$router.replace({ name: 'user-external', params: { id: this.user.id } })

        this.showOtherOffice = this.user.otherOffice && !this.user.office;
        this.showOtherDiscipline = this.user.otherDiscipline && !this.user.discipline;
    },
    methods: {
        async save() {
            this.saving = false;

            let data = {
                first_name: this.user.firstName,
                last_name: this.user.lastName,
            };

            if (this.user.otherOffice) {
                data.other_office = this.user.otherOffice;
            } else {
                data.office = this.user.office?.id;
            }

            if (this.user.otherDiscipline) {
                data.other_discipline = this.user.otherDiscipline;
            } else {
                data.discipline = this.user.discipline?.id;
            }

            await http.patch(
                `${process.env.VUE_APP_API_URL_V1}/core/users/${this.user.id}/`,
                data
            );

            this.$router.push({
                name: "user-detail",
                params: { id: this.user.id },
            });

            this.saving = false;
        },
    },
    watch: {
        showOtherOffice(val) {
            if (val)
                this.user.office = null;
            else
                this.user.otherOffice = null;
        },
        showOtherDiscipline(val) {
            if (val)
                this.user.discipline = null;
            else
                this.user.otherDiscipline = null;
        },
    }
};
</script>
