import { APPLICATIONS } from "@/constants";
import BaseData from "@/core/models/BaseData";
import moment from "moment";

const PRIORITIES = {
    1: 'High',
    2: 'Medium',
    3: 'Low',
}

const STATES = {
    W: 'Waiting',
    R: 'Running',
    C: 'Complete',
    E: 'Error',
    ST: 'Started',
    ED: 'Ended',
    ER: 'Error',
}

class Task extends BaseData {
    constructor(data) {
        super(data);
        this.lastChange = moment();
        this.state = this.state || 'W';
    }

    get priorityName() {
        return PRIORITIES[this.priority];
    }

    get applicationName() {
        return APPLICATIONS[this.application];
    }

    get stateName() {
        return STATES[this.state] || this.state;
    }

    get isRunning() {
        return this.state == 'R' || this.state == 'ST';
    }

    setState(state) {
        this.state = state;
        this.lastChange = moment();
    }
}

export default Task;
