<template>
    <figure v-if="userObject"
        :class="avatarClass"
        :title="user.name">
        <img :src="userObject.avatarUrl"
            class="is-rounded" />
    </figure>
</template>

<script>
export default {
    props: {
        user: Object,
        size: {
            type: String,
            default: 'medium',
        },
    },
    data() {
        return {
            userObject: null,
        }
    },
    async created() {
        await this.$store.dispatch("users/loadUserById", this.user.id);
        this.userObject = this.$store.getters["users/getUserById"](this.user.id);
    },
    computed: {
        avatarClass() {
            return {
                image: true,
                'is-16x16': this.size == 'small',
                'is-24x24': this.size == 'medium',
                'is-32x32': this.size == 'large',
            }
        }
    }
}
</script>