<template>
    <div v-if="item?.results">
        <section-nav :names="['audit-add']"
            class="is-pulled-right">
        </section-nav>
        <h2 class="title">Audit {{ item.id }}</h2>
        <div class="columns">
            <div class="column">
                <table class="table details">
                    <tr>
                        <th>Type:</th>
                        <td>{{ item.typeName }}</td>
                    </tr>
                    <tr>
                        <th>Created:</th>
                        <td>{{ item.created.fromNow() }}</td>
                    </tr>
                    <tr>
                        <th>Status:</th>
                        <td>{{ item.statusName }}</td>
                    </tr>
                    <tr v-if="item.project">
                        <th>Project:</th>
                        <td>
                            <router-link :to="{ name: 'project-detail', params: { id: item.project.id } }">
                                {{ item.project.name }}
                            </router-link>
                        </td>
                    </tr>
                    <tr v-if="item.library">
                        <th>Library:</th>
                        <td>
                            <router-link :to="{ name: 'content-library-detail', params: { id: item.library.id } }">
                                {{ item.library.title }}
                            </router-link>
                        </td>
                    </tr>
                    <tr>
                        <th>Template:</th>
                        <td>
                            <router-link :to="{ name: 'audit-template-edit', params: { id: item.template.id } }">
                                {{ item.template.name }}
                            </router-link>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="column">
                <div v-if="item.status == 'C'"
                    class="is-pulled-right">
                    <label>Overall score:</label>
                    <progress-bar v-model="item.score"></progress-bar>
                </div>
            </div>
        </div>
        <div v-if="item.status == 'C'">
            <audit-summary :audit="item"></audit-summary>
            <div class="my-5 is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <div class="control">
                        <div class="select">
                            <select v-model="filters.model">
                                <option :value="null">Show all files</option>
                                <option v-for="model in item.designModels"
                                    :value="model">{{ model.filename }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <div class="select">
                            <select v-model="filters.ruleType">
                                <option :value="null">Show all rule types</option>
                                <option v-for="type in ruleTypes">{{ type?.toUpperCase() }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <div class="select">
                            <select v-model="filters.result">
                                <option value="all">Show pass and fail results</option>
                                <option value="pass">Pass results only</option>
                                <option value="fail">Fail results only</option>
                            </select>
                        </div>
                    </div>
                    <clear-button @click="clearFilters"
                        v-if="isFiltered"></clear-button>
                </div>
                <h4 class="subtitle">
                    {{ filteredResults?.length }} Rules
                </h4>
            </div>

            <audit-result v-for="result in filteredResults"
                :key="result.id"
                :result="result"
                :model-count="item.designModels.length"
                :type="item.type"></audit-result>

            <report-link :project="item.project?.number"
                :data="{ audit: item.id }"
                type="MA"
                format="XLSX"></report-link>

            <a @click="rerun"
                class="button is-link is-pulled-right">
                <span class="icon">
                    <span class="mdi mdi-refresh"></span>
                </span>
                <span>
                    Re-run this audit
                </span>
            </a>
        </div>
        <div v-if="item.status == 'P'"
            class="notification is-info">Audit is still processing, please wait a few minutes</div>
        <div v-if="item.status == 'E'"
            class="notification is-danger">Audit could not be completed due to errors</div>
    </div>
</template>

<script>
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import ClearButton from "@/core/components/ClearButton";
import ProgressBar from "@/core/components/ProgressBar";
import ReportLink from "@/core/components/ReportLink";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import { showMessage } from '@/utils';
import Audit from "../models/Audit";
import AuditResult from "./AuditResult";
import AuditSummary from "./AuditSummary";

export default {
    mixins: [BaseDetailComponent],
    components: {
        AuditResult,
        AuditSummary,
        ClearButton,
        ProgressBar,
        ReportLink,
        SectionNav,
    },
    data() {
        return {
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/audits/${this.$route.params.id}/`,
            modelClass: Audit,
            filters: {
                model: null,
                result: 'all',
                ruleType: null,
            }
        };
    },
    methods: {
        async setup() {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/results/`,
                { audit: this.$route.params.id })
                .then(resp => resp.json());
            data.results.forEach(x => x.data = []);
            this.item.results = data.results;
        },
        clearFilters() {
            this.filters.result = 'all';
            this.filters.ruleType = null;
            this.filters.model = null;
        },
        async rerun() {
            const args = {
                project: this.item.project?.id,
                template: this.item.template.id,
                library: this.item.library?.id,
                type: this.item.type,
            };
            let data = await http.post(`${process.env.VUE_APP_API_URL_V1}/audits/`, args)
                .then(resp => resp.json());
            showMessage("Audit created");

            this.$router.push({ name: 'audit-detail', params: { id: data.id } });
        }
    },
    computed: {
        filteredResults() {
            let results = this.item.results.slice();

            if (this.filters.ruleType)
                results = results.filter(x => x.rule.element_types[0].toUpperCase() == this.filters.ruleType);

            if (this.filters.model)
                results = results.filter(result => {
                    if (!Array.isArray(result.data))
                        return false;

                    let found = false;
                    result.data.forEach(data => {
                        if (data.model__filename == this.filters.model.filename)
                            found = true;
                    })

                    return found;
                });

            if (this.filters.result == 'fail')
                results = results.filter(x => !x.result);
            else if (this.filters.result == 'pass')
                results = results.filter(x => x.result);

            return results;
        },
        ruleTypes() {
            if (!this.item.results)
                return [];

            return _.uniq(this.item.results.map(x => x.rule.element_types[0])).sort();
        },
        isFiltered() {
            return this.filters.result != 'all' || this.filters.ruleType || this.filters.model;
        }
    }
}
</script>
