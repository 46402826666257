<template>
    <section-nav :names="['task-list']"
        class="is-pulled-right">
    </section-nav>
    <h2 class="title">Live Tasks</h2>
    <div class="field is-grouped">
        <div class="control">
            <div class="select">
                <select v-model="filters.type">
                    <option selected
                        :value="null">All types</option>
                    <option v-for="type in types">{{ type }}</option>
                </select>
            </div>
        </div>
        <div class="control">
            <application-select v-model="filters.application"
                :show-all="true">
            </application-select>
        </div>
        <div class="control">
            <limit-select v-model="filters.limit"></limit-select>
        </div>
        <div class="control">
            <label class="checkbox p-3">
                <input type="checkbox"
                    v-model="filters.active">
                    Show running tasks only
            </label>
        </div>
    </div>
    <table class="table is-fullwidth mt-5">
        <thead>
            <tr>
                <th>Task</th>
                <th>Application</th>
                <th>Version</th>
                <th>Description</th>
                <td>Computer</td>
                <td>State</td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="task in tasks">
                <td>{{ task.task }}</td>
                <td>{{ task.applicationName }}</td>
                <td>
                    <span v-if="task.currentVersion && task.targetVersion">
                        {{ task.currentVersion }} → {{ task.targetVersion }}
                    </span>
                </td>
                <td>
                    <router-link v-if="task.content"
                        :to="{ name: 'content-detail', params: { id: task.content } }">
                        {{ task.description }}
                    </router-link>
                    <router-link v-if="task.model"
                        :to="{ name: 'model-detail', params: { id: task.model } }">
                        {{ task.description }}
                    </router-link>
                    <span v-if="task.path">{{ task.path }}</span>
                </td>
                <td>
                    {{ task.computer }}
                </td>
                <td>
                    <progress-bar v-if="task.isRunning">
                        {{ task.stateName }}
                    </progress-bar>
                    <span v-else>
                        {{ task.stateName }} at {{ task.lastChange.format('h:mma') }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template> 

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import LimitSelect from "@/core/components/LimitSelect";
import ProgressBar from "@/core/components/ProgressBar";
import SectionNav from "@/core/components/SectionNav";
import _ from "lodash";
import Task from "../models/Task";

export default {
    components: {
        SectionNav,
        ApplicationSelect,
        ProgressBar,
        LimitSelect
    },
    data() {
        return {
            taskMap: {},
            types: [],
            filters: {
                type: null,
                application: null,
                limit: null,
                active: false,
            }
        }
    },
    async created() {
        await this.$store.dispatch("bim/loadTaskTypes");
        this.types = this.$store.getters["bim/getTaskTypes"];

        this.initWebSockets();
    },
    methods: {
        initWebSockets() {
            this.taskMap = {};

            // Web sockets
            const wsUrl = `${process.env.VUE_APP_WEBSOCKET_URL}/task-events/`;
            const socket = new WebSocket(wsUrl);
            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                this.handleEvent(data);
            }
            socket.onerror = console.log;

            if (socket.readyState == WebSocket.OPEN)
                socket.onopen();
        },
        handleEvent(e) {
            let event = e.data.event;
            let taskId = e.data.task.id;
            let taskObj = null;

            if (this.taskMap[taskId]) {
                taskObj = this.taskMap[taskId]
            } else {
                taskObj = new Task(e.data.task);
            }

            taskObj.setState(event.type);
            taskObj.computer = event.computer_name;

            this.taskMap[taskId] = taskObj;
        }
    },
    computed: {
        tasks() {
            let tasks = Object.values(this.taskMap);

            if (this.filters.type)
                tasks = tasks.filter(x => x.task == this.filters.type);

            if (this.filters.application)
                tasks = tasks.filter(x => x.application == this.filters.application.code);

            if (this.filters.active)
                tasks = tasks.filter(x => x.isRunning);

            tasks = _.orderBy(tasks, 'lastChange', 'desc');

            return tasks.slice(0, this.filters.limit);
        }
    }
}
</script>
