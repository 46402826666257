<template>
    <div v-if="user">
        <h2 class="title">External User: {{ user }}</h2>
        <div v-if="user.isExternal">
            <div class="columns">
                <div class="column is-two-thirds">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Approved</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="project in user.restrictedProjects">
                                <td>{{ project.name }}</td>
                                <td>
                                    <span>
                                        {{
                                            project.approved
                                                ? "Approved"
                                                : "Pending approval"
                                        }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="column">
                    <div class="box">
                        <h4 class="subtitle">Request project access</h4>
                        <textarea class="textarea"
                            v-model="requestedProjects"></textarea>
                        <p class="pt-4">
                            Enter the Jacobs project number for each project you will be
                            working on, one number per line.
                        </p>
                        {{ projects }}
                        <p class="pt-4 is-italic">{{ terms }}</p>
                        <label class="checkbox py-3">
                            I acknowledge the terms of service for external users
                            <input v-model="acknowledge"
                                type="checkbox" />
                        </label>
                        <button @click="submit"
                            :disabled="!requestedProjects || !acknowledge"
                            class="button is-link mt-2">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else
            class="notification">You are not an external user</div>
    </div>
</template>

<script>
import { getSnippet } from "@/utils";
import _ from "lodash";
import http from "@/http";
import { showMessage } from "@/utils";

export default {
    data() {
        return {
            user: null,
            requestedProjects: "",
            terms: "",
            acknowledge: false,
        };
    },
    async mounted() {
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;

        this.terms = await getSnippet("external-user-terms");
    },
    methods: {
        async submit() {
            let projectNumbers = _.uniq(
                this.requestedProjects
                    .split(/\r?\n/)
                    .filter(x => x)
                    .map(x => x.trimEnd())
            );

            let resp = await http.patch(
                `${process.env.VUE_APP_API_URL_V1}/core/users/${this.user.id}/`,
                { requested_projects: projectNumbers });

            if (resp.status == 200) {
                await this.$store.dispatch("users/loadCurrentUser", true);
                this.user = this.$store.state.users.currentUser;
                this.requestedProjects = "";
                showMessage("Request submitted for approval");
            } else {
                showMessage("Request failed", 'danger');
            }
        },
    },
};
</script>
