<template>
    <div class="result mb-3"
        :class="{ pass: result.result, fail: !result.result }">
        <div class="m-3">
            <div class="is-pulled-right">
                <a @click="showRuleFilters = true"
                    v-if="!showRuleFilters"
                    title="Show rule details"
                    class="button is-ghost">
                    <span class="mdi mdi-information mdi-18px"></span>
                </a>
                <a @click="showRuleFilters = false"
                    v-if="showRuleFilters"
                    title="Hide rule details"
                    class="button is-ghost">
                    <span class="mdi mdi-chevron-up-circle mdi-18px"></span>
                </a>
            </div>
            <h4 class="is-size-6">
                {{ result.rule.name }}
            </h4>
        </div>
        <div class="m-3">
            <table v-if="showRuleFilters"
                class="detail">
                <tr>
                    <th>Polarity:</th>
                    <td>
                        {{ result.rule.polarity }}
                    </td>
                </tr>
                <tr>
                    <th>Threshold:</th>
                    <td>
                        {{ result.rule.threshold }}
                        <span v-if="!result.rule.absolute_threshold">%</span>
                    </td>
                </tr>
                <tr>
                    <th>Weight:</th>
                    <td>
                        {{ result.rule.weight }}
                    </td>
                </tr>
                <tr>
                    <th>Types:</th>
                    <td>
                        {{ result.rule.element_types.map(x => x.toUpperCase()).join(', ') }}
                    </td>
                </tr>
                <tr>
                    <th>Filters:</th>
                    <td>
                        <pre>{{ result.rule.filters }}</pre>
                    </td>
                </tr>
            </table>
            <table class="detail">
                <tr v-if="hasData">
                    <th>Matching files:</th>
                    <td>
                        <progress-bar v-model="matchedFilesPercentage">
                            {{ filenames.length }} / {{ modelCount }}
                        </progress-bar>
                    </td>
                </tr>
                <tr>
                    <th>Matching elements:</th>
                    <td>
                        <progress-bar v-model="matchedElementsPercentage">
                            {{ result.count.toLocaleString() }} / {{ result.total.toLocaleString() }}
                        </progress-bar>
                    </td>
                </tr>
                <tr>
                    <th>Result:</th>
                    <td>
                        <span v-if="result.result"
                            class="has-text-success">
                            <span class="mdi mdi-check"></span>
                            Pass
                        </span>
                        <span v-else
                            class="has-text-danger">
                            <span class="mdi mdi-close"></span>
                            Fail
                            (more than {{ result.rule.threshold }}<span v-if="!result.rule.absolute_threshold">%</span>)
                        </span>
                    </td>
                </tr>
            </table>

            <div v-if="loading"
                class="has-text-centered">
                <spinner :active="loading"
                    type="cog"
                    size="medium"></spinner>
            </div>

            <div v-else>

                <div v-if="showDetails && loaded"
                    class="result-details">

                    <div>
                        <div class="field is-grouped mt-3">
                            <search-input v-model="search.q"
                                placeholder="Search in results"></search-input>
                            <div class="control">
                                <div class="select">
                                    <select v-model="search.filename">
                                        <option :value="null">All files</option>
                                        <option v-for="filename in filenames">{{ filename }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="subTypes.length"
                                class="control">
                                <div class="select">
                                    <select v-model="search.sub_type">
                                        <option :value="null">All types</option>
                                        <option v-for="subType in subTypes">{{ subType }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <base-table v-if="hasData"
                            :data="tableData"></base-table>
                        <div v-else>
                            No data available
                        </div>
                    </div>

                </div>

                <div v-if="!loaded || hasData"
                    class="has-text-centered">
                    <a @click="showDetails = true"
                        v-if="!showDetails"
                        title="Show result details">
                        <span class="mdi mdi-chevron-down mdi-24px"></span>
                    </a>
                    <a @click="showDetails = false"
                        v-if="showDetails"
                        title="Hide result details">
                        <span class="mdi mdi-chevron-up mdi-24px"></span>
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import _ from "lodash";
import ProgressBar from "@/core/components/ProgressBar";
import BaseTable from "@/core/components/BaseTable";
import SearchInput from "@/core/components/SearchInput";
import http from "@/http";
import Spinner from "@/core/components/Spinner";

export default {
    props: ['result', 'modelCount', 'type'],
    components: {
        ProgressBar,
        BaseTable,
        SearchInput,
        Spinner,
    },
    data() {
        return {
            showRuleFilters: false,
            showDetails: false,
            loaded: false,
            loading: false,
            search: {
                q: null,
                filename: null,
                sub_type: null,
            }
        }
    },
    mounted() {

    },
    methods: {
        renderHeader(header) {
            header = header.replace('data__', '');
            return _.startCase(header);
        },
        async loadDetail() {
            this.loading = true;
            let resultData = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/results/${this.result.id}/`)
                .then(resp => resp.json());
            this.result.data = resultData.data;
            this.loading = false;
            this.loaded = true;
        }
    },
    computed: {
        filenames() {
            if (this.result.data.length)
                return _.uniq(this.result.data.map(x => x.model__filename || x.content__title)).sort();
            else
                return [];
        },
        subTypes() {
            if (this.result.data.length)
                return _.uniq(this.result.data.filter(x => x.sub_type).map(x => x.sub_type)).sort();
            else
                return [];
        },
        matchedFilesPercentage() {
            return parseInt(this.filenames.length * 100 / this.modelCount);
        },
        matchedElementsPercentage() {
            if (this.result.total > 0)
                return parseInt(this.result.count * 100 / this.result.total);
            else
                return 0;
        },
        filteredData() {
            return this.result.data.filter(x => {
                let found = true;

                if (this.search.q)
                    found = found && x.name.toLowerCase().includes(this.search.q);

                if (this.search.sub_type)
                    found = found && x.sub_type == this.search.sub_type;

                if (this.search.filename)
                    found = found && (x.model__filename == this.search.filename || x.content__title == this.search.filename);

                return found;
            });
        },
        hasData() {
            return this.filteredData.length;
        },
        tableData() {
            if (this.hasData) {
                let stopKeys = ['guid'];
                if (this.type == 'M')
                    stopKeys.push('content_id');
                else if (this.type == 'C')
                    stopKeys.push('model_id');

                return {
                    headers: Object.keys(this.filteredData[0])
                        .filter(x => !stopKeys.includes(x))
                        .map(x => this.renderHeader(x)),
                    data: this.filteredData.map(x => Object.entries(x).filter(x => !stopKeys.includes(x[0])).map(x => x[1])),
                }
            } else {
                return {
                    headers: [],
                    data: [],
                }
            }
        },
    },
    watch: {
        showDetails() {
            if (!this.loaded)
                this.loadDetail();
        }
    }
}
</script>

<style lang="sass">
.card
    .card-header
        .card-header-title
            padding: 0

.result
    border: 2px solid #aaa
    border-radius: 0.25rem

    &.fail
        border-color: #d9534f 

    &.pass
        border-color: #5cb85c

    .result-details
        max-height: 400px
        overflow-y: auto

        td,th 
            font-size: 90%
</style>