<template>
    <div class="control">
        <autocomplete v-model="selectedUser"
            :initial-value="selectedUser?.name"
            :placeholder="placeholder"
            :load-results="loadUsers">
        </autocomplete>
    </div>
</template>
 
<script>
import http from "@/http";
import Autocomplete from "@/core/components/Autocomplete.vue";
import User from "../models/User";

export default {
    props: {
        placeholder: {
            default: 'Search users'
        },
        initialValue: String,
        modelValue: Object,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedUser: null,
        }
    },
    created() {
        this.selectedUser = this.modelValue;
    },
    methods: {
        async loadUsers(q) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/`, { q })
                .then(resp => resp.json());
            return data.results.map(x => new User(x));
        },
    },
    watch: {
        selectedUser(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedUser = val;
        }
    }
}
</script>

