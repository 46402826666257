<template>
    <div ref="el"
        class="editor"></div>
</template>

<script>
import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/edit/closetag.js';
import 'codemirror/mode/django/django.js';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint.js';

export default {
    props: {
        modelValue: Object,
        mode: String,
        hinter: {
            type: Object,
            required: true,
        },
    },
    emits: ['change'],
    data() {
        return {
            value: null,
        }
    },
    mounted() {
        this.value = this.modelValue;
        this.initEditor();
    },
    methods: {
        async initEditor() {
            if (!this.hinter)
                return;  

            CodeMirror.registerHelper('hint', this.hinter.name, this.hinter.hintFunction);
            CodeMirror.commands.autocomplete = (cm) => {
                CodeMirror.showHint(cm, CodeMirror.hint[this.hinter.name]);
            };
            const editor = CodeMirror(this.$refs.el, {
                value: this.value,
                mode: this.mode,
                lineNumbers: false,
                readOnly: false,
                showCursorWhenSelecting: true,
                autoCloseBrackets: true,
                autoCloseTags: true,
            })

            editor.on('change', () => {
                this.$emit('update:modelValue', editor.getValue());
            })

            editor.on('keyup', function (cm, event) {
                if (cm.state.completionActive)
                    return;

                const ignoreKeys = [8, 13, 37, 39, 36, 35];
                if (ignoreKeys.includes(event.keyCode))
                    return;

                CodeMirror.commands.autocomplete(cm, null, { completeSingle: false });
            });
        },
    },
    watch: {
        modelValue(val) {
            this.value = val;
        },
        hinter() {
            CodeMirror.registerHelper('hint', this.hinter.name, this.hinter.hintFunction);
            CodeMirror.commands.autocomplete = (cm) => {
                CodeMirror.showHint(cm, CodeMirror.hint[this.hinter.name]);
            };
        }
    },
}
</script>


<style lang="sass">
.editor 
    height: 100%
    width: 100%
    overflow: hidden
    

.editor .CodeMirror 
    height: 100%
    background: lightyellow

</style>