<template>
    <slot v-if="!hidden">
    </slot>
    <a @click="hidden = !hidden">
        <span class="icon">
            <span v-if="hidden"
                class="mdi mdi-eye-off-outline"
                title="Click to reveal"></span>
            <span v-else
                class="mdi mdi-eye-outline"
                title="Click to hide"></span>
        </span>
    </a>
</template>

<script>
export default {
    data() {
        return {
            hidden: true,
        }
    },  
}
</script>