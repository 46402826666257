<template>
    <button @click="save"
        v-if="selectedUsers.length"
        class="button is-link is-pulled-right">
        Update {{ selectedUsers.length }} users
    </button>
    <h2 class="title">{{ pageTitle }} External Users</h2>
    <div class="field is-grouped is-grouped-multiline">
        <div class="control">
            <div class="select">
                <select v-model="filters.approved">
                    <option :value="null">Choose state</option>
                    <option :value="true">Approved</option>
                    <option :value="false">Not approved</option>
                </select>
            </div>
        </div>
        <search-input v-model="filters.q"
            placeholder="Filter by name or email"></search-input>
        <project-autocomplete v-model="filters.project"
            placeholder="Filter by project"></project-autocomplete>
        <limit-select v-model="filters.limit"></limit-select>
        <clear-button @click="clearFilters"
            v-if="isFiltered"></clear-button>
    </div>
    <table class="table is-fullwidth">
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Approved</th>
                <th>Projects</th>
                <th>Select users</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in items"
                @dblclick="user.selected = !user.selected"
                :class="{ highlight: user.selected }">
                <td>{{ user.fullName }}</td>
                <td>
                    <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                </td>
                <td>
                    <input type="checkbox"
                        v-model="user.isApproved">
                </td>
                <td>
                    <ul>
                        <li v-for="project in user.restrictedProjects">
                            <router-link :to="{ name: 'project-detail', params: { id: project.id } }">
                                {{ project.name }}
                            </router-link>

                            <input type="checkbox"
                                v-model="project.approved"
                                title="Approve project membership"
                                class="ml-2">

                            <a @click="removeProject(user, project)"
                                title="Remove project">
                                <span class="icon">
                                    <span class="mdi mdi-close"></span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </td>
                <td>
                    <input type="checkbox"
                        v-model="user.selected">
                </td>
                <td>
                    {{ user.comment }}
                </td>
            </tr>
        </tbody>
    </table>
    <pagination :has-prev="hasPrev"
        :has-next="hasNext"
        @pagechanged="onPageChange">
    </pagination>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from '@/core/components/ClearButton';
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from '@/core/components/SearchInput';
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import moment from "moment";
import User from '../models/User';

export default {
    mixins: [BaseListComponent],
    components: {
        ClearButton,
        LimitSelect,
        Pagination,
        ProjectAutocomplete,
        SearchInput,
    },
    data() {
        return {
            filters: {
                approved: null,
                external: true,
                limit: null,
                offset: 0,
                project: null,
                q: null,
            }
        }
    },
    methods: {
        async loadItems() {
            let filters = this.cleanedFilters;

            if (this.filters.project)
                filters.project = filters.project.number;

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/`, filters)
                .then(resp => resp.json());

            this.resultsCount = data.count;
            this.items = data.results.map((x) => new User(x));
        },
        removeProject(user, project) {
            user.restrictedProjects = user.restrictedProjects.filter(x => x.id !== project.id);
        },
        save() {
            this.selectedUsers.forEach(async user => {
                user.comment = '';
                const data = {
                    is_approved: user.isApproved,
                    restricted_projects: (user.restrictedProjects || []).map(x => {
                        return {
                            id: x.id,
                            approved: x.approved
                        };
                    }),
                };
                await http.patch(`${process.env.VUE_APP_API_URL_V1}/core/users/${user.id}/`, data);
                user.comment = `Updated at ${moment().format("h:mm:ss a")}`;
                user.selected = false;
            });
        },
    },
    computed: {
        selectedUsers() {
            return this.items.filter(x => x.selected);
        }
    }

}
</script>