<template>
    <div class="columns">
        <div class="column">
            <router-link :to="{ name: 'stats-summary' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Summary
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Summary statistics</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column">
            <router-link :to="{ name: 'stats-tools' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Tools
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Tool usage statistics</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column">
            <router-link :to="{ name: 'stats-events' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Events
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Browse model, tool, content and project events</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column">
            <router-link :to="{ name: 'stats-content' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Content
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Content usage statistics</div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
