<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedUnits">
                <option :value="null" v-if="showAll">All units</option>
                <option value="I">Imperial</option>
                <option value="M">Metric</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
        showAll: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedUnits: null,
        }
    },
    created() {
        this.selectedUnits = this.modelValue || null;
    },
    watch: {
        selectedUnits(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedUnits = val;
        }
    }
}
</script>