<template>
    <div>
        <h2 v-if="items"
            class="title">
            {{ pageTitle }} Images
        </h2>
        <div class="field is-grouped is-grouped-multiline">
            <application-select v-model="filters.application"
                :show-all="true">
            </application-select>
            <content-library-select v-model="filters.library"
                :show-all="true">
            </content-library-select>
            <content-creator-select v-model="filters.creator"
                :show-all="true">
            </content-creator-select>
            <search-input v-model="filters.q"
                placeholder="Text search">
            </search-input>
            <clear-button v-if="isFiltered"
                @click="clearFilters">
            </clear-button>
        </div>
        <div v-if="tags"
            class="level">
            <div class="level-left">
                <div class="level-item">
                    <span>
                        Image Tags:
                    </span>
                </div>
                <div class="level-item">
                    <span class="tags">
                        <a @click="filters.image_tags = [tag]"
                            v-for="tag in tags"
                            class="tag"
                            :class="{'is-success': filters.image_tags?.includes(tag) }"
                            :key="tag">{{ tag }}
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="level controls">
            <div class="level-left">
                <div v-for="(slot, i) in actionSlots"
                    :key="i"
                    class="level-item">
                    <div class="select">
                        <select v-model="slot.action">
                            <option v-for="action in actions"
                                :value="action"
                                :key="action">
                                {{ action }}
                            </option>
                        </select>
                    </div>
                    <span v-if="i < actionSlots.length - 1"
                        class="icon">
                        <span class="mdi mdi-arrow-right-bold"></span>
                    </span>
                </div>
                <div class="level-item">
                    <button v-if="actionSlots.length > 1"
                        class="button is-ghost"
                        @click="removeAction"
                        title="Remove action">
                        <span class="icon">
                            <span class="mdi mdi-minus-circle"></span>
                        </span>
                    </button>
                    <button @click="addAction"
                        class="button is-ghost"
                        title="Add action">
                        <span class="icon">
                            <span class="mdi mdi-plus-circle"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div class="level-right">
                <div v-if="selectedImagesCount.length"
                    class="level-item">
                    <button @click="resetAll"
                        class="button is-ghost">
                        <span class="icon">
                            <span class="mdi mdi-restore"></span>
                        </span>
                        <span>Reset all</span>
                    </button>
                    <button @click="runActions"
                        class="button is-link">
                        Run
                        {{ actionSlots.length }} {{ actionSlots.length === 1 ? 'action' : 'actions' }} on
                        {{ selectedImagesCount }}
                        {{ selectedImagesCount === 1 ? 'image' : 'images' }}
                    </button>
                </div>
            </div>
        </div>
        <div v-for="item in items"
            class="tile-row">
            <div class="tile title">
                <router-link :to="{
                    name: 'content-detail',
                    params: { id: item.id }
                }"
                    class="has-text-weight-medium is-size-6">{{ item.title }}
                </router-link>
                <button @click="regenerate(item)"
                    class="button is-ghost"
                    title="Regenerate thumbnails">
                    <span class="icon">
                        <span class="mdi mdi-refresh"></span>
                    </span>
                </button>
            </div>
            <div v-for="image in item.images"
                class="tile">
                <div class="tile-body">
                    <a @click="imageClick(image)">
                        <figure class="image">
                            <img :src="image.thumbnail">
                        </figure>
                        <ul class="actions">
                            <li v-for="action in image.actions">{{ action.name }}</li>
                        </ul>
                    </a>
                    <tag-node 
                        v-for="tag in image.tags"
                        :text = "tag"
                        :size = "'small'">  
                    </tag-node>
                </div>
                <div class="tile-header">
                    <a @click="showDetail(image)"
                        class="button is-ghost is-pulled-right">
                        <span class="icon">
                            <span class="mdi mdi-magnify"></span>
                        </span>
                    </a>
                </div>
                <div class="tile-footer">
                    <a @click="revert(item)"
                        v-if="image.can_revert"
                        title="Revert to original version">
                        <span class="icon">
                            <span class="mdi mdi-restore"></span>
                        </span>
                    </a>
                    <a @click="deleteImage(item)"
                        class="is-pulled-right"
                        title="Delete">
                        <span class="icon">
                            <span class="mdi mdi-trash-can-outline"></span>
                        </span>
                    </a>
                </div>
            </div>
            <div class="is-clearfix"></div>
        </div>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
        <detail-dialog ref="detailDialog">
            <div class="images">
                <img :src="selectedImage.image" />
            </div>
        </detail-dialog>
    </div>
</template>

<script>
import { ACTIONS, DELETE_ACTION } from "@/content/constants";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import DetailDialog from "@/core/components/DetailDialog";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import TagNode from "@/core/components/TagNode";
import http from '@/http';
import { cleanData } from "@/utils";
import ContentCreatorSelect from "./ContentCreatorSelect";
import ContentLibrarySelect from "./ContentLibrarySelect";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ContentLibrarySelect,
        ContentCreatorSelect,
        SearchInput,
        DetailDialog,
        ClearButton,
        Pagination,
        TagNode,
    },
    data() {
        return {
            filters: {
                application: null,
                library: null,
                creator: null,
                limit: null,
                offset: 0,
                tag: null,
            },
            tags: [],
            actionSlots: [],
            actions: ACTIONS,
            selectedImage: null,
            selectedImagesCount: 0,
            data: [],
        };
    },
    async mounted() {
        this.actionSlots = [{ action: this.actions[0] }];
        await this.$store.dispatch("core/loadTagsList", 'content.ContentImage');
        this.tags = this.$store.getters["core/getTagsByContentType"]('content.ContentImage');
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;
            if (filters.library)
                filters.library = filters.library.id;
            if (filters.creator)
                filters.creator = filters.creator.id;
            return filters;
        },
    },
    methods: {
        async loadItems() {
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/all/`, 
                this.cleanedFilters)
                .then(resp => resp.json());

            this.items = data.results;
            this.resultsCount = data.count;
        },
        async showDetail(image) {
            this.selectedImage = image;
            await this.$refs.detailDialog.show({ title: image.name });
        },
        async imageUpdate(image, data) {
            await http.patch(`${process.env.VUE_APP_API_URL_V2}/content/images/${image.id}/`, data)
                .then(resp => {
                    Object.assign(image, resp.data);
                });
        },
        resetAll() {
            for (const content of this.items) {
                for (const image of content.images) {
                    image.actions = [];
                }
            };
        },
        imageClick(img) {
            this.selectedImage = img;
            if (!img.actions || img.actions.length == 0) {
                img.actions = this.actionSlots.map(x => x.action);
                this.selectedImagesCount++;
            } else {
                img.actions = null;
                this.selectedImagesCount--;
            }
        },
        addAction() {
            this.actionSlots.push({
                action: this.actions[0],
            });
        },
        removeAction() {
            this.actionSlots.pop();
        },
        revert(image) {
            this.imageUpdate(image, { revert: true });
        },
        deleteImage(image) {
            if (image.actions && image.actions[0] == DELETE_ACTION) {
                image.actions = [];
                this.selectedImagesCount--;
            } else {
                image.actions = [DELETE_ACTION];
                this.selectedImagesCount++;
            }
        },
        regenerate(content) {
            const data = {
                content: content.id,
                task: 'GenerateThumbnail',
                application: content.application,
                name: 'Auto',
            }
            http.post(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/`, data);
        },
        async runActions() {
            for (const content of this.items) {
                for (const image of content.images) {
                    if (image.actions) {
                        const actionNames = image.actions.map(x => x.name);
                        const actionFunctions = image.actions.filter(x => x.f).map(x => x.f);
                        await this.runActionOnImage(image, content, actionNames, actionFunctions);
                    }
                }
            }
        },
        async runActionOnImage(image, content, actionNames, actionFunctions) {
            try {
                const data = await http.patch(
                    `${process.env.VUE_APP_API_URL_V2}/content/images/${image.id}/`,
                    { transforms: actionNames })
                    .then(resp => resp.json());

                Object.assign(image, data);
                image.actions = null;
                this.selectedImagesCount--;

                for (const f of actionFunctions)
                    f(image, content);

                let thumb = image.thumbnail;
                image.thumbnail = '';
                image.thumbnail = thumb;

            } catch (error) {
                console.error("Error running action on image:", error);
            }
        },
    },
};
</script>

<style lang="sass" scoped>
.tile 
    float: left
    margin: 5px
    position: relative
    height: 140px
    width: 110px
    border: 1px solid silver

    &.title 
        width: 300px
        font-weight: 700
        align-items: center
        text-overflow: ellipsis
        border: none

    .tile-header 
        position: absolute
        top: 0
        left: 0
        right: 0
        height: 20px
        padding: 0px 3px

    .tile-body 
        position: absolute
        height: 100px
        left: 0
        right: 0
        bottom: 20px
        margin: 0 5px

        .actions
            position: absolute
            top: 50%
            left: 50%
            color: red
            font-size: 75%
            text-align: center
            transform: translate(-50%, -50%)
            background-color: #fff

    .tile-footer 
        position: absolute
        bottom: 10px
        left: 0
        right: 0
        height: 20px
        padding: 2px 3px

.images 
    height: 450px
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    position: relative

.controls 
    padding: 1em
    background-color: #e5e5e5
    border: 1px solid #c8c8c8
    margin-bottom: 2em

</style>