<template>
    <div>
        <section-nav :names="['notification-add']"
            class="is-pulled-right">
        </section-nav>
        <h2 class="title">
            {{ pageTitle }} Notifications
        </h2>
        <div class="field is-grouped is-multiline">
            <select-component v-model="filters.type"
                :show-all="true"
                show-all-text="All types"
                :choices="types"></select-component>
            <select-component v-model="filters.level"
                :show-all="true"
                show-all-text="All levels"
                :choices="levels"></select-component>
            <select-component v-model="filters.target"
                :show-all="true"
                show-all-text="All targets"
                :choices="targets"></select-component>
            <clear-button @click="clearFilters"
                v-if="isFiltered"></clear-button>
        </div>
        <table class="table is-fullwidth mt-5">
            <thead>
                <tr>
                    <th>Text</th>
                    <th>Type</th>
                    <th>Level</th>
                    <th>Target</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Projects</th>
                    <th>Dismissable</th>
                    <th>Active</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="notification in items"
                    :key="notification.id"
                    :class="{ inactive: !notification.active }">
                    <td>
                        <router-link v-if="notification.type == 'MS'"
                            :to="{ name: 'notification-edit', params: { id: notification.id } }">
                            {{ notification.text }}
                        </router-link>
                        <span v-else>{{ notification.text }}</span>
                    </td>
                    <td>
                        {{ notification.typeName }}
                    </td>
                    <td>
                        {{ notification.levelName.toUpperCase() }}
                    </td>
                    <td>
                        {{ notification.targetNames.join(', ') }}
                    </td>
                    <td>{{ notification.start }}</td>
                    <td>{{ notification.end }}</td>
                    <td>
                        <span v-if="notification.projects.length">
                            {{ notification.projects.map(x => x.number).join(', ') }}
                        </span>
                    </td>
                    <td>
                        <span v-if="notification.dismissable"
                            class="icon">
                            <span class="mdi mdi-check-bold"></span>
                        </span>
                    </td>
                    <td>
                        <span v-if="notification.active"
                            class="icon">
                            <span class="mdi mdi-check-bold"></span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import Pagination from "@/core/components/Pagination";
import http from '@/http';
import { startCase, cloneDeep } from "lodash";
import Notification, { LEVELS, TARGETS, TYPES } from "../models/Notification";
import ClearButton from "./ClearButton";
import SectionNav from "./SectionNav";
import SelectComponent from "./SelectComponent";

let levels = cloneDeep(LEVELS);

Object.keys(levels).forEach(x => levels[x] = `${startCase(levels[x])} (or above)`);

export default {
    mixins: [BaseListComponent],
    components: { SectionNav, Pagination, SelectComponent, ClearButton },
    data() {
        return {
            filters: {
                show_all: true,
                limit: 20,
                offset: 0,
                type: null,
                level: null,
                target: null,
            },
            types: TYPES,
            levels: levels,
            targets: TARGETS,
        }
    },
    methods: {
        async loadItems() {
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/core/notifications/`,
                this.cleanedFilters)
                .then(resp => resp.json());

            this.items = data.results.map(x => new Notification(x));
            this.resultsCount = data.count;
        },
    }
}
</script>

<style lang="sass">
.table
    tbody
        tr.inactive
            background: #f8f8f8
            td, td a
                color: #999
</style>