<template>
    <section-nav :names="['stats-events', 'stats-content']"
        class="is-pulled-right"></section-nav>
    <h2 class="title">Tool Events</h2>
    <div class="field is-grouped is-grouped-multiline">
        <office-select v-model="filters.office">
        </office-select>
        <discipline-select v-model="filters.discipline"
            :show-all="true">
        </discipline-select>
        <div class="control">
            <div class="select">
                <select v-model="filters.count">
                    <option value="id">
                        Count events
                    </option>
                    <option value="userprofile">
                        Count users
                    </option>
                </select>
            </div>
        </div>
        <application-select v-model="filters.application"
            :show-all="true">
        </application-select>
        <tools-select v-model="filters.tool"
            :show-all="true">
        </tools-select>
        <user-autocomplete v-model="filters.user"
            :placeholder="'Filter by user'">
        </user-autocomplete>
        <clear-button v-if="isFiltered"
            @click="clearFilters">
        </clear-button>
    </div>
    <div class="field is-grouped is-grouped-multiline">
        <label class="label p-2">From:</label>
        <div class="control">
            <input class="input"
                type="date"
                v-model="filters.start">
        </div>
        <label class="label p-2">To:</label>
        <div class="control">
            <input class="input"
                type="date"
                v-model="filters.end"
                :max="maxDate">
        </div>
        <label class="label p-2">Group results by:</label>
        <div class="control">
            <div class="select">
                <select v-model="filters.grouper">
                    <option value="date__day">Day</option>
                    <option value="date__week">Week</option>
                    <option value="date__month">Month</option>
                    <option value="tool_name">Tool name</option>
                    <option value="application">Application</option>
                </select>
            </div>
        </div>
        <label class="label p-2">Count per:</label>
        <div class="control">
            <div class="select">
                <select v-model="filters.count">
                    <option value="id">Event</option>
                    <option value="userprofile">User</option>
                </select>
            </div>
        </div>
    </div>
    <a @click="toggleViewMode"
        class="button is-pulled-right">
        <span class="icon">
            <span :class="viewModeIcon"></span>
        </span>
        <span>
            {{ viewModeText }}
        </span>
    </a>
    <div class="is-clearfix"></div>
    <base-chart v-if="mode == 'chart' && chartData"
        :data="chartData"
        :options="chartOptions">
    </base-chart>
    <base-table v-if="mode == 'table' && tableData"
        :data="tableData">
    </base-table>
    <a class="button is-pulled-right mt-6"
        :href="reportUrl">
        <span class="icon">
            <span class="mdi mdi-file-excel-outline"></span>
        </span>
        <span>Download report</span>
    </a>
</template>

<script>
import { APPLICATIONS, COLORS } from "@/constants";
import ApplicationSelect from "@/core/components/ApplicationSelect.vue";
import BaseChart from "@/core/components/BaseChart";
import BaseListComponent from "@/core/components/BaseListComponent";
import BaseTable from "@/core/components/BaseTable.vue";
import ClearButton from '@/core/components/ClearButton.vue';
import DisciplineSelect from "@/core/components/DisciplineSelect.vue";
import OfficeSelect from "@/core/components/OfficeSelect.vue";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import UserAutocomplete from "@/users/components/UserAutocomplete.vue";
import { cleanData } from "@/utils";
import _ from "lodash";
import moment from "moment";
import ToolsSelect from "./ToolsSelect.vue";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        BaseChart,
        BaseTable,
        ClearButton,
        DisciplineSelect,
        OfficeSelect,
        SectionNav,
        ToolsSelect,
        UserAutocomplete,
    },
    data() {
        return {
            filters: {
                office: null,
                discipline: null,
                count: 'id',
                application: null,
                tool: null,
                user: null,
                end: moment().format('YYYY-MM-DD'),
                start: moment().subtract(12, 'months').format('YYYY-MM-DD'),
                grouper: 'date__month',
            },
            groupedEvents: [],
            chartData: [],
            chartOptions: {},
            tableData: [],
            toolMap: {},
            mode: "chart",
        }
    },
    methods: {
        async loadItems() {
            this.groupedEvents = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/stats/tools/grouped/`,
                this.cleanedFilters
            ).then(resp => resp.json());

            const seriesLabel = this.filters.count == 'userprofile'
                ? 'Unique users'
                : 'Events';

            this.chartData = {
                labels: this.groupedEvents.map(x => this.renderGrouper(x.grouper)),
                datasets: [{
                    label: seriesLabel,
                    data: this.groupedEvents.map(x => x.count),
                    backgroundColor: COLORS.GREEN,
                }],
            };

            this.tableData = {
                headers: [this.renderHeader(this.filters.grouper), "Value"],
                data: this.groupedEvents.map(x => [
                    this.renderGrouper(x.grouper),
                    x.count.toLocaleString(),
                ]),
            };
        },
        renderGrouper(val) {
            if (this.filters.grouper == 'tool_name') {
                let tool = this.toolMap[val];
                if (tool)
                    return `${tool.description} (${tool.application})`;
                else
                    return val;
            }
            if (this.filters.grouper == 'application')
                return APPLICATIONS[val] ?? val;
            if (this.filters.grouper == 'date__month')
                return moment(val).format('MMMM YY');
            if (this.filters.grouper == 'date__week')
                return moment(val).format('YYYY-MM-DD');
            if (this.filters.grouper == 'date__day') {
                return moment(val).format('YYYY-MM-DD');
            }
            else
                return val;
        },
        toggleViewMode() {
            this.mode = this.mode === "chart" ? "table" : "chart";
        },
        renderHeader(val) {
            return _.startCase(val);
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;
            if (filters.office)
                filters.office = filters.office.id;
            if (filters.discipline)
                filters.discipline = filters.discipline.id;
            if (filters.user)
                filters.user = filters.user.id;

            return filters;
        },
        viewModeText() {
            return this.mode === "chart" ? "Change to table view" : "Change to chart view";
        },
        viewModeIcon() {
            return this.mode === "chart" ? "mdi mdi-table" : "mdi mdi-chart-bar";
        },
        maxDate() {
            return moment().format('YYYY-MM-DD');
        },
        reportUrl() {
            return `${process.env.VUE_APP_API_URL_V1}/stats/tools/report/?start=${this.filters.start}`;
        },
    },
};
</script>