<template>
    <div class="navbar-item has-dropdown is-hoverable"
        :key="user?.id"
        v-if="user?.id">
        <a class="navbar-link is-arrowless">
            <figure class="image">
                <img :src="user.avatarUrl"
                    class="is-rounded" />
            </figure>
        </a>
        <div class="navbar-dropdown">
            <span class="navbar-item">
                <span class="icon">
                    <span class="mdi mdi-account"></span>
                </span>
                <span>
                    Logged in as {{ user.fullName }}
                </span>
            </span>
            <router-link :to="{ name: 'user-detail', params: { id: user.id } }"
                class="navbar-item">
                <span class="icon">
                    <span class="mdi mdi-card-account-details"></span>
                </span>
                <span>
                    My profile page
                </span>
            </router-link>
            <template v-if="user.isAdmin">
                <router-link :to="{ name: 'manage' }"
                    class="navbar-item">
                    <span class="icon">
                        <span class="mdi mdi-cog"></span>
                    </span>
                    <span>
                        Management page
                    </span>
                </router-link>
            </template>
            <router-link :to="{ name: 'logout' }"
                class="navbar-item">
                <span class="icon">
                    <span class="mdi mdi-logout"></span>
                </span>
                <span>
                    Logout
                </span>
            </router-link>
            <router-link :to="{ name: 'password-change' }"
                class="navbar-item">
                <span class="icon">
                    <span class="mdi mdi-form-textbox-password"></span>
                </span>
                <span>
                    Change password
                </span>
            </router-link>
        </div>
    </div>
    <div v-else
        class="navbar-item">
        <router-link :to="{ name: 'login' }">
            Login
        </router-link>
    </div>
</template>

<script>
export default {
    async created() {
        await this.loadUser();

        if (!this.user)
            setTimeout(this.loadUser, 500);
    },
    methods: {
        async loadUser() {
            await this.$store.dispatch("users/loadCurrentUser");
        }
    },
    computed: {
        user() {
            return this.$store.state.users.currentUser;
        }
    }
};
</script>

<style lang="sass" scoped>
.navbar-item img
    max-height: 2.2rem

.navbar-dropdown
    background: #fff
    a, span
        color: #333
</style>
