<template>
    <div v-if="currentUser">
        <h2 class="title">Permissions</h2>
        <div class="columns">
            <div class="column is-one-third">
                <div class="control">
                    <user-autocomplete v-if="currentUser.isSuperUser"
                        v-model="user"
                        placeholder="Select user"></user-autocomplete>
                </div>

                <div class="control">
                    <label>User:</label> {{ user }}
                </div>

                <div v-if="user"
                    class="mt-3">
                    <label class="">Groups</label>
                    <div class="tags">
                        <span v-for="group in user.groups"
                            class="tag is-success">
                            {{ group }}
                        </span>
                    </div>
                    <label class="">Permissions</label>
                    <div class="tags scrollable">
                        <span v-for="permission in user.permissions"
                            class="tag is-info">
                            <template v-if="permission == '*'">All permissions (super user)</template>
                            <template v-else>{{ permission }}</template>
                        </span>
                    </div>
                </div>
            </div>
            <div class="column is-two-thirds">
                <div class="field is-grouped is-grouped-multiline">
                    <search-input v-model="filters.q"
                        placeholder="Filter by name or path"></search-input>
                    <div class="control">
                        <div class="select">
                            <select v-model="filters.routeGroup">
                                <option :value="null">All route groups</option>
                                <option v-for="group in routeGroups">{{ group }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <div class="select">
                            <select v-model="filters.userGroup">
                                <option :value="null">All user groups</option>
                                <option v-for="group in groups"
                                    :value="group">{{ group.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <div class="select">
                            <select v-model="filters.permission">
                                <option :value="null">All permissions</option>
                                <option v-for="permission in permissions">{{ permission }}</option>
                            </select>
                        </div>
                    </div>
                    <clear-button v-if="isFiltered"
                        @click="clearFilters"></clear-button>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Group</th>
                    <th>Name</th>
                    <th>Path</th>
                    <th>Permissions Required</th>
                    <th v-if="user">User Can Access</th>
                    <th>Groups</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="route in filteredRoutes">
                    <td>{{ route.group }}</td>
                    <td>{{ route.name }}</td>
                    <td>
                        <code>{{ route.path }}</code>
                    </td>
                    <td>
                        <span class="tags">
                            <span v-for="permission in route.permissionsRequired"
                                class="tag is-info">
                                {{ permission }}
                            </span>
                        </span>
                    </td>
                    <td v-if="user">
                        <span v-if="route.userCanAccess"
                            class="icon has-text-success">
                            <span class="mdi mdi-check-bold"></span>
                        </span>
                        <span v-else
                            class="icon has-text-danger">
                            <span class="mdi mdi-close-thick"></span>
                        </span>
                    </td>
                    <td>
                        <span class="tags">
                            <span v-for="group in route.groups"
                                class="tag is-success">
                                {{ group.name }}
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import SearchInput from "@/core/components/SearchInput";
import UserAutocomplete from "@/users/components/UserAutocomplete";
import { orderBy, uniq } from 'lodash';
import { PERMISSIONS } from '../../constants';

export default {
    mixins: [BaseListComponent],
    components: {
        UserAutocomplete,
        ClearButton,
        SearchInput,
    },
    data() {
        return {
            groups: [],
            permissions: Object.values(PERMISSIONS),
            user: null,
            currentUser: null,
            filters: {
                q: null,
                routeGroup: null,
                userGroup: null,
                permission: null,
            }
        }
    },
    async created() {
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
        this.currentUser = this.$store.state.users.currentUser;

        await this.$store.dispatch("users/loadGroups");
        this.groups = this.$store.state.users.groups;
    },
    computed: {
        routeGroups() {
            return uniq(this.items.filter(x => x.group).map(x => x.group));
        },
        filteredRoutes() {
            let routes = [...this.items];

            if (this.filters.routeGroup)
                routes = routes.filter(x => x.group == this.filters.routeGroup);

            if (this.filters.userGroup)
                routes = routes.filter(x => x.groups.includes(this.filters.userGroup))

            if (this.filters.permission)
                routes = routes.filter(x => x.permissionsRequired.includes(this.filters.permission))

            if (this.filters.q)
                routes = routes.filter(x => {
                    const normalizedQ = this.filters.q.toLowerCase();
                    return x.name.toLowerCase().includes(normalizedQ) ||
                        x.path.includes(normalizedQ);
                });

            return routes;
        },
    },
    methods: {
        async loadItems() {
            if (!this.user)
                return;

            this.items = this.$router.getRoutes().map(x => {
                let routePermissions = x.meta.permissionsRequired || [];
                let userHasPermissions = true;

                if (x.meta.permissionsRequired)
                    userHasPermissions = routePermissions.every(x => this.user.permissions.includes(x))

                let groups = [];
                for (let group of this.groups) {
                    let groupHasPermission = routePermissions.some(x => group.permissions.includes(x));
                    if (groupHasPermission)
                        groups.push(group);
                }

                return {
                    name: x.meta.title || x.name,
                    path: x.path,
                    permissionsRequired: routePermissions,
                    group: x.meta.group,
                    userCanAccess: this.user.isSuperUser || userHasPermissions,
                    groups: groups,
                }
            });

            this.items = orderBy(this.items, ['group', 'path']);
        }
    },
    watch: {
        async user(val) {
            if (val) {
                await this.user.load();
                await this.loadItems();
            }
        }
    }
}
</script>

<style lang="sass" scoped>
div.tags:not(:last-child)
    margin-bottom: 0.5em

.scrollable 
    max-height: 200px
    overflow-y: auto
</style>