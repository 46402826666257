<template>
    <base-chart type="line"
        :data="modelData"></base-chart>
</template>

<script>
import http from "@/http";
import BaseChart from "@/core/components/BaseChart";
import { COLORS } from "@/constants";

export default {
    props: ['model', 'period'],
    components: {
        BaseChart,
    },
    data() {
        return {
            modelData: {
                labels: [],
                datasets: [],
            },
        }
    },
    async mounted() {
        const args = {
            period: this.period,
        }
        let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/${this.model.id}/data/`, args)
            .then(resp => resp.json());

        this.modelData.labels = data.labels;

        let colors = Object.values(COLORS);
        let i = 0;
        for (const [k, v] of Object.entries(data.data)) {
            this.modelData.datasets.push(
                {
                    label: k,
                    data: v,
                    borderColor: colors[i % colors.length],
                }
            )
            i++;
        }
    }
}
</script>