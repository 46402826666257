<template>
    <a :class="classObject"
        @click="handleClick"
        v-if="action">
        {{ condition.name }}
    </a>
    <span :class="classObject"
        v-else>{{ condition.name }}</span>
</template>

<script>

import _ from "lodash";

export default {
    props: ['condition', 'action'],
    methods: {
        handleClick() {
            if (this.action == 'exclude') {
                this.condition.excluded = !this.condition.excluded;
                this.condition.included = false;
            }
            else if (this.action == 'include') {
                this.condition.included = !this.condition.included;
                this.condition.excluded = false;
            }
        }
    },
    computed: {
        classObject() {
            let active = (this.action == 'exclude' && this.condition.excluded) || (this.action == 'include' && this.condition.included);
            return {
                'is-success': active,
                'tag': true,
            };
        },
    }
}
</script>