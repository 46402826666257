<template>
    <h2 class="title">Enter your new password</h2>
    <div class="columns">
        <div class="column is-half">
            <FormKit type="form"
                @submit="save">
                <FormKit type="password"
                    label="Password"
                    validation="required"
                    validation-visibility="live"
                    name="password">
                </FormKit>
                <FormKit type="password"
                    label="Password confirmation"
                    name="password_confirm"
                    validation="required|confirm"
                    validation-visibility="live"
                    validation-label="Password confirmation">
                </FormKit>
            </FormKit>
        </div>
    </div>
</template>

<script>
import { FormKit } from "@formkit/vue";
import { showMessage } from '@/utils';

export default {
    methods: {
        async save(form) {
            let resp = await fetch(`${process.env.VUE_APP_API_URL_V1}/auth/password/reset/confirm/`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    uid: this.$route.params.uid,
                    token: this.$route.params.token,
                    new_password1: form.password,
                    new_password2: form.password_confirm,
                })
            });

            if (resp.status == 200) {
                showMessage('Your password has been reset')
                this.$router.push({ name: 'login' });
            } else {
                showMessage("Your password could not be reset", 'danger');
            }
        }
    }
}
</script>