import BaseData from "@/core/models/BaseData";
import { ASSOCIATED_FILETYPES } from "../constants";

class ContentAssociatedFile extends BaseData {
    constructor(data) {
        super(data);
        this.downloadUrl = `${process.env.VUE_APP_API_URL_V2}/content/associated-files/${this.id}/download/`;
    }

    get typeName() {
        return ASSOCIATED_FILETYPES[this.type];
    }

    toString() {
        return this.title;
    }
}

export default ContentAssociatedFile;
