<template>

</template>

<script>
export default {
    mounted() {
        this.$router.replace({
            name: 'not-found',
            query: { path: this.$route.path },
        });
    }
}
</script>