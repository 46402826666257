import { PERMISSIONS } from '@/constants';
import PageDetail from './components/PageDetail';
import PageIndex from './components/PageIndex';
import PageRedirect from './components/PageRedirect';

let routes = [
    {
        path: '/help/',
        name: 'page-index',
        component: PageIndex,
        meta: {
            title: 'Help',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        },
    },
    {
        path: '/help/:id/',
        name: 'page-detail-id',
        component: PageDetail,
        meta: {
            title: 'Help',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Index', route: 'page-index' },
            ],
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        }
    },
    {
        path: '/help/:slug/',
        name: 'page-detail-slug',
        component: PageDetail,
        meta: {
            title: 'Help',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Index', route: 'page-index' },
            ],
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        }
    },
    {
        path: '/help/redirect/',
        name: 'page-redirect',
        component: PageRedirect,
        meta: {
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        },
    },
]

routes.forEach(x => x.meta.group = 'Pages');
export default routes;