<template>
    <div class="control">
        <autocomplete v-model="selectedTemplate"
            :placeholder="placeholder"
            :load-results="loadTemplates"
            :result-namer="x => x.name"
            :result-getter="x => x.results">
        </autocomplete>
    </div>
</template>

<script>
import http from "@/http";

import Autocomplete from "@/core/components/Autocomplete.vue";

export default {
    props: {
        placeholder: String,
        modelValue: Object,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedTemplate: null,
        }
    },
    created() {
        this.selectedTemplate = this.modelValue;
    },
    methods: {
        async loadTemplates(q) {
            return await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/templates/?q=${q}`)
                .then(resp => resp.json());
        },
    },
    watch: {
        selectedTemplate(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedTemplate = val;
        },
    }
}
</script>