import BaseData from "@/core/models/BaseData";
import store from "@/store";
import AuditRuleSection from "./AuditRuleSection";
import { APPLICATIONS } from "@/constants";
import User from "@/users/models/User";

class AuditRule extends BaseData {
    constructor(data) {
        super(data);

        this.connector = 'AND';
        this.sections = [];

        if (data.filters) {
            if (data.filters.OR) {
                this.connector = 'OR';
                this.sections = data.filters.OR.map(x => new AuditRuleSection(x));
            } else if (data.filters.AND) {
                this.connector = 'AND';
                this.sections = data.filters.AND.map(x => new AuditRuleSection(x));
            } else {
                this.sections.push(new AuditRuleSection(data.filters));
            }
        }

        this.weight = this.weight || 1;
        this.threshold = this.threshold || 1;
        this.selected = false;

        if (this.creator)
            this.creator = new User(this.creator);
    }

    async load() {
        await store.dispatch("core/loadApplicationsList");
        this.application = store.getters["core/getApplicationByCode"](this.application?.code || this.application)
    }

    get typeName() {
        return this.type == 'M' ? 'Model' : 'Content';
    }

    get applicationName() {
        return APPLICATIONS[this.application] || this.application;
    }

    get normalizedWeight() {
        if (this.polarity)
            return `+${this.weight}`;
        else
            return `-${this.weight}`;
    }

    addSection() {
        this.sections.push(new AuditRuleSection());
    }

    removeSection(section) {
        this.sections = this.sections.filter(x => x != section);
    }

    duplicateSection(section) {
        let dupe = new AuditRuleSection(section.completedSection());
        this.sections.push(dupe);
    }

    isComplete() {
        return this.name && this.type && this.sections.length > 0 && this.sections.every(x => x.isComplete());
    }
}

export default AuditRule;