import BaseData from "@/core/models/BaseData";

const TYPES = {
    MA: 'Model Audit',
    ME: 'Model Elements',
    ML: 'Master List',
    RDS: 'Room Data Sheet',
}

const FORMATS = {
    PDF: 'PDF',
    XLSX: 'Excel',
}

const ICONS = {
    XLSX: 'mdi-file-excel-outline',
}


export default class Report extends BaseData {
    constructor(data) {
        super(data);
    }

    get typeName() {
        return TYPES[this.type] || this.type;
    }

    get formatName() {
        return FORMATS[this.format] || this.format;
    }

    get iconClass() {
        return ICONS[this.format] || 'mdi-file-outline';
    }
}
