<template>
    <h2 class="title">
        Create Audit
    </h2>
    <div class="field">
        <label class="label">Type</label>
        <div class="control">
            <div class="select">
                <select v-model="audit.type">
                    <option value="M">Model</option>
                    <option value="C">Content</option>
                </select>
            </div>
        </div>
    </div>
    <div class="field"
        v-if="audit.type == 'M'">
        <label class="label">Project</label>
        <project-autocomplete v-model="audit.project"
            placeholder="Search project" />
    </div>
    <div class="field"
        v-if="audit.type == 'C'">
        <label class="label">Library</label>
        <content-library-select v-model="audit.library" />
    </div>
    <div class="field">
        <label class="label">Template</label>
        <audit-template-autocomplete v-model="audit.template"
            placeholder="Search Templates" />
    </div>
    <save-button @click="save"
        :disabled="!showButton"
        :saving="saving"></save-button>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import SaveButton from "@/core/components/SaveButton";
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import Audit from "../models/Audit";
import AuditTemplateAutocomplete from "./AuditTemplateAutocomplete";
import { showMessage } from "@/utils";

export default {
    components: {
        ProjectAutocomplete,
        AuditTemplateAutocomplete,
        SaveButton,
        ContentLibrarySelect,
    },
    data() {
        return {
            audit: null,
            saving: false,
        }
    },
    created() {
        this.audit = new Audit({ type: 'M' });
    },
    methods: {
        async save() {
            if (!this.audit.template.rules.length) {
                showMessage(`Template ${this.audit.template.name} has no rules defined`, 'danger');
                return;
            }

            if (this.audit.project.model_count == 0) {
                showMessage(`Project ${this.audit.project.name} has no models`, 'danger');
                return;
            }

            this.saving = true;

            let data = {
                type: this.audit.type,
                template: this.audit.template.id,
            }
            if (this.audit.project)
                data.project = this.audit.project.id;
            if (this.audit.library)
                data.library = this.audit.library.id;

            let result = await http.post(`${process.env.VUE_APP_API_URL_V1}/audits/`, data)
                .then(resp => resp.json());

            this.saving = true;

            this.$router.push({
                name: 'audit-detail',
                params: {
                    id: result.id
                }
            });
        }
    },
    computed: {
        showButton() {
            if (this.audit.type == 'M')
                return this.audit.project?.id && this.audit.template?.id;
            else
                return this.audit.library?.id && this.audit.template?.id;
        }
    }
}
</script>

<style lang="sass" scoped>
input.custom-input
    width: 500px
</style>