<template>
    <div v-if="item">
        <router-link :to="{ name: 'parameter-collection-edit', params: { id: item.id } }"
            class="button is-pulled-right">
            Edit
        </router-link>
        <h2 class="title">{{ item.name }}</h2>
        <table class="detail">
            <tr>
                <th>Discipline</th>
                <td>{{ item.discipline?.name }}</td>
            </tr>
            <tr>
                <th>Library</th>
                <td>{{ item.library?.title }}</td>
            </tr>
            <tr>
                <th>Original filename</th>
                <td>{{ item.originalFilename }}</td>
            </tr>
            <tr>
                <th>Active</th>
                <td>{{ item.active }}</td>
            </tr>
        </table>
        <h3 class="subtitle mt-4 mb-2">{{ item.parameters.length }} Parameters</h3>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Data Type</th>
                    <th>Group</th>
                    <th>Binding Group</th>
                    <th>Categories</th>
                    <th>Instance</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="parameter in item.parameters">
                    <td>{{ parameter.name }}</td>
                    <td>{{ parameter.dataType }}</td>
                    <td>{{ parameter.group }}</td>
                    <td>{{ parameter.bindingGroup }}</td>
                    <td>{{ parameter.categories.join(', ') }}</td>
                    <td>
                        <span v-if="parameter.instance"
                            class="icon">
                            <span class="mdi mdi-check"></span>
                        </span>
                    </td>
                    <td>
                        {{ parameter.actionName }}
                        <span v-if="parameter.existingParameter">
                            "{{ parameter.existingParameter }}"
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <a class="button"
                :href="downloadUrl">
                <span class="icon">
                    <span class="mdi mdi-file-outline"></span>
                </span>
                <span>Download as text file</span>
            </a>
        </div>
    </div>
</template>

<script>
import http from "@/http";
import ParameterCollection from "../models/ParameterCollection";
import BaseDetailComponent from "@/core/components/BaseDetailComponent";

export default {
    mixins: [BaseDetailComponent],
    data() {
        return {
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/${this.$route.params.id}/`,
            modelClass: ParameterCollection,
        };
    },
    computed: {
        downloadUrl() {
            return `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/${this.$route.params.id}/?raw`;
        }
    }
};
</script>
