import BaseData from "@/core/models/BaseData";
import { APPLICATIONS } from "@/constants";


class ContentFilenameFormat extends BaseData {
    constructor(data) {
        super(data);
        this.application = {
            code: this.application,
            name: APPLICATIONS[this.application],
        }
        this.default = this.default || false;
        this.format = this.format || "{{ original_title }}";
        this.selected = true;
    }

    toString() {
        return `${this.application.name} ${this.format}`;
    }
}

export default ContentFilenameFormat;
