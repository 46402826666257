<template>
    <div class="panel">
        <div class="panel-heading">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <span>{{ resultsCount || 'Search' }} Parameters</span>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item"></div>
                    <a @click="$emit('selected')"
                        v-if="selectedParameters.length"
                        class="button is-small">
                        <span class="icon">
                            <span class="mdi mdi-plus-circle-outline"></span>
                        </span>
                        <span>Add selected parameters to collection </span>
                    </a>
                </div>
            </div>
        </div>
        <div class="panel-block">
            <div>
                <div class="field">
                    <label class="label">Filter by collection</label>
                    <parameter-collection-autocomplete v-model="filters.collection"
                        placeholder="Select Collection">
                    </parameter-collection-autocomplete>
                </div>
                <div class="field">
                    <label class="label">Filter by parameter name</label>
                    <search-input v-model="filters.q"
                        placeholder="Search parameters">
                    </search-input>
                </div>
            </div>
        </div>
        <div v-for="parameter in parameters"
            :class="{ selected: parameter.selected, saved: parameter.saved }"
            @click="parameter.selected = !parameter.selected"
            class="panel-block">
            <input type="checkbox"
                v-model="parameter.selected" />
            <label>{{ parameter.name }}</label>
        </div>
        <div v-if="parameters.length"
            class="panel-block">
            <pagination :has-prev="hasPrev"
                :has-next="hasNext"
                @pagechanged="onPageChange"></pagination>
        </div>
        <div v-else
            class="panel-block">No results</div>
    </div>
</template>

<script>
import http from "@/http";
import ParameterCollectionAutocomplete from "./ParameterCollectionAutocomplete";
import SearchInput from "@/core/components/SearchInput";
import { cleanData } from "@/utils.js";
import Pagination from '@/core/components/Pagination';


export default {
    props: {
        modelValue: Object,
    },
    emits: ["update:modelValue", "selected"],
    components: {
        ParameterCollectionAutocomplete,
        SearchInput,
        Pagination,
    },
    data() {
        return {
            filters: {
                q: null,
                collection: null,
                limit: 20,
                offset: 0,
            },
            parameters: [],
            resultsCount: null,
        };
    },
    created() { },
    computed: {
        selectedParameters() {
            return this.parameters.filter((x) => x.selected);
        },
        hasPrev() {
            return this.filters.offset > 0;
        },
        hasNext() {
            return this.parameters.length > 0 && (this.resultsCount > this.filters.offset + this.parameters.length);
        },
    },
    methods: {
        async loadParameters() {
            this.parameters = [];
            this.resultsCount = null;

            let filters = cleanData(this.filters);

            if (filters.collection)
                filters.collection = filters.collection.id;

            if (!filters.q && !filters.collection)
                return;

            let data = await http
                .get(
                    `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/`,
                    filters
                )
                .then((resp) => resp.json());
            this.resultsCount = data.count;

            if (typeof this.resultsCount != "number")
                this.resultsCount = this.resultsCount.value;

            this.parameters = data.results;
        },
        onPageChange(e) {
            if (e == 'previous')
                this.filters.offset -= this.filters.limit;
            else if (e == 'next')
                this.filters.offset += this.filters.limit;
        },
    },
    watch: {
        selectedParameters(val) {
            this.$emit("update:modelValue", val);
        },
        filters: {
            async handler() {
                await this.loadParameters();
            },
            deep: true,
        },
    },
};
</script>
