<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedType">
                <option :value="null"
                    v-if="showNone">None</option>
                <option :value="null"
                    v-if="showAll">All types</option>
                <option v-for="type in types"
                    :value="type">{{ type.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        application: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedType: null,
        }
    },
    async created() {
        await this.$store.dispatch("content/loadTypeList");
        if (this.modelValue)
            this.selectedType = this.types.find(x => x.code == this.modelValue.code);
        else
            this.selectedType = null;
    },
    computed: {
        types() {
            const allTypes = this.$store.getters["content/getTypes"];
            if (this.application)
                return allTypes.filter(x => x.application == this.application);
            else
                return allTypes;
        },
    },
    watch: {
        selectedType(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            if (val)
                this.selectedType = this.types.find(x => x.code == val.code);
            else
                this.selectedType = val;
        },
        application() {
            if (!this.selectedType || !this.types.includes(this.selectedType))
                this.selectedType = this.types[0];
        }
    }
}
</script>