<template>
    <transition name="fade">
        <div v-if="message"
            class="message">
            <p class="notification"
                :class="{ 'is-warning': message.type == 'warning', 'is-info': message.type == 'info', 'is-danger': message.type == 'danger' }">
                <button v-if="message.duration < 0"
                    @click="clear"
                    class="delete"></button>
                <div v-html="message.text"></div>
            </p>
        </div>
    </transition>
</template>


<script>
export default {
    data() {
        return {
            timeout: null,
        }
    },
    computed: {
        message() {
            return this.$store.getters["core/getMessage"];
        },
    },
    methods: {
        clear() {
            this.$store.dispatch('core/setMessage', null);
        }
    },
    watch: {
        message(val) {
            clearTimeout(this.timeout);

            if (val?.duration < 0)
                return;

            this.timeout = setTimeout(() => {
                this.clear();
            }, val?.duration || 3000);
        }
    }
}
</script>

<style lang="sass">
.message
    position: fixed
    top: 105px
    left: 50%
    max-width: 500px
    min-width: 200px
    transform: translate(-50%, -50%)
    z-index: 100
    box-shadow: var(--bulma-shadow)
</style>