import ImageAction from "./models/ImageAction";

const INITIAL = 'I';
const DRAFT = 'D';
const SUBMITTED = 'S';
const UNDER_REVIEW = 'R';
const PUBLISHED = 'P';
const REJECTED = 'X';

const STATES = [
    { code: INITIAL, title: 'Initial' },
    { code: DRAFT, title: 'Draft' },
    { code: SUBMITTED, title: 'Submitted' },
    { code: UNDER_REVIEW, title: 'Under review' },
    { code: PUBLISHED, title: 'Published' },
    { code: REJECTED, title: 'Rejected' },
]

const REQUEST_STATES = {
    C: "Complete",
    A: "Assigned",
    N: "New",
    R: "Rejected",
}

const TRANSITIONS = {
    managed: {
        I: [DRAFT],
        D: [INITIAL, SUBMITTED],
        S: [UNDER_REVIEW],
        R: [PUBLISHED, REJECTED],
        P: [REJECTED],
        X: [DRAFT],
    },
    unManaged: {
        I: [PUBLISHED],
        P: [REJECTED],
        X: [PUBLISHED],
        D: [PUBLISHED],
        S: [PUBLISHED],
        R: [PUBLISHED],
    },
}
const FAMILY = 'FA';
const REVIT_TEMPLATE = 'RT';
const REVIT_HOSTED_CONTENT = 'HC';
const FAMILY_TEMPLATE = 'FT';
const AUTOCAD_BLOCK = 'AB';
const AUTOCAD_TEMPLATE = 'AT';
const INVENTOR_PART = 'IP';
const INVENTOR_ASSEMBLY = 'IA';
const MICROSTATION_CELL = 'CL';
const CIVIL3D_SUBASSEMBLY = 'CS';
const CIVIL3D_GRAVITY_PIPE_PART = 'CP';
const CIVIL3D_PRESSURE_PART = 'CR';
const CIVIL3D_GRAVITY_STRUCTURE_PART = 'CT';

const ASSOCIATED_FILETYPES = {
    TC: 'Revit Type Catalog',
    IP: 'Inventor Part XML',
    CP: 'Civil3D Part XML',
    CS: 'Cutsheet',
    GE: 'General',
}

const ACTIONS = [
    new ImageAction('AUTO_CONTRAST'),
    new ImageAction('AUTO_CROP'),
    new ImageAction('EQUALIZE'),
    new ImageAction('GRAYSCALE'),
    new ImageAction('INVERT'),
    new ImageAction('MAKE_DEFAULT', (image, content) => {
        content.images.forEach(image => {
            if (image.name == AUTO_NAME)
                image.name = '';
        })
        image.name = AUTO_NAME;
    }),
    new ImageAction('MONOCHROME'),
    new ImageAction('POSTERIZE'),
    new ImageAction('REMOVE_BACKGROUND'),
]

const DELETE_ACTION = new ImageAction('DELETE', (image, content) => {
    content.images = content.images.filter(x => x.id != image.id);
});

export {
    ACTIONS,
    ASSOCIATED_FILETYPES,
    DELETE_ACTION,
    FAMILY,
    REQUEST_STATES,
    STATES,
    TRANSITIONS,

    AUTOCAD_BLOCK,
    AUTOCAD_TEMPLATE,
    CIVIL3D_GRAVITY_PIPE_PART,
    CIVIL3D_GRAVITY_STRUCTURE_PART,
    CIVIL3D_PRESSURE_PART,
    CIVIL3D_SUBASSEMBLY,
    FAMILY_TEMPLATE,
    INVENTOR_ASSEMBLY,
    INVENTOR_PART,
    MICROSTATION_CELL,
    REVIT_HOSTED_CONTENT,
    REVIT_TEMPLATE,
};