import BaseData from "@/core/models/BaseData";

class Page extends BaseData {
    constructor(data) {
        super(data);
        this.children = [];
        this.expanded = false;
    }
}

export default Page;
