<template>
    <div>
        <div>
            <h2 class="title"
                v-if="items">
                {{ pageTitle }} Models
            </h2>
            <div v-show="!bulkEditMode"
                class="field is-grouped is-grouped-multiline">
                <search-input v-model="filters.filename"
                    placeholder="Filter by Filename&hellip;">
                </search-input>
                <project-autocomplete v-model="filters.project"
                    placeholder="Filter by Project&hellip;">
                </project-autocomplete>
                <div class="control">
                    <div class="select">
                        <select v-model.number="filters.version">
                            <option :value="null"
                                disabled
                                selected
                                hidden>All versions</option>
                            <option v-for="version in versions"
                                :key="version.id">{{ version }}</option>
                        </select>
                    </div>
                </div>
                <application-select v-model="filters.application"
                    :show-all="true">
                </application-select>
                <discipline-select v-model="filters.discipline"
                    :show-all="true">
                </discipline-select>
                <div class="control">
                    <div class="select">
                        <select v-model.number="filters.active">
                            <option :value="null">Active and Inactive</option>
                            <option :value="true">Active</option>
                            <option :value="false">Inactive</option>
                        </select>
                    </div>
                </div>
                <limit-select v-model.number="filters.limit"></limit-select>
                <clear-button @click="clearFilters"
                    v-if="isFiltered">
                </clear-button>
            </div>
        </div>
        <div v-show="bulkEditMode"
            class="panel">
            <div class="panel-heading">
                Edit {{ selectedModels.length == 1 ? '1 model' : selectedModels.length + ' models' }}
            </div>
            <div class="panel-block">
                <div class="field form-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <project-autocomplete v-model="bulk.project"
                                    :placeholder="'Select project'">
                                </project-autocomplete>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <discipline-select v-model="bulk.discipline"
                                    :show-all="true">
                                </discipline-select>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <div class="select">
                                    <select v-model="bulk.active">
                                        <option :value="null">Select state</option>
                                        <option :value="true">Active</option>
                                        <option :value="false">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button class="button is-normal"
                                    @click="bulkSave">Save</button>
                                <button class="button is-normal is-text"
                                    @click="bulkCancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth mt-5">
            <thead>
                <tr>
                    <th>Filename</th>
                    <th>Project</th>
                    <th>Version</th>
                    <th>Units</th>
                    <th>Application</th>
                    <th>Discipline</th>
                    <th>Active</th>
                    <th>
                        <input v-model="allChecked"
                            type="checkbox"
                            title="Select all for editing">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="model in items"
                    :class="{ saved: model.saved, selected: model.selected, error: model.error }"
                    :key="model.id">
                    <td>
                        <router-link :to="{ name: 'model-detail', params: { id: model.id } }">
                            {{ model.filename }}
                        </router-link>
                        <p>{{ model.folder }}</p>
                        <p :class="{ 'has-text-success-dark': model.saved, 'has-text-danger-dark': model.error }">{{
                            model.comment }}</p>
                    </td>
                    <td>
                        <span class="text-nowrap">
                            {{ model.project }}
                        </span>
                    </td>
                    <td>
                        {{ model.version }}
                    </td>
                    <td>
                        {{ model.unitsName }}
                    </td>
                    <td>
                        {{ model.applicationName }}
                    </td>
                    <td>
                        {{ model.discipline }}
                    </td>
                    <td>
                        <span v-if="model.active"
                            class="icon">
                            <span class="mdi mdi-check-bold"></span>
                        </span>
                        <span v-else
                            class="icon">
                            <span class="mdi mdi-close-thick"></span>
                        </span>
                    </td>
                    <td>
                        <input type="checkbox"
                            v-model="model.selected"
                            title="Select for editing">
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent.js";
import ClearButton from "@/core/components/ClearButton";
import DisciplineSelect from "@/core/components/DisciplineSelect.vue";
import LimitSelect from "@/core/components/LimitSelect.vue";
import Pagination from "@/core/components/Pagination.vue";
import SearchInput from "@/core/components/SearchInput.vue";
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete.vue";
import { cleanData } from "@/utils.js";
import _ from "lodash";
import Model from '../models/Model';

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ClearButton,
        DisciplineSelect,
        LimitSelect,
        Pagination,
        ProjectAutocomplete,
        SearchInput,
    },
    data() {
        return {
            filters: {
                active: true,
                application: null,
                discipline: null,
                q: null,
                project: null,
                filename: null,
                version: null,
                limit: null,
                offset: 0,
            },
            bulk: {
                project: null,
                active: true,
                discipline: null,
            },
            versions: _.range(2013, new Date().getFullYear() + 2),
            allChecked: false,
        };
    },
    computed: {
        bulkEditMode() {
            return this.selectedModels.length > 0;
        },
        selectedModels() {
            return this.items.filter(x => x.selected);
        },
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;

            if (filters.discipline)
                filters.discipline = filters.discipline.id;

            if (filters.project) {
                filters.project_id = filters.project.id;
                delete filters.project;
            }

            return filters;
        }
    },
    methods: {
        async loadItems() {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/`, this.cleanedFilters)
                .then(resp => resp.json());
            this.resultsCount = data.count;
            this.items = data.results.map(x => new Model(x));
            this.originalModels = _.cloneDeep(this.items);
        },
        async save(model) {
            model.comment = "Saving model";

            let data = {
                active: model.active || false,
            };

            if (model.project)
                data.project = model.project;

            if (model.discipline)
                data.discipline = model.discipline;

            try {
                await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/models/${model.id}/`, data)
                model.saved = true;
                model.error = false;
                model.comment = "Model saved";

            } catch (err) {
                model.saved = false;
                model.error = true;
                model.comment = err;
            }
        },
        cancelSave(model) {
            model.reset();
        },
        bulkSave() {
            this.selectedModels.forEach(x => {
                this.save(x);
                x.selected = false;
            });
            this.loadItems();
        },
        bulkCancel() {
            this.selectedModels.forEach(x => {
                this.cancelSave(x);
            });
        },
        handleChanges() {
            this.selectedModels.forEach(x => {
                x.project = this.bulk.project?.number || x.project;

                if (this.bulk.discipline?.id)
                    x.discipline = this.bulk.discipline.name;

                if (this.bulk.active !== '')
                    x.active = this.bulk.active;
            });
        },
        async loadUrlArgs() {
            let args = this.$route.query;
            if (!args)
                return;

            if (args.project) {
                await this.$store.dispatch("projects/loadProjectById", args.project);
                let project = this.$store.getters["projects/getProjectById"](args.project);
                this.filters.project_number = project.number;
            }
        },
    },
    watch: {
        bulk: {
            deep: true,
            handler() {
                this.handleChanges();
            }
        },
        selectedModels: {
            deep: true,
            handler() {
                this.items.forEach(item => {
                    // Check if the item is selected
                    if (item.selected) {
                        this.handleChanges();
                    } else {
                        // Reset unselected item to its original values
                        const originalModel = this.originalModels.find(model => model.id === item.id);
                        if (originalModel) {
                            item.project = originalModel.project;
                            item.discipline = originalModel.discipline;
                            item.active = originalModel.active;
                        }
                        if (item.hasChanged())
                            item.resetToMark();
                    }
                });
            }
        },
        allChecked(val) {
            this.items.forEach(x => x.selected = val);
        }
    }
}
</script>