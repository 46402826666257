<template>
    <span class="control">
        <span class="tags has-addons">
            <span class="tag is-info">{{ field.name }}</span>
            <span class="tag is-dark">{{ field.data_type }}</span>
        </span>
    </span>
</template>
    
<script>
    export default {
        props: ['field'],
    }
</script>