import http from '@/http';
import User from './models/User';

export default {
    namespaced: true,
    state: {
        currentUser: null,
        users: {},
        groups: [],
    },
    actions: {
        async loadCurrentUser({ commit, state }, force = false) {

            if (state.currentUser && !force)
                return;

            let user = null
            let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/current/`);
            if (resp?.status == 200) {
                let data = await resp.json();

                if (data)
                    user = new User(data);
            }
            commit('setCurrentUser', { user });
        },
        async loadUserById({ commit, state }, id) {

            if (state.users[id])
                return;

            let user = null
            let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/${id}/`);
            if (resp?.status == 200) {
                let data = await resp.json();

                if (data)
                    user = new User(data);
            }
            commit('setUser', { user });
        },
        async loadGroups({ commit, state }) {
            if (state.groups?.length)
                return;

            let resp = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/groups/`);
            if (resp?.status == 200) {
                let data = await resp.json();
                commit('setGroups', { groups: data.results });
            }
        },
        logoutCurrentUser({ commit }) {
            commit('logoutCurrentUser');
        },
    },
    mutations: {
        setUser: (state, { user }) => {
            state.users[user.id] = user;
        },
        setCurrentUser: (state, { user }) => {
            state.currentUser = user;
        },
        logoutCurrentUser: (state) => {
            state.currentUser = null;
        },
        setGroups: (state, { groups }) => {
            state.groups = groups;
        },
    },
    getters: {
        getUserById: state => (id) => state.users[id],
    },
}
