import auditsRoutes from '@/audits/routes';
import authRoutes from '@/auth/routes';
import bimRoutes from '@/bim/routes';
import contentRoutes from '@/content/routes';
import PageNotFoundRedirect from "@/core/components/PageNotFoundRedirect";
import coreRoutes from '@/core/routes';
import pagesRoutes from '@/pages/routes';
import projectRoutes from '@/projects/routes';
import statsRoutes from '@/stats/routes';
import store from "@/store";
import userRoutes from '@/users/routes';
import { showMessage } from '@/utils';
import _ from "lodash";
import { createRouter, createWebHashHistory } from 'vue-router';
import { PERMISSIONS } from './constants';

const allRoutes = [
    ...auditsRoutes,
    ...authRoutes,
    ...bimRoutes,
    ...contentRoutes,
    ...coreRoutes,
    ...pagesRoutes,
    ...projectRoutes,
    ...statsRoutes,
    ...userRoutes,
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found-redirect',
        component: PageNotFoundRedirect,
        meta: {},
    }
];

const defaultIcons = {
    '-list': 'table-large',
    '-add': 'plus',
    '-edit': 'pencil',
    '-detail': 'card-text-outline',
    'stats': 'chart-line',
    'upload': 'upload',
}

// Ensure all routes have the default breadcrumbs
allRoutes.forEach(x => {
    if (!x.meta.breadcrumbs)
        x.meta.breadcrumbs = [{ name: 'Home', route: 'home' }];

    if (!x.meta.icon) {
        for (const [k, v] of Object.entries(defaultIcons)) {
            if (x.name?.includes(k))
                x.meta.icon = v;
        }
    }
})

const router = createRouter({
    history: createWebHashHistory(''),
    routes: allRoutes,
})

let user = null;
const externalAllowedRoutes = [
    'content-request-add',
    'denied',
    'download',
    'feedback',
    'home',
    'login',
    'logout',
    'not-found',
    'not-found-redirect',
    'password-change',
    'user-current',
    'user-external',
];

// Check user has permissions to access each route
router.beforeEach(async (to, from) => {

    // Try to load the current user
    if (!user)
        await store.dispatch("users/loadCurrentUser");

    user = store.state.users.currentUser;

    // Externals are only allowed a small set of urls
    if (user && user.isExternal && !externalAllowedRoutes.includes(to.name))
        return { name: 'denied' };

    // Get required permissions from the route meta object
    const permissionsRequired = to.meta.permissionsRequired;
    if (permissionsRequired) {

        // If user is still null then they are logged out so redirect to login form
        if (!user) {
            showMessage(`You must be logged in to access ${to.path}`, 'danger');
            return { name: 'login' };
        }

        // Superuser status overrides permissions
        if (user.isSuperUser)
            return true;

        // If is_superuser permission is required then fail because user is not a superuser
        if (permissionsRequired.includes(PERMISSIONS.IS_SUPERUSER))
            return { name: 'denied' };

        // Check that user has the required permissions, if not redirect to denied page
        const userHasPermissions = permissionsRequired.every(x => user.permissions.includes(x));
        if (!userHasPermissions)
            return { name: 'denied' };
    }

    return true;
})

export default router;