<template>
    <nav class="navbar is-transparent"
        role="navigation"
        aria-label="main navigation">

        <div class="navbar-brand">
            <router-link :to="{ name: 'home' }"
                class="navbar-item">
                <img class="logo"
                    src="/static/logo.svg" />
            </router-link>
            <a @click="expanded = !expanded"
                :class="{ 'is-active': expanded }"
                role="button"
                class="navbar-burger burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div :class="{ 'is-active': expanded }"
            class="navbar-menu">
            <div class="navbar-end">
                <router-link :to="{ name: 'download' }"
                    class="navbar-item"
                    title="Download the latest Jacobs Tools installer">
                    <span class="mdi mdi-cloud-download"></span> &nbsp;
                    Download Desktop App
                </router-link>
                <router-link class="navbar-item"
                    :to="{ name: 'content-list' }">Content Lab</router-link>
                <router-link class="navbar-item"
                    :to="{ name: 'bim-index' }">BIM</router-link>
                <router-link class="navbar-item"
                    :to="{ name: 'project-list' }">Projects</router-link>
                <router-link class="navbar-item"
                    :to="{ name: 'user-list' }">Users</router-link>
                <router-link class="navbar-item"
                    :to="{ name: 'stats-index' }">Stats</router-link>
                <router-link class="navbar-item"
                    :to="{ name: 'page-index' }">Help</router-link>
                <user-info class="is-hidden-mobile"></user-info>
            </div>
        </div>

    </nav>
</template>

<script>
import UserInfo from "@/core/components/UserInfo";

export default {
    components: {
        UserInfo,
    },
    data() {
        return {
            expanded: false,
        }
    }
};
</script>

<style lang="sass" scoped>
.navbar
    background: transparent

    .navbar-menu
        box-shadow: none

    a.navbar-item, a.navbar-link
        color: #fafafa

    a.navbar-item:hover, a.navbar-item:focus
        color: #fafafa

    .navbar-end
        margin-right: 2em

    .logo
        width: 140px
        margin-left: -7px

</style>
