import DesignModel from "@/bim/models/Model";
import BaseData from "@/core/models/BaseData";
import { RESTRICTION_LEVELS } from "@/projects/constants";
import _ from "lodash";
import AuditProfile from "../../audits/models/AuditProfile";
import ContentFilenameFormat from "../../content/models/ContentFilenameFormat";

class Project extends BaseData {

    constructor(data) {
        // This will clash with the property defined below
        if (data)
            delete data.model_information_update_frequency;

        super(data);

        this.contentLibraries = this.contentLibraries || [];
        this.admins = this.admins || [];
        if (this.filenameFormats)
            this.filenameFormats = this.filenameFormats.map(x => new ContentFilenameFormat(x));
        else
            this.filenameFormats = [];

        this.restrictionLevel = this.restrictionLevel || 'E';
        this.auditProfiles = this.auditProfiles
            || [{ application: 'S', max_weight: 'M', model_update_frequency: 7 }]

        this.auditProfiles = this.auditProfiles.map(x => new AuditProfile(x));

        if (this.models)
            this.models = this.models.map(x => new DesignModel(x));
    }

    get restrictionLevelName() {
        return RESTRICTION_LEVELS[this.restrictionLevel] || this.restrictionLevel;
    }

    get maxWeight() {
        if (!this.auditProfiles?.length)
            return 'M';

        return this.auditProfiles[0].maxWeight;
    }

    get modelInformationUpdateFrequency() {
        if (!this.auditProfiles?.length)
            return 7;

        return this.auditProfiles[0].modelUpdateFrequency;
    }

    set modelInformationUpdateFrequency(val) {
        this.auditProfiles.forEach(x => x.modelUpdateFrequency = val);
    }

    set maxWeight(val) {
        this.auditProfiles.forEach(x => x.maxWeight = val);
    }

    revitVersion() {
        if (this.models)
            return _.uniq(this.models.map(x => x.version)).sort().join(', ');
        else
            return '';
    }

    toString() {
        return `${this.number} - ${this.name}`;
    }
}

export default Project;
