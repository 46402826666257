<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedGroup">
                <option :value="null"
                    v-if="showAll">All groups</option>
                <option v-for="group in groups"
                    :value="group">
                    {{ group.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showAll: {
            type: Boolean,
            default: false,
        },
        application: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedGroup: null,
        }
    },
    created() {
        this.selectedGroup = this.modelValue;
        this.$store.dispatch("content/loadGroupList");
    },
    computed: {
        groups() {
            let allGroups = this.$store.getters["content/getGroups"];
            if (this.application) {
                let appGroups = allGroups.filter(x => x.application == this.application)
                this.selectedGroup = appGroups[0]
                return appGroups
            }
            else
                return allGroups;
        },
    },
    watch: {
        selectedGroup(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedGroup = val;
        },
        application() {
            this.selectedGroup = this.groups[0];
        }
    }
}
</script>