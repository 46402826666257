const COLORS = {
    BLUE: 'rgb(0, 255, 255)',
    BROWN: 'maroon',
    GREEN: 'rgb(99, 255, 132)',
    GREY: 'rgb(201, 203, 207)',
    ORANGE: 'rgb(255, 159, 64)',
    PURPLE: 'rgb(153, 102, 255)',
    RED: 'rgb(255, 99, 132)',
    YELLOW: 'rgb(255, 205, 86)',
};

const AUTOCAD = 'A';
const INVENTOR = 'I';
const MICROSTATION = 'M';
const REVIT = 'R';
const STANDALONE = 'S';

const APPLICATIONS = {
    [REVIT]: "Revit",
    [AUTOCAD]: "AutoCAD",
    [MICROSTATION]: "MicroStation",
    [INVENTOR]: "Inventor",
    [STANDALONE]: "Standalone"
}

const STATES_MODERATED = {
    I: { name: "Initial", action: "Initial", targets: ['D'] },
    D: { name: "Draft", action: "Assign", targets: ['S'] },
    S: { name: "Submitted", action: "Submit", targets: ['R'] },
    R: { name: "Under Review", action: "Review", targets: ['P'] },
    P: { name: "Published", action: "Publish", targets: ['X'] },
    X: { name: "Rejected", action: "Reject", targets: ['D'] },
};

const STATES_UNMODERATED = {
    I: { name: "Initial", action: "Initial", targets: ['D'] },
    D: { name: "Draft", action: "Draft", targets: ['P'] },
    P: { name: "Published", action: "Publish", targets: ['X'] },
    X: { name: "Rejected", action: "Reject", targets: ['D'] },
};

const PERMISSIONS = {
    "IS_SUPERUSER": "is_superuser",
    "IS_AUTHENTICATED": "is_authenticated",

    "ADD_ADDRESS": "add_address",
    "ADD_AUDIT": "add_audit",
    "ADD_AUDITELEMENT": "add_auditelement",
    "ADD_AUDITRULE": "add_auditrule",
    "ADD_AUDITRULERESULT": "add_auditruleresult",
    "ADD_AUDITSUMMARY": "add_auditsummary",
    "ADD_AUDITTEMPLATE": "add_audittemplate",
    "ADD_AUDITTEMPLATERULE": "add_audittemplaterule",
    "ADD_BUILDING": "add_building",
    "ADD_CADFILE": "add_cadfile",
    "ADD_CADFILEVERSION": "add_cadfileversion",
    "ADD_CATEGORY": "add_category",
    "ADD_CERTIFICATION": "add_certification",
    "ADD_CERTIFICATIONTYPE": "add_certificationtype",
    "ADD_CHANGE": "add_change",
    "ADD_CLIENT": "add_client",
    "ADD_CODE": "add_code",
    "ADD_CODEATTACHMENT": "add_codeattachment",
    "ADD_COMMENT": "add_comment",
    "ADD_CONTENT": "add_content",
    "ADD_CONTENTASSOCIATEDFILE": "add_contentassociatedfile",
    "ADD_CONTENTAUDIT": "add_contentaudit",
    "ADD_CONTENTEVENT": "add_contentevent",
    "ADD_CONTENTFIELDVALUE": "add_contentfieldvalue",
    "ADD_CONTENTFILE": "add_contentfile",
    "ADD_CONTENTFILENAMEFORMAT": "add_contentfilenameformat",
    "ADD_CONTENTFILENAMEMAPPING": "add_contentfilenamemapping",
    "ADD_CONTENTHOST": "add_contenthost",
    "ADD_CONTENTIMAGE": "add_contentimage",
    "ADD_CONTENTLIBRARY": "add_contentlibrary",
    "ADD_CONTENTLIBRARYFIELD": "add_contentlibraryfield",
    "ADD_CONTENTREQUEST": "add_contentrequest",
    "ADD_CONTENTREQUESTFILE": "add_contentrequestfile",
    "ADD_CONTENTSET": "add_contentset",
    "ADD_CONTENTSYMBOL": "add_contentsymbol",
    "ADD_CONTENTTRANSFORM": "add_contenttransform",
    "ADD_CONTENTTYPE": "add_contenttype",
    "ADD_CONTENTTYPOLOGY": "add_contenttypology",
    "ADD_CONTENTVERSION": "add_contentversion",
    "ADD_DATAFIELD": "add_datafield",
    "ADD_DATAFIELDGROUP": "add_datafieldgroup",
    "ADD_DISCIPLINE": "add_discipline",
    "ADD_DOCUMENT": "add_document",
    "ADD_DOCUMENTATIONLINK": "add_documentationlink",
    "ADD_EVENT": "add_event",
    "ADD_FACILITYTYPE": "add_facilitytype",
    "ADD_FAILURE": "add_failure",
    "ADD_FILENAMEFORMAT": "add_filenameformat",
    "ADD_FLATPAGE": "add_flatpage",
    "ADD_GENERICRESOURCE": "add_genericresource",
    "ADD_GROUP": "add_group",
    "ADD_HOSTEDCONTENT": "add_hostedcontent",
    "ADD_HOSTEDCONTENTVERSION": "add_hostedcontentversion",
    "ADD_LOADEDFAMILY": "add_loadedfamily",
    "ADD_LOGENTRY": "add_logentry",
    "ADD_MODELLINK": "add_modellink",
    "ADD_MODELSTATE": "add_modelstate",
    "ADD_NOTIFICATION": "add_notification",
    "ADD_OFFICE": "add_office",
    "ADD_OFFLINETASK": "add_offlinetask",
    "ADD_ORGANIZATION": "add_organization",
    "ADD_PAGE": "add_page",
    "ADD_PAGEATTACHMENT": "add_pageattachment",
    "ADD_PERMISSION": "add_permission",
    "ADD_PROJECT": "add_project",
    "ADD_PROJECTATTACHMENT": "add_projectattachment",
    "ADD_PROJECTCATEGORY": "add_projectcategory",
    "ADD_PROJECTCERTIFICATIONMEMBERSHIP": "add_projectcertificationmembership",
    "ADD_PROJECTCODEMEMBERSHIP": "add_projectcodemembership",
    "ADD_PROJECTCOMPLIANCE": "add_projectcompliance",
    "ADD_PROJECTEVENT": "add_projectevent",
    "ADD_PROJECTIMAGE": "add_projectimage",
    "ADD_PROJECTROLE": "add_projectrole",
    "ADD_PROJECTSCOPE": "add_projectscope",
    "ADD_REPORT": "add_report",
    "ADD_REVITADDIN": "add_revitaddin",
    "ADD_REVITADDINSTATUS": "add_revitaddinstatus",
    "ADD_REVITBUILD": "add_revitbuild",
    "ADD_REVITEVENT": "add_revitevent",
    "ADD_REVITINFOMESSAGE": "add_revitinfomessage",
    "ADD_REVITJOURNAL": "add_revitjournal",
    "ADD_REVITMODEL": "add_revitmodel",
    "ADD_REVITMODELELEMENT": "add_revitmodelelement",
    "ADD_REVITPROFILE": "add_revitprofile",
    "ADD_REVITTEMPLATE": "add_revittemplate",
    "ADD_REVITTEMPLATESURVEY": "add_revittemplatesurvey",
    "ADD_REVITVIEW": "add_revitview",
    "ADD_REVITWARNING": "add_revitwarning",
    "ADD_REVITWARNINGEVENT": "add_revitwarningevent",
    "ADD_SECTION": "add_section",
    "ADD_SESSION": "add_session",
    "ADD_SHAREDPARAMETER": "add_sharedparameter",
    "ADD_SHAREDPARAMETERCOLLECTION": "add_sharedparametercollection",
    "ADD_SHAREDPARAMETERCOLLECTIONMEMBERSHIP": "add_sharedparametercollectionmembership",
    "ADD_SHAREDPARAMETERGROUP": "add_sharedparametergroup",
    "ADD_SHAREDPARAMETERSET": "add_sharedparameterset",
    "ADD_SITE": "add_site",
    "ADD_SITEFEEDBACK": "add_sitefeedback",
    "ADD_SNIPPET": "add_snippet",
    "ADD_SOURCE": "add_source",
    "ADD_STATELOG": "add_statelog",
    "ADD_SYNONYM": "add_synonym",
    "ADD_TAG": "add_tag",
    "ADD_TAGGEDITEM": "add_taggeditem",
    "ADD_TASKEVENT": "add_taskevent",
    "ADD_TASKINSTANCE": "add_taskinstance",
    "ADD_THUMBNAIL": "add_thumbnail",
    "ADD_THUMBNAILDIMENSIONS": "add_thumbnaildimensions",
    "ADD_TOKEN": "add_token",
    "ADD_TOKENPROXY": "add_tokenproxy",
    "ADD_TOOLEVENT": "add_toolevent",
    "ADD_USERPROFILE": "add_userprofile",
    "ADD_USERPROFILEPROJECTRESTRICTION": "add_userprofileprojectrestriction",
    "ADD_USERTOOLVERSION": "add_usertoolversion",
    "ADD_VIEWCHECKER": "add_viewchecker",
    "ADD_VIEWCHECKERSECTION": "add_viewcheckersection",
    "ADD_WEBEVENT": "add_webevent",
    "ADD_WORKSUMMARY": "add_worksummary",
    "ADD_XITEM": "add_xitem",
    "ASSIGN_CONTENTREQUEST": "assign_contentrequest",
    "CHANGE_ADDRESS": "change_address",
    "CHANGE_AUDIT": "change_audit",
    "CHANGE_AUDITELEMENT": "change_auditelement",
    "CHANGE_AUDITRULE": "change_auditrule",
    "CHANGE_AUDITRULERESULT": "change_auditruleresult",
    "CHANGE_AUDITSUMMARY": "change_auditsummary",
    "CHANGE_AUDITTEMPLATE": "change_audittemplate",
    "CHANGE_AUDITTEMPLATERULE": "change_audittemplaterule",
    "CHANGE_BUILDING": "change_building",
    "CHANGE_CADFILE": "change_cadfile",
    "CHANGE_CADFILEVERSION": "change_cadfileversion",
    "CHANGE_CATEGORY": "change_category",
    "CHANGE_CERTIFICATION": "change_certification",
    "CHANGE_CERTIFICATIONTYPE": "change_certificationtype",
    "CHANGE_CHANGE": "change_change",
    "CHANGE_CLIENT": "change_client",
    "CHANGE_CODE": "change_code",
    "CHANGE_CODEATTACHMENT": "change_codeattachment",
    "CHANGE_COMMENT": "change_comment",
    "CHANGE_CONTENT": "change_content",
    "CHANGE_CONTENTASSOCIATEDFILE": "change_contentassociatedfile",
    "CHANGE_CONTENTAUDIT": "change_contentaudit",
    "CHANGE_CONTENTEVENT": "change_contentevent",
    "CHANGE_CONTENTFIELDVALUE": "change_contentfieldvalue",
    "CHANGE_CONTENTFILE": "change_contentfile",
    "CHANGE_CONTENTFILENAMEFORMAT": "change_contentfilenameformat",
    "CHANGE_CONTENTFILENAMEMAPPING": "change_contentfilenamemapping",
    "CHANGE_CONTENTHOST": "change_contenthost",
    "CHANGE_CONTENTIMAGE": "change_contentimage",
    "CHANGE_CONTENTLIBRARY": "change_contentlibrary",
    "CHANGE_CONTENTLIBRARYFIELD": "change_contentlibraryfield",
    "CHANGE_CONTENTREQUEST": "change_contentrequest",
    "CHANGE_CONTENTREQUESTFILE": "change_contentrequestfile",
    "CHANGE_CONTENTSET": "change_contentset",
    "CHANGE_CONTENTSYMBOL": "change_contentsymbol",
    "CHANGE_CONTENTTRANSFORM": "change_contenttransform",
    "CHANGE_CONTENTTYPE": "change_contenttype",
    "CHANGE_CONTENTTYPOLOGY": "change_contenttypology",
    "CHANGE_CONTENTVERSION": "change_contentversion",
    "CHANGE_DATAFIELD": "change_datafield",
    "CHANGE_DATAFIELDGROUP": "change_datafieldgroup",
    "CHANGE_DISCIPLINE": "change_discipline",
    "CHANGE_DOCUMENT": "change_document",
    "CHANGE_DOCUMENTATIONLINK": "change_documentationlink",
    "CHANGE_EVENT": "change_event",
    "CHANGE_FACILITYTYPE": "change_facilitytype",
    "CHANGE_FAILURE": "change_failure",
    "CHANGE_FILENAMEFORMAT": "change_filenameformat",
    "CHANGE_FLATPAGE": "change_flatpage",
    "CHANGE_GENERICRESOURCE": "change_genericresource",
    "CHANGE_GROUP": "change_group",
    "CHANGE_HOSTEDCONTENT": "change_hostedcontent",
    "CHANGE_HOSTEDCONTENTVERSION": "change_hostedcontentversion",
    "CHANGE_ISSUE": "change_issue",
    "CHANGE_LOADEDFAMILY": "change_loadedfamily",
    "CHANGE_LOGENTRY": "change_logentry",
    "CHANGE_MODELLINK": "change_modellink",
    "CHANGE_MODELSTATE": "change_modelstate",
    "CHANGE_NOTIFICATION": "change_notification",
    "CHANGE_OFFICE": "change_office",
    "CHANGE_OFFLINETASK": "change_offlinetask",
    "CHANGE_ORGANIZATION": "change_organization",
    "CHANGE_PAGE": "change_page",
    "CHANGE_PAGEATTACHMENT": "change_pageattachment",
    "CHANGE_PERMISSION": "change_permission",
    "CHANGE_PROJECT": "change_project",
    "CHANGE_PROJECTATTACHMENT": "change_projectattachment",
    "CHANGE_PROJECTCATEGORY": "change_projectcategory",
    "CHANGE_PROJECTCERTIFICATIONMEMBERSHIP": "change_projectcertificationmembership",
    "CHANGE_PROJECTCODEMEMBERSHIP": "change_projectcodemembership",
    "CHANGE_PROJECTCOMPLIANCE": "change_projectcompliance",
    "CHANGE_PROJECTEVENT": "change_projectevent",
    "CHANGE_PROJECTIMAGE": "change_projectimage",
    "CHANGE_PROJECTROLE": "change_projectrole",
    "CHANGE_PROJECTSCOPE": "change_projectscope",
    "CHANGE_REPORT": "change_report",
    "CHANGE_REVITADDIN": "change_revitaddin",
    "CHANGE_REVITADDINSTATUS": "change_revitaddinstatus",
    "CHANGE_REVITBUILD": "change_revitbuild",
    "CHANGE_REVITEVENT": "change_revitevent",
    "CHANGE_REVITINFOMESSAGE": "change_revitinfomessage",
    "CHANGE_REVITJOURNAL": "change_revitjournal",
    "CHANGE_REVITMODEL": "change_revitmodel",
    "CHANGE_REVITMODELELEMENT": "change_revitmodelelement",
    "CHANGE_REVITPROFILE": "change_revitprofile",
    "CHANGE_REVITTEMPLATE": "change_revittemplate",
    "CHANGE_REVITTEMPLATESURVEY": "change_revittemplatesurvey",
    "CHANGE_REVITVIEW": "change_revitview",
    "CHANGE_REVITWARNING": "change_revitwarning",
    "CHANGE_REVITWARNINGEVENT": "change_revitwarningevent",
    "CHANGE_SECTION": "change_section",
    "CHANGE_SESSION": "change_session",
    "CHANGE_SHAREDPARAMETER": "change_sharedparameter",
    "CHANGE_SHAREDPARAMETERCOLLECTION": "change_sharedparametercollection",
    "CHANGE_SHAREDPARAMETERCOLLECTIONMEMBERSHIP": "change_sharedparametercollectionmembership",
    "CHANGE_SHAREDPARAMETERGROUP": "change_sharedparametergroup",
    "CHANGE_SHAREDPARAMETERSET": "change_sharedparameterset",
    "CHANGE_SITE": "change_site",
    "CHANGE_SITEFEEDBACK": "change_sitefeedback",
    "CHANGE_SNIPPET": "change_snippet",
    "CHANGE_SOURCE": "change_source",
    "CHANGE_STATELOG": "change_statelog",
    "CHANGE_SYNONYM": "change_synonym",
    "CHANGE_TAG": "change_tag",
    "CHANGE_TAGGEDITEM": "change_taggeditem",
    "CHANGE_TASKEVENT": "change_taskevent",
    "CHANGE_TASKINSTANCE": "change_taskinstance",
    "CHANGE_THUMBNAIL": "change_thumbnail",
    "CHANGE_THUMBNAILDIMENSIONS": "change_thumbnaildimensions",
    "CHANGE_TOKEN": "change_token",
    "CHANGE_TOKENPROXY": "change_tokenproxy",
    "CHANGE_TOOLEVENT": "change_toolevent",
    "CHANGE_USERPROFILE": "change_userprofile",
    "CHANGE_USERPROFILEPROJECTRESTRICTION": "change_userprofileprojectrestriction",
    "CHANGE_USERTOOLVERSION": "change_usertoolversion",
    "CHANGE_VIEWCHECKER": "change_viewchecker",
    "CHANGE_VIEWCHECKERSECTION": "change_viewcheckersection",
    "CHANGE_WEBEVENT": "change_webevent",
    "CHANGE_WORKSUMMARY": "change_worksummary",
    "CHANGE_XITEM": "change_xitem",
    "COMPLETE_CONTENTREQUEST": "complete_contentrequest",
    "COMPLETE_FAMILYREQUEST": "complete_familyrequest",
    "DELETE_ADDRESS": "delete_address",
    "DELETE_AUDIT": "delete_audit",
    "DELETE_AUDITELEMENT": "delete_auditelement",
    "DELETE_AUDITRULE": "delete_auditrule",
    "DELETE_AUDITRULERESULT": "delete_auditruleresult",
    "DELETE_AUDITSUMMARY": "delete_auditsummary",
    "DELETE_AUDITTEMPLATE": "delete_audittemplate",
    "DELETE_AUDITTEMPLATERULE": "delete_audittemplaterule",
    "DELETE_BUILDING": "delete_building",
    "DELETE_CADFILE": "delete_cadfile",
    "DELETE_CADFILEVERSION": "delete_cadfileversion",
    "DELETE_CATEGORY": "delete_category",
    "DELETE_CERTIFICATION": "delete_certification",
    "DELETE_CERTIFICATIONTYPE": "delete_certificationtype",
    "DELETE_CHANGE": "delete_change",
    "DELETE_CLIENT": "delete_client",
    "DELETE_CODE": "delete_code",
    "DELETE_CODEATTACHMENT": "delete_codeattachment",
    "DELETE_COMMENT": "delete_comment",
    "DELETE_CONTENT": "delete_content",
    "DELETE_CONTENTASSOCIATEDFILE": "delete_contentassociatedfile",
    "DELETE_CONTENTAUDIT": "delete_contentaudit",
    "DELETE_CONTENTEVENT": "delete_contentevent",
    "DELETE_CONTENTFIELDVALUE": "delete_contentfieldvalue",
    "DELETE_CONTENTFILE": "delete_contentfile",
    "DELETE_CONTENTFILENAMEFORMAT": "delete_contentfilenameformat",
    "DELETE_CONTENTFILENAMEMAPPING": "delete_contentfilenamemapping",
    "DELETE_CONTENTHOST": "delete_contenthost",
    "DELETE_CONTENTIMAGE": "delete_contentimage",
    "DELETE_CONTENTLIBRARY": "delete_contentlibrary",
    "DELETE_CONTENTLIBRARYFIELD": "delete_contentlibraryfield",
    "DELETE_CONTENTREQUEST": "delete_contentrequest",
    "DELETE_CONTENTREQUESTFILE": "delete_contentrequestfile",
    "DELETE_CONTENTSET": "delete_contentset",
    "DELETE_CONTENTSYMBOL": "delete_contentsymbol",
    "DELETE_CONTENTTRANSFORM": "delete_contenttransform",
    "DELETE_CONTENTTYPE": "delete_contenttype",
    "DELETE_CONTENTTYPOLOGY": "delete_contenttypology",
    "DELETE_CONTENTVERSION": "delete_contentversion",
    "DELETE_DATAFIELD": "delete_datafield",
    "DELETE_DATAFIELDGROUP": "delete_datafieldgroup",
    "DELETE_DISCIPLINE": "delete_discipline",
    "DELETE_DOCUMENT": "delete_document",
    "DELETE_DOCUMENTATIONLINK": "delete_documentationlink",
    "DELETE_EVENT": "delete_event",
    "DELETE_FACILITYTYPE": "delete_facilitytype",
    "DELETE_FAILURE": "delete_failure",
    "DELETE_FILENAMEFORMAT": "delete_filenameformat",
    "DELETE_FLATPAGE": "delete_flatpage",
    "DELETE_GENERICRESOURCE": "delete_genericresource",
    "DELETE_GROUP": "delete_group",
    "DELETE_HOSTEDCONTENT": "delete_hostedcontent",
    "DELETE_HOSTEDCONTENTVERSION": "delete_hostedcontentversion",
    "DELETE_ISSUE": "delete_issue",
    "DELETE_LOADEDFAMILY": "delete_loadedfamily",
    "DELETE_LOGENTRY": "delete_logentry",
    "DELETE_MODELLINK": "delete_modellink",
    "DELETE_MODELROOM": "delete_modelroom",
    "DELETE_MODELSTATE": "delete_modelstate",
    "DELETE_NOTIFICATION": "delete_notification",
    "DELETE_OFFICE": "delete_office",
    "DELETE_OFFLINETASK": "delete_offlinetask",
    "DELETE_ORGANIZATION": "delete_organization",
    "DELETE_PAGE": "delete_page",
    "DELETE_PAGEATTACHMENT": "delete_pageattachment",
    "DELETE_PERMISSION": "delete_permission",
    "DELETE_PROJECT": "delete_project",
    "DELETE_PROJECTATTACHMENT": "delete_projectattachment",
    "DELETE_PROJECTCATEGORY": "delete_projectcategory",
    "DELETE_PROJECTCERTIFICATIONMEMBERSHIP": "delete_projectcertificationmembership",
    "DELETE_PROJECTCODEMEMBERSHIP": "delete_projectcodemembership",
    "DELETE_PROJECTCOMPLIANCE": "delete_projectcompliance",
    "DELETE_PROJECTEVENT": "delete_projectevent",
    "DELETE_PROJECTIMAGE": "delete_projectimage",
    "DELETE_PROJECTROLE": "delete_projectrole",
    "DELETE_PROJECTSCOPE": "delete_projectscope",
    "DELETE_REPORT": "delete_report",
    "DELETE_REVITADDIN": "delete_revitaddin",
    "DELETE_REVITADDINSTATUS": "delete_revitaddinstatus",
    "DELETE_REVITBUILD": "delete_revitbuild",
    "DELETE_REVITEVENT": "delete_revitevent",
    "DELETE_REVITINFOMESSAGE": "delete_revitinfomessage",
    "DELETE_REVITJOURNAL": "delete_revitjournal",
    "DELETE_REVITMODEL": "delete_revitmodel",
    "DELETE_REVITMODELELEMENT": "delete_revitmodelelement",
    "DELETE_REVITPROFILE": "delete_revitprofile",
    "DELETE_REVITTEMPLATE": "delete_revittemplate",
    "DELETE_REVITTEMPLATESURVEY": "delete_revittemplatesurvey",
    "DELETE_REVITVIEW": "delete_revitview",
    "DELETE_REVITWARNING": "delete_revitwarning",
    "DELETE_REVITWARNINGEVENT": "delete_revitwarningevent",
    "DELETE_SECTION": "delete_section",
    "DELETE_SESSION": "delete_session",
    "DELETE_SHAREDPARAMETER": "delete_sharedparameter",
    "DELETE_SHAREDPARAMETERCOLLECTION": "delete_sharedparametercollection",
    "DELETE_SHAREDPARAMETERCOLLECTIONMEMBERSHIP": "delete_sharedparametercollectionmembership",
    "DELETE_SHAREDPARAMETERGROUP": "delete_sharedparametergroup",
    "DELETE_SHAREDPARAMETERSET": "delete_sharedparameterset",
    "DELETE_SITE": "delete_site",
    "DELETE_SITEFEEDBACK": "delete_sitefeedback",
    "DELETE_SNIPPET": "delete_snippet",
    "DELETE_SOURCE": "delete_source",
    "DELETE_STATELOG": "delete_statelog",
    "DELETE_SYNONYM": "delete_synonym",
    "DELETE_TAG": "delete_tag",
    "DELETE_TAGGEDITEM": "delete_taggeditem",
    "DELETE_TASKEVENT": "delete_taskevent",
    "DELETE_TASKINSTANCE": "delete_taskinstance",
    "DELETE_THUMBNAIL": "delete_thumbnail",
    "DELETE_THUMBNAILDIMENSIONS": "delete_thumbnaildimensions",
    "DELETE_TOKEN": "delete_token",
    "DELETE_TOKENPROXY": "delete_tokenproxy",
    "DELETE_TOOLEVENT": "delete_toolevent",
    "DELETE_USERPROFILE": "delete_userprofile",
    "DELETE_USERPROFILEPROJECTRESTRICTION": "delete_userprofileprojectrestriction",
    "DELETE_USERTOOLVERSION": "delete_usertoolversion",
    "DELETE_VIEWCHECKER": "delete_viewchecker",
    "DELETE_VIEWCHECKERSECTION": "delete_viewcheckersection",
    "DELETE_WEBEVENT": "delete_webevent",
    "DELETE_WORKSUMMARY": "delete_worksummary",
    "DELETE_XITEM": "delete_xitem",
    "FLAG_CONTENT": "flag_content",
    "LOCK_CONTENT": "lock_content",
    "MANAGE_CONTENT": "manage_content",
    "PUBLISH_CONTENT": "publish_content",
    "PUBLISH_FAMILY": "publish_family",
    "REJECT_CONTENT": "reject_content",
    "REJECT_FAMILY": "reject_family",
    "REVIEW_CONTENT": "review_content",
    "REVIEW_FAMILY": "review_family",
    "VIEW_ACCESSATTEMPT": "view_accessattempt",
    "VIEW_ACCESSFAILURELOG": "view_accessfailurelog",
    "VIEW_ACCESSLOG": "view_accesslog",
    "VIEW_ADDRESS": "view_address",
    "VIEW_ALL_PROJECTS": "view_all_projects",
    "VIEW_AUDIT": "view_audit",
    "VIEW_AUDITELEMENT": "view_auditelement",
    "VIEW_AUDITRULE": "view_auditrule",
    "VIEW_AUDITRULERESULT": "view_auditruleresult",
    "VIEW_AUDITSUMMARY": "view_auditsummary",
    "VIEW_AUDITTEMPLATE": "view_audittemplate",
    "VIEW_AUDITTEMPLATERULE": "view_audittemplaterule",
    "VIEW_BUILDING": "view_building",
    "VIEW_CATEGORY": "view_category",
    "VIEW_CERTIFICATION": "view_certification",
    "VIEW_CERTIFICATIONTYPE": "view_certificationtype",
    "VIEW_CHANGE": "view_change",
    "VIEW_CLIENT": "view_client",
    "VIEW_CODE": "view_code",
    "VIEW_CODEATTACHMENT": "view_codeattachment",
    "VIEW_COMMENT": "view_comment",
    "VIEW_CONTENT": "view_content",
    "VIEW_CONTENTASSOCIATEDFILE": "view_contentassociatedfile",
    "VIEW_CONTENTEVENT": "view_contentevent",
    "VIEW_CONTENTFIELDVALUE": "view_contentfieldvalue",
    "VIEW_CONTENTFILE": "view_contentfile",
    "VIEW_CONTENTFILENAMEFORMAT": "view_contentfilenameformat",
    "VIEW_CONTENTFILENAMEMAPPING": "view_contentfilenamemapping",
    "VIEW_CONTENTIMAGE": "view_contentimage",
    "VIEW_CONTENTLIBRARY": "view_contentlibrary",
    "VIEW_CONTENTLIBRARYFIELD": "view_contentlibraryfield",
    "VIEW_CONTENTREQUEST": "view_contentrequest",
    "VIEW_CONTENTREQUESTFILE": "view_contentrequestfile",
    "VIEW_CONTENTSET": "view_contentset",
    "VIEW_CONTENTSYMBOL": "view_contentsymbol",
    "VIEW_CONTENTTRANSFORM": "view_contenttransform",
    "VIEW_CONTENTTYPE": "view_contenttype",
    "VIEW_CONTENTTYPOLOGY": "view_contenttypology",
    "VIEW_CONTENTVERSION": "view_contentversion",
    "VIEW_DATAFIELD": "view_datafield",
    "VIEW_DISCIPLINE": "view_discipline",
    "VIEW_DOCUMENTATIONLINK": "view_documentationlink",
    "VIEW_FACILITYTYPE": "view_facilitytype",
    "VIEW_FAILURE": "view_failure",
    "VIEW_FAMILY": "view_family",
    "VIEW_FAMILYREQUEST": "view_familyrequest",
    "VIEW_FAMILYTRANSFORM": "view_familytransform",
    "VIEW_FAMILYTYPE": "view_familytype",
    "VIEW_FAMILYVERSION": "view_familyversion",
    "VIEW_FILENAMEFORMAT": "view_filenameformat",
    "VIEW_FLATPAGE": "view_flatpage",
    "VIEW_GENERICRESOURCE": "view_genericresource",
    "VIEW_GROUP": "view_group",
    "VIEW_HOSTEDCONTENT": "view_hostedcontent",
    "VIEW_HOSTEDCONTENTVERSION": "view_hostedcontentversion",
    "VIEW_ISSUE": "view_issue",
    "VIEW_LIBRARY": "view_library",
    "VIEW_LIBRARYIMAGE": "view_libraryimage",
    "VIEW_LIBRARYIMAGEGROUP": "view_libraryimagegroup",
    "VIEW_LOADEDFAMILY": "view_loadedfamily",
    "VIEW_LOGENTRY": "view_logentry",
    "VIEW_MICROSOFTACCOUNT": "view_microsoftaccount",
    "VIEW_MODELAUDIT": "view_modelaudit",
    "VIEW_MODELAUDITCATEGORY": "view_modelauditcategory",
    "VIEW_MODELAUDITITEM": "view_modelaudititem",
    "VIEW_MODELAUDITRESPONSE": "view_modelauditresponse",
    "VIEW_MODELAUDITTEMPLATE": "view_modelaudittemplate",
    "VIEW_MODELAUDITTEMPLATERULE": "view_modelaudittemplaterule",
    "VIEW_MODELAUDITTYPE": "view_modelaudittype",
    "VIEW_MODELLINGNOTE": "view_modellingnote",
    "VIEW_MODELLINK": "view_modellink",
    "VIEW_MODELROOM": "view_modelroom",
    "VIEW_MODELSTATE": "view_modelstate",
    "VIEW_NOTIFICATION": "view_notification",
    "VIEW_OFFICE": "view_office",
    "VIEW_OFFLINETASK": "view_offlinetask",
    "VIEW_ORGANIZATION": "view_organization",
    "VIEW_PAGE": "view_page",
    "VIEW_PAGEATTACHMENT": "view_pageattachment",
    "VIEW_PERMISSION": "view_permission",
    "VIEW_PROJECT": "view_project",
    "VIEW_PROJECTATTACHMENT": "view_projectattachment",
    "VIEW_PROJECTCATEGORY": "view_projectcategory",
    "VIEW_PROJECTCERTIFICATIONMEMBERSHIP": "view_projectcertificationmembership",
    "VIEW_PROJECTCODEMEMBERSHIP": "view_projectcodemembership",
    "VIEW_PROJECTCOMPLIANCE": "view_projectcompliance",
    "VIEW_PROJECTEVENT": "view_projectevent",
    "VIEW_PROJECTITEM": "view_projectitem",
    "VIEW_PROJECTITEMMATCH": "view_projectitemmatch",
    "VIEW_PROJECTROLE": "view_projectrole",
    "VIEW_PROJECTSCOPE": "view_projectscope",
    "VIEW_REPORT": "view_report",
    "VIEW_REVITADDIN": "view_revitaddin",
    "VIEW_REVITADDINSTATUS": "view_revitaddinstatus",
    "VIEW_REVITBUILD": "view_revitbuild",
    "VIEW_REVITEVENT": "view_revitevent",
    "VIEW_REVITINFOMESSAGE": "view_revitinfomessage",
    "VIEW_REVITJOURNAL": "view_revitjournal",
    "VIEW_REVITMODEL": "view_revitmodel",
    "VIEW_REVITMODELELEMENT": "view_revitmodelelement",
    "VIEW_REVITPROFILE": "view_revitprofile",
    "VIEW_REVITTEMPLATE": "view_revittemplate",
    "VIEW_REVITTEMPLATESURVEY": "view_revittemplatesurvey",
    "VIEW_REVITVIEW": "view_revitview",
    "VIEW_REVITWARNING": "view_revitwarning",
    "VIEW_REVITWARNINGEVENT": "view_revitwarningevent",
    "VIEW_SECTION": "view_section",
    "VIEW_SESSION": "view_session",
    "VIEW_SHAREDPARAMETER": "view_sharedparameter",
    "VIEW_SHAREDPARAMETERCOLLECTION": "view_sharedparametercollection",
    "VIEW_SHAREDPARAMETERCOLLECTIONMEMBERSHIP": "view_sharedparametercollectionmembership",
    "VIEW_SHAREDPARAMETERSET": "view_sharedparameterset",
    "VIEW_SITE": "view_site",
    "VIEW_SITEFEEDBACK": "view_sitefeedback",
    "VIEW_SNIPPET": "view_snippet",
    "VIEW_SOURCE": "view_source",
    "VIEW_STATELOG": "view_statelog",
    "VIEW_SYNONYM": "view_synonym",
    "VIEW_TAG": "view_tag",
    "VIEW_TAGGEDITEM": "view_taggeditem",
    "VIEW_TASKEVENT": "view_taskevent",
    "VIEW_TASKINSTANCE": "view_taskinstance",
    "VIEW_THUMBNAIL": "view_thumbnail",
    "VIEW_THUMBNAILDIMENSIONS": "view_thumbnaildimensions",
    "VIEW_TOKEN": "view_token",
    "VIEW_TOKENPROXY": "view_tokenproxy",
    "VIEW_TOOLEVENT": "view_toolevent",
    "VIEW_USERPROFILE": "view_userprofile",
    "VIEW_USERPROFILEPROJECTRESTRICTION": "view_userprofileprojectrestriction",
    "VIEW_USERTOOLVERSION": "view_usertoolversion",
    "VIEW_VIEWCHECKER": "view_viewchecker",
    "VIEW_VIEWCHECKERSECTION": "view_viewcheckersection",
    "VIEW_WEBEVENT": "view_webevent",
    "VIEW_WORKSUMMARY": "view_worksummary",
}

const COUNTRIES =
{
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AU': 'Australia',
    'BE': 'Belgium',
    'CA': 'Canada',
    'CL': 'Chile',
    'CN': 'China',
    'CZ': 'Czechia',
    'DK': 'Denmark',
    'EG': 'Egypt',
    'FR': 'France',
    'DE': 'Germany',
    'HK': 'Hong Kong',
    'IN': 'India',
    'ID': 'Indonesia',
    'IQ': 'Iraq',
    'IE': 'Ireland',
    'IL': 'Israel',
    'IT': 'Italy',
    'JP': 'Japan',
    'KZ': 'Kazakhstan',
    'MY': 'Malaysia',
    'NL': 'Netherlands',
    'NZ': 'New Zealand',
    'PH': 'Philippines',
    'PL': 'Poland',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'RO': 'Romania',
    'SA': 'Saudi Arabia',
    'SG': 'Singapore',
    'SK': 'Slovakia',
    'ZA': 'South Africa',
    'KR': 'South Korea',
    'ES': 'Spain',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'TW': 'Taiwan',
    'TH': 'Thailand',
    'US': 'USA',
    'UA': 'Ukraine',
    'AE': 'United Arab Emirates',
    'GB': 'United Kingdom'
}

const FEET_TO_MM = 25.4 * 12;
const SQFT_TO_SQM = 0.092903;

export {
    APPLICATIONS,
    AUTOCAD,
    COLORS,
    COUNTRIES,
    FEET_TO_MM,
    INVENTOR,
    MICROSTATION,
    PERMISSIONS,
    REVIT,
    SQFT_TO_SQM,
    STATES_MODERATED,
    STATES_UNMODERATED,
};
