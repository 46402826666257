<template>
    <select-component v-model="selectedDiscipline"
        :show-all-text="showAllText"
        :choices="choices"></select-component>
</template>

<script>
import SelectComponent from './SelectComponent.vue';

export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    components: {
        SelectComponent,
    },
    data() {
        return {
            disciplines: [],
            selectedDiscipline: null,
        }
    },
    async created() {
        this.selectedDiscipline = this.modelValue;
        await this.$store.dispatch("core/loadDisciplineList");
        this.disciplines = this.$store.getters["core/getDisciplines"];
    },
    computed: {
        choices() {
            return this.disciplines.map(x => [x, x.name]);
        },
        showAllText() {
            if (this.showNone)
                return 'No discipline';
            else if (this.showAll)
                return 'All disciplines';
        },
    },
    watch: {
        selectedDiscipline(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            if (val)
                this.selectedDiscipline = this.disciplines.find(x => x.id == val.id);
            else
                this.selectedDiscipline = null;
        }
    }
}
</script>