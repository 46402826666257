<template>
    <h2 class="title">You do not have access to {{ redirectedFrom || 'this page' }}</h2>
</template>

<script>
export default {
    data() {
        return {
            redirectedFrom: this.$route.redirectedFrom?.fullPath,
        }
    },
}
</script>