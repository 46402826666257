<template>
    <div>
        <h2 class="title">Manage this Model</h2>
        <div class="columns">

            <!-- File data -->
            <div class="column">
                <h4 class="subtitle">Data from File</h4>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Model Cloud ID</th>
                            <td>{{ modelData.model_bim_360_id }}</td>
                        </tr>
                        <tr>
                            <th>Project Cloud ID</th>
                            <td>{{ modelData.project_bim_360_id }}</td>
                        </tr>
                        <tr>
                            <th>Project Name</th>
                            <td>{{ modelData.project_name }}</td>
                        </tr>
                        <tr>
                            <th>Project Number</th>
                            <td>{{ modelData.project_number }}</td>
                        </tr>
                        <tr>
                            <th>Filepath</th>
                            <td>{{ modelData.filepath }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Actions -->
            <div class="column is-narrow">
                <h4 class="subtitle">Actions</h4>
                <div class="buttons">
                    <template v-if="model"
                        class="buttons">
                        <button v-if="modelData.model_bim_360_id != model.bim360Id"
                            @click="updateModel({ bim360_id: modelData.model_bim_360_id })"
                            class="button is-fullwidth">
                            <span>Update cloud GUID</span>
                            <span class="icon">
                                <span class="mdi mdi-arrow-right"></span>
                            </span>
                        </button>
                        <button v-if="modelData.project_number != model.project"
                            @click="updateModel({ project: modelData.project_number })"
                            class="button is-fullwidth">
                            <span>Update project</span>
                            <span class="icon">
                                <span class="mdi mdi-arrow-right"></span>
                            </span>
                        </button>
                        <a @click="model = null"
                            class="button is-fullwidth">
                            <span>Deselect model</span>
                            <span class="icon">
                                <span class="mdi mdi-close"></span>
                            </span>
                        </a>
                    </template>
                    <template v-else>
                        <div class="field">
                            <div class="control">
                                <button @click="createModel"
                                    class="button is-fullwidth">Create model</button>
                            </div>
                            <div v-if="candidateModels.length"
                                class="control my-3 ml-2">
                                <label class="label">or choose from possible matches:</label>
                            </div>
                            <div v-for="candidateModel in candidateModels"
                                class="control">
                                <a @click="model = candidateModel"
                                    class="button is-fullwidth">
                                    {{ candidateModel.filename }}
                                </a>
                                <span class="help">{{ candidateModel.comment }}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <!-- Model -->
            <div class="column">
                <h4 class="subtitle">Matched Model</h4>
                <div v-if="model">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>Model Cloud ID</th>
                                <td>{{ model.bim360Id }}</td>
                            </tr>
                            <tr>
                                <th>Project Cloud ID</th>
                                <td>{{ model.bim360ProjectId }}</td>
                            </tr>
                            <tr>
                                <th>Project Number</th>
                                <td>{{ model.project }}</td>
                            </tr>
                            <tr>
                                <th>Filepath</th>
                                <td>{{ model.folder }}{{ model.filename }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <router-link :to="{ name: 'model-detail', params: { id: model.id } }"
                        target="_blank"
                        class="button is-pulled-right">
                        Open model detail
                    </router-link>
                </div>
                <div v-else>No exact match found</div>
            </div>
        </div>

        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </div>
</template>

<script>
import ConfirmDialog from "@/core/components/ConfirmDialog";
import Spinner from "@/core/components/Spinner";
import http from "@/http";
import { showMessage, urlQueryParams } from "@/utils";
import _ from "lodash";
import DesignModel from "../models/Model";

export default {
    components: {
        ConfirmDialog,
        Spinner,
    },
    data() {
        return {
            modelData: null,
            model: null,
            candidateModels: [],
            loading: false,
        }
    },
    async created() {
        let params = urlQueryParams(window.location);
        this.modelData = Object.fromEntries(params);

        // Look up model
        if (this.modelData.model_id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/${this.modelData.model_id}/`)
                .then(resp => resp.json());
            this.model = new DesignModel(data);
        } else if (this.modelData.model_bim_360_id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/?bim360_id=${this.modelData.model_bim_360_id}`)
                .then(resp => resp.json());
            if (data.results.length == 1) {
                this.model = new DesignModel(data.results[0]);
            }
        }

        if (!this.model) {
            this.loadCandidateModels();
        }
    },
    computed: {
        modelIsChanged() {

        }
    },
    methods: {
        async createModel() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Create new model',
                message: 'Are you sure you want to create a new model? ',
            });

            if (!ok)
                return

            const data = {
                bim360_id: this.modelData.model_bim_360_id,
                project_bim360_id: this.modelData.project_bim_360_id,
                project: this.modelData.project_number,
                path: this.modelData.filepath,
                application: 'R',
            };

            let resp = await http.post(`${process.env.VUE_APP_API_URL_V1}/bim/models/`, data)
            let result = await resp.json();
            if (resp.status == 201) {
                this.loadModel(result.id);
                showMessage('Model created');
            } else {
                showMessage('Model could not be created', 'danger');
            }         
        },
        async updateModel(data) {
            if (!data)
                return;

            const ok = await this.$refs.confirmDialog.show({
                title: 'Update model',
                message: `Are you sure you want to change this model?`,
            });

            if (!ok)
                return

            let resp = await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/models/${this.model.id}/`, data);
            if (resp.status == 200) {
                showMessage('Model updated');
            } else {
                showMessage('Model could not be updated', 'danger');
            }

            this.loadModel();
        },
        async loadModel(id) {
            let result = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/${id || this.model.id}/`)
                .then(resp => resp.json());
            this.model = new DesignModel(result);
        },
        loadCandidateModels: _.debounce(async function () {
            this.loading = false;
            this.candidateModels = [];

            if (this.modelData.model_bim_360_id) {
                let results = await this.loadModels({ limit: 5, bim360_id: this.modelData.model_bim_360_id });
                results.forEach(x => x.comment = 'Cloud GUID matches');
                this.candidateModels = this.candidateModels.concat(results);
            }

            if (this.modelData.filepath) {
                let results = await this.loadModels({ limit: 5, filename: this.modelData.filepath });
                results.forEach(x => x.comment = 'Filepath matches');
                this.candidateModels = this.candidateModels.concat(results);
            }

            this.loading = false;
        }, 200),
        async loadModels(data) {
            let results = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/`, data)
                .then(resp => resp.json());
            return results.results.map(x => new DesignModel(x));
        }
    },
}
</script>