import http from '@/http';
import { parseTree } from './utils.js';
import Page from './models/Page.js';

export default {
    namespaced: true,
    state: {
        roots: {},
        pageIdMap: {},
    },
    actions: {
        async loadTree({ commit, state }, treeId) {
            if (!state.roots[treeId]) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/pages/index/`, { tree: treeId })
                    .then(resp => resp.json());
                let allPages = data.results.map(x => new Page(x));
                state.roots[treeId] = parseTree(allPages);
                commit('SET_TREE_NODE', { data: { id: treeId, node: parseTree(data.results) } })

                for (let page of allPages) {
                    state.pageIdMap[page.id] = page;
                }
            }
        },
        expandNode({ commit, state }, pageId) {
            commit('EXPAND_NODE', { data: pageId });
        },
        resetNodes({ commit, state }) {
            commit('RESET_NODES');
        },
    },
    mutations: {
        SET_TREE_NODE: (state, { data }) => {
            state.roots[data.treeId] = data.node;
        },
        SET_PAGE_ID_MAP: (state, { data }) => {
            state.pageIdMap = data;
        },
        EXPAND_NODE: (state, { data }) => {
            state.pageIdMap[data].expanded = true;
        },
        RESET_NODES: (state) => {
            Object.keys(state.pageIdMap)
                .forEach(x => state.pageIdMap[x].expanded = false);
        },
    },
    getters: {
        getTreeRoot: state => (treeId) => state.roots[treeId],
        getPageById: state => (pageId) => state.pageIdMap[pageId],
    }
}