import BaseData from "@/core/models/BaseData";
import { APPLICATIONS } from "@/constants";

const TYPE_MESSAGE = 'MS';
const TYPE_NEW_CONTENT = 'NC';
const TYPE_PUBLISHED_CONTENT = 'PC';
const TYPE_UPDATED_CONTENT_SET = 'SU';

const LEVELS = {
    D: 'debug',
    I: 'info',
    W: 'warning',
    E: 'danger',
}

const ACTIONS = {
    [TYPE_MESSAGE]: 'message',
    [TYPE_NEW_CONTENT]: 'created',
    [TYPE_PUBLISHED_CONTENT]: 'published',
    [TYPE_UPDATED_CONTENT_SET]: 'updated',
}

const TYPES = {
    [TYPE_MESSAGE]: 'Message',
    [TYPE_NEW_CONTENT]: 'New content',
    [TYPE_PUBLISHED_CONTENT]: 'Published content',
    [TYPE_UPDATED_CONTENT_SET]: 'Content set updated',
}

let TARGETS = {
    'W': 'Web',
    'C': 'Content Lab',
}

Object.assign(TARGETS, APPLICATIONS);

class Notification extends BaseData {
    constructor(data) {
        super(data);

        if (this.start)
            this.start = this.start.format('YYYY-MM-DD');

        if (this.end)
            this.end = this.end.format('YYYY-MM-DD');

        if (!this.level)
            this.level = 'I';

        if (!this.targets)
            this.targets = ['W'];
    }

    get cssClass() {
        return `has-background-${LEVELS[this.level]}`;
    }

    get actionDescription() {
        return ACTIONS[this.type] || this.type;
    }

    get typeName() {
        return TYPES[this.type] || this.type;
    }

    get levelName() {
        return LEVELS[this.level] || this.level;
    }

    get targetNames() {
        return this.targets.map(x => TARGETS[x] || APPLICATIONS[x] || x);
    }
}

export default Notification;
export {LEVELS, TYPES, TARGETS};
