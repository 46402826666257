<template>
    <div v-if="item">
        <a v-if="!item.processing"
            :href="item.attachment"
            class="button is-link is-pulled-right">
            <span class="icon">
                <span class="mdi"
                    :class="item.iconClass"></span>
            </span>
            <span>
                Download report
            </span>
        </a>
        <h2 class="title">Report {{ item.id }}</h2>
        <table class="table">
            <tr>
                <th>Project:</th>
                <td>{{ item.project.number }}</td>
            </tr>
            <tr>
                <th>Type:</th>
                <td>{{ item.typeName }}</td>
            </tr>
            <tr>
                <th>Format:</th>
                <td>{{ item.formatName }}</td>
            </tr>
            <tr>
                <th>Status:</th>
                <td>
                    {{ item.processing ? 'Processing' : 'Complete' }}
                </td>
            </tr>
            <tr>
                <th>Filename:</th>
                <td>{{ item.filename }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import BaseDetailComponent from './BaseDetailComponent';
import Report from '../models/Report';
import { asyncTimeout } from '@/utils';

export default {
    mixins: [BaseDetailComponent],
    data() {
        return {
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/core/reports/${this.$route.params.id}/`,
            modelClass: Report,
        }
    },
    methods: {
        async setup() {
            // If report is still processing then keep reloading until it is complete
            let timeout = 1000;
            while (this.item.processing) {
                await asyncTimeout(timeout);
                this.loadItem();
                timeout *= 2;
            }
        }
    }
}

</script>