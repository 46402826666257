<template>
    <h2 class="title"
        v-if="items">
        {{ pageTitle }}
        Audit Elements
    </h2>
    <h3 class="subtitle"
        v-if="filters.model || filters.project">
        <div v-if="filters.model">Model: {{ filters.model.filename }}</div>
        <div v-if="filters.project">Project: {{ filters.project.name }}</div>
    </h3>
    <div class="field is-grouped is-grouped-multiline">
        <div class="control">
            <div class="select">
                <select v-model="filters.base_type">
                    <option value="C">Content elements</option>
                    <option value="M">Model elements</option>
                </select>
            </div>
        </div>
        <application-select v-model="filters.application"
            :show-all="true"></application-select>
        <project-autocomplete v-model="filters.project"
            placeholder="Search projects"
            class="projects">
        </project-autocomplete>
        <model-select v-model="filters.model"
            :project="filters.project"
            v-if="filters.project">
        </model-select>
    </div>
    <div class="field is-grouped is-grouped-multiline">
        <div class="control"
            v-if="filters.application">
            <div class="select">
                <select v-model="filters.type">
                    <option :value="null">All types</option>
                    <option v-for="type in types"
                        :value="type">{{ type.name.toUpperCase() }}</option>
                </select>
            </div>
        </div>
        <search-input v-model="filters.name"
            placeholder="Search by name"></search-input>
        <audit-rule-autocomplete v-model="filters.rule"
            v-if="filters.application"
            :application="filters.application"
            placeholder="Search audit rules">
        </audit-rule-autocomplete>
        <div class="control p-3">
            <label class="checkbox">
                <input type="checkbox"
                    v-model="filters.duplicates">
                Duplicates
            </label>
        </div>
    </div>
    <div class="field is-grouped is-grouped-multiline">
        <limit-select v-model="filters.limit"></limit-select>
        <label class="p-3">Display units:</label>
        <units-select v-model="displayUnits"></units-select>

        <div v-if="filters.type"
            class="control">
            <div class="dropdown is-hoverable">
                <div class="dropdown-trigger">
                    <button class="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu">
                        <span v-if="hiddenFields.length">
                            {{ hiddenFields.length }} hidden field{{ hiddenFields.length == 1 ? '' : 's' }}
                        </span>
                        <span v-else>Show / hide fields</span>
                        <span class="icon is-small">
                            <i class="mdi mdi-chevron-down"
                                aria-hidden="true"></i>
                        </span>
                    </button>
                </div>
                <div class="dropdown-menu"
                    id="dropdown-menu"
                    role="menu">
                    <div class="dropdown-content">
                        <ul>
                            <li v-for="key in filters.type.keys"
                                class="dropdown-item">
                                <label>
                                    <input type="checkbox"
                                        v-model="key.visible">
                                    {{ key.name }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <clear-button @click="clearFilters"
            v-if="isFiltered"></clear-button>
    </div>
    <hr v-if="filters.type">
    <div class="field is-grouped is-grouped-multiline"
        v-if="filters.type">
        <div class="control">
            <div class="select">
                <select v-model="filters.sub_type">
                    <option :value="null">All sub types</option>
                    <option v-for="subType in filters.type.subTypes">{{ subType }}</option>
                </select>
            </div>
        </div>
        <div v-for="key in filters.type.keys"
            class="control">
            <div v-if="key.type != 'object' && key.type != 'list'"
                class="field has-addons">
                <div class="control">
                    <a class="button is-static">
                        {{ key.name }}
                    </a>
                </div>
                <div class="control">

                    <div v-if="key.choices && key.choices.length > 1"
                        class="select">
                        <select v-model="filters['data__' + key.name]">
                            <option value="">Filter by {{ key.name }}</option>
                            <option v-for="choice in key.choices">{{ choice }}</option>
                        </select>
                    </div>

                    <input v-if="!key.choices && key.type == 'string'"
                        v-model="filters['data__' + key.name + '__icontains']"
                        :placeholder="'Filter by ' + key.name"
                        class="input"
                        type="text">

                    <input v-if="!key.choices && key.type == 'number'"
                        v-model="filters['data__' + key.name]"
                        :placeholder="'Filter by ' + key.name"
                        class="input"
                        type="number">

                    <div v-if="!key.choices && key.type == 'bool'"
                        class="select">
                        <select v-model="filters['data__' + key.name]">
                            <option :value="null">Any value</option>
                            <option :value="true">True</option>
                            <option :value="false">False</option>
                        </select>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div v-if="message"
        class="notification">{{ message }}</div>
    <div v-if="showResults"
        class="table-container mt-4">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <td v-if="filters.base_type == 'C'">Content</td>
                    <th v-if="filters.base_type == 'M'">Model</th>
                    <th>Type</th>
                    <th v-if="filters.type">Sub-type</th>
                    <th>Name</th>
                    <th>Creator</th>
                    <th>Duplicates</th>
                    <th></th>
                    <th v-if="filters.type"
                        v-for="key in filters.type.keys"
                        :class="{ hidden: !key.visible }">
                        {{ key.name }}
                    </th>
                    <th v-if="hiddenFields.length">
                        <a @click="filters.type.keys.forEach(x => x.visible = true)">
                            Unhide {{ hiddenFields.length }} field{{ hiddenFields.length == 1 ? '' : 's' }}
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="element in items"
                    :key="element.id">
                    <td v-if="filters.base_type == 'C'">
                        {{ element.content?.title }}
                    </td>
                    <td v-if="filters.base_type == 'M'">
                        {{ element.model?.filename }}
                    </td>
                    <td>
                        {{ element.type.toUpperCase() }}
                    </td>
                    <td v-if="filters.type">
                        {{ element.subType }}
                    </td>
                    <td>
                        {{ element.name }}
                    </td>
                    <td>
                        {{ element.creator }}
                    </td>
                    <td>
                        <ul v-if="element.duplicates"
                            class="has-text-danger">
                            <li v-for="duplicate in element.duplicates">
                                {{ duplicate }}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <span v-if="element.duplicates?.length">
                            <a v-if="filters.identity"
                                @click="filters.identity = null"
                                title="Show all elements">
                                <span class="icon">
                                    <span class="mdi mdi-close">
                                    </span>
                                </span>
                            </a>
                            <a v-else
                                @click="filters.identity = element.identity"
                                title="Show only duplicates of this element">
                                <span class="icon">
                                    <span class="mdi mdi-content-copy">
                                    </span>
                                </span>
                            </a>
                        </span>
                    </td>
                    <td v-if="filters.type"
                        v-for="key in filters.type.keys"
                        :class="{ hidden: !key.visible }">
                        <span class="key"
                            v-html="formatValue(element, key.name)">
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <report-link v-if="items.length"
            :project="this.filters.project?.number"
            :data="reportData"
            type="ME"
            format="XLSX"
            class="is-pulled-right"></report-link>

        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange"></pagination>
    </div>
</template>

<script>
import ModelSelect from "@/bim/components/ModelSelect";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import ReportLink from "@/core/components/ReportLink";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import UnitsSelect from "@/core/components/UnitsSelect";
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import { cleanData } from "@/utils";
import _ from "lodash";
import { arrayFormatter, objectFormatter, typeFormatters } from "../formatters";
import AuditElement from "../models/AuditElement";
import AuditElementType from "../models/AuditElementType";
import AuditRuleAutocomplete from "./AuditRuleAutocomplete";

export default {
    mixins: [BaseListComponent],
    data() {
        return {
            conditions: [],
            resultsCount: 0,
            types: [],
            message: null,
            filters: {
                application: null,
                base_type: 'M',
                creator: null,
                duplicates: false,
                identity: null,
                limit: 100,
                model: null,
                name: null,
                offset: 0,
                project: null,
                sub_type: null,
                type: null,
                rule: null,
            },
            displayUnits: 'I',
        }
    },
    components: {
        ApplicationSelect,
        ClearButton,
        DisciplineSelect,
        LimitSelect,
        ModelSelect,
        Pagination,
        ProjectAutocomplete,
        ReportLink,
        SearchInput,
        SectionNav,
        UnitsSelect,
        AuditRuleAutocomplete,
    },
    mounted() {
        this.displayUnits = this._store.displayUnits || this.displayUnits;
    },
    methods: {
        async loadUrlArgs() {
            let modelId = this.$route.query.model;
            if (modelId) {
                await this.$store.dispatch('bim/loadModelById', modelId);
                this.filters.model = this.$store.getters['bim/getModelById'](modelId)
            }

            let projectId = this.$route.query.project;
            if (projectId) {
                await this.$store.dispatch('projects/loadProjectById', projectId);
                this.filters.project = this.$store.getters['projects/getProjectById'](projectId)
            }

            let application = this.$route.query.application;
            if (application)
                this.filters.application = application;
        },
        loadItems: _.debounce(async function () {
            if (!this.filters.application || !this.filters.project) {
                this.message = "Application and project must be selected";
                this.items = [];
                return;
            }

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/elements/`, this.cleanedFilters)
                .then(resp => resp.json());
            this.resultsCount = data.count;
            this.items = data.results.map(x => new AuditElement(x));
            this.message = null;

        }, 200),
        async loadSummary() {
            let args = {};

            if (this.filters.application)
                args.application = this.filters.application?.code || this.filters.application;
            else
                return;

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/elements-summary/`, args)
                .then(resp => resp.json());

            this.types = data.types.map(x => new AuditElementType({ name: x, count: 0, sub_types: [], keys: [], loaded: false }));
        },
        async loadSummaryDetail() {
            if (!this.filters.type || this.filters.type.loaded)
                return;

            let args = { type: this.filters.type.name };
            if (this.filters.project)
                args.project = this.filters.project.number;

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/elements-summary-detail/`, args)
                .then(resp => resp.json());

            Object.assign(this.filters.type, new AuditElementType(data));
            this.filters.type.loaded = true;

            this.filters.type.keys.forEach(x => {
                if (x.type == 'bool')
                    this.filters['data__' + x.name] = null;
            })
        },
        formatValue(element, key) {
            const val = element.data[key];

            try {
                const formatter = typeFormatters[element.type];
                if (formatter && formatter[key])
                    return formatter[key](val, this);
                else if (Array.isArray(val))
                    return arrayFormatter(val, this);
                else if (_.isObject(val))
                    return objectFormatter(val, this);
                return val;
            } catch (e) {
                console.log(e);
                return val;
            }
        },
        clearFilters() {
            // Clear everything except project and application
            const project = this.filters.project;
            const application = this.filters.application;

            this.filters = Object.assign({}, this._cache.filters);

            this.filters.project = project;
            this.filters.application = application;
        },
    },
    computed: {
        reportData() {
            const data = {};

            if (this.filters.application)
                data.application = this.filters.application.code;

            if (this.filters.model)
                data.models = [this.filters.model.id];

            if (this.filters.type)
                data.types = [this.filters.type];

            return data;
        },
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;
            if (filters.discipline)
                filters.discipline = filters.discipline.name;
            if (filters.project)
                filters.project = filters.project.number;
            if (filters.model)
                filters.model = filters.model.id;
            if (filters.type)
                filters.type = filters.type.name;
            if (filters.rule)
                filters.rule = filters.rule.id;

            return filters;
        },
        showResults() {
            return this.filters.application && this.filters.project;
        },
        hiddenFields() {
            return this.filters.type?.keys.filter(x => !x.visible) || [];
        }
    },
    watch: {
        'filters.application': {
            async handler(val) {
                if (val) {
                    this.message = '';
                    await this.loadSummary();
                } else {
                    this.message = "Select an application to continue";
                    return;
                }
            }
        },
        'filters.project': {
            async handler() {
                await this.loadSummary();
            }
        },
        'filters.type': {
            async handler() {
                // Remove any data filters
                Object.keys(this.filters)
                    .filter(x => x.startsWith('data__'))
                    .forEach(x => delete this.filters[x])

                await this.loadSummaryDetail();
            }
        },
        filters: {
            handler(val) {
                this.updateHistory(
                    cleanData({
                        application: this.filters.application?.code,
                        model: this.filters.model?.id,
                        project: this.filters.project?.id,
                    }));
            },
            deep: true,
        },
        displayUnits(val) {
            this._store.displayUnits = val;
        }
    },
}
</script>

<style lang="sass">
.control.projects
    .has-icons-left
        width: 500px

th.hidden
    display: none

td.hidden
    display: none

.color-swatch 
    display: inline-block
    width: 1.5em
    height: 1.5em
    margin-right: 0.5em

</style>