<template>
    <div>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Tags
        </h2>
        <div class="field is-grouped is-grouped-multiline">
            <search-input v-model="filters.q"
                placeholder="Search tags"></search-input>
            <content-library-select v-model="filters.library"
                :show-all="true"></content-library-select>
            <div class="control">
                <div class="select">
                    <select v-model="filters.model">
                        <option value="content.Content">Content</option>
                        <option value="content.ContentImage">Content Image</option>
                        <option value="bim.AuditRule">Audit Rule</option>
                    </select>
                </div>
            </div>
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button @click="clearFilters"
                v-if="isFiltered"></clear-button>
        </div>
        <div class="field is-grouped is-grouped-multiline">
            <div class="control">
                <input v-model="filters.min_count"
                    placeholder="Minimum count"
                    type="number"
                    class="input">
            </div>
            <div class="control">
                <input v-model="filters.max_count"
                    placeholder="Maximum count"
                    type="number"
                    class="input">
            </div>
        </div>
        <div class="columns">
            <div class="column is-half">
                <div v-if="modifiedTags.length"
                    class="panel">
                    <div class="panel-heading">
                        {{ modifiedTags.length }} Modified Tags
                    </div>
                    <div v-for="tag in modifiedTags"
                        class="panel-block">
                        <span v-if="tag.deleted"
                            class="icon">
                            <span class="mdi mdi-close"></span>
                        </span>
                        {{ tag.name }}
                        <a @click="tag.restore()"
                            title="Undo change to tag">
                            <span class="icon">
                                <span class="mdi mdi-undo"></span>
                            </span>
                        </a>
                    </div>
                    <div class="panel-block">
                        <div class="buttons is-pulled-right">
                            <button @click="clear"
                                class="button">Clear</button>
                            <button @click="save"
                                class="button is-link">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Tag</th>
                    <th>Actions</th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="tag in items">
                    <td>
                        <span v-if="!tag.editing">{{ tag.name }}</span>
                        <div v-if="tag.editing"
                            class="field has-addons">
                            <div class="control">
                                <input v-model="tag.name"
                                    type="text"
                                    class="input">
                            </div>
                            <div class="control">
                                <a @click="tag.editing = false"
                                    class="button">
                                    <span class="icon">
                                        <span class="mdi mdi-check"></span>
                                    </span>
                                </a>
                            </div>
                            <div class="control">
                                <a @click="tag.restore(); tag.editing = false"
                                    class="button">
                                    <span class="icon">
                                        <span class="mdi mdi-close"></span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a @click="tag.delete()"
                            v-if="!tag.deleted"
                            title="Delete tag">
                            <span class="icon">
                                <span class="mdi mdi-delete"></span>
                            </span>
                        </a>
                        <a @click="tag.editing = true"
                            v-if="!tag.editing"
                            title="Edit tag">
                            <span class="icon">
                                <span class="mdi mdi-pencil"></span>
                            </span>
                        </a>
                    </td>
                    <td>{{ tag.count }}</td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import Tag from "@/core/models/Tag";
import http from "@/http";
import { cleanData, showMessage } from "@/utils";
import _ from "lodash";

export default {
    mixins: [BaseListComponent],
    components: {
        Pagination,
        SearchInput,
        LimitSelect,
        ContentLibrarySelect,
        ClearButton,
    },
    data() {
        return {
            filters: {
                q: null,
                model: 'content.Content',
                library: null,
                min_count: null,
                max_count: null,
                limit: null,
                offset: 0,
            }
        }
    },
    methods: {
        loadItems: _.debounce(async function () {
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/core/tags-manage/`,
                this.cleanedFilters)
                .then(resp => resp.json());
            this.items = data.results.map(x => new Tag(x));
            this.resultsCount = data.count;

        }, 200),
        clear() {
            this.modifiedTags.forEach(x => x.restore());
        },
        async save() {
            const data = {
                tags: this.modifiedTags,
                model: this.filters.model,
                library: this.filters.library?.id,
            }
            await http.post(`${process.env.VUE_APP_API_URL_V1}/core/tags-manage/`, data);
            showMessage('Tags saved');
            this.modifiedTags.forEach(x => x.setMark());
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.library)
                filters.library = filters.library.id;

            return filters;
        },
        modifiedTags() {
            return this.items.filter(x => x.hasChanged());
        },
    }
}
</script>