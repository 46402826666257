<template>
    <div class="columns is-multiline">
        <div class="column is-one-third">
            <router-link :to="{ name: 'model-list' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Model List
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Complete list of all Design models </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-one-third">
            <router-link :to="{ name: 'journal-list' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Journals
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Revit model journal information </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-one-third">
            <router-link :to="{ name: 'model-events' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Events
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Track model activity in real time</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-one-third">
            <router-link :to="{ name: 'task-list' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Tasks
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Monitor progress of offline tasks</div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-one-third">
            <router-link :to="{ name: 'parameter-list' }">
                <div class="card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Parameters
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Create shared parameters and collections </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="column is-one-third">
            <router-link :to="{ name: 'audit-list' }">
                <div class="is-one-third card section-index">
                    <div class="card-header">
                        <div class="card-header-title">
                            Audits
                        </div>
                    </div>
                    <div class="card-content">
                        <div>Automated model audits</div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
