<template>
    <div v-if="item">
        <div class="buttons is-pulled-right">
            <a @click="showRawData = !showRawData"
                class="button">
                <span class="icon">
                    <span class="mdi mdi-code-braces"></span>
                </span>
                <span v-if="showRawData">Hide raw data</span>
                <span v-else>Show raw data</span>
            </a>
            <router-link :to="{ name: 'content-detail', params: { id: item.content.id } }"
                class="button">Content object</router-link>
        </div>
        <h2 class="title">Audit Summary: {{ item.content.title }}</h2>
        <pre v-if="showRawData">{{ item.data }}</pre>
        <div v-else>
            <div>
                <div class="tags">
                    <span v-for="condition in item.conditions"
                        class="tag is-link">
                        {{ condition }}
                    </span>
                </div>
                <table class="table">
                    <tbody>
                        <audit-summary-section v-for="section in item.summaryFields"
                            :section="section">
                        </audit-summary-section>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AuditSummary from '../models/AuditSummary';
import AuditSummarySection from "./AuditSummarySection";
import BaseDetailComponent from "@/core/components/BaseDetailComponent";

export default {
    mixins: [BaseDetailComponent],
    components: { AuditSummarySection },
    data() {
        return {
            showRawData: false,
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/audits/summaries/${this.$route.params.id}/`,
            modelClass: AuditSummary,
        };
    },
}
</script>