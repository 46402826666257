<template>
    <div class="panel">
        <div class="panel-heading">
            <router-link :to="{ name: 'audit-rule-edit', params: { id: rule.id } }"
                title="Edit rule"
                class="is-pulled-right">
                <span class="icon">
                    <span class="mdi mdi-pencil"></span>
                </span>
            </router-link>
            {{ rule.name }}
            <span :class="{ 'has-text-success': rule.hasResults, 'has-text-grey': !rule.hasResults }"
                class="icon">
                <span class="mdi mdi-check-circle mdi-18px"
                    v-if="rule.hasResults"
                    title="This rule is proven valid"></span>
                <span class="mdi mdi-help-circle mdi-18px"
                    title="This rule has not yet been proven valid"
                    v-else></span>
            </span>
        </div>
        <div class="panel-block">
            <div class="is-italic">
                {{ rule.summary }}
            </div>
        </div>
        <div v-if="rule.description"
            class="panel-block">
            <div class="is-italic">
                {{ rule.description }}
            </div>
        </div>
        <table class="table is-fullwidth">
            <tbody>
                <tr>
                    <th>Type:</th>
                    <td>{{ rule.typeName }}</td>
                </tr>
                <tr>
                    <th>Application:</th>
                    <td>{{ rule.applicationName }}</td>
                </tr>
                <tr>
                    <th>Weighting:</th>
                    <td>
                        <span :class="{ 'is-success': rule.polarity, 'is-danger': !rule.polarity }"
                            class="tag">{{ rule.normalizedWeight }}</span>
                    </td>
                </tr>
                <tr>
                    <th>Element types:</th>
                    <td>
                        {{ rule.elementTypes.map(x => x.toUpperCase()).join(', ') }}
                    </td>
                </tr>
                <tr>
                    <th>Tags:</th>
                    <td>
                        <div v-if="rule.tags.length"
                            class="tags">
                            <span v-for=" tag in rule.tags"
                                class="tag">{{ tag }}</span>
                        </div>
                        <span v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <th>Project:</th>
                    <td>{{ rule.project?.name || '-' }}</td>
                </tr>
                <tr>
                    <th>Creator:</th>
                    <td>{{ rule.creator || '-' }}</td>
                </tr>
            </tbody>
        </table>
        <div class="panel-block">
            <div>
                <a @click="showDetail = true"
                    v-if="!showDetail">
                    <span class="icon">
                        <span class="mdi mdi-chevron-down mdi-18px"></span>
                    </span>
                    <span>Show details</span>
                </a>
                <div v-if="showDetail">
                    <pre>{{ rule.filters }}</pre>
                </div>
                <a @click="showDetail = false"
                    v-if="showDetail">
                    <span class="icon">
                        <span class="mdi mdi-chevron-up mdi-18px"></span>
                    </span>
                    <span>Hide details</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['rule'],
    data() {
        return {
            showDetail: false,
        }
    },
}
</script>

<style lang="sass" scoped>
    pre
        background: transparent
        padding-top: 0

    .panel
        table
            margin-bottom: 0

            th 
                width: 10em
</style>