import http from '@/http';
import ContentLibrary from './models/ContentLibrary';
import ContentVersion from './models/ContentVersion';
import ContentSet from './models/ContentSet';
import ContentType from './models/ContentType';

export default {
    namespaced: true,
    state: {
        libraries: [],
        categories: [],
        auditstates: [],
        sets: [],
        groups: [],
        creators: [],
        fields: [],
        types: [],
        versions: {},
    },
    actions: {
        async loadLibraryList({ commit, state }) {
            if (state.libraries.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/libraries/?all=true`)
                    .then(resp => resp.json());
                commit('SET_LIBRARY_LIST', { data: data.results.map(x => new ContentLibrary(x)) });
            }
        },
        async loadCategoriesList({ commit, state }) {
            if (state.categories.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/categories/`)
                    .then(resp => resp.json());
                commit('SET_CATEGORIES_LIST', { data });
            }
        },
        async loadSetList({ commit, state }) {
            if (state.sets.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/sets/`, { limit: 999, no_favs: true })
                    .then(resp => resp.json());
                commit('SET_SET_LIST', { data: data.results.map(x => new ContentSet(x)) });
            }
        },
        async loadGroupList({ commit, state }) {
            if (state.groups.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/groups-full/`)
                    .then(resp => resp.json());
                commit('SET_GROUP_LIST', { data });
            }
        },
        async loadCreatorList({ commit, state }) {
            if (state.creators.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/creators/`, { all: true, limit: 999 })
                    .then(resp => resp.json());
                commit('SET_CREATOR_LIST', { data: data.results });
            }
        },
        async loadFieldList({ commit, state }) {
            if (state.fields.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/fields/`)
                    .then(resp => resp.json());
                commit('SET_FIELD_LIST', { data });
            }
        },
        async loadTypeList({ commit, state }) {
            if (state.types.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/types/`)
                    .then(resp => resp.json());
                commit('SET_TYPE_LIST', { data: data.map(x => new ContentType(x)) });
            }
        },
        async loadVersions({ commit, state }, id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/versions/`, { content: id })
                .then(resp => resp.json());
            commit('SET_VERSIONS_LIST', { contentId: id, data: data.results.map(x => new ContentVersion(x)) });
        },
    },
    mutations: {
        SET_LIBRARY_LIST: (state, { data }) => {
            state.libraries = data;
        },
        SET_CATEGORIES_LIST: (state, { data }) => {
            state.categories = data;
        },
        SET_SET_LIST: (state, { data }) => {
            state.sets = data;
        },
        SET_GROUP_LIST: (state, { data }) => {
            state.groups = data;
        },
        SET_CREATOR_LIST: (state, { data }) => {
            state.creators = data;
        },
        SET_FIELD_LIST: (state, { data }) => {
            state.fields = data;
        },
        SET_TYPE_LIST: (state, { data }) => {
            state.types = data;
        },
        SET_VERSIONS_LIST: (state, { contentId, data }) => {
            state.versions[contentId] = data;
        },
    },
    getters: {
        getLibraries: state => state.libraries,
        getCategories: state => state.categories,
        getSets: state => state.sets.filter(x => !x.user),
        getGroups: state => state.groups,
        getCreators: state => state.creators,
        getLibraryByCode: state => (code) => state.libraries.find(x => x.code == code),
        getLibraryById: state => (id) => state.libraries.find(x => x.id == id),
        getFields: state => state.fields,
        getTypes: state => state.types,
        getTypeByCode: state => (code) => state.types.find(x => x.code == code),
        getVersions: state => (id) => state.versions[id] || [],
    }
}