<template>
    <div v-if="model">

        <h2 class="title">
            {{ model.filename }}
        </h2>

        <div class="columns">
            <div class="column is-half">
                <div class="field">
                    <label class="label">Discipline</label>
                    <discipline-select v-model="model.discipline"
                        :show-none="true">
                    </discipline-select>
                </div>
                <div class="field">
                    <label class="label">Project</label>
                    <div class="control">
                        <project-autocomplete v-model="model.project"
                            placeholder="Select Project">
                        </project-autocomplete>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox"
                                v-model="model.active">
                            Active
                        </label>
                    </div>
                    <div class="help">
                        Show this model in listing or search results
                    </div>
                </div>
                <div class="field">
                    <label class="label">Project info source*</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="model.updateStrategy">
                                <option v-for="(strategy, code) in updateStrategies"
                                    :key="code"
                                    :value="code">
                                    {{ strategy }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="help">
                        How will the model project info and database entry be updated?
                    </div>
                </div>
                <div class="field">
                    <label class="label">Cloud GUID</label>
                    <div class="control">
                        <locked-input :type="text"
                            v-model="model.bim360Id"
                            class="guid"></locked-input>
                    </div>
                    <div class="help">The Revit BIM 360 id or similar field</div>
                </div>
            </div>
        </div>

        <save-button @click="save"
            :saving="saving">
        </save-button>
    </div>
</template>

<script>
import http from "@/http";

import DisciplineSelect from "@/core/components/DisciplineSelect.vue";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import SaveButton from "@/core/components/SaveButton";
import { UPDATE_STRATEGIES } from "../constants";
import DesignModel from "../models/Model";
import LockedInput from "@/core/components/LockedInput";

export default {
    components: {
        DisciplineSelect,
        ProjectAutocomplete,
        SaveButton,
        LockedInput
    },
    data() {
        return {
            model: null,
            saving: false,
            updateStrategies: UPDATE_STRATEGIES,
        };
    },
    async created() {
        if (this.$route.params.id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/${this.$route.params.id}/`)
                .then(resp => resp.json());
            this.model = new DesignModel(data);
        }
    },
    methods: {
        async save() {
            this.saving = true;

            let data = {
                active: this.model.active,
                discipline: this.model.discipline?.name,
                project: this.model.project.number,
                updateStrategy: this.model.updateStrategy,
                bim360_id: this.model.bim360Id,
            }

            await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/models/${this.model.id}/`, data)
                .then(resp => resp.json());

            this.saving = false;
            this.$router.push({
                name: 'model-detail',
                params: {
                    id: this.model.id,
                }
            });
        },
    }
}
</script>

<style lang="sass">
.guid
    input
        font-family: monospace
        width: 25em
</style>