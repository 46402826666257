<template>
    <div v-if="library">
        <h2 class="title">{{ library.title }}</h2>
        <FormKit type="form"
            @submit="save">
            <div class="columns">
                <div class="column">
                    <FormKit type="text"
                        label="Title"
                        name="title"
                        validation="required"
                        v-model="library.baseTitle">
                    </FormKit>
                </div>
                <div class="column">
                    <FormKit type="text"
                        label="Code"
                        name="code"
                        v-model="library.code">
                    </FormKit>
                </div>
                <div class="column is-narrow">
                    <div :style="{ width: '75px' }">
                        <FormKit type="color"
                            label="Color"
                            name="color"
                            v-model="library.color">
                        </FormKit>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <FormKit type="select"
                        label="Scope"
                        name="scope"
                        validation="required"
                        :options="{
                            GLO: 'Global',
                            MKT: 'Market',
                        }"
                        v-model="library.scope">
                    </FormKit>
                </div>
                <div class="column">
                    <FormKit type="select"
                        label="Region"
                        name="region"
                        :options="{
                            APME: 'Asia Pacific Middle East',
                            EURO: 'Europe',
                            AMER: 'America',
                        }"
                        v-model="library.region">
                    </FormKit>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <FormKit type="textarea"
                        label="Description"
                        name="description"
                        rows="4"
                        v-model="library.description">
                    </FormKit>
                </div>
                <div class="column">
                    <FormKit type="text"
                        name="tags"
                        label="Tags"
                        help="Standard tags that will be suggested when new content is created"
                        v-model="library.tags">
                    </FormKit>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <FormKit type="checkbox"
                        label="Moderated"
                        name="moderated"
                        help="Does new content need to be approved by a library admin?"
                        v-model="library.enableWorkflows">
                    </FormKit>
                </div>
                <div class="column">
                    <FormKit type="checkbox"
                        label="Requests enabled"
                        name="requests_enabled"
                        help="Should this library accept content requests?"
                        v-model="library.requestsEnabled">
                    </FormKit>
                </div>
                <div class="column">
                    <FormKit type="checkbox"
                        label="Requires code"
                        name="requires_code"
                        help="Families in this library must have a code"
                        v-model="library.requiresCode">
                    </FormKit>
                </div>
            </div>
            <div class="panel">
                <div class="panel-heading">Data fields</div>
                <li v-for="dataField in allDataFields"
                    :key="dataField"
                    class="panel-block">
                    <label class="checkbox">
                        <input type="checkbox"
                            v-model="library.dataFields"
                            :value="dataField">
                        {{ dataField }}
                    </label>
                </li>
                <div class="panel-block">
                    <p class="notification is-info">
                        Warning: de-selecting fields will delete all content item values for that field.
                    </p>
                </div>
            </div>
            <content-filename-format-edit-panel v-model="library.filenameFormats"
                :show-default="true"
                :library="library">
            </content-filename-format-edit-panel>
        </FormKit>
    </div>
</template>

<script>
import http from "@/http";
import { FormKit } from "@formkit/vue";
import ContentLibrary from "../models/ContentLibrary";
import ContentFilenameFormatEditPanel from "@/content/components/ContentFilenameFormatEditPanel";

export default {
    components: {
        ContentFilenameFormatEditPanel,
        FormKit,
    },
    data() {
        return {
            allDataFields: [],
            library: null,
        };
    },
    async created() {
        await this.fetchData()
    },
    methods: {
        async fetchData() {
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/libraries/${this.$route.params.id}/`
            ).then(resp => resp.json());
            this.library = new ContentLibrary(data);
            this.library.dataFields = this.library.dataFields.map(x => x.name);
            this.library.tags = this.library.tags.join(', ');

            await this.$store.dispatch("content/loadFieldList");
            this.allDataFields = this.$store.getters["content/getFields"].map(x => x.name);
        },
        async save(formData, node) {
            let data = {
                code: this.library.code,
                color: this.library.color,
                data_fields: this.library.dataFields,
                description: this.library.description,
                enable_workflows: this.library.enableWorkflows,
                region: this.library.region,
                requests_enabled: this.library.requestsEnabled,
                requires_code: this.library.requiresCode,
                scope: this.library.scope,
                title: this.library.baseTitle,
                filename_formats: this.library.filenameFormats.map(x => {
                    return {
                        application: x.application.code,
                        format: x.format,
                    }
                })
            };

            if (this.library.tags)
                data.tags = this.library.tags;

            let resp = await http.patch(
                `${process.env.VUE_APP_API_URL_V2}/content/libraries/${this.$route.params.id}/`,
                data);

            if (resp.status != 200) {
                let respData = await resp.json();
                node.setErrors(respData.join(". "));
            } else {
                this.$router.push({
                    name: "content-library-detail",
                    params: {
                        id: this.library.id,
                    }
                });
            }
        },
    },
}
</script>
