<template>
    <div class="panel">
        <div class="panel-heading">
            <router-link :to="{ name: 'associated-files', params: { id: content.id } }"
                class="is-pulled-right"
                title="Add or edit associated files">
                <span class="icon">
                    <span class="mdi mdi-pencil"></span>
                </span>
            </router-link>
            {{ content.associatedFiles.length || 'No' }}
            Associated {{ content.associatedFiles.length == 1 ? 'File' : 'Files' }}
        </div>
        <div class="panel-block">
            <table class="detail"
                v-if="content.associatedFiles.length">
                <tr v-for="file in content.associatedFiles">
                    <th>{{ file.typeName }}</th>
                    <td>
                        <a :href="file.downloadUrl">
                            <span class="mdi mdi-file-outline"></span>
                            {{ file.title }}
                        </a>
                    </td>
                </tr>
            </table>
            <div v-else>
                <p class="p-2">Did you know you can also store other files related to this content item?</p>
                <p class="p-2">They can be specification documents, product information, Revit type catalogs or any
                    other file type.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['content'],
}
</script>