import BaseData from "@/core/models/BaseData";
import { APPLICATIONS } from "@/constants";

class AuditProfile extends BaseData {
    constructor(data) {
        super(data);
    }

    get applicationName() {
        return APPLICATIONS[this.application] || this.application;
    }

    get maxWeightName() {
        return {
            L: 'Light',
            M: 'Medium',
            H: 'Heavy'
        }[this.maxWeight];
    }
}

export default AuditProfile;