<template>
    <div v-if="item">
        <div class="is-pulled-right">
            <div class="buttons">
                <custom-link :route="{ name: 'content-edit', params: { id: item.id } }">
                </custom-link>
                <button class="button"
                    @click="createGenerateThumbnailTask">
                    Regenerate thumbnails
                </button>
                <button class="button"
                    v-if="isPublished"
                    @click="rejectItem()">
                    Reject current version
                </button>
                <div v-if="userCanDelete"
                    class="dropdown is-hoverable">
                    <div class="dropdown-trigger">
                        <button class="button"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu4">
                            <span>Delete</span>
                            <span class="icon is-small">
                                <span class="mdi mdi-chevron-down"></span>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu"
                        id="dropdown-menu4"
                        role="menu">
                        <div class="dropdown-content">
                            <a class="dropdown-item"
                                @click="deleteItem()">
                                Delete permanently
                            </a>
                            <a @click="archiveItem()"
                                v-if="item.active"
                                class="dropdown-item">
                                Archive
                            </a>
                            <a @click="unArchiveItem()"
                                v-if="!item.active"
                                class="dropdown-item">
                                Make active
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h2 class="title">{{ item.title }}</h2>
        <div class="buttons">
            <a v-if="item.application.code != 'R' && item.currentVersion"
                :href="downloadFile"
                class="button is-success">
                <span class="icon">
                    <span class="mdi mdi-cloud-download"></span>
                </span>
                <span>
                    Download version {{ item.currentVersion.version }}
                    ({{ item.currentVersion.files[0].applicationFormat }})
                </span>
            </a>
        </div>
        <p v-if="item.description !== 'null'"
            class="description mb-3">{{ item.description }}</p>

        <div class="fixed-grid">
            <div class="grid">
                <div class="cell">
                    <content-properties :content="item" />
                </div>
                <div v-if="item.images.length"
                    class="cell">
                    <image-carousel :images="item.images"
                        :showRegen="true"
                        @createTask="createGenerateThumbnailTask" />
                </div>
                <div class="cell">
                    <content-stats-panel :content="item" />
                </div>
                <div class="cell">
                    <content-versions :content="item"
                        ref="contentVersionsRef" />
                </div>
                <div v-if="item.symbols.length"
                    class="cell is-col-span-2">
                    <content-types :content="item" />
                </div>
                <div class="cell">
                    <content-associated-files :content="item" />
                </div>
                <div class="cell">
                    <content-tasks :content="item"
                        :key="tasksUpdated" />
                </div>
                <div class="cell">
                    <content-activity :content="item" />
                </div>
            </div>
        </div>
        <teleport to="body">
            <confirm-dialog ref="confirmDialog"></confirm-dialog>
        </teleport>
    </div>
</template>

<script>
import { PERMISSIONS } from "@/constants";
import ConfirmDialog from "@/core/components/ConfirmDialog";
import CustomLink from "@/core/components/CustomLink";
import ImageCarousel from "@/core/components/ImageCarousel";
import http from "@/http";
import User from "@/users/models/User";
import { showMessage } from "@/utils";
import moment from "moment";
import Content from "../models/Content";
import ContentActivity from "./ContentActivity";
import ContentAssociatedFiles from "./ContentAssociatedFiles";
import ContentProperties from "./ContentProperties";
import ContentStatsPanel from "./ContentStatsPanel";
import ContentTasks from "./ContentTasks";
import ContentTypes from "./ContentTypes";
import ContentVersions from "./ContentVersions";
import BaseDetailComponent from "@/core/components/BaseDetailComponent";

export default {
    mixins: [BaseDetailComponent],
    components: {
        ConfirmDialog,
        ContentActivity,
        ContentAssociatedFiles,
        ContentProperties,
        ContentStatsPanel,
        ContentTasks,
        ContentTypes,
        ContentVersions,
        CustomLink,
        ImageCarousel,
    },
    data() {
        return {
            tasksUpdated: null,
            user: null,
            detailUrl: `${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/`,
            modelClass: Content,
        };
    },
    computed: {
        isPublished() {
            return this.item.state == 'P';
        },
        isAdmin() {
            if (this.item.library.admins)
                return this.item.library.admins.find(user => user.id == this.user.id);
        },
        downloadFile() {
            return this.item.currentVersion.files[0].downloadUrl
        },
        userCanDelete() {
            return this.user?.hasPermission(PERMISSIONS.DELETE_CONTENT);
        },
    },
    methods: {
        async setup() {
            let userdata = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/users/current/`)
                .then(resp => resp.json());
            this.user = new User(userdata);

            this.emitter.emit("breadcrumbs", [
                { route: 'home', name: 'Home' },
                { route: 'content-list', name: 'All Content' },
                { route: 'content-list', name: this.item.library.title, query: { library: this.item.library.id } },
                { route: 'content-detail', name: this.item.title, params: { id: this.item.id } },
            ]);
        },
        async createGenerateThumbnailTask() {
            const data = {
                content: this.item.id,
                task: 'GenerateThumbnail',
                application: this.item.application.code,
                name: 'Auto',
            }
            let resp = await http.post(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/`, data);
            if (resp.status == 201) {
                this.tasksUpdated = moment().format();
            }
        },
        async rejectItem() {
            this.$refs.contentVersionsRef.transition('X');
        },
        async deleteItem() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete',
                message: 'Are you sure you want delete this item? ',
            });

            if (!ok)
                return

            let resp = await http.delete(`${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/`);
            if (resp.ok)
                this.$router.push({ name: 'content-list' });
            else
                showMessage("Content item could not be deleted", 'danger');
        },
        async archiveItem() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Archive',
                message: 'Are you sure you want archive this item? ',
            });

            if (!ok)
                return

            let fd = new FormData();
            fd.append('active', false)

            let resp = await http.patch(
                `${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/`,
                fd);
            if (resp.ok)
                this.item.active = false;
            else
                showMessage("Content item could not be archived", 'danger');
        },
        async unArchiveItem() {
            let fd = new FormData();
            fd.append('active', true)

            let resp = await http.patch(
                `${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/`,
                fd);
            if (resp.ok)
                this.item.active = true;
            else
                showMessage("Content item could not be un-archived", 'danger');
        }
    },
};
</script>

<style lang="sass" scoped>
.image img
    max-height: 600px
    width: auto

.description
    font-style: italic

</style>
