<template>
    <div class="buttons"
        :class="{ 'has-addons': showAttached }">
        <custom-link :route="route"
            :strip="strip"
            :show-button="true"
            v-for="route in filteredRoutes">
        </custom-link>
    </div>
</template>

<script>
import router from "@/router";
import CustomLink from "@/core/components/CustomLink";

export default {
    props: {
        names: {
            type: Array,
            required: false,
            default: [],
        },
        routes: {
            type: Array,
            required: false,
            default: [],
        },
        strip: {
            type: String,
            required: false,
        },
        prefix: {
            type: String,
            required: false,
        },
        showAttached: {
            type: Boolean,
            default: true,
        }
    },
    components: { CustomLink },
    async created() {
        await this.$store.dispatch("users/loadCurrentUser");
    },
    computed: {
        filteredRoutes() {
            let allRoutes = router.getRoutes();
            let user = this.$store.state.users.currentUser;

            let filteredRoutes = [];

            if (this.routes.length) {
                filteredRoutes = this.routes;
                let allRoutesMap = allRoutes.reduce((obj, item) => Object.assign(obj, { [item.name]: item }), {});

                // Attach meta information from allRoutes
                filteredRoutes.forEach(x => {
                    x.meta = allRoutesMap[x.name].meta;
                });
            } else {
                filteredRoutes = allRoutes.filter(x => {
                    if (this.$route.name == x.name)
                        return false;

                    if (x.path.includes(':'))
                        return false;

                    if (this.names.length)
                        return this.names.includes(x.name);

                    if (this.prefix)
                        return x.path.startsWith(this.prefix)
                });
            }

            // Filter out any routes the user has no permissions for
            filteredRoutes = filteredRoutes.filter(x => {
                if (user.permissions[0] == "*")
                    return true;
                else
                    return (x.meta.permissionsRequired || []).every(x => user.permissions.includes(x));
            })

            // Sort routes to match names if provided
            if (this.names.length)
                filteredRoutes.sort((a, b) => this.names.indexOf(a.name) - this.names.indexOf(b.name));

            return filteredRoutes;
        }
    },
}
</script>