import { snakeToCamel, camelToSnake } from "@/utils";
import moment from 'moment';
import _ from "lodash";

const DATETIME_PAT = /^20\d{2}-\d{2}-\d{2}T/;

class BaseData {
    constructor(data) {
        data = data || {};
        Object.keys(data).forEach(k => {
            let key = snakeToCamel(k);
            let value = data[k];

            // Is it a datetime string?
            if (typeof value == 'string' && value.match(DATETIME_PAT))
                value = moment(value);

            this[key] = value;
        });

        this.setMark();
    }

    getData() {
        let data = {};
        Object.keys(this).forEach(k => {
            let key = camelToSnake(k);
            let value = this[k];
            data[key] = value;
        });
        return data;
    }

    setMark() {
        let current = _.cloneDeep(this);
        delete current._mark;
        this._mark = _.cloneDeep(current)
    }

    hasChanged() {
        let current = _.cloneDeep(this);
        delete current._mark;
        return !(_.isEqual(this._mark, current));
    }

    resetToMark() {
        Object.assign(this, this._mark);
    }
}

export default BaseData;