<template>
    <div class="control">
        <div class="field is-grouped">
            <div class="control">
                <div class="field has-addons">
                    <div v-if="selectedQuery && isQueryString"
                        class="control">
                        <button @click="deleteQuery"
                            title="Delete saved query"
                            class="button">
                            <span class="icon">
                                <span class="mdi mdi-delete-outline"></span>
                            </span>
                        </button>
                    </div>
                    <div v-if="isQueryString && savedQueries.length"
                        class="control">
                        <div class="select">
                            <select v-model="selectedQuery">
                                <option :value="null"
                                    disabled>Saved queries</option>
                                <option v-for="query in savedQueries"
                                    :value="query">{{ query.name }}</option>
                            </select>
                        </div>
                    </div>
                    <search-input v-model="q"
                        v-if="isQueryString"
                        placeholder="Search by advanced query">
                    </search-input>
                    <search-input v-model="q"
                        v-else
                        placeholder="Search by text">
                    </search-input>
                    <div v-if="isQueryString && q"
                        class="control">
                        <button @click="save"
                            title="Save query"
                            class="button">
                            <span class="icon">
                                <span class="mdi mdi-content-save-outline"></span>
                            </span>
                        </button>
                    </div>
                    <div class="control">
                        <button :class="{ 'is-success': isQueryString }"
                            @click="isQueryString = !isQueryString"
                            title="Enable advanced queries"
                            class="button">
                            <span class="icon">
                                <span class="mdi mdi-lightbulb-outline"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <input-dialog ref="inputDialog"></input-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import PersistentObjectStore from "@/objectStore.js";
import InputDialog from "@/core/components/InputDialog";
import ConfirmDialog from "@/core/components/ConfirmDialog";
import SearchInput from "@/core/components/SearchInput";

export default {
    props: {
        modelValue: Object,
    },
    emits: ['update:modelValue'],
    components: {
        InputDialog,
        ConfirmDialog,
        SearchInput,
    },
    data() {
        return {
            isQueryString: false,
            q: null,
            store: null,
            selectedQuery: null,
        }
    },
    mounted() {
        this.store = new PersistentObjectStore(this.$route.href);
    },
    computed: {
        savedQueries() {
            return this.store?.get('queries') || [];
        }
    },
    methods: {
        async save() {
            const result = await this.$refs.inputDialog.show({
                title: 'Save query',
                message: 'Enter query name',
            });

            if (!result)
                return;

            this.selectedQuery = { name: result, query: this.q };
            let queries = this.savedQueries.filter(x => x.name != result);
            queries.push(this.selectedQuery);
            this.store.set('queries', queries);
        },
        async deleteQuery() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete query',
                message: `Are you sure you want to delete query ${this.selectedQuery.name} ?`,
            });

            if (!ok)
                return;

            let queries = this.savedQueries.filter(x => x.name != this.selectedQuery.name);
            this.store.set('queries', queries);
            this.selectedQuery = null;
        },
        clearSearch() {
            this.q = null;
        },
    },
    watch: {
        selectedQuery(val) {
            this.q = val?.query;
        },
        isQueryString(val) {
            this.$emit('update:modelValue', { is_query_string: val, text: this.q });
        },
        q(val) {
            this.$emit('update:modelValue', { is_query_string: this.isQueryString, text: val });
        },
        modelValue(val) {
            this.q = val.text;
            this.isQueryString = val.is_query_string;
        }
    }
}
</script>