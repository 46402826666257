<template>
    <div class="field is-grouped is-grouped-multiline">
        <autocomplete @selected="selectTag"
            :placeholder="placeholder"
            :load-results="loadTags"
            :result-namer="x => x"
            :result-getter="x => x">
        </autocomplete>
        <span class="control tags p-3"
            v-if="showResults && tags.length">
            <span class="tag"
                v-for="tag in tags"
                :key="tag.name">
                {{ tag?.name }}
                <button class="delete is-small"
                    @click="removeTag(tag)">
                </button>
            </span>
        </span>
    </div>
</template>

<script>
import Autocomplete from "@/core/components/Autocomplete.vue";
import Tag from "@/core/models/Tag";
import http from "@/http";

export default {
    props: {
        modelValue: Array,
        showResults: {
            default: true,
        },
        placeholder: {
            default: 'Select tag',
        }
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            tags: [],
            tag: null,
        }
    },
    created() {
        this.tags = this.modelValue || [];
    },
    methods: {
        async loadTags(q) {
            return await http.get(`${process.env.VUE_APP_API_URL_V1}/core/tags/`, { q })
                .then(resp => resp.json());
        },
        removeTag(tag) {
            this.tags = this.tags.filter(x => x != tag);
            this.$emit('selected', this.tags);
        },
        selectTag(tag) {
            tag = tag?.trim();
            if (tag)
                this.tags.push(new Tag({ name: tag }));
        }
    },
    watch: {
        tags(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.tags = val;
        },
    },
}
</script>