<template>
    <modal-dialog ref="dialog">
        <div class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button class="delete"
                aria-label="close"
                @click="cancel"></button>
        </div>
        <div class="modal-card-body">
            <p>{{ message }}</p>
        </div>
        <div class="modal-card-foot">
            <button class="button is-info is-pulled-right"
                @click="confirm">OK</button>
            <button class="button"
                @click="cancel">Cancel</button>
        </div>
    </modal-dialog>
</template>

<script>
import ModalDialog from "./ModalDialog";

export default {
    components: { ModalDialog },
    data() {
        return {
            title: null,
            message: null,
            resolvePromise: null,
            rejectPromise: null,
        };
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title || "Confirm";
            this.message = opts.message || "Are you sure?";

            this.$refs.dialog.open();

            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },

        confirm() {
            this.$refs.dialog.close();
            this.resolvePromise(true);
        },

        cancel() {
            this.$refs.dialog.close();
            this.resolvePromise(false);
        },
    },
};
</script>
