import store from "@/store";
import moment from "moment";
import { isArray, startCase } from "lodash";

const BACK_SLASH = '\\';
const FORWARD_SLASH = '/';

const urlEncode = (obj) => {
    var str = [];
    Object.keys(obj).forEach(key => {
        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    });
    return str.join("&");
}

const urlQueryParams = () => {
    return new URL(window.location.href.replace('#/', '')).searchParams;
}

const getFileExtension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

const snakeToCamel = (str) => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

const camelToSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const cleanData = (data) => {
    let cleaned = Object.assign({}, data);
    Object.keys(cleaned).forEach(key => {
        let val = cleaned[key];
        if (val === null || val == NaN || val == undefined) {
            delete cleaned[key];
        }
    });
    return cleaned;
}

const fileSizeFormat = (bytes, decimalPlaces = 1) => {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB'];
    let u = -1;
    const r = 10 ** decimalPlaces;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(decimalPlaces) + ' ' + units[u];
}

const getSnippet = async (slug) => {
    await store.dispatch("core/loadSnippet", slug);
    const snippet = store.getters["core/getSnippet"](slug);
    return snippet.text;
}

const showMessage = (text, type, duration) => {
    store.dispatch(
        'core/setMessage', {
        text: text,
        type: type || 'info',
        duration: duration || 3000,
    });
}


const sentenceCase = (string) => {
    return [...string][0].toUpperCase() + [...string].slice(1).join('')
}

const formatErrorMessage = (data, showLabels = false) => {
    let out = [];

    for (let row of Object.entries(data)) {
        let label = startCase(row[0]);
        let messages = row[1];

        if (!isArray(messages))
            messages = [messages];

        if (showLabels)
            out.push(`${label}: ${sentenceCase(messages.join('<br>'))}`);
        else
            out.push(sentenceCase(messages.join('<br>')));
    }

    return out.join('');
}

const htmlEncode = (rawStr) => {
    if (typeof rawStr != 'string')
        return rawStr;

    return rawStr.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
    });
}

const validateEmail = (email) => {
    var pat = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    return pat.test(email);
}

const asyncTimeout = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

const slugify = (text) => {
    return text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

const getDomainName = () => {
    let url = new URL(process.env.VUE_APP_API_URL_V1);

    // If http then assume this is a local dev server
    if (url.protocol == 'http:')
        return url.hostname;

    // If url is a subdomain return just the domain
    let bits = url.hostname.split('.');
    if (bits.length > 2)
        return bits.slice(1, bits.length).join('.');
    else
        return url.hostname;
}

const getPathSeparator = (path) => {
    const backSlashBits = path.split(BACK_SLASH);
    const forwardSlashBits = path.split(FORWARD_SLASH);
    if (backSlashBits.length > forwardSlashBits.length)
        return BACK_SLASH;
    else
        return FORWARD_SLASH;
}

const splitFilepath = (filepath) => {
    var sep = getPathSeparator(filepath);

    var bits = filepath.split(sep)
    var filename = bits.pop();
    var directory = bits.join(sep);

    var bits = filename.split('.');
    var ext = bits.pop();
    var basename = bits.join('.');

    return {
        directory: directory,
        filename: filename,
        ext: ext,
        basename: basename,
    }
}

const getRandomString = (numchars) => {
    return (Math.random() + 1).toString(36).substring(2, numchars + 2)
}

const truncateFilepath = (path, maxLength = 100, showFilename = true, normalize = true, prefix = '..') => {

    var sep = getPathSeparator(path);

    if (!showFilename)
        path = splitFilepath(path).directory + sep;

    if (path.length <= maxLength)
        return path;

    let allBits = path.split(sep);
    allBits = allBits.reverse();

    let outBits = [];
    let length = 0;
    let truncated = false;

    for (const bit of allBits) {
        length += bit.length;
        if (length > maxLength) {
            truncated = true;
            break;
        }

        outBits.unshift(bit);
    }

    if (normalize)
        sep = FORWARD_SLASH;

    if (truncated)
        return prefix + sep + outBits.join(sep);
    else
        return outBits.join(sep);
}


const getDateRange = (start, end, period = 'days') => {

    let firstDate = moment(start);
    let lastDate = moment(end);

    if (firstDate.isSame(lastDate, period))
        return [lastDate];

    let date = firstDate;
    const dates = [moment(date)];

    do {
        date.add(1, period);
        dates.push(moment(date));
    } while (date.isBefore(lastDate));

    return dates;
};

export {
    asyncTimeout,
    camelToSnake,
    cleanData,
    fileSizeFormat,
    formatErrorMessage,
    getDateRange,
    getDomainName,
    getFileExtension,
    getPathSeparator,
    getRandomString,
    getSnippet,
    htmlEncode,
    sentenceCase,
    showMessage,
    slugify,
    snakeToCamel,
    splitFilepath,
    truncateFilepath,
    urlEncode,
    urlQueryParams,
    validateEmail,
};

