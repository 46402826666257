<template>
    <div>
        <section-nav :names="sectionRoutes"
            strip="Audit"
            class="is-pulled-right">
        </section-nav>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Audits
        </h2>
        <div class="field is-grouped">
            <application-select :show-all="true"
                v-model="filters.application"></application-select>
            <project-autocomplete v-model="filters.project"
                placeholder="Search project" />
            <audit-template-autocomplete v-model="filters.template"
                placeholder="Search Templates" />
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button v-if="isFiltered"
                @click="clearFilters"></clear-button>
        </div>
        <table class="table is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Audit Template</th>
                    <th>Application</th>
                    <th>Project</th>
                    <th>Rules</th>
                    <th>Audit date</th>
                    <th>Status</th>
                    <th>Score</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="audit in items">
                    <td>{{ audit.template.name }}</td>
                    <td>{{ audit.applicationName }}</td>
                    <td>{{ audit.project?.name }}</td>
                    <td>{{ audit.ruleCount }}</td>
                    <td>{{ audit.created.fromNow() }}</td>
                    <td>{{ audit.statusName }}</td>
                    <td>
                        <progress-bar v-model="audit.score"
                            :show-percent="true"></progress-bar>
                    </td>
                    <td>
                        <router-link :to="{ name: 'audit-detail', params: { id: audit.id } }"
                            title="View results">
                            <span class="mdi mdi-file-document-outline"></span>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import ProgressBar from "@/core/components/ProgressBar";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete";
import { cleanData } from "@/utils.js";
import Audit from "../models/Audit";
import AuditTemplateAutocomplete from "./AuditTemplateAutocomplete";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        AuditTemplateAutocomplete,
        ClearButton,
        LimitSelect,
        Pagination,
        ProgressBar,
        ProjectAutocomplete,
        SearchInput,
        SectionNav,
    },
    data() {
        return {
            filters: {
                application: null,
                project: null,
                template: null,
                limit: null,
                offset: 0,
            },
            sectionRoutes: [
                'audit-summary-list',
                'audit-element-list',
                'audit-rule-list',
                'audit-template-list',
                'audit-add',
            ],
            listUrl: `${process.env.VUE_APP_API_URL_V1}/audits/`,
            modelClass: Audit,
        };
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.project)
                filters.project = filters.project.id;
            if (filters.template)
                filters.template = filters.template.id;
            if (filters.application)
                filters.application = filters.application.code;

            return filters;
        }
    },
};
</script>
