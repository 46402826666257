import BaseData from "@/core/models/BaseData";
import { PARAMETER_ACTIONS } from "../constants";

class Parameter extends BaseData {

    constructor(data) {
        super(data);
        this.categories = this.categories || [];
        this.action = this.action || 'A';   
    }

    get actionName() {
        return PARAMETER_ACTIONS[this.action] || this.action;
    }
}

export default Parameter;
