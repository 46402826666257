import auditsStore from '@/audits/store';
import bimStore from '@/bim/store';
import contentStore from '@/content/store';
import coreStore from '@/core/store';
import projectsStore from '@/projects/store';
import statsStore from '@/stats/store';
import usersStore from '@/users/store';
import pagesStore from '@/pages/store';

import { createStore } from 'vuex';

const store = createStore({
    modules: {
        audits: auditsStore,
        bim: bimStore,
        content: contentStore,
        core: coreStore,
        projects: projectsStore,
        stats: statsStore,
        users: usersStore,
        pages: pagesStore,
    }
})

export default store
