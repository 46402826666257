<template>
    <div class="has-text-centered">
        <h2 class="title mt-6">
            Download the Content Lab Desktop App
        </h2>
        <div class="carousel-wrapper">
            <div class="carousel mt-6">
                <image-carousel :images="images"
                    :show-controls="false"
                    :show-title="false"
                    :interval="3500" />
            </div>
        </div>
        <div class="button-wrapper">
            <div v-if="latestPublicBuild">
                <a :href="latestPublicBuild.attachment"
                    class="button is-link is-large">
                    <span class="mdi mdi-cloud-download mr-2"></span>
                    Download latest version
                </a>
                <div class="mt-3 ml-3">
                    <small>
                        <strong>VERSION {{ latestPublicBuild.globalVersion }}</strong>,
                        build {{ latestPublicBuild.build }}, released
                        {{ latestPublicBuild.updated.format('MMMM Do, YYYY') }}
                    </small>
                    <p v-if="latestPublicBuild.description"
                        v-html="latestPublicBuild.description"
                        class="has-text-left content"></p>
                </div>
            </div>
            <button v-if="!showAll"
                @click="showAll = true"
                class="has-text-weight-light m-3">
                <span class="icon">
                    <span class="mdi mdi-chevron-down"></span>
                </span>
                <span>
                    Show all releases
                </span>
            </button>
            <div v-if="showAll"
                class="mt-6">
                <hr>
                <div v-if="latestBetaBuild">
                    <a :href="latestBetaBuild.attachment"
                        class="button is-ghost">
                        Download latest Beta version
                    </a>
                    <br>
                    <small>
                        <strong>VERSION {{ latestBetaBuild.globalVersion }}</strong>,
                        build {{ latestBetaBuild.build }}, released
                        {{ latestBetaBuild.updated.format('MMMM Do, YYYY') }}
                    </small>
                </div>
                <div v-if="otherBuilds.length">
                    <hr>
                    <h4 class="subtitle is-6">Older Builds:</h4>
                    <div v-for="build in otherBuilds">
                        <a :href="build.attachment">
                            Version {{ build.globalVersion }}
                            {{ build.typeName }} build
                        </a>
                        <small>{{ build.updated.format('MMMM Do, YYYY') }}</small>
                    </div>
                </div>
            </div>
            <button v-if="showAll"
                @click="showAll = false"
                class="has-text-weight-light m-5">
                <span class="icon">
                    <span class="mdi mdi-chevron-up"></span>
                </span>
                <span>
                    Hide releases
                </span>
            </button>
        </div>
        <p class="block is-size-5 p-6">
            Having trouble downloading or installing Content Lab?<br>
            The quickest way to get help is to use our
            <a
                href="https://teams.microsoft.com/l/channel/19%3a9250792c82754d678dbb4949c4adb867%40thread.skype/Jacobs%2520Tools%2520-%2520Content%2520Lab?groupId=0e37a9ef-f36b-41b6-8fbe-bbb96034c808&tenantId=37247798-f42c-42fd-8a37-d49c7128d36b">
                <span class="mdi mdi-forum"></span>
                Teams channel
            </a>
        </p>
    </div>
</template>

<script>
import ApplicationDownload from "../models/ApplicationDownload";
import ImageCarousel from "./ImageCarousel";
import http from "@/http";

export default {
    components: { ImageCarousel },
    data() {
        return {
            images: [],
            downloads: [],
            showAll: false,
        }
    },
    async created() {
        for (let i = 1; i <= 9; i++)
            this.images.push({ image: `./static/content-lab-screenshot-${i}.png` });

        let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/application-downloads/`)
            .then(x => x.json());
        this.downloads = data.results.map(x => new ApplicationDownload(x));
    },
    computed: {
        latestPublicBuild() {
            return this.downloads.filter(x => x.type == 'P')[0];
        },
        latestBetaBuild() {
            return this.downloads.filter(x => x.type == 'B')[0];
        },
        otherBuilds() {
            let lpb = this.latestPublicBuild?.id;
            let lbb = this.latestBetaBuild?.id;

            return this.downloads.filter(x => ![lpb, lbb].includes(x.id))
        }
    }
}
</script>

<style lang="sass">
.carousel-wrapper
    margin: 0 auto
    width: 800px

    .carousel
        text-align: center
        .panel
            border: none
            
            .images
                height: 600px
                width: 800px
                
                img
                    max-height: 600px
                    max-width: 800px
                    box-shadow: 0 0 25px #aaa

.button-wrapper
    width: 35%
    margin: 3em auto 0 auto
</style>