
<template>
    <div class="spinner" v-if="active">
        <span :class="classObject"></span>
    </div>
</template>


<script>
export default {
    props: ['active', 'type', 'size'],
    computed: {
        classObject() {
            let obj = {
                'mdi': true,
                'mdi-spin': true,
            };

            if (this.type)
                obj[`mdi-${this.type}`] = true;
            else
                obj['mdi-loading'] = true;

            if (this.size == 'medium')
                obj['mdi-24px'] = true;

            if (this.size == 'large')
                obj['mdi-48px'] = true;

            return obj;
        }
    }
}
</script>

<style lang="sass">
    .spinner
        text-align: center
        color: #aaa
</style>