<template>
    <div v-if="parameter">
        <h2 class="title">
            <template v-if="parameter.id">
                Edit Parameter: {{ parameter.name }}
            </template>
            <template v-else>
                Add Parameter: {{ parameter.name }}
            </template>
        </h2>
        <div class="columns">
            <div class="column is-half">
                <div class="field is-grouped is-grouped-multiline">
                    <input-component v-model="parameter.name"
                        :required="true"
                        @changed="onFieldChanged"
                        label="Name"
                        name="name"
                        help="Enter a parameter name"></input-component>
                    <input-component v-model="parameter.descriptor"
                        @changed="onFieldChanged"
                        label="Descriptor"
                        help="A human readable string to distinguish between parameters with same name"></input-component>
                </div>
                <div class="field">
                    <label class="label">Description</label>
                    <input-component v-model="parameter.description"
                        @changed="onFieldChanged"
                        help="Tooltip description"
                        type="textarea"></input-component>
                </div>
                <div class="field is-grouped is-grouped-multiline">
                    <input-component v-model="parameter.guid"
                        :required="true"
                        :disabled="true"
                        :validator="validators.isGuid"
                        @changed="onFieldChanged"
                        label="GUID"></input-component>
                    <input-component v-model="parameter.data_type"
                        :required="true"
                        @changed="onFieldChanged"
                        :choices="types"
                        label="Data type"
                        type="select"></input-component>
                    <input-component v-model="parameter.group"
                        :required="true"
                        @changed="onFieldChanged"
                        :choices="groups"
                        label="Group"
                        type="select"></input-component>
                    <input-component v-model="parameter.binding_group"
                        :required="true"
                        @changed="onFieldChanged"
                        :choices="bindingGroups"
                        label="Binding group"
                        type="select"></input-component>
                </div>
                <div class="field ">
                    <input-component v-model="parameter.visible"
                        @changed="onFieldChanged"
                        label="Visible"
                        type="checkbox"></input-component>
                    <input-component v-model="parameter.user_modifiable"
                        @changed="onFieldChanged"
                        label="User modifiable"
                        type="checkbox"></input-component>
                    <input-component v-model="parameter.instance"
                        @changed="onFieldChanged"
                        label="Instance"
                        type="checkbox"></input-component>
                </div>
                <button @click="save"
                    v-if="formIsValid"
                    class="button is-link">Submit</button>
                <button v-else
                    disabled
                    class="button is-link">Form is incomplete</button>
            </div>
        </div>
    </div>
</template>

<script>
import InputComponent from "@/core/components/InputComponent";
import { isGuid } from "@/core/validators";
import http from "@/http";
import { showMessage } from "@/utils";
import { isEmpty } from "lodash";
import { formatErrorMessage } from "../../utils";

export default {
    components: {
        InputComponent,
    },
    data() {
        return {
            parameter: null,
            types: [],
            groups: [],
            bindingGroups: [],
            isValid: true,
            validators: { isGuid },
            errors: {},
            saving: false,
        };
    },
    async mounted() {
        await this.$store.dispatch("bim/loadParameterTypes");
        this.types = this.$store.getters["bim/getParameterTypes"];

        await this.$store.dispatch("bim/loadParameterGroups");
        this.groups = this.$store.getters["bim/getParameterGroups"];

        await this.$store.dispatch("bim/loadParameterBindingGroups");
        this.bindingGroups = this.$store.getters["bim/getParameterBindingGroups"];

        if (this.$route.params.id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/${this.$route.params.id}/`)
                .then(resp => resp.json());

            this.parameter = data;
        } else {
            this.parameter = {
                name: '',
                descriptor: '',
                description: '',
                guid: crypto.randomUUID(),
                data_type: '',
                group: '',
                binding_group: '',
                visible: true,
                user_modifiable: true,
            }
        }
    },
    computed: {
        formIsValid() {
            return !this.errors || isEmpty(this.errors);
        }
    },
    methods: {
        onFieldChanged(e) {
            if (e.valid)
                delete this.errors[e.name];
            else
                this.errors[e.name] = e.value;
        },
        async save() {
            if (!this.formIsValid)
                return;

            this.saving = true;
            let success = false;
            let resp = null;

            if (this.parameter.id) {
                // Edit form - make http patch request
                resp = await http.patch(
                    `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/${this.parameter.id}/`,
                    this.parameter);
                success = resp.status == 200;
            } else {
                // Add form - make http post request
                resp = await http.post(
                    `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/`,
                    this.parameter);
                success = resp.status == 201;
            }

            if (success) {
                showMessage(`Parameter ${this.parameter.name} has been saved`);
                this.$router.push({
                    name: "parameter-list",
                });
            } else {
                let data = await resp.json();
                showMessage(formatErrorMessage(data), 'danger');
            }

            this.saving = false;
        },
    },
}
</script>
