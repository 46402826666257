<template>
    <div v-if="template">
        <h2 class="title">Edit {{ template.name }}</h2>
        <div class="columns ">
            <div class="column">
                <div class="field">
                    <label class="label">Name:</label>
                    <input type="text"
                        class="input"
                        v-model="template.name">
                </div>
                <div class="field">
                    <label class="label">Rule type:</label>
                    <div class="select">
                        <select v-model="template.type">
                            <option value="C">Content</option>
                            <option value="M">Model</option>
                        </select>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Application:</label>
                    <application-select v-model="template.application">
                    </application-select>
                </div>
                <div class="field">
                    <label class="label">Project:</label>
                    <div class="field is-grouped">
                        <project-autocomplete v-model="template.project">
                        </project-autocomplete>
                        <div class="control ml-3"
                            v-if="template.project">
                            <span class="tag">
                                {{ template.project.name }}
                                <button @click="template.project = null"
                                    class="delete is-small"></button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Rules:</label>
                    <div class="tags">
                        <span v-for="rule in template.rules"
                            class="tag is-success">
                            {{ rule.name }}
                            <button @click="deselectRule(rule)"
                                class="delete is-small"
                                title="Remove rule from template"></button>
                        </span>
                    </div>
                    <div class="panel">
                        <div class="panel-block is-block">
                            <div class="field is-grouped">
                                <div class="control">
                                    <div class="select">
                                        <select v-model="filters.element_type">
                                            <option :value="null">Show all types</option>
                                            <option v-for="type in types"
                                                :value="type">{{ type.toUpperCase() }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <div class="select">
                                        <select v-model="filters.creator">
                                            <option :value="null">Show all creators</option>
                                            <option v-for="creator in creators"
                                                :value="creator.id">{{ creator }}</option>
                                        </select>
                                    </div>
                                </div>
                                <search-input v-model="filters.q"
                                    placeholder="Filter rules"></search-input>
                                <clear-button @click="clearFilters"
                                    v-if="isFiltered"></clear-button>
                            </div>
                        </div>
                        <div class="panel-block"
                            v-for="rule in items">
                            <audit-template-rule-panel :rule="rule"
                                @selected="selectRule">
                            </audit-template-rule-panel>
                        </div>
                        <div v-if="hasPrev || hasNext"
                            class="panel-block">
                            <pagination :has-prev="hasPrev"
                                :has-next="hasNext"
                                @pagechanged="onPageChange"></pagination>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <button @click="save"
                        class="button is-link">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import http from "@/http";
import ProjectAutocomplete from '@/projects/components/ProjectAutocomplete';
import { showMessage } from "@/utils";
import _, { template } from "lodash";
import AuditRule from '../models/AuditRule';
import AuditTemplate from '../models/AuditTemplate';
import AuditTemplateRulePanel from "./AuditTemplateRulePanel";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        AuditTemplateRulePanel,
        ClearButton,
        Pagination,
        ProjectAutocomplete,
        SearchInput,
    },
    data() {
        return {
            template: null,
            types: [],
            creators: [],
            filters: {
                application: null,
                creator: null,
                element_type: null,
                limit: 10,
                offset: 0,
                q: null,
            },
        }
    },
    async created() {

        // Get the template object
        const templateId = this.$route.params.id;
        if (templateId) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/templates/${templateId}/`)
                .then(resp => resp.json())
            this.template = new AuditTemplate(data);
        } else {
            this.template = new AuditTemplate();
        }

        await this.template.load();
    },
    computed: {
        isFiltered() {
            return this.filters.element_type ||
                this.filters.application ||
                this.filters.creator ||
                this.filters.q;
        }
    },
    methods: {
        selectRule(rule) {
            if (!this.template.rules.map(x => x.id).includes(rule.id))
                this.template.rules.push(rule);
        },
        deselectRule(rule) {
            this.template.rules = this.template.rules.filter(x => x.id != rule.id);
        },
        loadItems: _.debounce(async function () {
            if (!this.template)
                return;

            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/audits/rules/`, this.cleanedFilters)
                .then(resp => resp.json())

            this.resultsCount = data.count;
            this.items = data.results.map(x => new AuditRule(x));

            // Merge creators into one list
            let itemsCreators = this.items
                .filter(x => x.creator)
                .map(x => x.creator);
            this.creators = _.sortBy(
                _.uniqBy(
                    this.creators.concat(itemsCreators), x => x.id),
                x => x.toString()
            );
        }, 200),
        async save() {
            let data = {
                name: this.template.name,
                type: this.template.type,
                application: this.template.application.code,
                project: this.template.project ? this.template.project.id : null,
                rules: this.template.rules.map(x => {
                    return {
                        id: x.id,
                        polarity: x.polarity == 'true',
                        weight: x.weight,
                        threshold: x.threshold,
                        absolute_threshold: x.absolute_threshold || false,
                    }
                }),
            };

            let httpMethod = http.post;
            let url = `${process.env.VUE_APP_API_URL_V1}/audits/templates/`;

            if (this.template.id) {
                httpMethod = http.patch;
                url = `${process.env.VUE_APP_API_URL_V1}/audits/templates/${this.template.id}/`;
            }

            try {
                let templateData = await httpMethod(url, data)
                    .then(resp => resp.json());

                showMessage("Audit template saved");

                // For newly created templates
                if (!this.template.id) {
                    this.template.id = templateData.id;
                }

                this.$router.push({ name: 'audit-template-list' });

            } catch (err) {
                showMessage("Audit template could not be saved", 'danger');
            }
        },
    },
    watch: {
        'template.application': {
            async handler(val) {
                if (!val)
                    return;

                this.filters.application = val.code;

                await this.$store.dispatch("audits/loadElementTypes", val.code);
                this.types = this.$store.getters["audits/getElementTypes"](val.code);
                this.filters.element_type = null;
            },
        }
    },
}
</script>
