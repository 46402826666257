<template>
    <div class="field is-grouped is-grouped-multiline">
        <div v-for="field in fields"
            :class="{ required: field.required }"
            class="control">
            <label class="label">
                {{ field.name }}
            </label>
            <div class="control">
                <div v-if="field.options"
                    class="select">
                    <select @input="handleChanged"
                        v-model="field.value">
                        <option :value="null">No value</option>
                        <option v-for="option in field.options">{{ option }}</option>
                    </select>
                </div>
                <template v-else>
                    <input @input="handleChanged"
                        class="input"
                        v-if="field.type == 'str'"
                        v-model="field.value">
                    <input @input="handleChanged"
                        class="input"
                        v-if="field.type == 'int'"
                        type="number"
                        v-model="field.value">
                    <input @input="handleChanged"
                        v-if="field.type == 'bool'"
                        type="checkbox"
                        v-model="field.value">
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/http";

export default {
    props: ['modelValue', 'library'],
    emits: ['update:modelValue'],
    data() {
        return {
            fields: [],
        }
    },
    async created() {
        await this.loadFields();
    },
    methods: {
        async loadFields() {
            let url = `${process.env.VUE_APP_API_URL_V2}/content/fields/`;
            let params = {};

            if (this.library)
                params.libraries = this.library.id;

            this.fields = await http.get(url, params)
                .then(resp => resp.json());

            const contentFields = this.modelValue.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {});

            this.fields.forEach(x => {
                x.value = contentFields[x.name];
                if (x.options && !x.values)
                    x.values = x.options;
            });
        },
        handleChanged() {
            this.$emit('update:modelValue', this.fields);
        },
    },
    watch: {
        async library() {
            await this.loadFields();
        },
    }
}
</script>