<template>
    <div>
        <ul class="list-unstyled">
            <li v-for="user in users">
                {{ user.name }} ({{ user.email }})
                <a @click="remove(user)">
                    <span class="mdi mdi-close"></span>
                </a>
            </li>
        </ul>
        <div class="field is-grouped">
            <div class="control">
                <user-autocomplete v-model="selectedUser"
                    :placeholder="placeholder || 'Search users'">
                </user-autocomplete>
            </div>
            <div class="control">
                <a v-if="selectedUser"
                    @click="add"
                    class="button">
                    Add user
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import UserAutocomplete from "./UserAutocomplete";

export default {
    props: {
        placeholder: String,
        modelValue: Object,
    },
    components: { UserAutocomplete },
    data() {
        return {
            users: [],
            selectedUser: null,
        }
    },
    emits: ['update:modelValue'],
    created() {
        this.users = this.modelValue;
    },
    methods: {
        remove(user) {
            this.users = this.users.filter(x => x.id != user.id);
        },
        add() {
            this.users.push({
                id: this.selectedUser.id,
                email: this.selectedUser.email,
                name: `${this.selectedUser.first_name} ${this.selectedUser.last_name}`,
            });
            this.selectedUser = null;
        }
    },
    watch: {
        users(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.users = val;
        }
    },
}
</script>