<template>
    <div>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }}
            Content Audits
        </h2>
        <div class="field is-grouped">
            <!-- <div class="control">
                <div class="select">
                    <select v-model="filters.type">
                        <option value="C">Content</option>
                        <option value="M">Model</option>
                    </select>
                </div>
            </div> -->
            <template v-if="filters.type == 'C'">
                <content-library-select v-model="filters.library"
                    :show-all="true"></content-library-select>
                <application-select v-model="filters.application"
                    :show-all="true">
                </application-select>
                <div class="control">
                    <div class="select">
                        <select v-model="filters.state">
                            <option value="">All states</option>
                            <option value="I">Initial</option>
                            <option value="D">Draft</option>
                            <option value="S">Submitted</option>
                            <option value="R">Under Review</option>
                            <option value="X">Rejected</option>
                            <option value="P">Published</option>
                        </select>
                    </div>
                </div>
            </template>
            <search-input v-model="filters.q"
                placeholder="Search name&hellip;">
            </search-input>
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button @click="clearFilters"
                v-if="isFiltered">
            </clear-button>
        </div>
        <div class="columns">
            <div class="column">
                <h4 class="is-text-4">Include</h4>
                <div class="tags">
                    <audit-condition :condition="condition"
                        action="include"
                        v-for="condition in conditions"
                        :key="condition.id">
                    </audit-condition>
                </div>
            </div>
            <div class="column">
                <h4>Exclude</h4>
                <div class="tags">
                    <audit-condition :condition="condition"
                        action="exclude"
                        v-for="condition in conditions"
                        :key="condition.id">
                    </audit-condition>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Results</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="summary in items"
                    :key="summary.id">
                    <td>
                        <router-link :to="{ name: 'audit-summary-detail', params: { id: summary.id } }">
                            <img :src="summary.thumbnail">
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="{ name: 'audit-summary-detail', params: { id: summary.id } }">
                            {{ summary.content?.title || summary.model?.filename }}
                        </router-link>
                    </td>
                    <td>
                        <div class="tags mb-0">
                            <audit-condition :condition="{ name: condition }"
                                v-for="condition in summary.conditions"
                                :key="condition.id">
                            </audit-condition>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange"></pagination>
    </div>
</template>

<script>
import ContentCategorySelect from "@/content/components/ContentCategorySelect";
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import http from "@/http";
import { cleanData } from "@/utils.js";
import AuditSummary from "../models/AuditSummary";
import AuditCondition from "./AuditCondition";

export default {
    mixins: [BaseListComponent],
    data() {
        return {
            conditions: [],
            resultsCount: 0,
            filters: {
                type: 'C',
                library: null,
                application: null,
                offset: 0,
                limit: null,
                state: "",
            },
            listUrl: `${process.env.VUE_APP_API_URL_V1}/audits/summaries/`,
            modelClass: AuditSummary,
        }
    },
    components: {
        ApplicationSelect,
        AuditCondition,
        ClearButton,
        ContentCategorySelect,
        ContentLibrarySelect,
        LimitSelect,
        Pagination,
        SearchInput,
    },
    async created() {
        this.conditions = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/conditions/`)
            .then(resp => resp.json());
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (this.included)
                filters.included = this.included;
            if (this.excluded)
                filters.excluded = this.excluded;
            if (this.filters.library)
                filters.library = filters.library.code;
            if (this.filters.application)
                filters.application = filters.application?.code;

            return filters;
        },
        excluded() {
            return this.conditions.filter(x => x.excluded).map(x => x.id).join(',');
        },
        included() {
            return this.conditions.filter(x => x.included).map(x => x.id).join(',');
        },
    },
    watch: {
        conditions: {
            handler() {
                this.load();
            },
            deep: true,
        }
    },
}
</script>

<style lang="sass" scoped>
table.summary 
    td
        padding: 0
        margin: 0 5px 0 0
        border: none
</style>