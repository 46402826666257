import BaseData from "@/core/models/BaseData";

const TYPES = {
    A: 'Alpha',
    B: 'Beta',
    P: 'Public',
}
export default class ApplicationDownload extends BaseData {
    constructor(data) {
        super(data);
    }

    get typeName() {
        return TYPES[this.type] || this.type;
    }
}
