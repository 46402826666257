<template>
    <div>
        <h1 class="title">
            {{ pageTitle }} Revit Journals
        </h1>
        <h3 class="subtitle">
            <div v-if="filters.model">Model: {{ filters.model.filename }}</div>
            <div v-if="filters.project">Project: {{ filters.project.name }}</div>
        </h3>
        <div class="field is-grouped is-grouped-multiline">
            <user-autocomplete placeholder="Filter by user"
                v-model="filters.user"></user-autocomplete>
            <div class="control">
                <label class="label p-2">Start:</label>
            </div>
            <div class="control">
                <input class="input"
                    type="date"
                    v-model="filters.start">
            </div>
            <div class="control">
                <label class="label p-2">End:</label>
            </div>
            <div class="control">
                <input class="input"
                    type="date"
                    v-model="filters.end">
            </div>
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button v-if="isFiltered"
                @click="clearFilters">
            </clear-button>
        </div>
        <table class="table is-fullwidth is-striped">
            <thead>
                <tr>
                    <th>Model</th>
                    <th>Project</th>
                    <th>User</th>
                    <th>Created</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="journal in items"
                    :key="journal.id">
                    <td>
                        <router-link :to="{ name: 'journal-detail', params: { id: journal.id } }">
                            {{ journal.model.filename }}
                        </router-link>
                    </td>
                    <td>
                        {{ journal.model.project }}
                    </td>
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: journal.user.id } }"
                            target="_blank">
                            {{ journal.user.name }}
                        </router-link>
                    </td>
                    <td>
                        {{ journal.created.fromNow() }}
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from '@/core/components/ClearButton.vue';
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import UserAutocomplete from "@/users/components/UserAutocomplete";
import { cleanData } from "@/utils.js";
import moment from "moment";
import Journal from "../models/Journal";

export default {
    mixins: [BaseListComponent],
    components: {
        ClearButton,
        LimitSelect,
        Pagination,
        UserAutocomplete,
    },
    data() {
        return {
            filters: {
                model: null,    // TODO Set from route.query
                user: null,
                limit: null,
                offset: 0,
                start: moment().subtract(365, 'days').format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
            },
            listUrl: `${process.env.VUE_APP_API_URL_V1}/bim/journals/`,
            modelClass: Journal,
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.user)
                filters.user = filters.user.id;

            if (filters.model)
                filters.model = filters.model.id;

            return filters;
        }
    },
    methods: {
        async loadUrlArgs() {
            let modelId = this.$route.query.model;
            if (modelId) {
                this.filters.model = { id: modelId };
                await this.$store.dispatch('bim/loadModelById', modelId);
                this.filters.model = this.$store.getters['bim/getModelById'](modelId)
            }
        },
    },
}
</script>