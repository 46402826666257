<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedApplication">
                <option :value="null"
                    v-if="showNone">
                    None
                </option>
                <option :value="null"
                    v-if="showAll">
                    All Applications
                </option>
                <option v-for="app in applications"
                    :key="app"
                    :value="app">
                    {{ app.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedApplication: null,
        }
    },
    created() {
        this.selectedApplication = this.modelValue;
        this.$store.dispatch("core/loadApplicationsList");
    },
    computed: {
        applications() {
            return this.$store.getters["core/getApplications"].filter(x => x.code != 'S');
        },
    },
    watch: {
        selectedApplication(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            // modelValue might just be the application code
            if(typeof val == 'string')
                this.selectedApplication = this.applications.find(x => x.code == val) ;
            else
                this.selectedApplication = val;
        }
    }
}
</script>