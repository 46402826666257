import { PERMISSIONS } from '@/constants';
import StatsContent from './components/StatsContent';
import StatsEvents from './components/StatsEvents';
import StatsIndex from './components/StatsIndex';
import StatsSummary from './components/StatsSummary';
import StatsTools from './components/StatsTools';

let routes = [
    {
        path: '/stats/',
        name: 'stats-index',
        component: StatsIndex,
        meta: {
            title: 'Stats',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITEVENT],
        }
    },
    {
        path: '/stats/tools/',
        name: 'stats-tools',
        component: StatsTools,
        meta: {
            title: "Tools",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Stats index', route: 'stats-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_TOOLEVENT],
        }
    },
    {
        path: '/stats/content/',
        name: 'stats-content',
        component: StatsContent,
        meta: {
            title: "Content",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Stats index', route: 'stats-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTEVENT],
        }
    },
    {
        path: '/stats/events/',
        name: 'stats-events',
        component: StatsEvents,
        meta: {
            title: "Events",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Stats index', route: 'stats-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITEVENT],
        }
    },
    {
        path: '/stats/summary/',
        name: 'stats-summary',
        component: StatsSummary,
        meta: {
            title: "Tools",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Stats index', route: 'stats-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_CONTENTEVENT],
        }
    },
]

routes.forEach(x => x.meta.group = 'Stats');
export default routes;