import BaseData from "@/core/models/BaseData";
import User from '@/users/models/User';

class ContentActivity extends BaseData {
    constructor(data) {
        super(data);

        if(this.user)
            this.user = new User(data.user);
    }
}

export default ContentActivity;
