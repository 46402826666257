<template>
    <div>
        <div class="is-pulled-right">
            <div class="control">
                <router-link :to="{ name: 'content-request-add' }"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-plus"></span>
                    </span>
                    <span>Request Content</span>
                </router-link>
            </div>
        </div>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Requests
        </h2>
        <div class="field is-grouped">
            <div class="control">
                <div class="select">
                    <select v-model="filters.state">
                        <option value="">All states</option>
                        <option v-for="(state, stateCode) in states"
                            :key="stateCode"
                            :value="stateCode">
                            {{ state }}
                        </option>
                    </select>
                </div>
            </div>
            <content-library-select v-model="filters.library"
                :show-all="true">
            </content-library-select>
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button v-if="isFiltered"
                @click="clearFilters"></clear-button>
        </div>
        <table class="table is-fullwidth mt-5">
            <thead>
                <tr>
                    <th>Request</th>
                    <th>Description</th>
                    <th>Library</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Requested by</th>
                    <th>Assigned to</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="req in items">
                    <td>
                        <router-link :to="{ name: 'content-request-detail', params: { id: req.id } }">
                            {{ req.description }}
                        </router-link>
                    </td>
                    <td>{{ req.description }}</td>
                    <td>{{ req.library.title }}</td>
                    <td>{{ req.stateName }}</td>
                    <td>{{ req.created.fromNow() }}</td>
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: req.author.id } }"
                            v-if="req.author"
                            target="_blank">
                            {{ req.author.name }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: req.creator.id } }"
                            v-if="req.creator"
                            target="_blank">
                            {{ req.creator.name }}
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import { REQUEST_STATES } from "@/content/constants";
import BaseListComponent from "@/core/components/BaseListComponent.js";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect.vue";
import Pagination from "@/core/components/Pagination.vue";
import { cleanData } from "@/utils";
import ContentRequest from "../models/ContentRequest";

export default {
    mixins: [BaseListComponent],
    components: {
        Pagination,
        LimitSelect,
        ContentLibrarySelect,
        ClearButton,
    },
    data() {
        return {
            filters: {
                library: null,
                limit: null,
                offset: 0,
                q: null,
                state: '',
            },
            states: REQUEST_STATES,
            listUrl: `${process.env.VUE_APP_API_URL_V2}/content/requests/`,
            modelClass: ContentRequest,
        };
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters)

            if (filters.library)
                filters.library = filters.library.code;

            return filters;
        }
    }
};
</script>