<template>
    <div class="columns is-multiline">
        <div v-for="group in Object.keys(groups)"
            class="column is-one-third">
            <div class="panel">
                <div class="panel-heading">
                    {{ group }}
                </div>
                <template v-for="route in groups[group]">
                    <div class="panel-block"
                        v-if="!route.path.includes(':')">
                        <router-link :to="{ name: route.name }">
                            {{ route.name }}
                        </router-link>
                    </div>
                    <template v-else>
                        <div class="panel-block has-text-grey-light"
                            v-if="showDetailRoutes">
                            {{ route.name }}
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <label class="checkbox is-pulled-right">
            <input type="checkbox"
                v-model="showDetailRoutes">
            Show all routes
        </label>
        <div class="is-clearfix"></div>
    </div>
</template>

<script>
import router from "@/router";
import _ from "lodash";

export default {
    data() {
        return {
            groups: [],
            showDetailRoutes: false,
        }
    },
    created() {
        let routes = router.getRoutes().filter(x => {
            return x.name; // && !x.path.includes(':');
        });

        routes = _.sortBy(routes, [x => x.meta.group, x => x.name]);

        this.groups = _.groupBy(routes, x => {
            return x.meta.group;
        })
    },
}
</script>