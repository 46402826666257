<template>
    <h2 class="title">Login complete</h2>
</template>

<script>
import router from '@/router';

export default {
    async created() {
        setTimeout(() => {
            router.push({ name: 'home' });
        }, 500)
    },
}
</script>