import { PERMISSIONS } from '@/constants';
import UserDetail from './components/UserDetail';
import UserEdit from './components/UserEdit';
import UserList from './components/UserList';
import UserManage from './components/UserManage';
import UserExternalEdit from './components/UserExternalEdit';

let routes = [
    {
        path: '/users/',
        name: 'user-list',
        component: UserList,
        meta: {
            title: 'Users',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_USERPROFILE],
        }
    },
    {
        path: '/users/:id/',
        name: 'user-detail',
        component: UserDetail,
        meta: {
            title: "User Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Users', route: 'user-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_USERPROFILE],
        }
    },
    {
        path: '/users/current/',
        name: 'user-current',
        component: UserEdit,
        meta: {
            title: "User Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Users', route: 'user-list' },
            ],
            icon: 'pencil',
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        }
    },
    {
        path: '/users/external/',
        name: 'user-external',
        component: UserExternalEdit,
        meta: {
            title: "External User Edit",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        }
    },
    {
        path: '/users/:id/edit/',
        name: 'user-edit',
        component: UserEdit,
        meta: {
            title: 'User Edit',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Users', route: 'user-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_USERPROFILE],
        }
    },
    {
        path: '/users/manage/',
        name: 'user-manage',
        component: UserManage,
        meta: {
            title: "Manage External Users",
            icon: 'account-multiple',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Users', route: 'user-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_USERPROFILE],
        }
    }
]

routes.forEach(x => x.meta.group = 'Users');
export default routes;