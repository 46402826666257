import { APPLICATIONS } from "@/constants";
import BaseData from "@/core/models/BaseData";
import moment from 'moment';
import store from "@/store";
import { truncateFilepath } from "@/utils";

const AUTODESK_CLOUD_MODEL_PREFIXES = [
    'BIM 360',
    'A360',
    'Autodesk Docs',
]

const MAX_SHORT_PATH_LENGTH = 100;
const ACCDOCS_PAT = /C:\/Users\/[A-Za-z0-9]+\/DC\/ACCDocs\/(.*)\//

class DesignModel extends BaseData {
    constructor(data) {
        super(data);
        this.date = data.date ? moment(data.date) : null;
        this.managed = data.date != null;
        this.selected = false;
        this.saved = false;
        this.error = false;
        this.comment = '';

        if (this.path)
            this.folder = this.path.slice(0, this.path.indexOf(this.filename));

        // Remember this initial state
        this._cache = Object.assign({}, this);
    }

    reset() {
        Object.assign(this, this._cache);
    }

    get isCloudModel() {
        for (let prefix of AUTODESK_CLOUD_MODEL_PREFIXES)
            if (this.path.startsWith(prefix))
                return true;

        return false;
    }

    get isLocalModel() {
        let bits = this.path.split('\\');
        return bits.length > 1;
    }

    get applicationName() {
        return APPLICATIONS[this.application];
    }

    get unitsName() {
        if (this.units == 'M')
            return 'Metric';
        else if (this.units == 'I')
            return 'Imperial';
        else
            return null;
    }

    async load() {
        let projectNumber = this.project;
        await store.dispatch('projects/loadProjectByNumber', projectNumber);
        this.project = store.getters['projects/getProjectByNumber'](projectNumber);
    }

    get location() {
        let path =  truncateFilepath(this.path, 80, false);
        if(ACCDOCS_PAT.test(path))
            return path.replace(ACCDOCS_PAT, 'Autodesk Docs://$1/');
        else
            return path;
    }
}

export default DesignModel;
