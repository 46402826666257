import { PERMISSIONS } from '@/constants';
import Denied from './components/Denied';
import Download from './components/Download';
import Feedback from './components/Feedback';
import Home from './components/Home';
import Manage from './components/Manage';
import NotificationEdit from './components/NotificationEdit';
import NotificationList from './components/NotificationList';
import PageNotFound from './components/PageNotFound';
import PermissionsDashboard from './components/PermissionsDashboard';
import ReportDetail from './components/ReportDetail';
import SiteMap from './components/SiteMap';
import TagManage from './components/TagManage';

let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: "Welcome to Jacobs Tools",
        }
    },
    {
        path: '/download/',
        name: 'download',
        component: Download,
        meta: {},
    },
    {
        path: '/denied/',
        name: 'denied',
        component: Denied,
        meta: {},
    },
    {
        path: '/not-found/',
        name: 'not-found',
        component: PageNotFound,
        meta: {
            title: 'Page not found',
        },
    },
    {
        path: '/site-map/',
        name: 'sitemap',
        component: SiteMap,
        meta: {
            title: 'Site Map',
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        }
    },
    {
        path: '/tags/',
        name: 'tags-manage',
        component: TagManage,
        meta: {
            permissionsRequired: [PERMISSIONS.CHANGE_TAG, PERMISSIONS.DELETE_TAG],
        },
    },
    {
        path: '/feedback/',
        name: 'feedback',
        component: Feedback,
        meta: {
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        },
    },
    {
        path: '/reports/:id/',
        name: 'report-detail',
        component: ReportDetail,
        meta: {
            title: 'Report Detail',
            permissionsRequired: [PERMISSIONS.VIEW_REPORT],
        },
    },
    {
        path: '/manage/',
        name: 'manage',
        component: Manage,
        meta: {
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.IS_SUPERUSER],
        },
    },
    {
        path: '/manage/permissions/',
        name: 'permissions',
        component: PermissionsDashboard,
        meta: {
            title: 'Permissions',
            icon: 'account-group',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Manage', route: 'manage' },
            ],
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        },
    },
    {
        path: '/notifications/',
        name: 'notification-list',
        component: NotificationList,
        meta: {
            title: 'Notifications',
            icon: 'message-outline',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Manage', route: 'manage' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_NOTIFICATION],
        },
    },
    {
        path: '/notifications/:id/',
        name: 'notification-edit',
        component: NotificationEdit,
        meta: {
            title: 'Edit Notification',
            icon: 'message-outline',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Manage', route: 'manage' },
                { name: 'Notifications', route: 'notification-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_NOTIFICATION],
        },
    },
    {
        path: '/notifications/add/',
        name: 'notification-add',
        component: NotificationEdit,
        meta: {
            title: 'Add Notification',
            icon: 'message-outline',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Manage', route: 'manage' },
                { name: 'Notifications', route: 'notification-list' },
            ],
            permissionsRequired: [PERMISSIONS.ADD_NOTIFICATION],
        },
    },
]

routes.forEach(x => x.meta.group = 'Core');
export default routes;