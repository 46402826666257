import BaseData from "@/core/models/BaseData";

class Journal extends BaseData {
    constructor(data) {
        super(data);
        this.modelName = this.model.filename.slice(0, this.model.filename.lastIndexOf('.'));

        if (this.parsedData) {
            this.sortedData = {};
            const sortedKeys = Object.keys(this.parsedData).sort();
            sortedKeys.forEach(key => {
                this.sortedData[key] = this.parsedData[key];
            });
        }
    }
}

export default Journal;
