import { REQUEST_STATES } from "@/content/constants";
import BaseData from "@/core/models/BaseData";
import Attachment from "./Attachment";
import User from "@/users/models/User";

class ContentRequest extends BaseData {
    constructor(data) {
        super(data);

        if (this.attachments)
            this.attachments = data.attachments.map(x => new Attachment(x));
        else
            this.attachments = [];
        
        if (this.author)
            this.author = new User(this.author);

        if (this.creator)
            this.creator = new User(this.creator);
    }

    get stateName() {
        return REQUEST_STATES[this.state];
    }
}

export default ContentRequest;