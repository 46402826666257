import BaseData from "@/core/models/BaseData";
import User from "@/users/models/User";
import moment from "moment";
import store from "@/store";


const APPLICATION_INITIALIZED = 'AI';
const CLOSED = 'DC';
const FAMILY_LOADED = 'FE';
const MODIFIED = 'DM';
const OPENED = 'DO';
const SAVED = 'DS';
const SYNC_CANCELLED = 'YC';
const SYNC_END = 'YE';
const SYNC_START = 'YS';

const EVENT_TYPES = {
    [APPLICATION_INITIALIZED]: "Started",
    [CLOSED]: "Closed",
    [FAMILY_LOADED]: "Modified",
    [MODIFIED]: "Modified",
    [OPENED]: "Opened",
    [SAVED]: "Saved",
    [SYNC_CANCELLED]: "Sync cancelled",
    [SYNC_END]: "Synced",
    [SYNC_START]: "Syncing",
}


class ModelSession extends BaseData {
    constructor(data) {
        super(data);
        this.userprofile = new User(data.userprofile);
        this.syncing = this.type == SYNC_START;
        this.active = this.type != CLOSED;
        this.highlight = false;
        this.lastActivity = moment();

        this.loadModel(data.model_id);
    }

    get status() {
        return EVENT_TYPES[this.type] || '';
    }

    async loadModel(modelId) {
        await store.dispatch('bim/loadModelById', modelId);
        this.model = store.getters['bim/getModelById'](modelId)
    }

    setStatus(eventType) {
        this.lastActivity = moment();
        this.type = eventType;
        this.highlight = true;

        if (eventType == SYNC_START) {
            this.syncing = true;
        } else if (eventType == SYNC_END) {
            this.highlight = false;
        } else {
            this.syncing = false;
        }

        if (eventType == CLOSED)
            this.active = false;

        if (eventType != SYNC_START)
            setTimeout(() => this.highlight = false, 1000);
    }

}

export default ModelSession;
