import { APPLICATIONS } from "@/constants";
import BaseData from "@/core/models/BaseData";
import store from "@/store";

class AuditTemplate extends BaseData {
    constructor(data) {
        super(data);
        this.application = this.application || 'R';
        this.type = this.type || 'M';
        this.rules = this.rules || [];
    }

    get typeName() {
        return this.type == 'M' ? 'Model' : 'Content';
    }

    get applicationName() {
        return APPLICATIONS[this.application] || this.application;
    }

    async load() {
        await store.dispatch("core/loadApplicationsList");
        this.application = store.getters["core/getApplicationByCode"](this.application?.code || this.application)
    }
}

export default AuditTemplate;