<template>
    <div>
        <h2 class="title">{{ sessions.length }} Sessions</h2>
        <div v-if="show.map"
            style="height: 400px">
            <MapChart :countryData="countryCounts"
                highColor="#ff0000"
                lowColor="#aaaaaa"
                countryStrokeColor="#909090"
                defaultCountryFillColor="#dadada" />
        </div>
        <div class="level mt-4">
            <div class="level-left">
                <div class="level-item">
                    <span v-if="filters.office"
                        class="tags has-addons">
                        <span class="tag is-link">{{ filters.office }}</span>
                        <a @click="filters.office = null"
                            class="tag is-delete"></a>
                    </span>
                </div>
                <div class="level-item">
                    <span v-if="filters.country"
                        class="tags has-addons">
                        <span class="tag is-primary">{{ filters.country }}</span>
                        <a @click="filters.country = null"
                            class="tag is-delete"></a>
                    </span>
                </div>
                <div class="level-item">
                    <span v-if="filters.project"
                        class="tags has-addons">
                        <span class="tag is-warning">{{ filters.project }}</span>
                        <a @click="filters.project = null"
                            class="tag is-delete"></a>
                    </span>
                </div>
                <div class="level-item">
                    <span v-if="filters.model"
                        class="tags has-addons">
                        <span class="tag is-success">{{ filters.model }}</span>
                        <a @click="filters.project = null; filters.model = null"
                            class="tag is-delete"></a>
                    </span>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <label class="checkbox">
                        <input type="checkbox"
                            v-model="show.inactive">
                        Show closed sessions
                    </label>
                </div>
                <div class="level-item">
                    <label class="checkbox">
                        <input type="checkbox"
                            v-model="show.map">
                        Show map
                    </label>
                </div>
                <div class="level-item">
                    <limit-select v-model="filters.limit"></limit-select>
                </div>
                <div class="level-item">
                    <clear-button v-if="isFiltered"
                        @click="clearFilters"></clear-button>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth mt-4">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Office</th>
                    <th>Country</th>
                    <th>Project</th>
                    <th>Model</th>
                    <th>Last Activity</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="session in sessions"
                    :class="{ highlight: session.highlight, inactive: !session.active }">
                    <td>
                        <div class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <figure class="image is-32x32">
                                        <img :src="session.userprofile.avatarUrl"
                                            class="is-rounded">
                                    </figure>
                                </div>
                                <div class="level-item">
                                    {{ session.userprofile.name }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a @click="filters.office = session.userprofile.office?.name">
                            {{ session.userprofile.office?.name }}
                        </a>
                    </td>
                    <td>
                        <a @click="filters.country = session.userprofile.office?.countryName">
                            {{ session.userprofile.office?.countryName }}
                        </a>
                    </td>
                    <td>
                        <a @click="filters.project = session.model.project"
                            v-if="session.model">
                            {{ session.model.project }}
                        </a>
                    </td>
                    <td>
                        <a @click="filters.project = session.model.project; filters.model = session.model.filename"
                            v-if="session.model">
                            {{ session.model.filename }}
                        </a>
                    </td>
                    <td>
                        <progress-bar v-if="session.syncing">
                            Syncing
                        </progress-bar>
                        <span v-else>
                            {{ session.status }} {{ session.lastActivity.format('h:mma') }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect";
import ProgressBar from "@/core/components/ProgressBar";
import http from "@/http";
import _ from "lodash";
import moment from "moment";
import MapChart from 'vue-map-chart';
import ModelSession from "../models/ModelSession";

export default {
    components: { MapChart, ClearButton, ProgressBar, LimitSelect },
    data() {
        return {
            sessionMap: {},
            filters: {
                office: null,
                country: null,
                model: null,
                project: null,
                limit: null,
                orderBy: 'lastActivity',
                orderDirection: 'desc',
            },
            show: {
                map: true,
                inactive: false,
            },
        }
    },
    async created() {
        let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/sessions/`)
            .then(resp => resp.json());

        data.results.forEach(x => {
            let session = new ModelSession(x);
            let key = this.getSessionKey(session)
            this.sessionMap[key] = session;
        })

        this.initWebSockets();

        // Update page on tab change
        document.addEventListener('visibilitychange', e => {
            if (document.hidden)
                this.lastDocumentHidden = moment();
            else
                if (moment().diff(this.lastDocumentHidden, 'minutes') > 10)
                    location.reload();
        });
    },
    computed: {
        sessions() {
            let sessions = Object.values(this.sessionMap);

            if (!this.show.inactive)
                sessions = sessions.filter(x => x.active);

            if (this.filters.office)
                sessions = sessions.filter(x => x.userprofile.office?.name == this.filters.office)

            if (this.filters.country)
                sessions = sessions.filter(x => x.userprofile.office?.countryName == this.filters.country)

            if (this.filters.project)
                sessions = sessions.filter(x => x.model.project == this.filters.project)

            if (this.filters.model)
                sessions = sessions.filter(x => x.model.filename == this.filters.model)

            sessions = _.orderBy(sessions, this.filters.orderBy, this.filters.orderDirection);


            return sessions.slice(0, this.filters.limit);
        },
        countryCounts() {
            let counts = {};

            for (let session of this.sessions.filter(x => x.active)) {
                const countryCode = session.userprofile.office?.country;
                if (counts[countryCode])
                    counts[countryCode]++;
                else
                    counts[countryCode] = 1;
            }
            return counts;
        },
        isFiltered() {
            return this.filters.office || this.filters.country || this.filters.model || this.filters.project;
        },
    },
    methods: {
        clearFilters() {
            this.filters = {
                office: null,
                country: null,
                model: null,
                project: null,
            };
        },
        getSessionKey(data) {
            const modelId = data.modelId || data.model_id;
            return `${modelId}${data.userprofile.id}`;
        },
        initWebSockets() {
            // Web sockets
            const wsUrl = `${process.env.VUE_APP_WEBSOCKET_URL}/revit-events/`;
            const socket = new WebSocket(wsUrl);
            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                this.handleEvent(data);
            }
            socket.onerror = console.log;

            if (socket.readyState == WebSocket.OPEN)
                socket.onopen();
        },
        handleEvent(data) {
            let eventData = data.data.event;

            if (!eventData.userprofile)
                return;

            if (!eventData.model_id)
                return;

            const sessionKey = this.getSessionKey(eventData);
            let sessionObj = null;

            if (this.sessionMap[sessionKey]) {
                sessionObj = this.sessionMap[sessionKey]
                sessionObj.setStatus(eventData.type);
                this.sessionMap[sessionKey] = sessionObj;
            } else {
                sessionObj = new ModelSession(eventData);
                this.sessionMap[sessionKey] = sessionObj;
            }

            if (eventData.type == 'YS')
                document.title = `⚡ ${sessionObj.userprofile.name} is syncing ⚡`;
            else
                document.title = 'Model Events';

            // Mark any old sessions as inactive
            const now = moment();
            this.sessions.forEach(x => {
                if (now.diff(x.lastActivity, 'hours') >= 1)
                    x.active = false;
            });
        }
    }
}
</script>

<style lang="sass">
tr.highlight 
    background: lightYellow

tr.inactive 
    background: #eee

tr.highlight img 
    animation: pulse 2s infinite linear
        
@keyframes pulse 
    0% 
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4)
    70% 
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.0)
    100% 
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)            

</style>