import http from '@/http';
import Model from "./models/Model";

export default {
    namespaced: true,
    state: {
        models: {},
        parameters: {
            types: [],
            groups: [],
            bindingGroups: [],
            categories: [],
        },
        tasks: {
            types: [],
        }
    },
    actions: {
        async loadModelById({ commit, state }, id) {
            if (!state.models[id]) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/models/${id}/`)
                    .then(resp => resp.json());
                commit('SET_MODEL', {data: { id, model: new Model(data) }});
            }
        },
        async loadParameterTypes({ commit, state }) {
            if (state.parameters.types.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/types/`)
                    .then(resp => resp.json());
                commit('SET_PARAMETER_TYPES', { data });
            }
        },
        async loadParameterGroups({ commit, state }) {
            if (state.parameters.groups.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/groups/`)
                    .then(resp => resp.json());
                commit('SET_PARAMETER_GROUPS', { data });
            }
        },
        async loadParameterBindingGroups({ commit, state }) {
            if (state.parameters.bindingGroups.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/binding-groups/`)
                    .then(resp => resp.json());
                commit('SET_PARAMETER_BINDING_GROUPS', { data });
            }
        },
        async loadParameterCategories({ commit, state }) {
            if (state.parameters.categories.length == 0) {
                let data = await http.get(
                    `${process.env.VUE_APP_API_URL_V1}/bim/categories/`,
                    {
                        allows_bound_parameters: true,
                        limit: 9999,
                    })
                    .then(resp => resp.json());
                commit('SET_PARAMETER_CATEGORIES', { data: data.results });
            }
        },
        async loadTaskTypes({ commit, state }) {
            if (state.tasks.types.length == 0) {
                let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/info/`)
                    .then(resp => resp.json());
                commit('SET_TASK_TYPES', { data: data.tasks });
            }
        }
    },
    mutations: {
        SET_MODEL: (state, { data }) => {
            state.models[data.id] = data.model;
        },
        SET_PARAMETER_TYPES: (state, { data }) => {
            state.parameters.types = data;
        },
        SET_PARAMETER_GROUPS: (state, { data }) => {
            state.parameters.groups = data;
        },
        SET_PARAMETER_BINDING_GROUPS: (state, { data }) => {
            state.parameters.bindingGroups = data;
        },
        SET_PARAMETER_CATEGORIES: (state, { data }) => {
            state.parameters.categories = data;
        },
        SET_TASK_TYPES: (state, { data }) => {
            state.tasks.types = data;
        }
    },
    getters: {
        getParameterTypes: state => state.parameters.types,
        getParameterGroups: state => state.parameters.groups,
        getParameterBindingGroups: state => state.parameters.bindingGroups,
        getParameterCategories: state => state.parameters.categories,
        getTaskTypes: state => state.tasks.types,
        getModelById: state => (id) => state.models[id],
    }
}