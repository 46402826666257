import http from "@/http";
import store from "@/store";

export default {
    data() {
        return {
            item: null,
            user: null,
        }
    },
    async mounted() {
        await this.loadItem();

        await store.dispatch("users/loadCurrentUser");
        this.user = store.state.users.currentUser;

        await this.setup();
    },
    methods: {
        async loadItem() {
            let resp = await http.get(this.detailUrl);
            if (resp.status == 404)
                this.$router.replace({
                    name: 'not-found',
                    query: { path: this.$route.path }
                });

            let data = await resp.json();
            this.item = new this.modelClass(data);
            this[this.modelClass.name.toLowerCase()] = this.item;
        },
        async setup() {
            // Override in sub components
        }
    }
}