
const ADD = 'A';
const SWAP = 'S';
const ALIAS_NEW = 'LN';
const ALIAS_EXISTING = 'LE';

const PARAMETER_ACTIONS = {
    [ADD]: 'Add',
    [SWAP]: 'Swap with',
    [ALIAS_NEW]: 'Alias new',
    [ALIAS_EXISTING]: 'Alias existing'
}

const UPDATE_STRATEGIES = {
    NU: 'Disable info link',
    AD: 'Database writes to model',
    AR: 'Model writes to database',
    PU: 'Prompt user per model',
}

export { PARAMETER_ACTIONS, UPDATE_STRATEGIES };