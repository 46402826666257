<template>
    <section-nav :names="['task-dashboard']"
        class="is-pulled-right">
    </section-nav>
    <h2 class="title"
        v-if="items">
        {{ pageTitle }} Tasks
    </h2>
    <div class="field is-grouped">
        <application-select v-model="filters.application"
            :show-all="true">
        </application-select>
        <content-library-select v-model="filters.library"
            :show-all="true">
        </content-library-select>
        <div class="control">
            <div class="select">
                <select v-model="filters.state">
                    <option value="">All states</option>
                    <option value="W">Waiting</option>
                    <option value="R">Running</option>
                    <option value="C">Complete</option>
                    <option value="E">Error</option>
                </select>
            </div>
        </div>
        <limit-select v-model="filters.limit" />
        <clear-button @click="clearFilters"
            v-if="isFiltered"></clear-button>
    </div>
    <table class="table is-hoverable is-fullwidth">
        <thead>
            <tr>
                <th>Task</th>
                <th>Application</th>
                <th>Version</th>
                <th>Description</th>
                <th>Priority</th>
                <th>State</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="task in items">
                <td>{{ task.task }}</td>
                <td>{{ task.application.name }}</td>
                <td>{{ task.currentVersion }}</td>
                <td>{{ task.description }}</td>
                <td>{{ task.priorityName }}</td>
                <td>{{ task.stateName }}</td>
            </tr>
        </tbody>
    </table>
    <pagination :has-prev="hasPrev"
        :has-next="hasNext"
        @pagechanged="onPageChange">
    </pagination>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent.js";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect.vue";
import Pagination from "@/core/components/Pagination.vue";
import SearchInput from "@/core/components/SearchInput.vue";
import SectionNav from "@/core/components/SectionNav";
import http from '@/http';
import { cleanData } from "@/utils.js";
import _ from "lodash";
import Task from "../models/Task";

export default {
    mixins: [BaseListComponent],
    components: {
        Pagination,
        SearchInput,
        LimitSelect,
        ApplicationSelect,
        ContentLibrarySelect,
        ClearButton,
        SectionNav,
    },
    data() {
        return {
            filters: {
                application: null,
                library: null,
                limit: null,
                offset: 0,
                project: null,
                q: null,
                template: null,
                state: '',
            },
        };
    },

    methods: {
        loadItems: _.debounce(async function () {
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/bim/tasks/`,
                this.cleanedFilters)
                .then(resp => resp.json());
            this.resultsCount = data.count;
            this.items = data.results.map(x => new Task(x));
        }, 200),
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.library)
                filters.library = filters.library.code;
            if (filters.application)
                filters.application = filters.application.code;
            if (filters.project)
                filters.project = filters.project.id;
            if (filters.template)
                filters.template = filters.template.id;

            return filters;
        },
    }
};
</script>

