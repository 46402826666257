import { PERMISSIONS } from '@/constants';
import ProjectDetail from './components/ProjectDetail';
import ProjectEdit from './components/ProjectEdit';
import ProjectList from './components/ProjectList';

const sectionTitle = 'Projects';

let routes = [
    {
        path: '/projects/',
        name: 'project-list',
        component: ProjectList,
        meta: {
            title: sectionTitle,
            icon: 'folder-multiple-outline',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_PROJECT],
        }
    },
    {
        path: '/projects/:id/',
        name: 'project-detail',
        component: ProjectDetail,
        meta: {
            title: 'Project Detail',
            icon: 'folder-open-outline',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Projects', route: 'project-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_PROJECT],
        }
    },
    {
        path: '/projects/:id/edit/',
        name: 'project-edit',
        component: ProjectEdit,
        meta: {
            title: 'Project Edit',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Projects', route: 'project-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_PROJECT],
        }
    },
    {
        path: '/projects/add/',
        name: 'project-add',
        component: ProjectEdit,
        meta: {
            title: 'Add Project',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'Projects', route: 'project-list' },
            ],
            permissionsRequired: [PERMISSIONS.ADD_PROJECT],
        }
    },
]

routes.forEach(x => x.meta.group = 'Projects');
export default routes;