<template>
    <div class="card tags-list">
        <div class="card-header">
            Tags
        </div>
        <div class="card-content">
            <input v-if="showMinCount"
                type="range"
                min="0"
                max="10"
                v-model="minCount"
                :title="`Minimum count: ${minCount}`"
                class="is-pulled-right p-3">
            <div v-if="showMinCount"
                class="is-clearfix"></div>
            <div class="tags">
                <a v-for="tag in tags"
                    @click="selectTag(tag)"
                    :class="{ 'is-info': tag.selected }"
                    class="tag">
                    {{ tag.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/http';
import Tag from '../models/Tag';

export default {
    props: [
        'modelValue',
        'contentType',
        'library',
        'application',
        'units',
        'showMinCount',
    ],
    emits: ['update:modelValue'],
    data() {
        return {
            tags: [],
            minCount: 0,
        }
    },
    async created() {
        await this.loadTags();
    },
    methods: {
        selectTag(tag) {
            tag.selected = !tag.selected;
            this.$emit('update:modelValue', this.tags.filter(x => x.selected));
        },
        async loadTags() {
            let filters = {
                model: this.contentType,
                min_count: this.minCount,
                limit: 999,
            }

            if (this.library)
                filters.library = this.library;

            if (this.application)
                filters.application = this.application;

            if (this.units)
                filters.units = this.units;

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/tags-manage/`, filters)
                .then(resp => resp.json());

            this.tags = data.results.map(x => new Tag(x));
        }
    },
    watch: {
        modelValue: {
            handler: function (val) {
                const selectedNames = val.map(x => x.name);
                this.tags.forEach(x => {
                    x.selected = selectedNames.includes(x.name);
                });
            },
            deep: true,
        },
        async library() {
            await this.loadTags();
        },
        async application() {
            await this.loadTags();
        },
        async units() {
            await this.loadTags();
        },
        async minCount() {
            await this.loadTags();
        },
    },
}
</script>