<template>
    <div class="control">
        <button v-if="saving"
            class="button is-link"
            disabled>
            <span class="mdi mdi-refresh mdi-spin mr-2"></span>
            Saving&hellip;
        </button>
        <button v-else
            class="button is-link">
            <span class="mdi mdi-content-save mr-2"></span>
            <slot>Save</slot>
        </button>
    </div>
</template>


<script>
export default {
    props: ['saving'],
}
</script>