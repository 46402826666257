<template>
    <div v-if="hasShownResults"
        class="field">
        <label v-if="loading"
            class="label">Loading recommended classifications&hellip;</label>
        <template v-if="!loading && recommended.length">
            <label class="label">
                {{ recommended.length }} recommended classification{{ recommended.length == 1 ? '' : 's' }}
            </label>
            <div class="control tags">
                <a @click="selectTypology(typology)"
                    v-for="typology in recommended"
                    class="tag is-success">
                    {{ typology }}
                </a>
            </div>
        </template>
    </div>
</template>

<script>
import http from "@/http";
import LRU from "@/lru";
import _ from "lodash";
import ContentTypology from "../models/ContentTypology";

export default {
    props: {
        typologies: Array,
    },
    emits: ['selected'],
    data() {
        return {
            recommended: [],
            cache: new LRU(),
            loading: false,
            hasShownResults: false,
        }
    },
    mounted() {
        this.updateRecommendations();
    },
    methods: {
        selectTypology(typology) {
            this.$emit('selected', typology);
            this.recommended = this.recommended.filter(x => x.id != typology.id);
        },
        async updateRecommendations() {
            this.loading = true;

            let ids = [];
            this.typologies.forEach(x => {
                ids = ids.concat(x.associations);
            });
            ids = _.uniq(ids.filter(x => !this.typologies.map(y => y.id).includes(x)));
            ids = ids.filter(x => x);
            ids.sort();

            if (ids.length) {
                    const url = `${process.env.VUE_APP_API_URL_V2}/content/typologies/?ids=${ids.join(',')}`;
                const data = this.cache.read(url);

                if (data) {
                    this.recommended = data;
                } else {
                    let data = await http.get(url).then(resp => resp.json());
                    this.recommended = data.results.map(x => new ContentTypology(x)).slice(0, 10);
                    this.cache.write(url, this.recommended);
                }
            } else {
                this.recommended = [];
            }

            this.loading = false;

            if (!this.hasShownResults && this.recommended.length)
                this.hasShownResults = true;
        },
    },
    watch: {
        typologies(val) {
            this.updateRecommendations();
        }
    }
}
</script>