<template>
    <template v-if="visible && obj">

        <template v-if="show">
            <dl v-if="type == 'object'">
                <template v-for="entry in Object.entries(obj)">
                    <dt class="mr-2">{{ entry[0] }}:</dt>
                    <dd>
                        <render-object :obj="entry[1]"></render-object>
                    </dd>
                </template>
            </dl>
            <ul v-if="type == 'array'">
                <li v-for="x in obj">
                    <render-object :obj="x"></render-object>
                </li>
            </ul>
            <span v-if="type == 'simple'">{{ obj }}</span>
        </template>

        <button v-if="showButton"
            @click="show = !show"
            class="button is-small my-1">
            
            <template v-if="!show">
                <span class="icon">
                    <span class="mdi mdi-chevron-down"></span>
                </span>
                <span>Show object</span>
            </template>

            <template v-if="show">
                <span class="icon">
                    <span class="mdi mdi-chevron-up"></span>
                </span>
                <span>Hide object</span>
            </template>

        </button>

    </template>
</template>

<script>
import { isObject, isArray } from 'lodash';

export default {
    props: {
        obj: {
            default: null
        },
        visible: {
            default: true,
        },
    },
    data() {
        return {
            'type': null,
            'show': false,
        }
    },
    created() {
        if (isArray(this.obj)) {
            this.type = 'array';
            this.show = true;
        } else if (isObject(this.obj)) {
            this.type = 'object';
            this.show = false;
        } else {
            this.type = 'simple';
            this.show = true;
        }
    },
    computed: {
        showButton() {
            if (this.type == 'simple')
                return false;
            else if (this.type == 'array')
                return !this.visible;
            else
                return true;
        }
    }
}
</script>

<style lang="sass">
dl
    dt
        float: left
        font-weight: bold
</style>