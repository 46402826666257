import { PERMISSIONS } from '@/constants';
import BimIndex from './components/BimIndex';
import JournalDetail from './components/JournalDetail';
import JournalList from './components/JournalList';
import ModelDetail from './components/ModelDetail';
import ModelEdit from './components/ModelEdit';
import ModelEvents from './components/ModelEvents';
import ModelList from './components/ModelList';
import ModelManage from './components/ModelManage';
import ParameterCollectionDetail from './components/ParameterCollectionDetail';
import ParameterCollectionEdit from './components/ParameterCollectionEdit';
import ParameterCollectionList from './components/ParameterCollectionList';
import ParameterEdit from './components/ParameterEdit';
import ParameterList from './components/ParameterList';
import ParameterUpload from './components/ParameterUpload';
import TaskDashboard from './components/TaskDashboard';
import TaskList from './components/TaskList';

let routes = [
    {
        path: '/bim/',
        name: 'bim-index',
        component: BimIndex,
        meta: {
            title: 'BIM',
            permissionsRequired: [PERMISSIONS.IS_AUTHENTICATED],
        }
    },
    {
        path: '/bim/events/',
        name: 'model-events',
        component: ModelEvents,
        meta: {
            title: 'Model Events',
            icon: 'monitor-dashboard',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITEVENT],
        }
    },
    {
        path: '/bim/journals/',
        name: 'journal-list',
        component: JournalList,
        meta: {
            title: 'Journals',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITJOURNAL],
        }
    },
    {
        path: '/bim/journals/:id/',
        name: 'journal-detail',
        component: JournalDetail,
        meta: {
            title: 'Journal',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Journals', route: 'journal-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITJOURNAL],
        }
    },
    {
        path: '/bim/models/',
        name: 'model-list',
        component: ModelList,
        meta: {
            title: 'BIM Models',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_REVITMODEL],
        }
    },
    {
        path: '/bim/models/manage/',
        name: 'model-manage',
        component: ModelManage,
        meta: {
            title: 'Manage Models',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_REVITMODEL, PERMISSIONS.CHANGE_PROJECT],
        }
    },
    {
        path: '/bim/models/:id/',
        name: 'model-detail',
        component: ModelDetail,
        meta: {
            title: 'Model Detail',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Models', route: 'model-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITMODEL],
        }
    },
    {
        path: '/bim/models/:id/edit/',
        name: 'model-edit',
        component: ModelEdit,
        meta: {
            title: 'Edit Model',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Models', route: 'model-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_REVITMODEL],
        }
    },
    {
        path: '/bim/parameters/',
        name: 'parameter-list',
        component: ParameterList,
        meta: {
            title: 'Parameters',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_SHAREDPARAMETER],
        }
    },
    {
        path: '/bim/parameters/collections/',
        name: 'parameter-collection-list',
        component: ParameterCollectionList,
        meta: {
            title: 'Parameter Collections',
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_SHAREDPARAMETERCOLLECTION],
        }
    },
    {
        path: '/bim/parameters/add/',
        name: 'parameter-add',
        component: ParameterEdit,
        meta: {
            title: "Add Parameter",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
            ],
            permissionsRequired: [PERMISSIONS.ADD_SHAREDPARAMETER],
        }
    },
    {
        path: '/bim/parameters/:id/edit/',
        name: 'parameter-edit',
        component: ParameterEdit,
        meta: {
            title: "Edit Parameter",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_SHAREDPARAMETER],
        }
    },
    {
        path: '/bim/parameters/upload/',
        name: 'parameters-upload',
        component: ParameterUpload,
        meta: {
            title: "Upload Parameters",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
            ],
            permissionsRequired: [PERMISSIONS.ADD_SHAREDPARAMETERCOLLECTION],
        }
    },
    {
        path: '/bim/tasks/',
        name: 'task-list',
        component: TaskList,
        meta: {
            title: "All Tasks",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_TASKINSTANCE],
        }
    },
    {
        path: '/bim/tasks/live/',
        name: 'task-dashboard',
        component: TaskDashboard,
        meta: {
            title: "Live Tasks",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Tasks', route: 'task-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_TASKINSTANCE],
        }
    },
    {
        path: '/bim/parameters/collections/add/',
        name: 'parameter-collection-add',
        component: ParameterCollectionEdit,
        meta: {
            title: "Collection Add",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
                { name: 'Collections', route: 'parameter-collection-list' },
            ],
            permissionsRequired: [PERMISSIONS.ADD_SHAREDPARAMETERCOLLECTION],
        }
    },
    {
        path: '/bim/parameters/collections/:id/',
        name: 'parameter-collection-detail',
        component: ParameterCollectionDetail,
        meta: {
            title: "Collection Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
                { name: 'Collections', route: 'parameter-collection-list' },
            ],
            permissionsRequired: [PERMISSIONS.VIEW_SHAREDPARAMETERCOLLECTION],
        }
    },
    {
        path: '/bim/parameters/collections/:id/edit/',
        name: 'parameter-collection-edit',
        component: ParameterCollectionEdit,
        meta: {
            title: "Collection Detail",
            breadcrumbs: [
                { name: 'Home', route: 'home' },
                { name: 'BIM', route: 'bim-index' },
                { name: 'Parameters', route: 'parameter-list' },
                { name: 'Collections', route: 'parameter-collection-list' },
            ],
            permissionsRequired: [PERMISSIONS.CHANGE_SHAREDPARAMETERCOLLECTION],
        }
    },
]

routes.forEach(x => x.meta.group = 'BIM');
export default routes;