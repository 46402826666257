<template>
    <div class="panel">
        <div class="panel-heading">Content Stats</div>
        <div class="panel-block chart-block">
            <div>
                <spinner :active="loading"
                    type="cog"
                    size="large"></spinner>
                <base-chart v-if="chartData && !loading"
                    :data="chartData"
                    :options="chartOptions"
                    type="line"></base-chart>
            </div>
        </div>
        <div class="panel-block">
            <label class="p-3">Group by:</label>
            <div class="select">
                <select v-model="period">
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { COLORS } from "@/constants";
import BaseChart from "@/core/components/BaseChart";
import Spinner from "@/core/components/Spinner";
import http from "@/http";
import moment from "moment";

export default {
    props: ['content'],
    components: {
        BaseChart,
        Spinner,
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [],
            },
            chartOptions: {
                spanGaps: true,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'month',
                        }
                    },
                    y: {
                        display: false,
                    }
                },
            },
            period: 'month',
            loading: true,
        }
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            let start = moment().subtract(52, 'weeks');
            let end = moment();
            let args = {
                pad: 1,
                content: this.content.id,
                period: this.period,
                type: 'D',
                start: start.format('YYYY-MM-DD'),
                end: end.format('YYYY-MM-DD'),
            }
            let groupedData = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/stats/content/grouped/`, args)
                .then(resp => resp.json());

            this.chartData.labels = groupedData.map(x => x.period);
            this.chartData.datasets[0] =
            {
                label: 'Downloads',
                data: groupedData.map(x => x.count || null),
                borderColor: COLORS.BLUE,
            };

            args.type = 'V';
            groupedData = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/stats/content/grouped/`, args)
                .then(resp => resp.json());

            this.chartData.labels = groupedData.map(x => x.period);
            this.chartData.datasets[1] =
            {
                label: 'Views',
                data: groupedData.map(x => x.count || null),
                borderColor: COLORS.GREEN,
            };

            this.loading = false;
        }
    },
    computed: {
        total() {
            if (!this.loading)
                return this.chartData?.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0);
        }
    },
    watch: {
        period(val) {
            this.chartOptions.scales.x.time.unit = val;
            this.loadData();
        }
    }
}
</script>

<style lang="sass"
    scoped>
.chart-block
    display: block
</style>