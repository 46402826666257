<template>
    <div class="panel">
        <div class="panel-heading">{{ project.users.length }} Users (active recently)</div>
        <div class="panel-block" v-for="user in project.users" :key="user.id">
            <router-link :to="{ name: 'user-detail', params: { id: user.id } }">{{ user.name }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: ['project'],
};
</script>
