<template>
    <div class="control">
        <div class="has-addons field">
            <div class="control">
                <input v-model="value"
                    :type="type"
                    :disabled="locked"
                    :placeholder="placeholder"
                    class="input">
            </div>
            <div class="control">
                <button v-if="locked"
                    @click="locked = false"
                    title="Click to edit"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-lock-outline"></span>
                    </span>
                </button>
                <button v-else
                    @click="locked = true"
                    title="Click to lock"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-lock-open-outline"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        modelValue: String,
        placeholder: String,
        type: {
            default: 'text',
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            locked: true,
            value: null,
        }
    },
    created() {
        this.value = this.modelValue;
    },
    watch: {
        modelValue(val) {
            this.value = val;
        },
        value(val) {
            this.$emit('update:modelValue', val);
        }
    }
}
</script>