<template>
    <div>
        <autocomplete v-model="selectedSet"
            :placeholder="placeholder"
            :load-results="loadSets"
            :result-namer="x => x.name"
            :result-getter="x => x.results">
        </autocomplete>
    </div>
</template>
 
<script>
import http from "@/http";

import Autocomplete from "@/core/components/Autocomplete.vue";

export default {
    props: {
        placeholder: String,
        modelValue: Object,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            sets: [],
            selectedSet: null,
        }
    },
    created() {
        this.selectedSet = this.modelValue;
    },
    methods: {
        async loadSets(q) {
            return await http.get(`${process.env.VUE_APP_API_URL_V2}/content/sets/?q=${q}`)
                .then(resp => resp.json());
        },
    },
    watch: {
        modelValue(val) {
            this.selectedSet = val;
        },
        selectedSet(val) {
            this.$emit('update:modelValue', val);
        }
    }
}
</script>