<template>
    <div class="panel">
        <div class="panel-heading">
            <span class="is-pulled-right">
                <a v-if="!showParams"
                    @click="showParams = true">
                    <span class="mdi mdi-table"
                        title="Show table view"></span>
                </a>
                <a v-else
                    @click="showParams = false">
                    <span class="mdi mdi-format-list-bulleted"
                        title="Show list view"></span>
                </a>
            </span>
            <p>{{ symbols.length }} {{ symbols.length === 1 ? "Symbol" : "Symbols" }}</p>
        </div>
        <div class="panel-block"
            v-if="symbols.length > 20">
            <search-input v-model="filter" />
        </div>
        <template v-if="showParams">
            <div class="panel-block">
                <div class="table-container">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th v-for="field in fields"
                                    :key="field">{{ field }}</th>
                            </tr>
                        </thead>
                        <tr v-for="symbol in filteredSymbols"
                            :key="symbol.id">
                            <td class="symbol-name">{{ symbol.name }}</td>
                            <td v-for="field in fields"
                                :key="field">
                                <render-object :obj="symbol.params[field]"></render-object>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="panel-block"
                v-for="symbol in filteredSymbols"
                :key="symbol.id">
                {{ symbol.name }}
            </div>
        </template>
    </div>
</template>

<script>
import http from "@/http";
import SearchInput from "@/core/components/SearchInput";
import RenderObject from "@/core/components/RenderObject";

export default {
    props: ["content"],
    components: {
        SearchInput,
        RenderObject,
    },
    data() {
        return {
            symbols: [],
            showParams: false,
            filter: null,
        };
    },
    async mounted() {
        this.symbols = this.content.symbols;
        let data = await http.get(
            `${process.env.VUE_APP_API_URL_V2}/content/symbols/?content=${this.content.id}`)
            .then(resp => resp.json());
        this.symbols = data.results;
    },
    computed: {
        fields() {
            let firstSymbol = this.symbols[0];
            if (firstSymbol && firstSymbol.params)
                return Object.keys(this.symbols[0].params).filter(
                    (x) => x != "title"
                );
            else
                return [];
        },
        filteredSymbols() {
            let symbols = this.symbols.filter((x) => x.params);
            if (this.filter)
                symbols = symbols.filter((x) =>
                    x.name.toUpperCase().includes(this.filter.toUpperCase())
                );
            return symbols;
        },
    },
};
</script>

<style lang="sass" scoped>
.symbol-name
    white-space: nowrap
    font-weight: 400
</style>
