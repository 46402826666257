<template>
    <div class="control">
        <div class="select">
            <select v-model="selectedSet">
                <option :value="null"
                    v-if="showAll">All sets</option>
                <option :value="null"
                    v-if="showNone">No sets</option>
                <optgroup v-if="groupBy"
                    v-for="group in groupedSets"
                    :label="group[0]">
                    <option v-for="set in group[1]"
                        :value="set">
                        {{ set.name }}
                    </option>
                </optgroup>
                <option v-else
                    :value="set"
                    v-for="set in filteredSets">{{ set.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import { groupBy, orderBy } from 'lodash';

export default {
    props: {
        modelValue: Object,
        showAll: Boolean,
        showNone: Boolean,
        application: String,
        contentTypes: Array,
        groupBy: {
            type: String,
            required: false,
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            sets: [],
            selectedSet: null,
        }
    },
    async created() {
        this.selectedSet = this.modelValue;
        await this.$store.dispatch("content/loadSetList");
        this.sets = this.$store.getters["content/getSets"];
    },
    computed: {
        filteredSets() {
            let sets = this.sets;

            if (this.application)
                sets = sets.filter(x => x.application == this.application);

            if (this.contentTypes)
                sets = sets.filter(x => this.contentTypes.includes(x.contentType));

            return orderBy(sets, ['contentTypeName', 'name']);
        },
        groupedSets() {
            return Object.entries(groupBy(this.filteredSets, this.groupBy));
        },
    },
    watch: {
        selectedSet(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedSet = val;
        },
    }
}
</script>