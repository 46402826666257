<template>
    <div class="panel"
        v-if="images">
        <div class="panel-heading"
            v-if="showTitle">
            {{ images.length || 'No' }} {{ images.length == 1 ? 'Image' : 'Images' }}
        </div>
        <div class="panel-block">
            <div class="images">
                <transition name="fade">
                    <div :key="index"
                        class="image-wrapper"
                        v-if="activeImage">
                        <div>
                            <img :src="activeImage.image" />
                        </div>
                        <label v-if="activeImage.type">
                            {{ activeImage.type }}
                        </label>
                    </div>
                </transition>
            </div>
        </div>
        <div class="panel-block is-block"
            v-if="showControls && images.length > 1">
            <span class="is-pulled-right">
                <a class="button is-white"
                    @click="stop"
                    v-if="isRunning"
                    title="Pause slideshow">
                    <i class="mdi mdi-pause"></i>
                </a>
                <a class="button is-white"
                    @click="start"
                    v-if="!isRunning"
                    titlep="Start slideshow">
                    <i class="mdi mdi-play"></i>
                </a>
            </span>
            <div class="is-flex is-justify-content-center">
                <a class="button is-white"
                    v-if="hasPrev"
                    @click="showPrev">
                    <span class="mdi mdi-chevron-left"></span>
                    Previous
                </a>
                <a class="button is-white"
                    v-if="hasNext"
                    @click="showNext">
                    Next
                    <span class="mdi mdi-chevron-right"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: { type: Array },
        autoplay: { default: true },
        interval: { default: 2000 },
        showControls: { default: true },
        showTitle: { default: true },
    },
    data() {
        return {
            index: 0,
            intervalId: null,
        }
    },
    mounted() {
        if (this.autoplay)
            this.start();
    },
    methods: {
        showPrev() {
            this.index--;
        },
        showNext() {
            if (this.hasNext)
                this.index++;
            else
                this.index = 0;;
        },
        start() {
            this.intervalId = setInterval(() => {
                this.showNext();
            }, this.interval);
        },
        stop() {
            clearInterval(this.intervalId);
            this.intervalId = null;
        },
    },
    computed: {
        lastImageIndex() {
            return this.images.length - 1;
        },
        activeImage() {
            return this.images[this.index];
        },
        hasPrev() {
            return this.index > 0;
        },
        hasNext() {
            return this.index < this.lastImageIndex;
        },
        showNav() {
            return this.images.length > 1;
        },
        isRunning() {
            return !!this.intervalId;
        }
    }
}
</script>

<style lang="sass">
.images
    height: 450px
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    position: relative

    .image-wrapper
        position: absolute

        img 
            max-width: 400px
            max-height: 400px
</style>