<template>
  <div class="field is-grouped is-grouped-multiline level">
    <div class="control">
      <label>From:</label>
    </div>
    <div class="control">
      <input class="input"
             type="date"
             v-model="range.start">
    </div>
    <div class="control">
      <label>To:</label>
    </div>
    <div class="control">
      <input class="input"
             type="date"
             v-model="range.end"
             :max="maxDate">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    range: Object,
  },
  computed: {
    maxDate() {
      return moment().format('YYYY-MM-DD');
    },
  },
};
</script>
