<template>
    <div>
        <section-nav :names="['parameters-upload', 'parameter-collection-add']"
            class="is-pulled-right">
        </section-nav>
        <h1 class="title"
            v-if="items">
            {{ pageTitle }} Shared Parameters Collections
        </h1>
        <div class="field is-grouped">
            <div class="control">
                <discipline-select v-model="filters.discipline"
                    :show-all="true">
                </discipline-select>
            </div>
            <div class="control">
                <content-library-select v-model="filters.library"
                    :show-all="true">
                </content-library-select>
            </div>
            <div class="control">
                <search-input v-model="filters.q"
                    placeholder="Search name&hellip;">
                </search-input>
            </div>
            <div class="control">
                <limit-select v-model="filters.limit"></limit-select>
            </div>
            <clear-button @click="clearFilters"
                    v-if="isFiltered"></clear-button>
        </div>
        <table class="table is-fullwidth mt-5">
            <thead>
                <tr>
                    <th>Collection</th>
                    <th>Discipline</th>
                    <th>Parameters</th>
                    <th>Original Filename</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="collection in items">
                    <td>
                        <router-link :to="{ name: 'parameter-collection-detail', params: { id: collection.id } }">
                            {{ collection.name }}
                        </router-link>
                    </td>
                    <td>
                        {{ collection.discipline }}
                    </td>
                    <td>
                        {{ collection.parameterCount }}
                    </td>
                    <td>
                        {{ collection.originalFilename }}
                    </td>
                    <td>
                        <router-link :to="{ name: 'parameter-collection-edit', params: { id: collection.id } }">
                            <span class="icon">
                                <span class="mdi mdi-pencil"></span>
                            </span>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect.vue";
import BaseListComponent from "@/core/components/BaseListComponent.js";
import ClearButton from "@/core/components/ClearButton";
import DisciplineSelect from "@/core/components/DisciplineSelect.vue";
import LimitSelect from "@/core/components/LimitSelect.vue";
import Pagination from "@/core/components/Pagination.vue";
import SearchInput from "@/core/components/SearchInput.vue";
import SectionNav from "@/core/components/SectionNav";
import { cleanData } from "@/utils.js";
import ParameterCollection from "../models/ParameterCollection";

export default {
    mixins: [BaseListComponent],
    components: {
        ClearButton,
        ContentLibrarySelect,
        DisciplineSelect,
        LimitSelect,
        Pagination,
        SearchInput,
        SectionNav,
    },
    data() {
        return {
            filters: {
                q: null,
                limit: 100,
                offset: 0,
                discipline: null,
                library: null,
            },
            listUrl: `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/`,
            modelClass: ParameterCollection,
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);
            if (filters.discipline)
                filters.discipline = filters.discipline.name;
            if (filters.library)
                filters.library = filters.library.code;

            return filters;
        }
    }
}
</script>
