<template>
    <div>
        <h1 class="title"
            v-if="items">{{ pageTitle }} Users</h1>
        <div class="field is-grouped is-grouped-multiline">
            <search-input v-model="filters.q"
                placeholder="Search by name&hellip;">
            </search-input>
            <region-select v-model="filters.region"></region-select>
            <office-select v-model="filters.office"
                :show-all="true"
                :region="filters.region"></office-select>
            <discipline-select v-model="filters.discipline"
                :show-all="true"></discipline-select>
            <div class="control">
                <div class="select">
                    <select v-model="filters.global_version">
                        <option :value="null"
                            disabled
                            selected
                            hidden>All versions</option>
                        <option v-for="version in globalVersions">{{ version }}</option>
                    </select>
                </div>
            </div>
            <limit-select v-model.number="filters.limit"></limit-select>
            <clear-button @click="clearFilters"
                v-if="isFiltered"> </clear-button>
        </div>
        <div class="field is-grouped is-grouped-multiline p-3">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox"
                        v-model="filters.outdated_tools" />
                    Outdated tools
                </label>
            </div>
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox"
                        v-model="filters.latest_tools" />
                    Latest Tools
                </label>
            </div>
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox"
                        v-model="filters.outdated_revit" />
                    Outdated Revit
                </label>
            </div>
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox"
                        v-model="filters.recent" />
                    Show recently active only
                </label>
            </div>
        </div>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>
                        <span @click="sortBy('first_name')">
                            Name
                            <i v-if="sortField == 'first_name' && sortOrder == 'DESC'"
                                class="mdi mdi-chevron-down"></i>
                            <i v-if="sortField == 'first_name' && sortOrder == 'ASC'"
                                class="mdi mdi-chevron-up"></i>
                        </span>
                    </th>
                    <th>
                        <span @click="sortBy('discipline')">
                            Discipline
                            <i v-if="sortField == 'discipline' && sortOrder == 'DESC'"
                                class="mdi mdi-chevron-down"></i>
                            <i v-if="sortField == 'discipline' && sortOrder == 'ASC'"
                                class="mdi mdi-chevron-up"></i>
                        </span>
                    </th>
                    <th>
                        <span @click="sortBy('office')">
                            Office
                            <i v-if="sortField == 'office' && sortOrder == 'DESC'"
                                class="mdi mdi-chevron-down"></i>
                            <i v-if="sortField == 'office' && sortOrder == 'ASC'"
                                class="mdi mdi-chevron-up"></i>
                        </span>
                    </th>
                    <th>
                        <span @click="sortBy('tools_version')">
                            Tool Versions
                            <i v-if="sortField == 'tools_version' && sortOrder == 'DESC'"
                                class="mdi mdi-chevron-down"></i>
                            <i v-if="sortField == 'tools_version' && sortOrder == 'ASC'"
                                class="mdi mdi-chevron-up"></i>
                        </span>
                    </th>
                    <th>Application Versions</th>
                    <th>
                        <span @click="sortBy('last_active')">
                            Last Activity
                            <i v-if="sortField == 'email' && sortOrder == 'DESC'"
                                class="mdi mdi-chevron-down"></i>
                            <i v-if="sortField == 'email' && sortOrder == 'ASC'"
                                class="mdi mdi-chevron-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :key="user.id"
                    v-for="user in items">
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: user.id } }">
                            <figure class="image is-32x32 is-pulled-left">
                                <img :src="user.avatarUrl"
                                    class="is-rounded" />
                            </figure>
                            <span class="ml-2">{{ user.fullName }}</span>
                        </router-link>
                    </td>
                    <td>
                        <span v-if="user.discipline">
                            {{ user.discipline.name }}
                        </span>
                    </td>
                    <td>
                        <span v-if="user.office">{{ user.office.name }}</span>
                    </td>
                    <td>
                        <span v-for="version in user.toolVersions">
                            {{ version.typeName }}: {{ version.version }}<br />
                        </span>
                    </td>
                    <td>
                        <div v-for="version in user.revitVersions"
                            :key="version[1]">
                            {{ version[0] }}: {{ version[1] }}
                            <span class="mdi mdi-check has-text-success"
                                v-if="version[2]"
                                title="Current version"></span>
                            <span class="mdi mdi-close md-18 has-text-danger"
                                v-else
                                title="Outdated version"></span>
                        </div>
                    </td>
                    <td>
                        <span v-if="user.lastActive">
                            {{ user.lastActive.fromNow() }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import LimitSelect from "@/core/components/LimitSelect";
import OfficeSelect from "@/core/components/OfficeSelect";
import Pagination from "@/core/components/Pagination";
import RegionSelect from "@/core/components/RegionSelect";
import SearchInput from "@/core/components/SearchInput";
import { cleanData } from "@/utils.js";
import User from "../models/User";

export default {
    mixins: [BaseListComponent],
    components: {
        ClearButton,
        DisciplineSelect,
        LimitSelect,
        OfficeSelect,
        Pagination,
        RegionSelect,
        SearchInput,
    },
    data() {
        return {
            filters: {
                discipline: null,
                global_version: null,
                limit: null,
                office: null,
                offset: 0,
                recent: false,
                region: null,
                since: null,
            },
            toolVersions: [],
            listUrl: `${process.env.VUE_APP_API_URL_V1}/core/users/`,
            modelClass: User,
        };
    },
    async created() {
        await this.$store.dispatch("core/loadToolVersions");
        this.toolVersions = this.$store.getters["core/getToolVersions"];
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (!filters.recent)
                delete filters.recent;

            if (!filters.outdated_revit)
                delete filters.outdated_revit;

            if (!filters.outdated_tools)
                delete filters.outdated_tools;

            if (filters.discipline)
                filters.discipline = filters.discipline.id;
            else
                delete filters.discipline;

            if (filters.office)
                filters.office = filters.office.id;
            else
                delete filters.office;

            return filters;
        },
        globalVersions() {
            return this.toolVersions.filter(x => x.type == 'GL').map(x => x.major_version);
        }
    },
};
</script>
