<template>
    <div>
        <textarea class="textarea" 
            :placeholder="placeholder" 
            @focus="showControls = true" 
            v-model="comment">
        </textarea>
        <div v-if="showControls" class="my-2 is-clearfix">
            <span>
                Basic
                <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown</a>
                formatting supported
            </span>
            <button class="button is-pulled-right is-primary" 
                type="submit" 
                @click="submit">
                Submit
            </button>
        </div>
    </div>
</template>


<script>
import http from "@/http";

export default {
    props: [
        "callback",
        "content-type",
        "id",
        'placeholder',
    ],
    data() {
        return {
            showControls: false,
            comment: null,
            user: null,
        };
    },
    mounted() {
        //this.placeholder = this.placeholder || "Comment here";
        this.$store.dispatch("users/loadCurrentUser").then(() => {
            this.user = this.$store.state.users.currentUser;
        });
    },
    methods: {
        async submit() {
            this.showControls = false;
            const data = {
                comment: this.comment,
                content_type: this.contentType,
                object_id: this.id,
                user: this.user.id,
            };
            let resp = await http.post(
                `${process.env.VUE_APP_API_URL_V1}/core/comments/`,
                data);

            this.callback(resp);
            this.comment = "";
            this.showControls = true;
        },
    },
};
</script>
