<template>
    <h2 class="title">Forgot your password?</h2>
    <div class="columns">
        <div class="column is-half">
            <div class=field>
                Enter your email address below, and we'll email instructions for setting a new one.
            </div>
            <FormKit type="form"
                @submit="reset"
                submit-label="Send me instructions">
                <FormKit type="email"
                    placeholder="Email address"
                    validation="required|email"
                    name="email"></FormKit>
            </FormKit>
            <div class="notification mt-4">
                Note: This is for <em>local accounts only</em>,
                to reset your Autodesk password follow the instructions
                <a
                    href="https://knowledge.autodesk.com/customer-service/account-management/account-profile/account-security/reset-password-for-autodesk-account">
                    here
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { FormKit } from '@formkit/vue';
import { showMessage } from '@/utils';

export default {
    components: { FormKit },
    methods: {
        async reset(form) {
            let resp = await fetch(`${process.env.VUE_APP_API_URL_V1}/auth/password/reset/`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: form.email })
            });

            if (resp.status == 200)
                showMessage('Your password reset instructions have been sent to your email address')

            this.$router.push({ name: 'home' });
        }
    }
}
</script>