const traverse = (arr, parentId) => arr
    .filter((node) => node.parent === parentId)
    .reduce((result, current) => {
        const children = traverse(arr, current.id);
        if (children.length > 0) {
            current.children = children;
        }
        return [...result, current];
    }, []);

const parseTree = (arr) => arr
    .filter((node) => node.level === 0)
    .map((node) => ({
        ...node,
        children: traverse(arr, node.id),
    }))[0];

export { traverse, parseTree };