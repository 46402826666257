<template>
    <div class="columns">
        <div class="column is-half">
            <div class="is-pulled-right">
                Already have an account?
                <router-link :to="{ name: 'login' }">Login here</router-link>
            </div>
            <h2 class="title">Register</h2>
            <FormKit type="form"
                @submit="save">
                <FormKit type="email"
                    label="Email"
                    validation="required"
                    name="email">
                </FormKit>
                <FormKit type="password"
                    label="Password"
                    validation="required"
                    validation-visibility="live"
                    name="password">
                </FormKit>
                <FormKit type="password"
                    label="Password confirmation"
                    name="password_confirm"
                    validation="required|confirm"
                    validation-visibility="live"
                    validation-label="Password confirmation">
                </FormKit>
            </FormKit>
        </div>
    </div>
</template>

<script>
import { FormKit } from "@formkit/vue";
import { showMessage } from '@/utils';

export default {
    methods: {
        async save(form) {
            const data = {
                email: form.email,
                password1: form.password,
                password2: form.password_confirm,
            }
            let resp = await fetch(`${process.env.VUE_APP_API_URL_V1}/auth/registration/`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });

            if (resp.status == 201) {
                showMessage("Registration information received, check your email for more details")
                this.$router.push({ name: 'home' });
            } else {
                let result = await resp.json();
                if (result.password1)
                    showMessage(result.password1.join(" "), 'danger');
            }
        }
    }
}
</script>