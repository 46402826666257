class FileWrapper {
    constructor(file) {
        this.file = file;
        this.preview = '';
        this.state = '';
        this.message = '';
        this.route = null; 
    }

    get name() {
        return this.file?.name;
    }

    get size() {
        return this.file?.size;
    }

    get extension() {
        return this.name.split('.').pop();
    }
}

export default FileWrapper;