<template>
    <div class="field is-grouped is-grouped-multiline">
        <div class="control">
            <autocomplete @selected="handleSelect"
                :placeholder="placeholder"
                :load-results="loadProjects"
                :result-namer="x => x.number + ' - ' + x.name"
                :result-getter="x => x.results">
            </autocomplete>
        </div>
        <div class="control">
            <span class="tags m-2">
                <span v-for="project in selectedProjects"
                    class="tag">
                    {{ project.name }}
                    <button @click="deselect(project)"
                        class="delete is-small"></button>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import http from "@/http";

import Autocomplete from "@/core/components/Autocomplete.vue";

export default {
    props: {
        placeholder: {
            default: 'Search projects',
        },
        modelValue: Array,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            projects: [],
            selectedProjects: [],
        }
    },
    created() {
        this.selectedProjects = this.modelValue;
    },
    methods: {
        async loadProjects(q) {
            return await http.get(`${process.env.VUE_APP_API_URL_V1}/projects/?q=${q}`)
                .then(resp => resp.json());
        },
        handleSelect(project) {
            if (project)
                this.selectedProjects.push(project);
        },
        deselect(project) {
            this.selectedProjects = this.selectedProjects.filter(x => x != project);
        }
    },
    watch: {
        modelValue(val) {
            this.selectedProjects = val;
        },
        selectedProjects(val) {
            this.$emit('update:modelValue', val);
        }
    }
}
</script>