<template>
    <div class="panel">
        <div class="panel-heading"
            v-if="showTitle">
            Details
        </div>
        <div class="panel-block">
            <table class="detail">
                <tbody>
                    <tr>
                        <th>Application:</th>
                        <td>{{ content.applicationName }}</td>
                    </tr>
                    <tr>
                        <th>Type:</th>
                        <td>{{ content.typeName }}</td>
                    </tr>
                    <tr v-if="content.subType">
                        <th>Sub type:</th>
                        <td>{{ content.subType }}</td>
                    </tr>
                    <tr v-if="content.code">
                        <th>Code:</th>
                        <td>{{ content.code }}</td>
                    </tr>
                    <tr>
                        <th>Library:</th>
                        <td>
                            <router-link v-if="content.library?.id"
                                :to="{ name: 'content-library-detail', params: { id: content.library.id } }">
                                {{ content.libraryCode }}
                            </router-link>
                            <span v-else>{{ content.library }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>Discipline:</th>
                        <td>{{ content.disciplineName }}</td>
                    </tr>
                    <tr>
                        <th>Tags:</th>
                        <td>
                            <span v-if="!content.tags.length">-</span>
                            <span class="tags">
                                <span class="tag"
                                    v-for="tag in content.tags"
                                    :key="tag">{{ tag.name }}</span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>Sets:</th>
                        <td>
                            <span v-if="!content.sets.length">-</span>
                            <router-link v-for="set in content.sets"
                                :to="{ name: 'content-sets-edit', params: { id: set.id } }"
                                :key="set.id">{{ set.name }}</router-link>
                        </td>
                    </tr>
                    <tr>
                        <th>Active:</th>
                        <td>
                            <span v-if="content.active"
                                class="icon has-text-success">
                                <span class="mdi mdi-check-circle"></span>
                            </span>
                            <span v-else
                                class="icon has-text-danger">
                                <span class="mdi mdi-close-circle"></span>
                            </span>
                            <span>This item is {{ content.active ? 'active' : 'archived' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>Creator:</th>
                        <td>
                            <router-link :to="{ name: 'user-detail', params: { id: content.creator.id } }"
                                v-if="content.creator">
                                {{ content.creator.name }}
                            </router-link>
                        </td>
                    </tr>
                    <tr>
                        <th>Current Version:</th>
                        <td>{{ content.version }}</td>
                    </tr>
                    <tr>
                        <th>Units:</th>
                        <td>{{ content.unitsName }}</td>
                    </tr>
                    <tr>
                        <th>Filename:</th>
                        <td>{{ content.filename }}</td>
                    </tr>
                    <tr>
                        <th>Classifications:</th>
                        <td>
                            <p v-for="typology in content.typologies">
                                {{ typology }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>Audit:</th>
                        <td>
                            <div v-if="audit">
                                <div>
                                    <router-link :to="{ name: 'audit-summary-detail', params: { id: audit.id } }">
                                        {{ audit.date.fromNow() }}
                                    </router-link>
                                </div>
                                <ul class="tags">
                                    <li v-for="condition in audit.conditions"
                                        class="tag is-link">
                                        {{ condition }}
                                    </li>
                                </ul>
                            </div>
                            <span v-else>-</span>
                        </td>
                    </tr>
                    <tr v-for="field in content.dataFields">
                        <th>{{ field.name }}</th>
                        <td>{{ field.value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import http from "@/http";
import AuditSummary from "@/audits/models/AuditSummary";

export default {
    props: {
        content: Object,
        showTitle: {
            default: true
        }
    },
    data() {
        return {
            audit: null,
        }
    },
    async mounted() {
        let data = await http.get(
            `${process.env.VUE_APP_API_URL_V1}/audits/summaries/`,
            { content: this.content.id })
            .then(resp => resp.json());
        if (data.count == 1)
            this.audit = new AuditSummary(data.results[0]);

        await this.content.load();
    }
};
</script>
