<template>
    <ul v-if="node"
        class="page-menu">
        <li v-if="node.level == 0">
            <router-link :to="{ name: 'page-detail-id', params: { id: node.id } }"
                :key="node.id">
                {{ node.title }}
            </router-link>
        </li>
        <li v-if="node.level > 0"
            :class="{ leaf: !hasChildren }">
            <a v-if="hasChildren"
                @click="toggleChildren">
                <span :class="toggleChildrenIcon"></span>
            </a>
            <router-link :to="{ name: 'page-detail-id', params: { id: node.id } }"
                :key="node.id">
                <strong v-if="current.id == node.id">{{ node.title }}</strong>
                <span v-else>{{ node.title }}</span>
            </router-link>
        </li>
        <li v-show="node.expanded">
            <page-tree-node v-for="child in node.children"
                :key="child.id"
                :node="child"
                :parent="node"
                :current="current"></page-tree-node>
        </li>
    </ul>
</template>

<script>
export default {
    name: "PageTreeNode",
    props: ['node', 'current', 'parent'],
    async created() {
        // Expand the ancestor nodes for the current page and save to the store
        if (this.node?.id == this.current?.id) {
            let ancestor = this.node;
            while (ancestor.parent) {
                ancestor = this.$store.getters["pages/getPageById"](ancestor.parent);
                ancestor.expanded = true;
                this.$store.dispatch("pages/expandNode", ancestor.id);
            }
        }
    },
    computed: {
        hasChildren() {
            return this.node?.children?.length > 0
        },
        toggleChildrenIcon() {
            return this.node.expanded
                ? "mdi mdi-chevron-down is-size-5"
                : "mdi mdi-chevron-right is-size-5"
        },
    },
    methods: {
        toggleChildren() {
            // Expand or contract the node temporarily but don't save to the store
            this.node.expanded = !this.node.expanded;
        },
    },
    watch: {
        node: {
            immediate: true,
            handler(val) {
                if (val) {
                    val.expanded = val.level == 0 || this.$store.getters["pages/getPageById"](val.id)?.expanded;
                }
            }
        }
    },
}
</script>

<style lang="sass">
.page-menu
    li
        margin-left: 0.5em

        &.leaf
            margin-left: 1.75em
            padding: 4px

        .mdi
            vertical-align: middle
</style>