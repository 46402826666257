<template>
    <div class="panel">
        <div class="panel-heading">Activity</div>
        <div class="panel-block">
            <CommentForm content-type="content.Content"
                :id="content.id"
                :callback="loadItems"
                :placeholder="`Comment on ${content.title} here`">
            </CommentForm>
        </div>
        <div class="panel-block"
            v-for="item in filteredItems"
            :key="item.id"
            v-if="!loading">
            <div class="media">
                <div class="media-left">
                    <figure class="image is-48x48">
                        <img class="is-rounded"
                            v-if="item.user"
                            :src="item.user.avatarUrl" />
                    </figure>
                </div>
                <div class="media-content">
                    <div>
                        <span v-if="item.user">
                            <router-link :to="{ name: 'user-detail', params: { id: item.user.id } }"
                                target="_blank">
                                {{ item.user.fullName }}
                            </router-link>
                        </span>&nbsp;-&nbsp;
                        <span v-if="item.date">{{ item.date.fromNow() }}</span>
                    </div>
                    <div v-html="item.text"></div>
                </div>
            </div>
        </div>
        <div class="panel-block"
            v-if="!loading">
            <pagination :has-prev="hasPrev"
                :has-next="hasNext"
                @pagechanged="onPageChange"></pagination>
        </div>
        <div class="panel-block"
            v-if="loading">
            <spinner :active="loading"
                size="large"
                type="cog"></spinner>
        </div>
    </div>
</template>

<script>
import CommentForm from "@/core/components/CommentForm";
import Pagination from "@/core/components/Pagination";
import http from "@/http";
import ContentActivity from "../models/ContentActivity";
import Spinner from "../../core/components/Spinner.vue";

export default {
    props: ["content"],
    components: { CommentForm, Pagination, Spinner },
    data() {
        return {
            items: [],
            user: null,
            offset: 0,
            limit: 5,
            loading: false,
        };
    },
    async mounted() {
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
        await this.loadItems();
    },
    methods: {
        async loadItems() {
            this.loading = true;
            this.offset = 0;
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/activity/`)
                .then(resp => resp.json());

            this.items = data.map((x) => new ContentActivity(x));
            this.loading = false;
        },
        onPageChange(e) {
            if (e == 'next')
                this.offset += this.limit;
            else if (e == 'previous')
                this.offset -= this.limit;
        },
    },
    computed: {
        filteredItems() {
            return this.items.slice(this.offset, this.offset + this.limit);
        },
        hasPrev() {
            return this.offset > 0;
        },
        hasNext() {
            return (this.limit + this.offset) < this.items.length;
        },
    }
};
</script>

<style lang="sass" scoped>
    .activity-list
        height: 500px
        overflow-y: auto

    .panel-block
        display: block

        textarea.textarea
            height: 6em

    .pagination-previous, .pagination-next
        border: none
        box-shadow: none !important
</style>
