<template>
    <div class="container">
        <div class="columns">
            <div class="column">
                <a href=""> Ⓒ Jacobs </a>
            </div>
            <div class="column">
                <router-link :to="{ name: 'page-detail-slug', params: { slug: 'about' } }">
                    About
                </router-link>
                &bullet;
                <router-link :to="{ name: 'sitemap' }">
                    Site map
                </router-link>
                &bullet;
                <a href="http://jacobstools.com/toggle-spa/">Old site</a>
            </div>
            <div class="column">
                <a href="#"
                    @click.prevent="scrollToTop">
                    <span class="icon">
                        <span class="mdi mdi-arrow-up"></span>
                    </span>
                    <span>
                        Back to top
                    </span>
                </a>
            </div>
        </div>
    </div>
    <router-link :to="{ name: 'feedback', query: { url: this.url } }"
        id="feedback"
        class="button grow-on-hover">
        <span class="icon">
            <span class="mdi mdi-comment-outline"></span>
        </span>
        <span>Feedback</span>
    </router-link>
</template>

<script>
export default {
    computed: {
        url() {
            return this.$route.path;
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
}
</script>

<style lang="sass" scoped>
#feedback
    position: absolute
    right: 0
    top: -85px
    color: #363636
</style>
