class ImageAction {
    constructor(name, f) {
        this.name = name;
        this.f = f;
    }

    toString() {
        return this.name;
    }
}

export default ImageAction;