<template>
    <div>
        <div class="field is-grouped is-pulled-right">
            <div class="control">
                <router-link :to="{ name: 'content-import' }"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-table-large"></span>
                    </span>
                    <span>Bulk Rename</span>
                </router-link>
            </div>
            <div class="field has-addons">
                <div class="control">
                    <button class="button"
                        :class="{ 'is-focused': mode === 'tags' }"
                        @click="mode = 'tags'">
                        <span class="icon">
                            <span class="mdi mdi-tag-multiple"></span>
                        </span>
                        <span> Change tags </span>
                    </button>
                </div>
                <div class="control">
                    <button class="button"
                        :class="{ 'is-focused': mode === 'state' }"
                        @click="mode = 'state'">
                        <span class="icon">
                            <span class="mdi mdi-swap-horizontal"></span>
                        </span>
                        <span> Change state </span>
                    </button>
                </div>
                <div class="control">
                    <button class="button"
                        :class="{ 'is-focused': mode === 'typology' }"
                        @click="mode = 'typology'">
                        <span class="icon">
                            <span class="mdi mdi-sitemap"></span>
                        </span>
                        <span> Change Classification </span>
                    </button>
                </div>
            </div>
        </div>
        <h2 class="title">{{ pageTitle }} Content Items</h2>
        <div class="field is-grouped is-grouped-multiline">
            <application-select v-model="filters.application"
                :show-all="true">
            </application-select>
            <content-group-select v-if="filters.application?.code"
                v-model="filters.group"
                :application="filters.application?.code"
                :show-all="false">
            </content-group-select>
            <content-library-select v-model="filters.libraries"
                :show-all="true">
            </content-library-select>
            <div class="control">
                <div class="select">
                    <select v-model="filters.state">
                        <option :value="null">All states</option>
                        <option v-for="state in states"
                            :key="state.code"
                            :value="state.code">
                            {{ state.title }}
                        </option>
                    </select>
                </div>
            </div>
            <content-creator-select v-model="filters.creator"
                :show-all="true">
            </content-creator-select>
            <search-input v-model="filters.q"
                placeholder="Search by text">
            </search-input>
            <search-input v-model="filters.tags"
                placeholder="Search by tag">
            </search-input>
            <limit-select v-model="filters.limit" />
            <div class="control">
                <a @click="loadItems"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-refresh"></span>
                    </span>
                    <span>
                        Refresh
                    </span>
                </a>
            </div>
            <clear-button v-if="isFiltered"
                @click="clearFilters">
            </clear-button>
        </div>

        <hr>
        
        <div class="my-3">
            <button type="submit"
                :disabled="saving"
                class="button is-link is-pulled-right"
                v-if="showSave"
                @click="save">
                <span class="icon">
                    <span class="mdi mdi-content-save"></span>
                </span>
                <span> Save </span>
            </button>
        </div>

        <!-- States -->
        <div v-if="mode == 'state'"
            class="field">
            <div class="control"
                v-if="filters.state && filters.libraries?.id">
                <div class="select">
                    <select v-model="selected.state">
                        <option v-for="transition in transitions"
                            :value="transition">
                            {{ transition.title }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-else
                class="notification is-info is-light">
                You must select a library and a state above before you can update the
                state of a content item
            </div>
        </div>

        <div v-if="mode != 'state'"
            class="field is-grouped is-grouped-multiline">
            <div class="control">
                <div class="select">
                    <select v-model="selected.mode">
                        <option value="add">Add</option>
                        <option value="replace">Replace</option>
                    </select>
                </div>
            </div>

            <!-- Tags -->
            <div class="control"
                v-if="mode == 'tags'">
                <tags-autocomplete v-model="selected.tags"
                    placeholder="Search tags">
                </tags-autocomplete>
            </div>

            <!-- Typology -->
            <div class="control"
                v-if="mode == 'typology'">
                <typology-autocomplete v-model="selected.typologies">
                </typology-autocomplete>
            </div>
        </div>

        <table class="table is-fullwidth is-hoverable mt-3 content-list">
            <thead>
                <tr>
                    <th>Item</th>
                    <th></th>
                    <th>State</th>
                    <th>Creator</th>
                    <th v-if="mode == 'tags'">Tags</th>
                    <th v-if="mode == 'typology'">Classification</th>
                    <th>
                        <label v-if="!saving"
                            class="checkbox">
                            <input type="checkbox"
                                v-model="selectAll" />
                        </label>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="content in items"
                    :class="{ selected: content.selected, saved: content.saved }"
                    @click="content.selected = !content.selected">
                    <td>
                        <img :src="content.thumbnail" />
                    </td>
                    <td>
                        <router-link :to="{ name: 'content-detail', params: { id: content.id } }"
                            v-html="content.title">
                        </router-link>
                    </td>
                    <td>
                        <span v-if="isStateSearch">{{ content.stateName }}</span>
                        <span v-if="!isStateSearch">
                            {{ content.wipStateName ?? content.stateName }}
                        </span>
                        <span v-if="content.selected && mode == 'state' && selected.state">
                            &rarr;
                            {{ selected.state.title }}
                        </span>
                    </td>
                    <td>
                        <span v-if="content.creator">
                            {{ content.creator.name }}
                        </span>
                    </td>
                    <td v-if="mode == 'tags'">
                        <span class="tags">
                            <span class="tag"
                                v-if="selected.mode != 'replace'"
                                v-for="tag in content.tags">
                                {{ tag?.name }}
                            </span>
                            <span class="tag is-link"
                                v-if="content.selected"
                                v-for="tag in selected.tags">
                                {{ tag?.name }}
                            </span>
                        </span>
                    </td>
                    <td v-if="mode == 'typology'">
                        <div v-if="selected.mode != 'replace'">
                            <div v-for="typology in content.typologies">
                                {{ typology.name }}
                            </div>
                        </div>
                        <div v-if="content.selected">
                            <div v-for="typology in selected.typologies">
                                {{ typology.name }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <spinner v-if="content.saving"
                            :active="content.saving"
                            type="cog"
                            size="medium">
                        </spinner>
                        <input v-if="!saving"
                            v-model="content.selected"
                            type="checkbox" />
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import { STATES, TRANSITIONS } from "@/content/constants";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent.js";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect.vue";
import Pagination from "@/core/components/Pagination.vue";
import SearchInput from "@/core/components/SearchInput";
import Spinner from "@/core/components/Spinner";
import TagsAutocomplete from "@/core/components/TagsAutocomplete";
import http from "@/http";
import { asyncTimeout, cleanData, showMessage } from "@/utils";
import Content from "../models/Content";
import ContentCreatorSelect from "./ContentCreatorSelect";
import ContentGroupSelect from "./ContentGroupSelect";
import ContentLibrarySelect from "./ContentLibrarySelect";
import TypologyAutocomplete from "./TypologyAutocomplete";

const MODE = {
    TAGS: "tags",
    STATE: "state",
    TYPOLOGY: "typology",
};

let controller = null;

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ClearButton,
        ContentCreatorSelect,
        ContentGroupSelect,
        ContentLibrarySelect,
        LimitSelect,
        Pagination,
        SearchInput,
        Spinner,
        TagsAutocomplete,
        TypologyAutocomplete,
    },
    data() {
        return {
            filters: {
                application: null,
                creator: null,
                tags: null,
                typologies: null,
                libraries: null,
                state: null,
                limit: null,
                offset: 0,
                group: null,
            },
            mode: MODE.STATE,
            selectAll: false,
            states: STATES,
            selected: {
                state: null,
                typologies: [],
                tags: [],
                mode: "add",
            },
        };
    },
    methods: {
        async loadItems() {
            if (controller)
                controller.abort("Aborting previous request");

            controller = new AbortController();
            this.items = [];

            let resp = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/`,
                this.cleanedFilters,
                controller.signal
            );

            if (resp && resp.status == 200) {
                let data = await resp.json();
                this.items = data.results.map(x => new Content(x));
                this.resultsCount = data.count.value || data.count;
            }

            controller = null;
        },
        async save() {
            let selectedContent = this.items.filter(x => x.selected);
            selectedContent.forEach(x => (x.saving = true));
            let data = [];
            let url = `${process.env.VUE_APP_API_URL_V2}/content/bulk-update/`;

            // Prepare data for HTTP request
            if (this.mode == MODE.TAGS) {
                data = selectedContent.map(x => {
                    let tags = [];

                    if (this.selected.mode == "replace") tags = this.selected.tags;
                    else if (this.selected.mode == "add")
                        tags = this.selected.tags.concat(x.tags);
                    return {
                        id: x.id,
                        tags: tags.map(x => x.name),
                    };
                });
            } else if (this.mode == MODE.STATE) {
                data = selectedContent.map(x => {
                    return {
                        id: x.id,
                        state: this.selected.state.code,
                    };
                });
            } else if (this.mode == MODE.TYPOLOGY) {
                data = selectedContent.map(x => {
                    let typologies = [];
                    if (this.selected.mode == "replace")
                        typologies = this.selected.typologies.map((y) => y.id);
                    else if (this.selected.mode == "add")
                        typologies = _.uniq(
                            x.typologies
                                .map((y) => y.id)
                                .concat(this.selected.typologies.map((y) => y.id))
                        );

                    return {
                        id: x.id,
                        typologies: typologies,
                    };
                });
            }

            let resp = await http.post(url, data);

            if (resp.status == 200) {
                let data = await resp.json();
                showMessage(data);
            } else {
                showMessage("Bulk update failed", "danger");
            }

            selectedContent.forEach(x => {
                x.saving = false;
                x.selected = false;
            });

            await asyncTimeout(2000);
            await this.loadItems();
        },
    },
    computed: {
        showSave() {
            if (this.mode == MODE.STATE)
                return (
                    this.items.some(x => x.selected) &&
                    this.filters.libraries?.code &&
                    this.filters.state
                );
            else return this.items.some(x => x.selected);
        },
        transitions() {
            if (!this.filters.libraries) return [];

            if (!this.filters.state) return [];

            let codes = [];
            if (this.filters.libraries.enableWorkflows)
                codes = TRANSITIONS.managed[this.filters.state] || [];
            else
                codes = TRANSITIONS.unManaged[this.filters.state] || [];

            return codes.map(x => STATES.find((y) => y.code == x));
        },
        isStateSearch() {
            return this.filters.state == "P";
        },
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.application)
                filters.application = filters.application.code;

            if (filters.libraries)
                filters.libraries = filters.libraries.code;
            
            if (filters.creator)
                filters.creator = filters.creator.id;

            if (filters.group)
                filters.group = filters.group.name;

            if (this.filters.state) {
                if (this.isStateSearch) {
                    filters.state = this.filters.state;
                } else {
                    filters.wip_state = this.filters.state;
                    delete filters.state;
                }
            }

            return filters;
        },
        saving() {
            return this.items.some(x => x.saving);
        },
    },
    watch: {
        transitions: {
            immediate: true,
            handler(newTransitions) {
                if (newTransitions.length > 0) {
                    this.selected.state = newTransitions[0];
                }
            },
        },
        selectAll() {
            this.items.forEach(x => (x.selected = this.selectAll));
        },
    },
};
</script>

<style lang="sass" scoped>
.content-list
    em
        background-color: #ffff92
        font-weight: 400
</style>
