    <template>
    <bar-chart v-if="type == 'bar'"
        :chartData="data"
        :options="options" />
    <line-chart v-if="type == 'line'"
        :chartData="data"
        :options="options" />
</template>

<script>
import { Chart, registerables } from "chart.js";
import 'chartjs-adapter-moment';
import { BarChart, LineChart } from "vue-chart-3";

Chart.register(...registerables);

export default {
    components: { BarChart, LineChart },
    props: {
        data: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: false,
        },
        type: {
            type: String,
            default: 'bar',
        },
    },
};
</script>
