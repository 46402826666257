<template>
    <div class="field is-grouped is-grouped-multiline">
        <div class="field has-addons control">
            <div class="control"
                title="Constrain field name to list of choices">
                <button class="button"
                    @click="constrainNameChoice = false"
                    v-if="constrainNameChoice">
                    <span class="icon">
                        <span class="mdi mdi-checkbox-marked-outline"></span>
                    </span>
                </button>
                <button class="button"
                    @click="constrainNameChoice = true"
                    v-else>
                    <span class="icon">
                        <span class="mdi mdi-checkbox-blank-outline"></span>
                    </span>
                </button>
            </div>
            <div class="control is-expanded"
                v-if="constrainNameChoice">
                <div class="select is-fullwidth">
                    <select v-model="obj.name">
                        <option v-for="choice in choices">{{ choice }}</option>
                    </select>
                </div>
            </div>
            <div class="control"
                v-else>
                <input v-model="obj.name"
                    type="text"
                    class="input">
            </div>
        </div>
        <div class="control">
            <div class="select">
                <select v-model="obj.negate">
                    <option :value="false">is</option>
                    <option :value="true">is not</option>
                </select>
            </div>
        </div>
        <div class="control">
            <div class="select">
                <select v-model="obj.op">
                    <option v-for="op in info.ops"
                        :value="op.key">{{ op.label }}</option>
                </select>
            </div>
        </div>
        <div class="control">
            <input v-model="obj.value"
                placeholder="Enter value"
                type="text"
                class="input">
        </div>
        <div class="control"
            v-if="!valueIsObject">
            <div class="select">
                <select v-model="obj.typeFunction">
                    <option v-for="typeFunction in info.typeFunctions"
                        :value="typeFunction.func">{{ typeFunction.label }}</option>
                </select>
            </div>
            <div v-if="obj.error"
                class="help is-danger">
                {{ obj.error }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['obj', 'info', 'section', 'summary'],
    data() {
        return {
            objectValue: '',
            constrainNameChoice: true,
        }
    },
    created() {
        if (this.valueIsObject)
            this.objectValue = JSON.stringify(this.obj.value, null, 4);
    },
    computed: {
        typeSummary() {
            if (this.summary)
                return this.summary[this.section.type];
            else
                return null;
        },
        choices() {
            if (this.typeSummary && this.typeSummary.keys) {
                let keys = this.typeSummary.keys.map(x => x.name);
                keys.unshift('sub_type');
                keys.unshift('name');
                return keys;
            } else {
                return [];
            }
        },
        valueIsObject() {
            return typeof this.obj.value == 'object';
        },
    },
    watch: {
        objectValue() {
            if (this.valueIsObject) {
                this.obj.value = JSON.parse(this.objectValue);
                this.errorMessage = '';
            }
        },
        'obj.name': {
            handler() {
                let matchingType = this.typeSummary.keys.find(x => x.name == this.obj.name);
                if (!matchingType)
                    return;

                let typeName = matchingType.type;
                let typeFunction = this.info.typeFunctions.find(x => x.label == typeName);

                if (typeFunction)
                    this.obj.typeFunction = typeFunction.func;
            }
        },
        summary() {
            if (this.choices && this.obj.name && !this.choices.includes(this.obj.name))
                this.constrainNameChoice = false;
        }
    },
}
</script>