<template>
    <div class="panel">
        <div class="panel-heading">
            {{ tasks.length || 'No' }} {{ tasks.length == 1 ? 'Waiting Task' : 'Waiting Tasks' }}
        </div>
        <div class="panel-block is-block is-clearfix"
            v-for="task in tasks">
            <div>
                <a @click="deleteTask(task)"
                    class="is-pulled-right"
                    title="Delete task">
                    <span class="mdi mdi-close-thick"></span>
                </a>
                <code>
                    {{ task.task }}
                </code>
                <template v-if="task.targetVersion">&nbsp;→&nbsp;{{ task.targetVersion }}</template>
            </div>
            <div>
                <span class="mr-1">
                    Priority: {{ task.priorityName }}
                </span>
                <a v-if="task.priority != 1"
                    @click="raisePriority(task)"
                    title="Raise priority of task">
                    <span class="mdi mdi-arrow-up-bold"></span>
                </a>
                <a v-if="task.priority != 3"
                    @click="lowerPriority(task)"
                    title="Lower priority of task">
                    <span class="mdi mdi-arrow-down-bold"></span>
                </a>
            </div>
            <div>
                <small>
                    Created {{ task.created.fromNow() }}
                </small>
            </div>
        </div>
    </div>
    <teleport to="body">
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </teleport>
</template>

<script>
import http from "@/http";
import Task from "@/bim/models/Task";
import ConfirmDialog from "@/core/components/ConfirmDialog";

export default {
    props: ["content"],
    components: { ConfirmDialog },
    data() {
        return {
            tasks: [],
        };
    },
    async mounted() {
        await this.LoadTasks();
    },
    methods: {
        async raisePriority(task) {
            if (task.priority == 1)
                return;

            let priority = task.priority - 1;
            await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/${task.id}/`, { priority });
            await this.LoadTasks();
        },
        async lowerPriority(task) {
            if (task.priority == 3)
                return;

            let priority = task.priority + 1;
            await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/${task.id}/`, { priority });
            await this.LoadTasks();
        },
        async deleteTask(task) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete task',
                message: 'Are you sure you want to delete this task? ',
            });

            if (!ok)
                return

            const resp = await http.delete(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/${task.id}/`);
            await this.LoadTasks();
        },
        async LoadTasks() {
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/bim/tasks/?content=${this.content.id}&state=W`)
                .then(resp => resp.json());
            this.tasks = data.results.map(x => new Task(x));
        },
    }
};
</script>
