<template>
    <span class="is-pulled-right">
        <a @click="mode = 'D'"
            v-if="mode == 'M'"
            class="button">Show disciplines</a>
        <a @click="mode = 'M'"
            v-if="mode == 'D'"
            class="button">Show models</a>
    </span>
    <h4 class="subtitle">Summary</h4>
    <div class="audit-summary">
        <table class="table is-fullwidth">
            <thead>
                <th>
                    <span v-if="audit.type == 'M' && mode == 'M'">Model</span>
                    <span v-if="audit.type == 'C' && mode == 'M'">Content</span>
                    <span v-if="mode == 'D'">Discipline</span>
                </th>
                <th v-if="mode == 'M'">Extraction Date</th>
                <th>Score</th>
            </thead>
            <tbody>
                <tr v-for="row in summaryRows">
                    <td>
                        {{ row.name }}
                    </td>
                    <td v-if="mode == 'M'">{{ row.date?.fromNow() }}</td>
                    <td>
                        <progress-bar v-model="row.score"></progress-bar>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ProgressBar from "@/core/components/ProgressBar";

export default {
    props: ['audit'],
    components: { ProgressBar },
    data() {
        return {
            mode: 'M',
        }
    },
    computed: {
        summaryRows() {
            if (this.mode == 'M')
                return this.audit.summary;
            else
                return this.audit.disciplineSummary;
        }
    }
}
</script>

<style lang="sass">
.audit-summary
    max-height: 500px
    overflow-y: auto
</style>
