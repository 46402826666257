import BaseData from "@/core/models/BaseData";
import Parameter from "./Parameter";
import store from "@/store";


class ParameterCollection extends BaseData {
    constructor(data) {
        super(data);

        
        if (this.parameters) {
            this.parameters = this.parameters.map(x => new Parameter(x));
        } else {
            this.parameters = [];
        }

        if (this.library) {
            store.dispatch("content/loadLibraryList");
            this.library = store.getters["content/getLibraryByCode"](this.library);
        }
    }
}

export default ParameterCollection;
