<template>
    <li :class="classObject">
        <a @click="toggleChildren">
            <span class="icon">
                <span v-if="loading"
                    class="mdi mdi-refresh mdi-spin"></span>
                <template v-else>
                    <span v-if="typology.isLeaf"
                        class="mdi mdi-chevron-down mdi-rotate-45"></span>
                    <template v-else>
                        <span v-if="showChildren"
                            class="mdi mdi-chevron-down"></span>
                        <span v-if="!showChildren"
                            class="mdi mdi-chevron-right"></span>
                    </template>
                </template>
            </span>
            {{ typology }}
        </a>
        <a @click="select"
            v-if="typology.level > 0">
            <span class="icon">
                <span v-if="typology.selected"
                    class="mdi mdi-check-circle-outline"></span>
                <span v-else
                    class="mdi mdi-circle-outline"></span>
            </span>
        </a>
        <ul class="typologies"
            v-for="child in typology.children"
            v-if="showChildren">
            <typology-node :typology="child"
                :handler="handler"
                :preselected="preselected">
            </typology-node>
        </ul>
    </li>
</template>

<script>
import http from "@/http";
import ContentTypology from '../models/ContentTypology';

export default {
    props: ['typology', 'handler', 'preselected'],
    data() {
        return {
            showChildren: false,
            loading: false,
        }
    },
    methods: {
        async toggleChildren() {
            this.showChildren = !this.showChildren;

            if (!this.typology.isLeaf && !this.typology.children.length) {
                this.loading = true;
                const params = {
                    parent: this.typology.id,
                    level: this.typology.level + 1,
                    limit: 300
                }
                let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/typologies/`, params)
                    .then(resp => resp.json());

                this.typology.children = data.results.map(x => {
                    let child = new ContentTypology(x);

                    if (this.preselected)
                        child.selected = this.preselected.includes(child.id);

                    return child;
                });

                this.loading = false;
            }

            if (this.typology.isLeaf)
                this.select();
        },
        select() {
            this.typology.selected = !this.typology.selected;
            this.handler(this.typology);
        }
    },
    computed: {
        classObject() {
            let obj = {};
            obj[`level-${this.typology.level}`] = true;
            obj.selected = this.typology.selected;
            obj.leaf = !obj.branch;
            return obj;
        }
    },
    watch: {
        preselected(val) {
            if (val)
                this.typology.selected = this.preselected.includes(this.typology.id);
        }
    }
}

</script>

<style lang="sass">
ul.typologies
    li.level-0
        margin: 0
    li
        margin-left: 1em
</style>