<template>
    <div>
        <div class="field">
            <label class="label">Element type:</label>
            <div class="select control">
                <select v-model="section.type">
                    <option v-for="type in info.types"
                        :value="type">{{ type.toUpperCase() }}</option>
                </select>
            </div>
        </div>
        <div class="field">
            <label class="label">Rule criteria:</label>
            <div v-for="key in section.keys"
                v-if="section.type"
                class="field">
                <a class="button is-ghost is-pulled-right"
                    title="Remove criteria"
                    @click="removeKey(key)">
                    <span class="icon is-small">
                        <span class="mdi mdi-minus-circle"></span>
                    </span>
                    <span>
                        Remove criteria
                    </span>
                </a>
                <audit-rule-key-node :obj="key"
                    :info="info"
                    :section="section"
                    :summary="summary" />
            </div>
        </div>
        <a class="button is-ghost is-pulled-right"
            @click="addBlankKey"
            v-if="section.type">
            <span class="icon is-small">
                <span class="mdi mdi-plus-circle"></span>
            </span>
            <span>
                Add criteria
            </span>
        </a>
        <div>
            <a @click="showPreview = false"
                v-if="showPreview"
                class="button is-ghost">
                <span class="icon is-small">
                    <span class="mdi mdi-chevron-up-circle"></span>
                </span>
                <span>
                    Hide raw query
                </span>
            </a>
            <a @click="showPreview = true"
                class="button is-ghost"
                v-else>
                <span class="icon is-small">
                    <span class="mdi mdi-chevron-down-circle"></span>
                </span>
                <span>
                    Show raw query
                </span>
            </a>
        </div>
        <pre v-if="showPreview"
            class="mt-3">{{ section.completedSection() }}</pre>
    </div>
</template>

<script>
import http from "@/http";
import AuditRuleSectionKey from '../models/AuditRuleSectionKey';
import AuditRuleKeyNode from './AuditRuleKeyNode';

export default {
    props: ['section', 'info'],
    components: { AuditRuleKeyNode },
    data() {
        return {
            showPreview: false,
            summary: {},
        }
    },
    created() {
        if (this.section.type)
            this.loadSummaryDetail(this.section.type);
    },
    methods: {
        removeKey(key) {
            this.section.keys = this.section.keys.filter(x => x != key);
        },
        addKey(name, value, negate) {
            let key = new AuditRuleSectionKey(null, null, null, null);
            key.name = name || '';
            key.value = value || '';
            key.negate = negate || false;
            this.section.keys.push(key);
        },
        addBlankKey() {
            this.addKey('', '', false);
        },
        async loadSummaryDetail(type) {
            let json = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/elements-summary-detail/`, { type: type })
                .then(resp => resp.json());

            this.summary[type] = json;

            // Reassign summary so that child components update
            let summary = Object.assign({}, this.summary);
            this.summary = summary;
        }
    },
    watch: {
        'section.type': function (val) {
            this.loadSummaryDetail(val);
        },
    }
}
</script>