<template>
    <span v-if="userHasPermission">
        <router-link v-if="report?.id"
            :to="{ name: 'report-detail', params: { id: report.id } }"
            class="button"
            target="_blank">
            <span class="icon">
                <span :class="report.iconClass"
                    class="mdi"></span>
            </span>
            <span>
                Open report
            </span>
        </router-link>
        <span v-else>
            <a v-if="processing"
                class="button"
                disabled>
                <span class="icon">
                    <span class="mdi mdi-refresh mdi-spin"></span>
                </span>
                <span>
                    Processing report
                </span>
            </a>
            <a v-else
                @click="generate"
                class="button">
                <span class="icon">
                    <span :class="report.iconClass"
                        class="mdi"></span>
                </span>
                <span>
                    Generate {{ report.formatName }} report
                </span>
            </a>
        </span>
    </span>
    <span v-else
        class="button"
        disabled>
        <span class="icon">
            <span :class="report.iconClass"
                class="mdi"></span>
        </span>
        <span>
            You are not able to generate a report
        </span>
    </span>
</template>

<script>
import http from "@/http";
import Report from '../models/Report';
import { PERMISSIONS } from '@/constants';

export default {
    props: {
        type: String,
        format: String,
        data: Object,
        project: String,
    },
    data() {
        return {
            processing: false,
            report: null,
            userHasPermission: false,
        }
    },
    async created() {
        this.report = new Report({ type: this.type, format: this.format });

        await this.$store.dispatch("users/loadCurrentUser");
        let user = this.$store.state.users.currentUser;
        this.userHasPermission = user.hasPermission(PERMISSIONS.ADD_REPORT);
    },
    methods: {
        async generate() {
            this.processing = true;

            const reportData = {
                project_number: this.project,
                type: this.type,
                format: this.format,
                data: this.data,
            };

            let data = await http.post(`${process.env.VUE_APP_API_URL_V1}/core/reports/`, reportData)
                .then(resp => resp.json());
            this.report = new Report(data);

            this.processing = false;
        },
    },
}
</script>