import http from '@/http';


export default {
    namespaced: true,
    state: {
        elementTypes: {},
        rules: {},
    },
    actions: {
        async loadElementTypes({ commit, state }, application) {
            if (state.elementTypes[application])
                return;

            let results = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/elements-summary/`, {application})
                .then(resp => resp.json());
            commit('SET_ELEMENT_TYPES', { data: results.types, application });
        },
        async loadRuleById({ commit, state }, id) {
            if (state.rules[id])
                return;

            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/audits/rules/${id}/`)
                .then(resp => resp.json());
            commit('SET_RULE', { data });
        }
    },
    mutations: {
        'SET_ELEMENT_TYPES': (state, { data, application }) => {
            state.elementTypes[application] = data;
        },
        'SET_RULE': (state, { data }) => {
            state.rules[data.id] = data;
        },
    },
    getters: {
        getElementTypes: state => (application) => state.elementTypes[application],
        getRuleById: state => (id) => state.rules[id],
    }
}
