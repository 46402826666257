<template>
    <h2 class="title">Change password</h2>
    <div class="columns">
        <div class="column is-half">
            <FormKit type="form"
                submit-label="Save password"
                @submit="save">
                <FormKit type="password"
                    placeholder="Current password"
                    validation="required"
                    name="old_password"></FormKit>
                <FormKit type="password"
                    placeholder="New password"
                    validation="required"
                    name="password"></FormKit>
                <FormKit type="password"
                    placeholder="Confirm new password"
                    validation="required|confirm"
                    validation-label="Password confirmation"
                    name="password_confirm"></FormKit>
            </FormKit>
            <div class="notification mt-4">
                Note: This is for <em>local accounts only</em>,
                to change your Autodesk password follow the instructions
                <a
                    href="https://knowledge.autodesk.com/customer-service/account-management/account-profile/account-security/reset-password-for-autodesk-account">
                    here
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { FormKit } from '@formkit/vue';
import { showMessage, formatErrorMessage } from '@/utils';
import http from "@/http";

export default {
    components: { FormKit },
    methods: {
        async save(form) {
            let resp = await http.post(`${process.env.VUE_APP_API_URL_V1}/auth/password/change/`, {
                old_password: form.old_password,
                new_password1: form.password,
                new_password2: form.password_confirm,
            });
            if (resp.status == 200) {
                showMessage('Your password has been changed')
                this.$router.push({ name: 'home' });
            } else {
                let data = await resp.json();
                showMessage(formatErrorMessage(data), 'danger');
            }
        },
    }
}
</script>