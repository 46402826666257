<template>
    <div class="control">
        <div class="field is-grouped is-grouped-multiline">
            <autocomplete @selected="handleTypologySelect"
                :placeholder="'Select classification'"
                :load-results="loadTypologies"
                :result-namer="x => x">
            </autocomplete>
            <span class="control tags p-3"
                v-if="showResults">
                <span v-for="typology in typologies"
                    class="tag">
                    {{ typology }}
                    <button class="delete is-small"
                        @click="removeTypology(typology)">
                    </button>
                </span>
            </span>
        </div>
    </div>
</template>
 
<script>
import http from "@/http";

import Autocomplete from "@/core/components/Autocomplete.vue";
import ContentTypology from "../models/ContentTypology";

export default {
    props: {
        modelValue: Array,
        showResults: {
            default: true,
        }
    },
    emits: ['update:modelValue'],
    components: { Autocomplete },
    data() {
        return {
            typologies: [],
        }
    },
    created() {
        this.typologies = this.modelValue || [];
    },
    methods: {
        async loadTypologies(q) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V2}/content/typologies/?q=${q}`)
                .then(resp => resp.json());
            return data.results.map(x => new ContentTypology(x));
        },
        handleTypologySelect(typology) {
            if (!typology)
                return;

            if (!this.typologies.includes(typology))
                this.typologies.push(typology);
        },
        removeTypology(typology) {
            this.typologies = this.typologies.filter(x => x != typology);
        },
    },
    watch: {
        typologies(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.typologies = val;
        },
    },
}
</script>