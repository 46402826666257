<template>
    <div class="select">
        <select v-model="selectedCategory">
            <option :value="null">All Categories</option>
            <option v-for="cat in categories" :key="cat" :value="cat">{{ cat }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedCategory: null,
        }
    },
    created() {
        this.selectedCategory = this.modelValue;
        this.$store.dispatch("content/loadCategoriesList");
    },
    computed: {
        categories() {
            return this.$store.getters["content/getCategories"];
        },
    },
    watch: {
        selectedCategory(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedCategory = val;
        }
    }
}
</script>