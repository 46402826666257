<template>
    <div v-if="notification">
        <h2 class="title">
            <span v-if="$route.params.id">Edit</span>
            <span v-else>Add</span>
            Notification
        </h2>
        <div class="columns">
            <div class="column is-half">
                <div class="field">
                    <label class="label">Notification text:</label>
                    <div class="control">
                        <textarea v-model="notification.text"
                            class="textarea"></textarea>
                    </div>
                </div>
                <div class="field is-grouped">
                    <select-component v-model="notification.targets"
                        :choices="targets"
                        :multiple="true"
                        label="Targets"></select-component>

                    <select-component v-model="notification.level"
                        :choices="levels"
                        label="Level"
                        class="ml-3"></select-component>
                </div>
                <div class="field is-grouped">
                    <div class="field">
                        <label class="label">Start:</label>
                        <div class="control">
                            <input v-model="notification.start"
                                type="date"
                                class="input">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">End:</label>
                        <div class="control">
                            <input v-model="notification.end"
                                type="date"
                                class="input">
                        </div>
                    </div>
                    <div class="field">
                        <label class="checkbox">
                            Dismissable:
                            <input v-model="notification.dismissable"
                                type="checkbox">
                        </label>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Project:</label>
                    <project-autocomplete v-model="notification.project">
                    </project-autocomplete>
                </div>
                <div class="field">
                    <label class="label">Library:</label>
                    <content-library-select v-model="notification.library"
                        :show-none="true">
                    </content-library-select>
                </div>
            </div>
        </div>
        <button @click="disable"
            class="button is-pulled-right">Disable this notification</button>
        <save-button @click="save"
            :saving="saving">Submit</save-button>
    </div>
</template>

<script>
import { APPLICATIONS } from "@/constants";
import ContentLibrarySelect from '@/content/components/ContentLibrarySelect';
import http from "@/http";
import ProjectAutocomplete from '@/projects/components/ProjectAutocomplete';
import { showMessage } from "@/utils";
import moment from "moment";
import Notification from '../models/Notification';
import SaveButton from './SaveButton';
import SelectComponent from './SelectComponent';

export default {
    components: {
        SelectComponent,
        ProjectAutocomplete,
        ContentLibrarySelect,
        SaveButton,
    },
    data() {
        return {
            notification: null,
            targets: {
                W: 'Web',
                C: 'Content Lab',
            },
            levels: {
                D: 'Debug',
                I: 'Info',
                W: 'Warn',
                E: 'Error',
            },
            saving: false,
        }
    },
    async created() {
        Object.assign(this.targets, APPLICATIONS);

        const notificationId = this.$route.params.id;
        if (notificationId) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/core/notifications/${notificationId}/`)
                .then(resp => resp.json())
            this.notification = new Notification(data);
        } else {
            let projectId = this.$route.query.project;
            if (projectId)
                projectId = parseInt(projectId);

            let libraryId = this.$route.query.library;
            if (libraryId)
                libraryId = parseInt(libraryId);

            this.notification = new Notification({ library: libraryId, project: projectId });
        }

        if (this.notification.project) {
            await this.$store.dispatch('projects/loadProjectById', this.notification.project);
            this.notification.project = this.$store.getters['projects/getProjectById'](this.notification.project)
        }
    },
    methods: {
        async disable() {
            this.notification.end = moment().subtract(1, 'days').format('YYYY-MM-DD');
            this.save();
        },
        async save() {
            this.saving = true;

            let data = {
                dismissable: this.notification.dismissable,
                end: this.notification.end,
                level: this.notification.level,
                library: this.notification.library?.id,
                start: this.notification.start,
                targets: this.notification.targets,
                text: this.notification.text,
            }

            if (this.notification.project)
                data.projects = [this.notification.project.id]

            const notificationId = this.$route.params.id;
            if (notificationId) {
                let resp = await http.patch(`${process.env.VUE_APP_API_URL_V1}/core/notifications/${notificationId}/`, data);
                if (resp.status == 200) {
                    showMessage("Notification updated")
                    this.$router.push(this.successRoute);
                }

            } else {
                let resp = await http.post(`${process.env.VUE_APP_API_URL_V1}/core/notifications/`, data);
                if (resp.status == 201) {
                    showMessage("Notification created")
                    this.$router.push(this.successRoute);
                }
            }

            this.saving = false;
        }
    },
    computed: {
        successRoute() {
            if (this.$route.query.library)
                return { name: 'content-library-detail', params: { id: this.$route.query.library } };
            else if (this.$route.query.project)
                return { name: 'project-detail', params: { id: this.$route.query.project } };
            else
                return { name: 'notification-list' };
        },
    }
}
</script>