<template>
    <div v-if="contentRequest"
        class="columns">
        <div class="column is-half">
            <h2 class="title"
                v-if="mode == 'edit'">
                Edit Content Request
            </h2>
            <h2 class="title"
                v-else>Create Content Request
            </h2>
            <div class="field">
                <label class="label">
                    Library:
                </label>
                <content-library-select v-model="contentRequest.library"
                    :requests-enabled="true"
                    :show-none="true">
                </content-library-select>
            </div>
            <div class="field">
                <label class="label">
                    Description:
                </label>
                <div class="control">
                    <textarea v-model="contentRequest.description"
                        class="textarea">
            </textarea>
                </div>
            </div>
            <div class="field">
                <label class="label">
                    Assign Content Creator:
                </label>
                <content-creator-select v-model="contentRequest.creator"
                    :show-none="true">
                </content-creator-select>
            </div>
            <div class="field">
                <label class="label">
                    File Attachments:
                </label>
                <ul class="mb-4">
                    <li v-for="attachment in contentRequest.attachments"
                        :key="attachment.id">
                        <span class="icon">
                            <span class="mdi mdi-file-outline"></span>
                        </span>
                        <span>
                            {{ attachment.filename }}
                        </span>
                        <label class="checkbox is-pulled-right">
                            <input type="checkbox"
                                v-model="attachment.delete">
                            <span>
                                Delete
                            </span>
                        </label>
                    </li>
                </ul>
                <file-upload v-model="files"
                    :max-files="5"
                    :enable-upload="false"
                    :show-filenames="true"
                    :show-preview="false">
                </file-upload>
            </div>
            <save-button @click="save"
                :disabled="showButton"
                class="mt-2">
            </save-button>
        </div>
    </div>
</template>

<script>
import http from "@/http";

import FileUpload from "@/core/components/FileUpload";
import SaveButton from "@/core/components/SaveButton";
import ContentRequest from "../models/ContentRequest";
import ContentCreatorSelect from "./ContentCreatorSelect";
import ContentLibrarySelect from "./ContentLibrarySelect";

export default {
    components: {
        ContentCreatorSelect,
        ContentLibrarySelect,
        FileUpload,
        SaveButton,
    },
    data() {
        return {
            files: [],
            mode: 'add',
            contentRequest: null,
        }
    },
    async mounted() {
        if (this.$route.params.id) {
            this.mode = 'edit'
            let data = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/requests/${this.$route.params.id}/`)
                .then(resp => resp.json());
            this.contentRequest = new ContentRequest(data);
        } else {
            this.contentRequest = new ContentRequest();
        }
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
    },
    methods: {
        async save() {
            var formData = new FormData();

            formData.append('library', this.contentRequest.library.id);
            formData.append('description', this.contentRequest.description);

            if (this.contentRequest.creator)
                formData.append('creator', this.contentRequest.creator.id);

            this.files.forEach(x => formData.append('attachments', x.file));

            let deletedAttachments = this.contentRequest.attachments.filter(x => x.delete);
            if (deletedAttachments.length)
                formData.append('attachments_delete', deletedAttachments.map(x => x.id).join(','));

            let id = this.$route.params.id;
            if (id) {
                await http.patch(
                    `${process.env.VUE_APP_API_URL_V2}/content/requests/${this.$route.params.id}/`,
                    formData);
            } else {
                formData.append('author', this.user.id)
                let result = await http.post(
                    `${process.env.VUE_APP_API_URL_V2}/content/requests/`,
                    formData)
                    .then(resp => resp.json());
                id = result.id;
            }

            this.$router.push({
                name: 'content-request-detail',
                params: { id: id },
            });
        }
    },
    computed: {
        showButton() {
            return !(this.contentRequest?.library && this.contentRequest?.description)
        }
    },
}
</script>